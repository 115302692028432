export default {
  changePassword: {
    id: 'ACCOUNT_CHANGE_PASSWORD',
    request: ({ oldPassword, newPassword }) => ({
      query: `
        mutation ($oldPassword: String!, $newPassword: String!) {
          partner {
            changePassword(input: { data: { password: $newPassword, oldPassword: $oldPassword } }) {
              success
            }
          }
        }
      `,
      variables: {
        oldPassword,
        newPassword,
      },
    }),
    reduce: data => {
      return data.partner.changePassword
    },
  },
  update: {
    id: 'ACCOUNT_UPDATE',
    request: ({ firstName, lastName, lang }) => ({
      query: `
        mutation ($firstName: String, $lastName: String, $lang: LanguageEnum!) {
          account {
            update(input: { data: { firstName: $firstName, lastName: $lastName, lang: $lang } }) {
              firstName
              lastName
              lang
            }
          }
        }
      `,
      variables: {
        firstName,
        lastName,
        lang,
      },
    }),
    reduce: data => {
      return data.account.update
    },
  },
}
