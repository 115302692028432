import { HEUREKA } from '@/store/modules/heureka/types'
import { resetState } from '@/store/utils'
import createState from '@/store/modules/heureka/state'

export default {
  [HEUREKA.MUTATIONS.RUNNING_EXPORT]: (state, payload) => {
    state.isExportRunning = payload
  },
  [HEUREKA.MUTATIONS.GET_PLAN_RESPONSE]: (state, data) => {
    state.plan = data
  },
  [HEUREKA.MUTATIONS.GET_PARTNER_RESPONSE]: (state, data) => {
    state.partner = data
  },
  [HEUREKA.MUTATIONS.CATEGORY_SUMMARY_RESPONSE]: (state, payload) => {
    state.categorySummary = payload
  },
  [HEUREKA.MUTATIONS.BRAND_SUMMARY_RESPONSE]: (state, payload) => {
    state.partnerBrandList = payload
  },
  [HEUREKA.MUTATIONS.PRODUCT_SUMMARY_RESPONSE]: (state, payload) => {
    state.productSummary = payload
  },
  [HEUREKA.MUTATIONS.AVAILABILITY_SUMMARY_RESPONSE]: (state, payload) => {
    state.availabilitySummary = payload
  },
  [HEUREKA.MUTATIONS.SYNC_PRODUCT]: (state, payload) => {
    state.isSyncProduct = payload
  },
  [HEUREKA.MUTATIONS.SYNC_AVAILABILITY]: (state, payload) => {
    state.isSyncAvailability = payload
  },
  [HEUREKA.MUTATIONS.MALL_BRANDS_RESPONSE]: (state, payload) => {
    state.mallBrands = payload
  },
  [HEUREKA.MUTATIONS.GET_PRODUCT_LOG_RESPONSE]: (state, payload) => {
    state.productLog = payload
  },
  [HEUREKA.MUTATIONS.GET_AVAILABILITY_LOG_REQUEST]: (state, payload) => {
    state.availabilityLog = payload
  },
  [HEUREKA.MUTATIONS.IMPORT_PRODUCT_FROM_STREAM]: (state, payload) => {
    state.isSyncProduct = ['BEGIN', 'PROGRESS'].includes(payload.type)
  },
  [HEUREKA.MUTATIONS.IMPORT_AVAILABILITY_FROM_STREAM]: (state, payload) => {
    state.isSyncAvailability = ['BEGIN', 'PROGRESS'].includes(payload.type)
  },
  [HEUREKA.MUTATIONS.PRODUCT_FEED_IS_VALIDATING]: (state, payload) => {
    state.isValidatingProductFeed = payload
  },
  [HEUREKA.MUTATIONS.AVAILABILITY_FEED_IS_VALIDATING]: (state, payload) => {
    state.isValidatingAvailabilityFeed = payload
  },
  [HEUREKA.MUTATIONS.PARTNER_CATEGORY_TREE_RESPONSE]: (state, payload) => {
    state.partnerCategoryTree = payload
  },
  [HEUREKA.MUTATIONS.MALL_CATEGORY_LIST_RESPONSE]: (state, payload) => {
    state.mallCategoryList = payload
  },
  [HEUREKA.MUTATIONS.GET_PARTNER_CATEGORY_RESPONSE]: (state, payload) => {
    state.partnerCategoryDetail = payload
  },
  [HEUREKA.MUTATIONS.PARTNER_PARAM_LIST_RESPONSE]: (state, payload) => {
    state.paramList = payload
  },
  [HEUREKA.MUTATIONS.RESET]: state => {
    resetState(state, createState())
  },
}
