<script>
export default {
  name: 'FormMixin',
  props: {
    onSubmit: {
      type: Function,
      default: null,
    },
    isError: Boolean,
    errors: {
      type: Function,
      default: null,
    },
    isSending: Boolean,
  },
  methods: {
    createFileFormData(files) {
      let formData = new FormData()

      for (let i = 0; i < files.length; i++) {
        formData.append('files[' + i + ']', files[i])
      }

      return formData
    },
    createSingleFileFormData(files) {
      let formData = new FormData()

      if (files.length > 0) {
        formData.append('file', files[0])
      }

      return formData
    },
  },
}
</script>
