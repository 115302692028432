import { ACL } from '@/enums'

export const MALL_FEED = {
  FEED_SETTINGS: 'mall-settings',
  FEED_PRODUCT: 'mall-product',
}

export default [
  {
    path: '',
    name: MALL_FEED.FEED_SETTINGS,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "mall-feed" */ '../../../views/app/mall-feed/MallFeedSettingPage'
      ),
    meta: {
      resource: ACL.MALL_FEED,
    },
  },
  {
    path: 'stats',
    name: MALL_FEED.FEED_PRODUCT,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "mall-feed" */ '../../../views/app/mall-feed/MallFeedProductPage'
      ),
    meta: {
      resource: ACL.MALL_FEED,
    },
  },
]
