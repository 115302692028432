export const DELIVERIES = {
  NAMESPACE: 'deliveries',
  ACTIONS: {
    DELETE_DELIVERY_REQUEST: 'DELETE_DELIVERY_REQUEST',
    GET_DELIVERY_REQUEST: 'GET_DELIVERY_REQUEST',
    GET_AVAILABLE_DELIVERIES_REQUEST: 'GET_AVAILABLE_DELIVERIES_REQUEST',
    UPDATE_DELIVERY_REQUEST: 'UPDATE_DELIVERY_REQUEST',
    CREATE_DELIVERY_REQUEST: 'CREATE_DELIVERY_REQUEST',
    GET_TRANSPORT_LIST_REQUEST: 'GET_TRANSPORT_LIST_REQUEST',
    GET_DELIVERIES_ALERTS_REQUEST: 'GET_DELIVERIES_ALERTS_REQUEST',
    UPDATE_MALL_DELIVERY_STATUS_REQUEST: 'UPDATE_MALL_DELIVERY_STATUS_REQUEST',
    GET_MALL_DELIVERY_STATUS_REQUEST: 'GET_MALL_DELIVERY_STATUS_REQUEST',
    GET_MALL_DELIVERY_ACTIVE_REQUEST: 'GET_MALL_DELIVERY_ACTIVE_REQUEST',
    GET_DESTINATION_BRANCHES_REQUEST: 'GET_DESTINATION_BRANCHES_REQUEST',
    GET_ULOZENKA_REGISTRATION_BRANCHES_REQUEST: 'GET_ULOZENKA_REGISTRATION_BRANCHES_REQUEST',
    GET_WAREHOUSE_CALENDAR_REQUEST: 'GET_WAREHOUSE_CALENDAR_REQUEST',
    UPDATE_WAREHOUSE_CALENDAR_REQUEST: 'UPDATE_WAREHOUSE_CALENDAR_REQUEST',
    UPDATE_DELIVERY_MDP_REQUEST: 'UPDATE_DELIVERY_MDP_REQUEST',
  },
  MUTATIONS: {
    GET_DELIVERY_RESPONSE: 'GET_DELIVERY_RESPONSE',
    GET_AVAILABLE_DELIVERIES_RESPONSE: 'GET_AVAILABLE_DELIVERIES_RESPONSE',
    GET_TRANSPORT_LIST_RESPONSE: 'GET_TRANSPORT_LIST_RESPONSE',
    GET_DELIVERIES_ALERTS_RESPONSE: 'GET_DELIVERIES_ALERTS_REQUEST',
    GET_MALL_DELIVERY_STATUS_RESPONSE: 'GET_MALL_DELIVERY_STATUS_RESPONSE',
    GET_MALL_DELIVERY_ACTIVE_RESPONSE: 'GET_MALL_DELIVERY_ACTIVE_RESPONSE',
    GET_DESTINATION_BRANCHES_RESPONSE: 'GET_DESTINATION_BRANCHES_RESPONSE',
    GET_ULOZENKA_REGISTRATION_BRANCHES_RESPONSE: 'GET_ULOZENKA_REGISTRATION_BRANCHES_RESPONSE',
    GET_WAREHOUSE_CALENDAR_RESPONSE: 'GET_WAREHOUSE_CALENDAR_RESPONSE',
    RESET: 'RESET',
  },
}
