export default {
  productsGrid: {
    id: 'PRODUCTS_GRID',
    useCpapiV2: true,
    request: ({ filter, sorter, paging, search }) => ({
      query: `
        query ($filter: [[MpApiProductsFilterInput]], $sorter: [MpApiProductsSorterInput], $paging: PaginationInput, $search: String) {
          products {
            grid(input: {filter: $filter, sorter: $sorter, paging: $paging, search: $search}) {
              items {
                id
                product_id
                title
                status
                stage
                in_stock
                category_id
                price
                fair_price
                purchase_price
                rrp
                variants_count
                has_variants
                url
                labels_count
                image_url
                brand_id
              }
              filter {
                column
                operator
                value
              }
              sorter {
                column
                direction
              }
              paging {
                page
                total
                itemsPerPage
                lastPage
                nextPage
                previousPage
              }
            }
          }
        }
      `,
      variables: {
        filter,
        sorter,
        paging,
        search,
      },
    }),
    reduce: data => {
      return data.products.grid
    },
  },
  categoryList: {
    id: 'CATEGORY_LIST',
    request: () => ({
      query: `
        query {
          categories {
            list {
              category_id
              title
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data.categories.list
    },
  },
  deleteProducts: {
    id: 'DELETE_PRODUCTS',
    request: ({ productIds }) => ({
      query: `
        mutation ($productIds: [String!]!) {
          products {
            delete(input: {productIds: $productIds}) {
              success
            }
          }
        }
      `,
      variables: {
        productIds,
      },
    }),
    reduce: data => {
      return data.products.delete.success
    },
  },
  activateProducts: {
    id: 'ACTIVATE_PRODUCTS',
    request: ({ productIds }) => ({
      query: `
        mutation ($productIds: [String!]!) {
          products {
            activateProducts(input: {productIds: $productIds}) {
              success
            }
          }
        }
      `,
      variables: {
        productIds,
      },
    }),
    reduce: data => {
      return data.products.activateProducts.success
    },
  },
  activateAllProducts: {
    id: 'ACTIVATE_ALL_PRODUCTS',
    request: () => ({
      query: `
        mutation {
          products {
            activateAllProducts {
              success
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data.products.activateAllProducts.success
    },
  },
  setStatus: {
    id: 'SET_STATUS',
    request: ({ productId, status }) => ({
      query: `
        mutation ($productId: String!, $status: MpApiProductStatusEnum!) {
          products {
            setStatus(input: {productId: $productId, status: $status}) {
              id
            }
          }
        }
      `,
      variables: {
        productId,
        status,
      },
    }),
    reduce: data => {
      return data.products.setStatus
    },
  },
  brandList: {
    id: 'BRAND_LIST',
    request: () => ({
      query: `
        query {
          brands {
            list {
              brand_id
              title
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data.brands.list
    },
  },
  getProduct: {
    id: 'GET_PRODUCT',
    request: ({ productId }) => ({
      query: `
        query ($productId: String!) {
          products {
            get(input: {productId: $productId}) {
              id
              title
              shortdesc
              longdesc
              price
              force_price
              fair_price
              rrp
              weee_fee
              promotions {
                price,
                from,
                to,
                source
              }
              labels {
                label
                from
                to
              }
              vat
              brand_id
              category_id
              barcode
              priority
              recommended,
              availability {
                status,
                in_stock
              }
              delivery_delay
              free_delivery
              package_size
              dimensions {
                weight
                width
                length
                height
              }
              media {
                url,
                main
                energy_label
                information_list
              }
              parameters
              variable_parameters
              variants {
                url
                id
                title
                shortdesc
                longdesc
                priority
                package_size 
                barcode
                price
                force_price
                fair_price
                purchase_price
                rrp
                parameters
                media {
                  url
                  main
                  energy_label
                  information_list
                }
                promotions {
                  price
                  from
                  to
                  source
                }
                labels {
                  label
                  from
                  to
                }
                dimensions {
                  weight
                  width
                  length
                  height
                }
                availability {
                  status
                  in_stock
                }
                recommended
                delivery_delay
                free_delivery
                mallbox_allowed
                partner_title
              }
            }
          }
        }
      `,
      variables: {
        productId,
      },
    }),
    reduce: data => {
      return data.products.get
    },
  },
  getVariants: {
    id: 'GET_VARIANTS',
    request: ({ productId }) => ({
      query: `
        query ($productId: String!) {
          products {
            get(input: {productId: $productId}) {
              variants {
                id
                price
                url
                availability {
                  status
                  in_stock
                }
              }
            }
          }
        }
      `,
      variables: {
        productId,
      },
    }),
    reduce: data => {
      return data.products.get.variants
    },
  },
  getRecommended: {
    id: 'GET_RECOMMENDED',
    request: ({ filter, paging }) => ({
      query: `
        query ($filter: [[MpApiProductsFilterInput]], $paging: PaginationInput) {
          products {
            grid(input: {filter: $filter, paging: $paging}) {
              items {
                id
                title
                image_url
              }
            }
          }
        }
      `,
      variables: {
        filter,
        paging,
      },
    }),
    reduce: data => {
      return data.products.grid.items
    },
  },
  getRecommendedOptions: {
    id: 'GET_RECOMMENDED_OPTIONS',
    request: ({ paging, search }) => ({
      query: `
        query ($paging: PaginationInput, $search: String) {
          products {
            grid(input: {paging: $paging, search: $search}) {
              items {
                id
                title
                image_url
              }
            }
          }
        }
      `,
      variables: {
        paging,
        search,
      },
    }),
    reduce: data => {
      return data.products.grid.items
    },
  },
  updateProduct: {
    id: 'UPDATE_PRODUCT',
    request: ({ data, productId, forceToken }) => ({
      query: `
        mutation ($data: MpApiProductCreateInputData!, $productId: String!, $forceToken: String) {
          products {
            update(input: {data: $data, productId: $productId, forceToken: $forceToken}) {
              id
              barcode
              stage
              title
              url
              shortdesc
              longdesc
              category_id
              priority
              price
              purchase_price
              vat
              rrp
              weee_fee
              media {
                url
                main
              }
              promotions {
                price
                from
                to
                source
              }
              parameters
              variable_parameters
              dimensions {
                weight
                width
                length
                height
              }
              availability {
                status
                in_stock
              }
              labels {
                label
                from
                to
              }
              recommended
              delivery_delay
              free_delivery
              package_size
              mallbox_allowed
              brand_id
              variants {
                url
                id
                title
                shortdesc
                longdesc
                priority
                package_size
                barcode
                price
                purchase_price
                rrp
                parameters
                media {
                  url
                  main
                  energy_label
                  information_list
                }
                promotions {
                  price
                  from
                  to
                  source
                }
                labels {
                  label
                  from
                  to
                }
                dimensions {
                  weight
                  width
                  length
                  height
                }
                availability {
                  status
                  in_stock
                }
                recommended
                delivery_delay
                free_delivery
                mallbox_allowed
                partner_title
              }
            }
          }
        }
      `,
      variables: {
        data,
        productId,
        forceToken,
      },
    }),
    reduce: data => {
      return data.products.update
    },
  },
  uploadProductMedia: {
    id: 'UPLOAD_PRODUCT_MEDIA',
    request: ({ formData }) => ({
      url: '/media/upload',
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    }),
    reduce: data => {
      return data
    },
  },
  errorList: {
    id: 'ERROR_LIST',
    useCpapiV2: true,
    request: () => ({
      query: `
        query {
          products {
            checks {
              errors {
                code
                attribute
                value
                msg
                articles
              }
            }
          }
        }
      `,
    }),
    reduce: data => {
      return {
        items: data.products.checks.errors,
        paging: {
          page: 1,
          total: data.products.checks.errors.length,
          itemsPerPage: 100,
        },
        filter: [],
        sorter: [],
      }
    },
  },
  productBoxOverrideList: {
    id: 'PRODUCTS_BOX_OVERRIDE_LIST',
    useCpapiV2: true,
    request: ({ paging, filter }) => ({
      query: `
        query($paging: PaginationInput, $filter: [[ProductBoxOverridesGridFilterInput]]) {
          hci {
            productBoxOverrides(input: { paging: $paging, filter: $filter }) {
              items {
                id
                partnerId
                partnerProductId
                boxType
              }
              paging {
                page
                total
                itemsPerPage
                lastPage
                nextPage
                previousPage
              }
              filter {
                column
                value
                operator
              }
            }
          }
        }
      `,
      variables: {
        paging,
        filter,
      },
    }),
    reduce: data => {
      return data.hci.productBoxOverrides
    },
  },
  uploadProductBoxCsv: {
    id: 'PRODUCTS_UPLOAD_PRODUCT_BOX_CSV',
    request: ({ partnerId, data }) => ({
      url: `/hci-v2/partners/${partnerId}/box-overrides`,
      method: 'POST',
      headers: {
        'Content-Type': 'text/csv',
      },
      data: data,
    }),
  },
  deleteProductBoxOverride: {
    id: 'PRODUCTS_DELETE_BOX_OVERRIDE',
    request: ({ ids }) => ({
      query: `
        mutation ($ids: [String!]!) {
          hci {
            deleteProductBoxOverrides(input: {
              ids: $ids
            }) {
              success
            }
          }
        }
      `,
      variables: {
        ids,
      },
    }),
    reduce: data => {
      return data.hci.deleteProductBoxOverride
    },
  },
  deleteMediaOverride: {
    id: 'PRODUCTS_DELETE_MEDIA_OVERRIDE',
    request: ({ ids }) => ({
      query: `
        mutation ($ids: [String!]!) {
          hci {
            deleteProductMediaOverrides(input: {
              ids: $ids
            }) {
              success
            }
          }
        }
      `,
      variables: {
        ids,
      },
    }),
    reduce: data => {
      return data.hci.deleteProductMediaOverride
    },
  },
  uploadMediaCsv: {
    id: 'PRODUCTS_UPLOAD_MEDIA_CSV',
    request: ({ partnerId, data }) => ({
      url: `/hci-v2/partners/${partnerId}/media-overrides`,
      method: 'POST',
      headers: {
        'Content-Type': 'text/csv',
      },
      data: data,
    }),
  },
  productMediaOverrideList: {
    id: 'PRODUCTS_MEDIA_OVERRIDE_LIST',
    useCpapiV2: true,
    request: ({ paging, filter }) => ({
      query: `
        query($paging: PaginationInput, $filter: [[ProductMediaOverridesGridFilterInput]]) {
          hci {
            productMediaOverrides(input: { paging: $paging, filter: $filter }) {
              items {
                id
                partnerId
                partnerProductId
                url
                energyLabel
                informationList
              }
              paging {
                page
                total
                itemsPerPage
                lastPage
                nextPage
                previousPage
              }
              filter {
                column
                value
                operator
              }
            }
          }
        }
      `,
      variables: {
        paging,
        filter,
      },
    }),
    reduce: data => {
      return data.hci.productMediaOverrides
    },
  },
  labelsList: {
    id: 'LABELS_LIST',
    request: () => ({
      query: `
        query {
          products {
            labels {
              id
              title
              visible
            }
          }
        }
       `,
    }),
    reduce: data => {
      return data.products.labels
    },
  },
  productsExport: {
    id: 'PRODUCTS_EXPORT',
    request: () => ({
      url: `/portal/export/partner-products`,
      method: 'GET',
      responseType: 'blob',
    }),
  },
  productsImport: {
    id: 'PRODUCTS_IMPORT',
    request: data => ({
      url: `/portal/import/partner-products`,
      headers: {
        'Content-Type': 'application/xml',
      },
      method: 'POST',
      data: data,
    }),
  },
  getCategoryParams: {
    id: 'CATEGORY_PARAMS',
    request: ({ categoryId, language }) => ({
      query: `
        query ($categoryId: String!, $language: String!) {
          categories {
            parameterList(input: {categoryId: $categoryId, language: $language}) {
              value
              text
              measure_id
              mask
              options {
                value
                text
              }
              name
              class
              main
              mandatory_for_listing
              multiple
              unit
            }
          }
        }
      `,
      variables: {
        categoryId,
        language,
      },
    }),
    reduce: data => {
      return data.categories.parameterList
    },
  },
  createProduct: {
    id: 'CREATE_PRODUCT',
    request: ({ data }) => ({
      query: `
        mutation ($data: MpApiProductCreateInputData!) {
          products {
            create(input: {data: $data}) {
              id
              barcode
              stage
              title
              url
              shortdesc
              longdesc
              category_id
              priority
              price
              purchase_price
              vat
              rrp
              media {
                url
                main
              }
              promotions {
                price
                from
                to
                source
              }
              parameters
              variable_parameters
              dimensions {
                weight
                width
                length
                height
              }
              availability {
                status
                in_stock
              }
              labels {
                label
                from
                to
              }
              recommended
              delivery_delay
              free_delivery
              package_size
              mallbox_allowed
              brand_id
              variants {
                url
                id
                title
                shortdesc
                longdesc
                priority
                package_size
                barcode
                price
                purchase_price
                rrp
                parameters
                media {
                  url
                  main
                }
                promotions {
                  price
                  from
                  to
                  source
                }
                labels {
                  label
                  from
                  to
                }
                dimensions {
                  weight
                  width
                  length
                  height
                }
                availability {
                  status
                  in_stock
                }
                recommended
                delivery_delay
                free_delivery
                mallbox_allowed
                partner_title
              }
            }
          }
        }
      `,
      variables: {
        data,
      },
    }),
    reduce: data => {
      return data.products.create
    },
  },
  getCmtCategories: {
    id: 'GET_CMT_CATEGORIES',
    request: () => ({
      query: `
        query {
          categories {
            cmtCategoryTree
          }
        }
      `,
    }),
    reduce: data => {
      return data.categories.cmtCategoryTree
    },
  },
  getCategoryQueryRules: {
    id: 'GET_CATEGORY_QUERY_RULES',
    request: ({ query, language }) => ({
      query: `
        query ($query: String!, $language: String!) {
          categories {
            parseFulltextQuery(input: {query: $query, language: $language}) {
              result
            }
          }
        }
      `,
      variables: {
        query,
        language,
      },
    }),
    reduce: data => {
      return data.categories.parseFulltextQuery.result
    },
  },
}
