export default {
  vatList: {
    id: 'VAT_LIST',
    request: ({ filter, sorter, paging, search }) => ({
      query: `
        query ($filter: [[VatRatesGridFilterInput]], $sorter: [VatRatesGridSorterInput], $paging: PagingInput, $search: String!) {
          vatRates {
            vatRatesGrid (input: {filter: $filter, sorter: $sorter, paging: $paging, search: $search}) {
              items {
                id
                shop_id
                rate
                is_default
              }
              filter {
                column
                operator
                value
              }
              paging {
                page
                itemsPerPage
                total
                lastPage
                nextPage
                previousPage
              }
              sorter {
                column
                direction
              }
            }
          }
        }
      `,
      variables: {
        filter,
        sorter,
        paging,
        search,
      },
    }),
    reduce: data => {
      return data.vatRates.vatRatesGrid.items
    },
  },
}
