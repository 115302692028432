<template>
  <div class="d-flex flex-column h-100">
    <v-list dense class="pb-0" :class="{'flex-grow-1': !mobileLayout}">
      <template v-for="(item, key) in topList">
        <template v-if="item.visible === undefined || item.visible === true">
          <template v-if="item.children && item.children.length && !item.disabled">
            <nav-item-dropdown v-if="!miniVariant" :key="key" :item="item" />
          </template>
          <template v-else>
            <nav-item v-if="!miniVariant" :key="key" :item="item" />
          </template>
          <nav-item-menu v-if="miniVariant" :key="key" :item="item" />
        </template>
      </template>
    </v-list>
    <v-list dense class="py-0 mt-n1">
      <nav-item v-for="(item, key) in bottomList" :key="key" :item="item" />
    </v-list>
  </div>
</template>

<script>
import { ROUTES } from '@/router/routes'
import NavItem from '@/components/layout/components/nav/NavItem'
import NavItemDropdown from '@/components/layout/components/nav/NavItemDropdown'
import { mapState } from 'vuex'
import { AUTH } from '@/store/modules/auth/types'
import { ACL } from '@/enums/acl'
import { SHOP_ID } from '@/enums'
import NavItemMenu from '@/components/layout/components/nav/NavItemMenu'
import { WORKFLOW_RULES } from '@/enums/workflowRules'
import { FEFL } from '@/store/modules/fefl/types'

export default {
  name: 'Navigation',
  components: { NavItemDropdown, NavItem, NavItemMenu },
  props: {
    miniVariant: {
      type: Boolean,
      required: true,
    },
    mobileLayout: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      ROUTES,
    }
  },
  computed: {
    ...mapState(FEFL.NAMESPACE, ['fefl']),
    ...mapState(AUTH.NAMESPACE, ['acl', 'selectedPartner', 'workflowRules']),
    provider() {
      return this.selectedPartner ? this.selectedPartner.provider : null
    },
    isCzSk() {
      return this.selectedPartner ? [SHOP_ID.CZ10MA, SHOP_ID.SK10MA].includes(this.selectedPartner.shopId) : false
    },
    topList() {
      return this.menu.filter(item => !item.bottom)
    },
    bottomList() {
      return this.menu.filter(item => item.bottom)
    },
    hasSapId() {
      return !!this.selectedPartner?.sapId
    },
    menu() {
      return [
        {
          icon: 'home',
          title: 'navigation.home',
          routeName: ROUTES.HOME,
          external: false,
          disabled: false,
        },
        {
          icon: 'shopping_cart',
          title: 'navigation.orders',
          routeName: ROUTES.ORDERS.ORDERS_OVERVIEW,
          external: false,
          disabled: true,
        },
        {
          icon: 'rss_feed',
          title: 'navigation.heureka',
          routeName: ROUTES.HEUREKA.FEED_SETTINGS,
          external: false,
          disabled: true,
          visible: false,
        },
        {
          icon: 'rss_feed',
          title: 'navigation.feeds',
          routeName: ROUTES.MALL_FEED.FEED_SETTINGS,
          external: false,
          disabled: true,
          visible: false,
        },
        {
          icon: 'money',
          title: 'navigation.billing.title',
          routeName: ROUTES.BILLINGS.BILLINGS_INVOICES,
          external: false,
          disabled: true,
        },
        {
          icon: 'list',
          title: 'navigation.products',
          disabled: true,
          children: [
            {
              title: 'navigation.productList',
              routeName: ROUTES.PRODUCTS.PRODUCTS_OVERVIEW,
              external: false,
              disabled: true,
            },
            {
              title: 'navigation.categories',
              routeName: ROUTES.CATEGORIES,
              external: false,
              disabled: false,
            },
            {
              title: 'navigation.brands',
              routeName: ROUTES.BRANDS.BRANDS_OVERVIEW,
              external: false,
              disabled: true,
            },
          ],
        },
        {
          icon: 'local_shipping',
          title:
            this.selectedPartner && this.selectedPartner.transportV2
              ? 'navigation.deliveries.titleV2'
              : 'navigation.deliveries.title',
          disabled: true,
          children: [
            {
              title: 'navigation.deliveries.settings',
              routeName: ROUTES.DELIVERIES.DELIVERIES_OVERVIEW,
              external: false,
              disabled: true,
            },
            {
              title: 'navigation.deliveries.mall',
              routeName: ROUTES.DELIVERIES.MALL,
              external: false,
              disabled: true,
            },
          ],
        },
        {
          icon: 'work',
          title: 'navigation.partner',
          routeName: ROUTES.PARTNER.PARTNER_INFO_TAB,
          external: false,
          newFeature: true,
          disabled: true,
        },
        {
          icon: 'thumb_up',
          title: 'navigation.serviceQuality',
          routeName: ROUTES.SERVICE_QUALITY.CANCEL_RATE_TAB,
          external: false,
          disabled: false,
          visible: true,
          tag: 'navigation.tags.beta',
        } /*
        {
          icon: 'work',
          title: 'navigation.profile',
          disabled: true,
          children: [
            {
              title: 'navigation.serviceQuality',
              routeName: ROUTES.SERVICE_QUALITY.CANCEL_RATE_TAB,
              external: false,
              disabled: false,
              visible: true,
              tag: 'navigation.tags.beta',
            },
            {
              title: 'navigation.partner',
              routeName: ROUTES.PARTNER.PARTNER_INFO_TAB,
              external: false,
              disabled: true,
            },
          ],
        },*/,
        {
          icon: 'mdi-store',
          title: 'navigation.partnerServices',
          disabled: true,
          children: [
            {
              title: 'navigation.services',
              routeName: ROUTES.SERVICES.SERVICES,
              external: false,
              disabled: true,
            },
            {
              title: 'navigation.financing',
              routeName: ROUTES.FINANCING,
              external: false,
              disabled: true,
              tag: 'navigation.tags.new',
            },
          ],
        },
        {
          icon: 'pages',
          title: 'navigation.marketing.title',
          disabled: true,
          children: [
            {
              title: 'navigation.marketing.campaigns',
              routeName: ROUTES.CAMPAIGNS.LIST,
              external: false,
              disabled: true,
            },
            {
              title: 'navigation.marketing.partnerPage',
              routeName: ROUTES.MARKETING.PARTNER_PAGE,
              external: false,
              disabled: true,
            },
          ],
        },
        this.fefl &&
          this.fefl.statisticsMenu && {
            icon: 'equalizer',
            title: 'navigation.statistics.title',
            routeName: ROUTES.STATISTICS,
            external: false,
            disabled: true,
          },
        {
          icon: 'info',
          title: 'navigation.faq',
          routeName: ROUTES.FAQ.DEFAULT,
          external: false,
          disabled: true,
          bottom: true,
        },
      ].filter(Boolean)
    },
  },
  watch: {
    workflowRules: {
      handler() {
        this.recursiveMenu(this.menu)
      },
      deep: true,
    },
    acl: {
      handler() {
        this.recursiveMenu(this.menu)
      },
      deep: true,
    },
    fefl: {
      handler() {
        this.recursiveMenu(this.menu)
      },
      deep: true,
    },
    selectedPartner: {
      handler() {
        this.recursiveMenu(this.menu)
      },
      deep: true,
    },
  },
  created() {
    this.recursiveMenu(this.menu)
  },
  methods: {
    recursiveMenu(menu) {
      menu.forEach(item => {
        if (ROUTES.DELIVERIES.MALL === item.routeName) {
          item.visible = this.$can('read', ACL.MALL_DELIVERIES)
        }

        if (ROUTES.HEUREKA.FEED_SETTINGS === item.routeName) {
          item.visible = this.$can('read', ACL.HEUREKA_FEED)
        }

        if (ROUTES.MALL_FEED.FEED_SETTINGS === item.routeName) {
          item.visible = this.$can('read', ACL.MALL_FEED)
        }

        if (ROUTES.DELIVERIES.MALL === item.routeName) {
          item.visible = this.isCzSk && this.hasSapId
        }

        // @todo remove after removing old portal
        if (![ROUTES.HOME, ROUTES.USER.MESSAGES].includes(item.routeName)) {
          item.disabled = !(this.workflowRules && this.workflowRules[WORKFLOW_RULES.PORTAL_ACCESS])
        }

        if ([ROUTES.MARKETING.PARTNER_PAGE, ROUTES.FINANCING].includes(item.routeName)) {
          item.visible = this.isCzSk
        }

        if (item.children) {
          this.recursiveMenu(item.children)
        }
      })
    },
  },
}
</script>
