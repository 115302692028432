import { NOTIFICATIONS } from '@/store/modules/notifications/types'
import { callPortalGraphQL, getSelectedPartnerId } from '@/store/utils'
import { API } from '@/api'

export default {
  [NOTIFICATIONS.ACTIONS.NEW_APP_VERSION]: async ({ commit }) => {
    commit(NOTIFICATIONS.MUTATIONS.NEW_APP_VERSION, true)
  },
  [NOTIFICATIONS.ACTIONS.GET_SETTINGS_REQUEST]: async ({ dispatch, commit, rootGetters }) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.notifications.getSettings },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
      },
    })
    commit(NOTIFICATIONS.MUTATIONS.GET_SETTINGS_RESPONSE, data)
  },
  [NOTIFICATIONS.ACTIONS.UPDATE_SETTINGS_REQUEST]: async ({ dispatch, commit, rootGetters }, payload) => {
    try {
      const data = await callPortalGraphQL(dispatch, {
        requestData: { ...API.notifications.updateSettings },
        params: {
          partnerId: getSelectedPartnerId(rootGetters),
          data: payload.data,
          eventType: payload.eventType,
        },
        throwError: true,
      })
      commit(NOTIFICATIONS.MUTATIONS.UPDATE_SETTINGS_RESPONSE, data)

      return true
    } catch {
      return false
    }
  },
}
