import { CAMPAIGNS } from '@/store/modules/campaigns/types'
import { callApi, callPortalGraphQL, dispatchRoot, getSelectedPartnerId, withNamespace } from '@/store/utils'
import { API } from '@/api'
import { addSuccessMessage, addErrorMessage } from '@/services/flashMessages'
import { DATA_GRIDS } from '@/store/grid/grids'
import { GRID } from '@/store/grid/store/types'
import { downloader } from '@/services/downloader'

export default {
  [CAMPAIGNS.ACTIONS.LOAD_DETAIL]: async ({ dispatch, commit, rootGetters }, payload) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.campaigns.getCampaignDetail },
      params: {
        id: payload.id,
        partnerId: getSelectedPartnerId(rootGetters),
      },
    })
    commit(CAMPAIGNS.MUTATIONS.DETAIL_RESPONSE, data)
  },
  [CAMPAIGNS.ACTIONS.IMPORT_CSV]: async ({ dispatch, commit, rootGetters }, { formData, campaignId }) => {
    formData.append('partnerId', getSelectedPartnerId(rootGetters))
    try {
      const data = await callApi(dispatch, {
        requestData: { ...API.campaigns.uploadCsv },
        params: {
          formData: formData,
        },
        throwError: true,
      })
      addSuccessMessage(dispatch, API.campaigns.uploadCsv.id, 'campaigns.form.csv_uploaded', [data.products_count])
      commit(CAMPAIGNS.MUTATIONS.CSV_IMPORT_RESPONSE, data)
      commit(CAMPAIGNS.MUTATIONS.CSV_IMPORT_FAILED, {})
      dispatchRoot(dispatch, withNamespace(DATA_GRIDS.CAMPAIGN_IMPORTS, GRID.ACTIONS.GRID_FETCH), {
        namespace: DATA_GRIDS.CAMPAIGN_IMPORTS,
        params: {
          id: campaignId,
        },
      })
    } catch (data) {
      addErrorMessage(dispatch, API.campaigns.uploadCsv.id, 'campaigns.form.csv_import_errors')
      commit(CAMPAIGNS.MUTATIONS.CSV_IMPORT_FAILED, data.response.data)
    }
  },
  [CAMPAIGNS.ACTIONS.SET_IMPORT_TO_PROCESS]: async ({ dispatch, rootGetters }, data) => {
    await callApi(dispatch, {
      requestData: { ...API.campaigns.setImportToProcess },
      params: {
        importId: data.importId,
        partnerId: getSelectedPartnerId(rootGetters),
        campaignId: data.campaignId,
        status: 'new',
        allowedToImport: true,
      },
    })
    addSuccessMessage(dispatch, API.campaigns.uploadCsv.id, 'campaigns.form.csv_set_to_import')
    dispatchRoot(dispatch, withNamespace(DATA_GRIDS.CAMPAIGN_IMPORTS, GRID.ACTIONS.GRID_FETCH), {
      namespace: DATA_GRIDS.CAMPAIGN_IMPORTS,
      params: {
        id: data.campaignId,
      },
    })
    dispatch(CAMPAIGNS.ACTIONS.LAST_IMPORT, { campaignId: data.campaignId })
    return true
  },
  [CAMPAIGNS.ACTIONS.LAST_IMPORT]: async ({ dispatch, commit, rootGetters }, payload) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.campaigns.lastImport },
      params: {
        campaignId: payload.campaignId,
        partnerId: getSelectedPartnerId(rootGetters),
      },
    })
    commit(CAMPAIGNS.MUTATIONS.LAST_IMPORT_DATA, data.campaignImports.last)
  },
  [CAMPAIGNS.ACTIONS.DOWNLOAD_IMPORT]: async ({ dispatch, rootGetters }, data) => {
    const fileData = await callApi(dispatch, {
      requestData: { ...API.campaigns.downloadImport },
      params: {
        campaignId: data.campaignId,
        importId: data.importId,
        partnerId: getSelectedPartnerId(rootGetters),
      },
    })

    downloader(fileData, `campaign-${data.campaignId}.txt`, 'text/plain; charset=utf-8;')
  },
}
