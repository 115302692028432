import { DIRECTION, createTableKey, createHeaderKey, createItemsPerPageKey } from '@/store/grid/utils'

const VERSION = '1.0.1'

export const createDefaultGridState = (namespace, defaultState = {}) => {
  return {
    version: VERSION,
    namespace,
    default: defaultState,
    // updatable fields
    filter: defaultState.filter || [],
    filterMeta: {},
    sorter: Object.prototype.hasOwnProperty.call(defaultState, 'sorter')
      ? defaultState.sorter
      : [
          {
            column: 'id',
            direction: DIRECTION.DESCENDING,
          },
        ],
    paging: {
      total: 0,
      nextPage: 0,
      previousPage: 0,
      lastPage: 0,
      // updatable fields
      page: 1,
      itemsPerPage: 50,
      ...defaultState.paging,
    },
    search: null,
    items: [],
    headersMeta: [],
    expandedItems: [],
    expandedFilter: [],
    personalFilters: [],
  }
}

export default (namespace, defaultState) => {
  let personalFilters = JSON.parse(localStorage.getItem(createTableKey(namespace)))
  let gridHeaderState = JSON.parse(localStorage.getItem(createHeaderKey(namespace)))
  let gridItemsPerPageState = JSON.parse(localStorage.getItem(createItemsPerPageKey(namespace)))

  const defaultGridState = createDefaultGridState(namespace, defaultState)

  let state = {}

  if (personalFilters && personalFilters.version === VERSION) {
    state = {
      ...personalFilters,
    }
  }

  if (gridHeaderState && gridHeaderState.version === VERSION) {
    state = {
      ...state,
      ...gridHeaderState,
    }
  }
  if (gridItemsPerPageState && gridItemsPerPageState.version === VERSION) {
    state = {
      ...state,
      paging: {
        ...defaultGridState.paging,
        itemsPerPage: gridItemsPerPageState.itemsPerPage,
      },
    }
  }
  return Object.assign(defaultGridState, state)
}
