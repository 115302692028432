export default {
  contract: {
    id: 'CONTRACT',
    request: ({ partnerId }) => ({
      query: `
          query ($partnerId: String!) {
            contract {
              get (input: {partnerId: $partnerId}) {
                id
                company 
                firstName 
                lastName 
                email 
                phone 
                in 
                bankAccount 
                street 
                city 
                state 
                zip 
                web 
                vatId 
              } 
            }
          }
        `,
      variables: {
        partnerId,
      },
    }),
    reduce: data => {
      return data.contract.get
    },
  },
  updateContract: {
    id: 'CONTRACT_UPDATE',
    request: ({ partnerId, data }) => ({
      query: `
          mutation ($partnerId: String!, $data: ContractInputData!) {
            contract {
              put (input: { partnerId: $partnerId, data: $data }) {
                company 
                firstName 
                lastName 
                email 
                phone 
                in 
                bankAccount 
                street 
                city 
                state 
                zip 
                web 
                vatId 
              } 
            }
          }
        `,
      variables: {
        partnerId,
        data,
      },
    }),
    reduce: data => {
      return data.contract.put
    },
  },
}
