export default {
  countries: {
    id: 'COUNTRIES',
    request: () => ({
      query: `
          query {
            countries {
              mallShop
              all
            }
          }
        `,
    }),
    reduce: data => {
      return data.countries
    },
  },
}
