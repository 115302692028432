import { API_LIMIT } from '@/store/modules/apiLimit/types'
import { resetState } from '@/store/utils'
import createState from '@/store/modules/apiLimit/state'

export default {
  [API_LIMIT.MUTATIONS.GET_PARTNER_DETAIL_API_LIMIT_RESPONSE]: (state, data) => {
    state.partnerDetailApiLimit = data
  },
  [API_LIMIT.MUTATIONS.GET_PARTNER_DETAIL_API_LIMIT_HISTORY_RESPONSE]: (state, data) => {
    state.partnerDetailApiLimitHistory = data
  },
  [API_LIMIT.MUTATIONS.GET_PARTNER_DETAIL_API_LIMIT_CURRENT_RESPONSE]: (state, data) => {
    state.partnerDetailApiLimitCurrent = data
  },
  [API_LIMIT.MUTATIONS.RESET]: state => {
    resetState(state, createState())
  },
}
