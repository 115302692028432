import { GRID } from '@/store/grid/store/types'
import { callPortalGraphQL, getSelectedPartnerId } from '@/store/utils'
import { GRID_REQUESTS } from '@/store/grid/grids'
import { prepareGridData } from '@/store/grid/utils'
import { createDefaultGridState } from '@/store/grid/store/state'
import { addSuccessMessage } from '@/services/flashMessages'

export default {
  [GRID.ACTIONS.GRID_FETCH]: async ({ dispatch, commit, state, rootGetters }, payload) => {
    if (!payload.namespace) {
      throw new Error('Table must have namespace.')
    }

    if (!GRID_REQUESTS[payload.namespace]) {
      throw new Error('Table must have request.')
    }

    const gridData = prepareGridData(state, payload)

    // @todo better solution
    gridData.partnerId = getSelectedPartnerId(rootGetters)

    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...GRID_REQUESTS[payload.namespace] },
      params: gridData,
    })
    data.search = gridData.search
    commit(GRID.MUTATIONS.GRID_RESPONSE, data)
    return data
  },
  [GRID.ACTIONS.GRID_FILTER]: async ({ dispatch, state }, payload) => {
    await dispatch(GRID.ACTIONS.GRID_FETCH, {
      namespace: payload.namespace,
      filter: payload.filter,
      filterMeta: payload.filterMeta,
      paging: {
        itemsPerPage: state.paging.itemsPerPage,
        page: 1,
      },
    })
  },
  [GRID.ACTIONS.GRID_FILTER_RESET]: async ({ dispatch, state }, payload) => {
    const defaultState = createDefaultGridState(payload.namespace, state.default)
    await dispatch(GRID.ACTIONS.GRID_FETCH, {
      namespace: payload.namespace,
      filter: defaultState.filter,
      filterMeta: defaultState.filterMeta,
      paging: {
        itemsPerPage: state.paging.itemsPerPage,
        page: 1,
      },
      search: defaultState.search,
    })
  },
  [GRID.ACTIONS.GRID_SEARCH]: async ({ dispatch, state }, payload) => {
    await dispatch(GRID.ACTIONS.GRID_FETCH, {
      namespace: payload.namespace,
      search: payload.search,
      paging: {
        page: 1,
        itemsPerPage: state.paging.itemsPerPage,
      },
    })
  },
  [GRID.ACTIONS.GRID_CHANGE_PAGING]: async ({ commit, dispatch }, payload) => {
    await dispatch(GRID.ACTIONS.GRID_FETCH, { namespace: payload.namespace, ...payload })
    commit(GRID.MUTATIONS.SAVE_ITEMS_PER_PAGE, payload.paging.itemsPerPage)
  },
  [GRID.ACTIONS.GRID_UPDATE_ROW]: ({ commit }, payload) => {
    commit(GRID.MUTATIONS.GRID_UPDATE_ROW, { index: payload.index, row: payload.row })
  },
  [GRID.ACTIONS.GRID_UPDATE_ROW_ITEM]: ({ commit }, payload) => {
    commit(GRID.MUTATIONS.GRID_UPDATE_ROW_ITEM, { index: payload.index, key: payload.key, value: payload.value })
  },
  [GRID.ACTIONS.GRID_FILTER_SAVE]: ({ dispatch, commit }, payload) => {
    commit(GRID.MUTATIONS.SAVE_FILTER, payload)
    addSuccessMessage(dispatch, 'filter', 'flashMessages.datagrid.filtersSaved')
  },
  [GRID.ACTIONS.GRID_FILTER_DELETE]: ({ commit }, payload) => {
    commit(GRID.MUTATIONS.DELETE_FILTER, payload)
  },
  [GRID.ACTIONS.GRID_HEADERS_SAVE]: ({ commit }, payload) => {
    commit(GRID.MUTATIONS.SAVE_HEADERS, payload)
  },
  [GRID.ACTIONS.GRID_TOGGLE_EXPAND_ROW]: ({ commit }, payload) => {
    commit(GRID.MUTATIONS.EXPAND_ROW, payload)
  },
  [GRID.ACTIONS.GRID_TOGGLE_EXPAND_FILTER]: ({ commit }) => {
    commit(GRID.MUTATIONS.TOGGLE_FILTER)
  },
}
