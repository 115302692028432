export const BILLINGS = {
  NAMESPACE: 'billings',
  ACTIONS: {
    EXPORT_BILLINGS_REQUEST: 'EXPORT_BILLINGS_REQUEST',
    DOWNLOAD_EXPORT_REQUEST: 'DOWNLOAD_EXPORT_REQUEST',
    GET_BILLINGS_STATUS_REQUEST: 'GET_BILLINGS_STATUS_REQUEST',
    BILLINGS_ACTIVATION_REQUEST: 'BILLINGS_ACTIVATION_REQUEST',
    INVOICE_FILE: 'INVOICE_FILE',
    GET_INVOICE_REQUEST: 'GET_INVOICE_REQUEST',
    OFFSET_PDF: 'OFFSET_PDF',
    GET_OFFSET_REQUEST: 'GET_OFFSET_REQUEST',
    OVERVIEW_FILE: 'OVERVIEW_FILE',
  },
  MUTATIONS: {
    RUNNING_EXPORT: 'RUNNING_EXPORT',
    GET_BILLINGS_STATUS_RESPONSE: 'GET_BILLINGS_STATUS_RESPONSE',
    GET_INVOICE_RESPONSE: 'GET_INVOICE_RESPONSE',
    GET_OFFSET_RESPONSE: 'GET_OFFSET_RESPONSE',
    RESET: 'RESET',
  },
  FILE_TYPES: {
    PDF: 'pdf',
    XLS: 'xlsx',
  },
}
