export const AUTH = {
  NAMESPACE: 'auth',
  GETTERS: {
    IS_LOGGED: 'IS_LOGGED',
    IS_CHECKED: 'IS_CHECKED',
    GET_LOGGED_ACCOUNT: 'GET_LOGGED_ACCOUNT',
    GET_REFRESH_TIME: 'GET_REFRESH_TIME',
    GET_LOGIN_AS_GIVEN_PARTNER: 'GET_LOGIN_AS_GIVEN_PARTNER',
    GET_SELECTED_PARTNER_ID: 'GET_SELECTED_PARTNER_ID',
    GET_SELECTED_ACCOUNT_ID: 'GET_SELECTED_ACCOUNT_ID',
    GET_SELECTED_PARTNER_SAP_ID: 'GET_SELECTED_PARTNER_SAP_ID',
    GET_SELECTED_PARTNER_SHOP_ID: 'GET_SELECTED_PARTNER_SHOP_ID',
  },
  ACTIONS: {
    CHECK_LOGGED_REQUEST: 'CHECK_LOGGED_REQUEST',
    SET_TOKEN: 'SET_TOKEN',
    WORKFLOW_RULES_REQUEST: 'WORKFLOW_RULES_REQUEST',
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGIN_AS_ADMIN_REQUEST: 'LOGIN_AS_ADMIN_REQUEST',
    REVOKE_CLIENT_ID_TOKEN: 'REVOKE_CLIENT_ID_TOKEN',
    ADD_CLIENT_ID_TOKEN: 'ADD_CLIENT_ID_TOKEN',
    LOGIN_AS_GIVEN_PARTNER: 'LOGIN_AS_GIVEN_PARTNER',
    GET_CLIENT_ID_REQUEST: 'GET_CLIENT_ID_REQUEST',
    SET_PASSWORD_CONFIRM_REQUEST: 'SET_PASSWORD_CONFIRM_REQUEST',
    CONFIRM_REGISTRATION_REQUEST: 'CONFIRM_REGISTRATION_REQUEST',
    GET_PARTNER_STATUS_REQUEST: 'GET_PARTNER_STATUS_REQUEST',
    GET_PARTNER_REGISTRATION_INFO_REQUEST: 'GET_PARTNER_REGISTRATION_INFO_REQUEST',
    PASSWORD_RESET_REQUEST: 'PASSWORD_RESET_REQUEST',
    UPDATE_PARTNER_REQUEST: 'UPDATE_PARTNER_REQUEST',
    UNIQUE_PUBLIC_TITLE_BY_PARTNER_REQUEST: 'UNIQUE_PUBLIC_TITLE_BY_PARTNER_REQUEST',
    UNIQUE_PUBLIC_TITLE_REQUEST: 'UNIQUE_PUBLIC_TITLE_REQUEST',
    ACL_GET_REQUEST: 'ACL_GET_REQUEST',
    LOGIN_TO_OLD_PORTAL: 'LOGIN_TO_OLD_PORTAL',
    LOGIN_TO_STREAM: 'LOGIN_TO_STREAM',
    GET_STREAM_TOKEN_REQUEST: 'GET_STREAM_TOKEN_REQUEST',
    LOGOUT_FROM_STREAM: 'LOGOUT_FROM_STREAM',
    UPDATE_ACCOUNT_REQUEST: 'UPDATE_ACCOUNT_REQUEST',
    SET_SELECTED_PARTNER: 'SET_SELECTED_PARTNER',
    RESET: 'RESET',
    REGISTRATION: 'REGISTRATION',
    ALLEGRO_REGISTER: 'ALLEGRO_REGISTER',
    UNIQUE_EMAIL_REQUEST: 'UNIQUE_EMAIL_REQUEST',
    UNIQUE_COMPANY_NAME_REQUEST: 'UNIQUE_COMPANY_NAME_REQUEST',
    SET_PASSWORD_VALID: 'SET_PASSWORD_VALID',
    SET_REGISTRATION_STEP: 'SET_REGISTRATION_STEP',
    SET_REGISTRATION_EMAIL: 'SET_REGISTRATION_EMAIL',
  },
  MUTATIONS: {
    CHECK_LOGGED_RESPONSE: 'CHECK_LOGGED_RESPONSE',
    SET_TOKEN: 'SET_TOKEN',
    WORKFLOW_RULES_RESPONSE: 'WORKFLOW_RULES_RESPONSE',
    LOGIN_RESPONSE: 'LOGIN_RESPONSE',
    LOGOUT_RESPONSE: 'LOGOUT_RESPONSE',
    LOGIN_AS_GIVEN_PARTNER: 'LOGIN_AS_GIVEN_PARTNER',
    REVOKE_CLIENT_ID_TOKEN: 'REVOKE_CLIENT_ID_TOKEN',
    ADD_CLIENT_ID_TOKEN: 'ADD_CLIENT_ID_TOKEN',
    SET_CHECKING_AUTH: 'SET_CHECKING_AUTH',
    GET_CLIENT_ID_RESPONSE: 'GET_CLIENT_ID_RESPONSE',
    SET_PASSWORD_CONFIRM_RESPONSE: 'SET_PASSWORD_CONFIRM_RESPONSE',
    CONFIRM_REGISTRATION_RESPONSE: 'CONFIRM_REGISTRATION_RESPONSE',
    GET_PARTNER_STATUS_RESPONSE: 'GET_PARTNER_STATUS_RESPONSE',
    GET_PARTNER_REGISTRATION_INFO_RESPONSE: 'GET_PARTNER_REGISTRATION_INFO_RESPONSE',
    PASSWORD_RESET_RESPONSE: 'PASSWORD_RESET_RESPONSE',
    UPDATE_PARTNER_RESPONSE: 'UPDATE_PARTNER_RESPONSE',
    ACL_RESPONSE: 'ACL_RESPONSE',
    UPDATE_TOKEN: 'UPDATE_TOKEN',
    UPDATE_ACCOUNT_RESPONSE: 'UPDATE_ACCOUNT_RESPONSE',
    RESET: 'RESET',
    SET_SELECTED_PARTNER: 'SET_SELECTED_PARTNER',
    REGISTRATION: 'REGISTRATION',
    ALLEGRO_REGISTER: 'ALLEGRO_REGISTER',
    UNIQUE_EMAIL_REQUEST: 'UNIQUE_EMAIL_REQUEST',
    UNIQUE_COMPANY_NAME_REQUEST: 'UNIQUE_COMPANY_NAME_REQUEST',
    SET_REGISTRATION_STEP: 'SET_REGISTRATION_STEP',
    SET_PASSWORD_VALID: 'SET_PASSWORD_VALID',
    SET_REGISTRATION_EMAIL: 'SET_REGISTRATION_EMAIL',
  },
}
