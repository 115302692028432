<template>
  <v-list-item v-if="listItem" :disabled="disabled" v-bind="{ ...$attrs, ...$props }" @click="trackEvent(getAction)" v-on="$listeners">
    <slot />
  </v-list-item>

  <router-link
    v-else-if="to"
    :to="to"
    :disabled="disabled"
    v-bind="{ ...$attrs }"
    @click="trackEvent(getAction)"
    v-on="$listeners"
  >
    <slot />
  </router-link>

  <a v-else-if="href || linkButton || download" :disabled="disabled" :href="linkButton ? 'javascript:;' : href" v-bind="{ ...$attrs, ...$props }" @click="trackEvent(getAction)" v-on="$listeners">
    <slot />
  </a>
</template>

<script>
import TrackMixin from '@/components/commons/anchor/TrackMixin'

export default {
  name: 'Anchor',
  mixins: [TrackMixin],
  props: {
    to: {
      type: Object,
      default: () => {},
    },
    href: {
      type: String,
      default: '',
    },
    download: {
      type: String,
      default: null,
    },
    listItem: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    linkButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getAction() {
      if (this.action) {
        return this.action
      } else {
        return this.to && this.to.name ? this.to.name : this.href
      }
    },
  },
}
</script>
