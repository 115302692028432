import { COUNTRIES } from '@/enums'
import isMobilePhone from 'validator/lib/isMobilePhone'
import isLength from 'validator/lib/isLength'

export const isPhone = (value, country) => {
  switch (country) {
    case COUNTRIES.CZ:
      return isMobilePhone(value, 'cs-CZ')
    case COUNTRIES.SK:
      return isMobilePhone(value, 'sk-SK')
    case COUNTRIES.SI:
      return isMobilePhone(value, 'sl-SI')
    case COUNTRIES.PL:
      return isMobilePhone(value, 'pl-PL')
    case COUNTRIES.HU:
      return /((?:\+?3|0)6)(?:-|\()?(\d{1,2})(?:-|\))?(\d{3})-?(\d{3,4})/.test(value)
    case COUNTRIES.HR:
      return isLength(value, { min: 7, max: 16 })
    default:
      return true
  }
}
