<template>
  <v-list class="pa-0">
    <v-list-item>
      <v-list-item-content v-if="!miniVariant">
        <v-list-item-title
          class="caption"
          :class="{ 'text-center': mobileLayout }"
          v-text="$t('navigation.copyright', [currentYear])"
        />
      </v-list-item-content>
      <v-list-item-action v-if="!mobileLayout" :class="{ 'justify-center': miniVariant }">
        <tooltip :text="$t(`navigation.${!miniVariant ? 'closeNav' : 'openNav'}`)" position-right>
          <btn :action="`navigation.${!miniVariant ? 'closeNav' : 'openNav'}`" icon @click="toggleNavigation">
            <v-icon>
              {{ !miniVariant ? 'mdi-chevron-double-left' : 'mdi-chevron-double-right' }}
            </v-icon>
          </btn>
        </tooltip>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import Tooltip from '@/components/commons/tooltip/Tooltip'

export default {
  name: 'PageFooter',
  components: { Tooltip },
  props: {
    miniVariant: {
      type: Boolean,
      required: true,
    },
    mobileLayout: {
      type: Boolean,
      default: false,
    },
    toggleNavigation: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    }
  },
}
</script>
