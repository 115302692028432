import { DELIVERIES } from '@/store/modules/deliveries/types'
import createState from '@/store/modules/deliveries/state'
import { resetState } from '@/store/utils'

export default {
  [DELIVERIES.MUTATIONS.GET_DELIVERY_RESPONSE]: (state, payload) => {
    state.delivery = payload
  },
  [DELIVERIES.MUTATIONS.GET_AVAILABLE_DELIVERIES_RESPONSE]: (state, payload) => {
    state.availableDeliveries = payload
  },
  [DELIVERIES.MUTATIONS.GET_TRANSPORT_LIST_RESPONSE]: (state, payload) => {
    state.transports = payload
  },
  [DELIVERIES.MUTATIONS.GET_DELIVERIES_ALERTS_RESPONSE]: (state, payload) => {
    state.alerts = payload
  },
  [DELIVERIES.MUTATIONS.GET_MALL_DELIVERY_STATUS_RESPONSE]: (state, payload) => {
    state.mdpSettings = payload
  },
  [DELIVERIES.MUTATIONS.GET_MALL_DELIVERY_ACTIVE_RESPONSE]: (state, payload) => {
    state.mdpActive = payload
  },
  [DELIVERIES.MUTATIONS.GET_DESTINATION_BRANCHES_RESPONSE]: (state, payload) => {
    state.destinationBranches = payload
  },
  [DELIVERIES.MUTATIONS.GET_ULOZENKA_REGISTRATION_BRANCHES_RESPONSE]: (state, payload) => {
    state.ulozenkaRegisterBranches = [...payload].sort((a, b) => (a.id < b.id ? -1 : 1))
  },
  [DELIVERIES.MUTATIONS.GET_WAREHOUSE_CALENDAR_RESPONSE]: (state, payload) => {
    state.warehouseSettings = payload
  },
  [DELIVERIES.MUTATIONS.RESET]: state => {
    resetState(state, createState())
  },
}
