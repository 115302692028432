export const USER_ROUTES = {
  PROFILE: 'user-profile',
  NOTIFICATIONS: 'user-notifications',
  MESSAGES: 'user-messages',
  SEND_MESSAGE: 'user-send-message',
  CONVERSATION: 'user-conversation',
  CONTACTS: 'contacts',
}

export default [
  {
    path: '',
    name: USER_ROUTES.PROFILE,
    component: () =>
      import(/* webpackPrefetch: true */ /* webpackChunkName: "profile" */ '../../../views/app/user/UserProfilePage'),
  },
]
