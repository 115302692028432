export const CMT_INTER_PARAM_OPERATORS = {
  OR: 'OR',
  AND: 'AND',
}

export const CMT_VALIDATION_OPERATORS = {
  IN: 'IN',
  ALL: 'ALL',
  NOT_IN: 'NOT IN',
  EQUAL: '=',
  NOT_EQUAL: '<>',
  GREATER_THEN: '>',
  LESS_THEN: '<',
  BETWEEN: '><',
}
