import { WORKFLOW } from '@/store/modules/workflow/types'
import { API } from '@/api'
import { callPortalGraphQL, getSelectedPartnerId, withNamespace } from '@/store/utils'
import { AUTH } from '@/store/modules/auth/types'
import { addSuccessMessage } from '@/services/flashMessages'

export default {
  [WORKFLOW.ACTIONS.WORKFLOW_REQUEST]: async ({ dispatch, commit, rootGetters }) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.workflow.workflow },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
      },
    })
    commit(WORKFLOW.MUTATIONS.WORKFLOW_RESPONSE, data)
  },
  [WORKFLOW.ACTIONS.WORKFLOW_UPDATE_REQUEST]: async ({ dispatch, state, commit, rootGetters }, payload) => {
    try {
      const data = await callPortalGraphQL(dispatch, {
        requestData: { ...API.workflow.updateWorkflow },
        params: {
          partnerId: getSelectedPartnerId(rootGetters),
          ...state.workflow,
          status: payload.status,
        },
      })

      await dispatch(withNamespace(AUTH.NAMESPACE, AUTH.ACTIONS.CHECK_LOGGED_REQUEST), null, { root: true })

      commit(WORKFLOW.MUTATIONS.WORKFLOW_UPDATE_RESPONSE, data)

      return true
    } catch (e) {
      return false
    }
  },
  [WORKFLOW.ACTIONS.WORKFLOW_UPDATE_TICK_REQUEST]: async ({ dispatch, state, commit, rootGetters }, payload) => {
    const index = state.workflow.ticks.findIndex(item => item.text === payload.tick)

    if (index !== -1) {
      const ticks = { ...state.workflow.ticks }

      ticks[index].checked = payload.checked

      const data = await callPortalGraphQL(dispatch, {
        requestData: { ...API.workflow.updateWorkflow },
        params: {
          partnerId: getSelectedPartnerId(rootGetters),
          status: state.workflow.status,
          ticks: { ...ticks },
        },
      })
      commit(WORKFLOW.MUTATIONS.WORKFLOW_UPDATE_RESPONSE, data)
    }
  },
  [WORKFLOW.ACTIONS.GET_FEES_REQUEST]: async ({ dispatch, commit }) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.workflow.getFees },
    })
    commit(WORKFLOW.MUTATIONS.GET_FEES_RESPONSE, data)
  },
  [WORKFLOW.ACTIONS.GET_COMMISSION_FEES_REQUEST]: async ({ dispatch, commit }) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.workflow.getCommissionFees },
    })
    commit(WORKFLOW.MUTATIONS.GET_COMMISSION_FEES_RESPONSE, data)
  },
  [WORKFLOW.ACTIONS.GET_CHECK_LIST_REQUEST]: async ({ dispatch, commit }) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.workflow.getCheckList },
    })
    commit(WORKFLOW.MUTATIONS.GET_CHECK_LIST_RESPONSE, data)
  },
  [WORKFLOW.ACTIONS.PRODUCT_TO_APPROVAL_REQUEST]: async ({ dispatch }) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.workflow.sendProductToApproval },
      })
      addSuccessMessage(dispatch, API.workflow.sendProductToApproval.id, 'flashMessages.workflow.approveSend')
      return true
    } catch {
      return false
    }
  },
}
