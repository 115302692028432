export const LOCAL_STORAGE = {
  PREFERS_NEW_VERSION: 'pui:prefers-new-version',
  USER_TOKEN: 'pui:user-token',
  SELECTED_PARTNER: 'pui:selected-partner-id',
  BACK_LINK: 'pui:back-link',
  STREAM_TOKEN: 'pui:stream-token',
  GRID: 'pui:grid',
  GRID_HEADER: 'pui:grid-header',
  GRID_ITEMS_PER_PAGE: 'pui:grid-items-per-page',
  PUBLIC_PAGES_LANG: 'pui:public-pages-lang',
  NAV_BAR: 'pui:nav-bar',
  LANG: 'pui:lang',
  PORTAL_DIALOG: 'pui:portal-dialog',
  PRODUCT_DETAIL: {
    TITLE: 'pui:product-detail:title',
    SHORTDESC: 'pui:product-detail:shortdesc',
    LONGDESC: 'pui:product-detail:longdesc',
    PRICE: 'pui:product-detail:price',
    FORCE_PRICE: 'pui:product-detail:force_price',
    FAIR_PRICE: 'pui:product-detail:fair_price',
    WEEE_FEE: 'pui:product-detail:weee_fee',
    VAT: 'pui:product-detail:vat',
    PROMOTIONS: 'pui:product-detail:promotions',
    LABELS: 'pui:product-detail:labels',
    MEDIA: 'pui:product-detail:media',
    ID: 'pui:product-detail:id',
    BRAND_ID: 'pui:product-detail:brand_id',
    CATEGORY_ID: 'pui:product-detail:category_id',
    BARCODE: 'pui:product-detail:barcode',
    PRIORITY: 'pui:product-detail:priority',
    AVAILABILITY: 'pui:product-detail:availability',
    DELIVERY_DELAY: 'pui:product-detail:delivery_delay',
    PACKAGE_SIZE: 'pui:product-detail:package_size',
    DIMENSIONS: 'pui:product-detail:dimensions',
    PARAMETERS: 'pui:product-detail:parameters',
    VARIANTS: 'pui:product-detail:variants',
    RECOMMENDED: 'pui:product-detail:recommended',
    HELPER: 'pui:product-detail:helper',
  },
}
