import { API } from '@/api'

export const DATA_GRIDS = {
  ADDRESSES: 'ADDRESSES',
  BRANDS: 'BRANDS',
  CAMPAIGN_IMPORTS: 'CAMPAIGN_IMPORTS',
  CAMPAIGN_LIST: 'CAMPAIGN_LIST',
  CONTACTS: 'CONTACTS',
  DELIVERIES: 'DELIVERIES',
  HEUREKA_OVERRIDES: 'HEUREKA_OVERRIDES',
  MALL_PRODUCT_FEED: 'MALL_PRODUCT_FEED',
  MALL_PRODUCT_FEED_ERROR_LOG: 'MALL_PRODUCT_FEED_ERROR_LOG',
  MALL_AVAILABILITY_FEED: 'MALL_AVAILABILITY_FEED',
  INVOICES: 'INVOICES',
  MDP_DELIVERIES: 'MDP_DELIVERIES',
  OFFSETS: 'OFFSETS',
  ORDERS_ALL: 'ORDERS_ALL',
  ORDERS_DELIVERED: 'ORDERS_DELIVERED',
  ORDERS_MISSING_STATUS: 'ORDERS_MISSING_STATUS',
  ORDERS_OPEN: 'ORDERS_OPEN',
  ORDERS_SHIPPED: 'ORDERS_SHIPPED',
  ORDERS_SHIPPING: 'ORDERS_SHIPPING',
  PRODUCTS: 'PRODUCTS',
  WEBHOOKS: 'WEBHOOKS',
  WEBHOOKS_LOGS: 'WEBHOOKS_LOGS',
  PRODUCTS_BOX_OVERRIDES: 'PRODUCTS_BOX_OVERRIDES',
  PRODUCTS_MEDIA_OVERRIDES: 'PRODUCTS_MEDIA_OVERRIDES',
  PRODUCTS_ERRORS: 'PRODUCTS_ERRORS',
  PRODUCTS_SOLD_OUT: 'PRODUCTS_SOLD_OUT',
  PRODUCTS_LAST_ITEMS: 'PRODUCTS_LAST_ITEMS',
  PRODUCTS_BLOCKED_ITEMS: 'PRODUCTS_BLOCKED_ITEMS',
  DIP_DELIVERIES: 'DIP_DELIVERIES',
}

export const GRID_REQUESTS = {
  [DATA_GRIDS.ADDRESSES]: API.partner.addressesGrid,
  [DATA_GRIDS.BRANDS]: API.heureka.brandsGrid,
  [DATA_GRIDS.CAMPAIGN_IMPORTS]: API.campaigns.getUploadedCsv,
  [DATA_GRIDS.CAMPAIGN_LIST]: API.campaigns.getCampaignList,
  [DATA_GRIDS.CONTACTS]: API.partner.contactsGrid,
  [DATA_GRIDS.DELIVERIES]: API.deliveries.deliveriesGrid,
  [DATA_GRIDS.MALL_PRODUCT_FEED]: API.mallFeed.productFeedGrid,
  [DATA_GRIDS.MALL_PRODUCT_FEED_ERROR_LOG]: API.mallFeed.productFeedErrorLogGrid,
  [DATA_GRIDS.HEUREKA_OVERRIDES]: API.heureka.categoriesOverrideList,
  [DATA_GRIDS.INVOICES]: API.billings.invoices,
  [DATA_GRIDS.MDP_DELIVERIES]: API.deliveries.mdpDeliveriesGrid,
  [DATA_GRIDS.OFFSETS]: API.billings.offsets,
  [DATA_GRIDS.ORDERS_ALL]: API.orders.ordersGrid,
  [DATA_GRIDS.ORDERS_DELIVERED]: API.orders.ordersGrid,
  [DATA_GRIDS.ORDERS_MISSING_STATUS]: API.orders.missingStatusGrid,
  [DATA_GRIDS.ORDERS_OPEN]: API.orders.ordersGrid,
  [DATA_GRIDS.ORDERS_SHIPPED]: API.orders.ordersGrid,
  [DATA_GRIDS.ORDERS_SHIPPING]: API.orders.ordersGrid,
  [DATA_GRIDS.WEBHOOKS]: API.partner.getPartnerWebhook,
  [DATA_GRIDS.WEBHOOKS_LOGS]: API.partner.getPartnerWebhookLogs,
  [DATA_GRIDS.PRODUCTS]: API.products.productsGrid,
  [DATA_GRIDS.PRODUCTS_SOLD_OUT]: API.products.productsGrid,
  [DATA_GRIDS.PRODUCTS_LAST_ITEMS]: API.products.productsGrid,
  [DATA_GRIDS.PRODUCTS_BLOCKED_ITEMS]: API.products.productsGrid,
  [DATA_GRIDS.PRODUCTS_BOX_OVERRIDES]: API.products.productBoxOverrideList,
  [DATA_GRIDS.PRODUCTS_MEDIA_OVERRIDES]: API.products.productMediaOverrideList,
  [DATA_GRIDS.PRODUCTS_ERRORS]: API.products.errorList,
  [DATA_GRIDS.DIP_DELIVERIES]: API.dip.dipGrid,
}
