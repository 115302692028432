import { FEFL } from '@/store/modules/fefl/types'
import { callFeflEndpoint } from '@/store/utils'
import { API } from '@/api'

export default {
  [FEFL.ACTIONS.GET_FEFL_REQUEST]: async ({ dispatch, commit }) => {
    const data = await callFeflEndpoint(dispatch, {
      requestData: { ...API.fefl.getFefl },
    })
    commit(FEFL.MUTATIONS.GET_FEFL_RESPONSE, data)
  },
}
