export const PRODUCTS_INTERVAL = {
  PRODUCTS_0: 0,
  PRODUCTS_360: 360,
  PRODUCTS_720: 720,
  PRODUCTS_1440: 1440,
}

export const AVAILABILITY_CHECK_FREQUENCY = {
  AVAILABILITY_0: 0,
  AVAILABILITY_15: 15,
  AVAILABILITY_30: 30,
  AVAILABILITY_60: 60,
}
