export default () => ({
  delivery: null,
  availableDeliveries: null,
  alerts: [],
  transports: [],
  mdpSettings: null,
  mdpActive: null,
  destinationBranches: [],
  ulozenkaRegisterBranches: [],
  warehouseSettings: null,
})
