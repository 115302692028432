export default () => ({
  brands: [],
  categories: [],
  labels: [],
  product: null,
  recommended: [],
  recommendedOptions: [],
  cmtCategories: {
    partnerId: null,
    tree: null,
  },
  ui: {
    productActivationProgress: {
      running: false,
      total: 0,
      done: 0,
    },
  },
  variants: [],
  categoryQueryRules: [],
})
