import axios from 'axios'
import Raven from 'raven-js'
import { config } from '@/config'
import { ENDPOINTS, GQL_SERVICE_ERRORS, LOCAL_STORAGE } from '@/enums'
import { logout, onError, removeError, startSending, stopSending } from '@/services/utils'
import { getSelectedPartnerId } from '@/store/utils'
import { i18n } from '@/localization'

const graphqlClient = axios.create({
  baseURL: config.backend.graphqlBaseUrl,
  method: 'POST',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
  },
})

/*
const setStatisticsInterval = () => {
  setInterval(() => {
    sendResponseTimeData()
  }, SENTRY.LOGGING_INTERVAL)
}
 */

const send = config => {
  const headers = config.headers || {}
  headers['X-Origin'] = window.location.protocol + '//' + window.location.host + window.location.pathname

  const token = localStorage.getItem(LOCAL_STORAGE.USER_TOKEN)
  if (token) {
    headers['Authorization'] = `${token}`
  }

  return graphqlClient.request({ ...config, headers: { ...headers } })
}

const createRequest =
  (route, store, useCpapiV2 = false) =>
  ({ query, variables, controller }) => {
    const baseURL = useCpapiV2
      ? `${config.backend.graphqlBaseUrlV2}/graphql${route}`
      : `${config.backend.graphqlBaseUrl}${route}`

    return send({
      signal: controller.signal,
      data: JSON.stringify({
        query,
        variables,
      }),
      baseURL,
      headers: {
        'X-Selected-Partner': getSelectedPartnerId(store.rootGetters),
        'X-App': 'portal-ui',
      },
    })
  }

const checkNodeError = (errors, store, id, errorType) => {
  errors.forEach(item => {
    // logout
    if (item.extensions.exception.description === 'UNAUTHORIZED') {
      logout(store.commit, store.dispatch)

      return
    }

    const allowedErrors = Object.keys(i18n.t('apiErrors'))
    if (allowedErrors.includes(item.extensions.exception.description)) {
      onError(
        store,
        id,
        item.extensions.exception.description ? item.extensions.exception.description : 'UNKNOWN',
        errorType,
        item.extensions.exception.extra.attributes,
      )
    } else {
      onError(store, id, 'UNKNOWN', errorType, item.extensions.exception.extra.attributes)
    }
  })
}

const checkError = (errors, store, id, errorType) => {
  errors.forEach(item => {
    // logout
    if (item.code === 'UNAUTHORIZED') {
      logout(store.commit, store.dispatch)

      return
    }

    if ('attributes' in item && item.attributes.length) {
      onError(store, id, item.code ? item.code : 'UNKNOWN', errorType, item.attributes || [])
    } else {
      onError(store, id, item.code ? item.code : 'UNKNOWN', errorType, [item.message] || [])
    }
  })
}

// eslint-disable-next-line no-unused-vars
const call = ({ requestData, params = null, throwError = false, store }, sender) => {
  // const begin = performance.now()
  const { id, errorType, loadingType, request, reduce, mock } = requestData

  if (!id) {
    throw new Error('Request must have id.')
  }

  if (mock) {
    // eslint-disable-next-line
    console.log(`App using mock request id=[${id}]`)
    return mock()
  }

  return new Promise((resolve, reject) => {
    const controller = new AbortController()
    startSending(store.commit, id, errorType, loadingType, controller)

    const payload = {
      ...request(params),
      controller,
    }

    return sender(payload)
      .then(res => {
        stopSending(store.commit, id)
        removeError(store.commit, id)

        // process error on success
        if (res.data.errors && res.data.errors.length > 0) {
          if (res.data.errors[0].extensions.exception) {
            checkNodeError(res.data.errors, store, id, errorType)
          } else {
            if (res.data.errors[0].code !== GQL_SERVICE_ERRORS.MPAPI_PRICE_DIFFERENCE_VALIDATION) {
              checkError(res.data.errors, store, id, errorType)
            }
          }

          if (throwError) {
            reject(res.data.errors)
          }

          return
        }

        if (reduce) {
          resolve(reduce(res.data.data))
        }

        // Prevent spamming sentry, we have enough data for now
        // if (Raven.isSetup()) {
        //   const responseTime = performance.now() - begin
        //   if (responseTime > SENTRY.MS_ERROR_THRESHOLD) {
        //     Raven.captureMessage(`Request [${id}] took too long: ${responseTime}ms on PUI - ${resolver}/graphql`)
        //   }
        //   REQUEST_MAP[resolver].push(responseTime)
        // }

        resolve(res.data.data)
      })
      .catch(err => {
        // eslint-disable-next-line
        console.error('Response ERROR!', err)

        if (axios.isCancel(err)) {
          console.warn('Request has been cancelled due to navigation change')
        } else if (err.response) {
          // logout
          if (err.response.status && err.response.status === 401) {
            logout(store.commit, store.dispatch)

            return
          }

          // process errors
          if (err.response.data.errors && err.response.data.errors.length > 0) {
            if (Raven.isSetup()) {
              Raven.captureException(`SERVER ERROR: ${id}`, { extra: err.response.data.errors }).setTagsContext({
                level: 'hard',
              })
            }
            if (err.response.data.errors[0].extensions.exception) {
              checkNodeError(err.response.data.errors, store, id, errorType)
            } else {
              if (err.response.data.errors[0].code !== GQL_SERVICE_ERRORS.MPAPI_PRICE_DIFFERENCE_VALIDATION) {
                checkError(err.response.data.errors, store, id, errorType)
              }
            }
          }
        } else {
          // unknown error
          onError(store, id, 'UNKNOWN', errorType)
        }

        stopSending(store.commit, id)

        if (throwError) {
          reject(err)
        }
      })
  })
}

// if (Raven.isSetup()) {
//   setStatisticsInterval()
// }

const callAuthGraphQL = params => call(params, createRequest(`/${ENDPOINTS.AUTH}`, params.store))
const callCockpitGraphQL = params => call(params, createRequest(`/${ENDPOINTS.COCKPIT}`, params.store))
const callPublicGraphQL = params => call(params, createRequest(`/${ENDPOINTS.PUBLIC}`, params.store))
const callPortalGraphQL = params =>
  call(params, createRequest(`/${ENDPOINTS.PORTAL}`, params.store, params.requestData.useCpapiV2))
const callCpapiV2GraphQL = params => call(params, createRequest(`/${ENDPOINTS.PORTAL}`, params.store, true))
const callCpapiV2PublicGraphQL = params => call(params, createRequest(`/${ENDPOINTS.PUBLIC}`, params.store, true))

export {
  callAuthGraphQL,
  callCockpitGraphQL,
  callPublicGraphQL,
  callPortalGraphQL,
  callCpapiV2GraphQL,
  callCpapiV2PublicGraphQL,
}
