import { HEUREKA } from '@/store/modules/heureka/types'
import {
  callPortalGraphQL,
  getSelectedPartnerId,
  removeKeys,
  callApi,
  dispatchRoot,
  withNamespace,
  getSelectedShopId,
  callFileSystemApi,
  callCpapiV2GraphQL,
} from '@/store/utils'
import { API } from '@/api'
import { addErrorMessage, addSuccessMessage } from '@/services/flashMessages'
import { downloader } from '@/services/downloader'
import { getCountry } from '@/enums'
import { DATA_GRIDS } from '@/store/grid/grids'
import { GRID } from '@/store/grid/store/types'
import { openWindow } from '@/services/utils'

export default {
  [HEUREKA.ACTIONS.CATEGORY_SHORTCUT]: async ({ dispatch }, payload) => {
    if (!payload.categoryUrl) {
      addErrorMessage(dispatch, 'CATEGORY_SHORTCUT', 'categories.categoryShorcut.error')
      return
    }
    try {
      openWindow(`${payload.categoryUrl}`)
    } catch (e) {
      addErrorMessage(dispatch, 'CATEGORY_SHORTCUT', 'categories.categoryShorcut.error')
    }
  },
  [HEUREKA.ACTIONS.DOWNLOAD_CATEGORY_DETAIL_REQUEST]: async ({ dispatch, commit, rootGetters }, payload) => {
    if (!payload.categoryId || !payload.menuItemId || !payload.type) {
      addErrorMessage(dispatch, API.heureka.exports.downloadCategoryDetail.id, 'categories.export.error')
      return
    }
    commit(HEUREKA.MUTATIONS.RUNNING_EXPORT, payload.type)
    try {
      const language = getCountry(getSelectedShopId(rootGetters))
      const data = await callApi(dispatch, {
        requestData: { ...API.heureka.exports.downloadCategoryDetail },
        throwError: true,
        params: {
          categoryId: payload.categoryId,
          menuItemId: payload.menuItemId,
          type: payload.type,
          language,
        },
      })
      const mimeType = payload.type === 'json' ? `application/json` : 'application/vnd.ms-excel'
      downloader(data, `category-${payload.categoryId}-${payload.menuItemId}.${payload.type}`, mimeType)
    } catch (e) {
      addErrorMessage(dispatch, API.heureka.exports.downloadCategoryDetail.id, 'categories.export.error')
    }
    commit(HEUREKA.MUTATIONS.RUNNING_EXPORT, false)
  },
  [HEUREKA.ACTIONS.GET_PLAN_REQUEST]: async ({ dispatch, commit, rootGetters }) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.heureka.getPlan },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
      },
    })
    commit(HEUREKA.MUTATIONS.GET_PLAN_RESPONSE, data)
  },
  [HEUREKA.ACTIONS.UPDATE_PLAN_PRODUCT_REQUEST]: async ({ dispatch, rootGetters, commit, state }, payload) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.heureka.updateProductPlan },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
        data: {
          availabilityInterval: state.availabilityInterval,
          productsInterval: payload.productsInterval,
        },
      },
    })
    commit(HEUREKA.MUTATIONS.GET_PLAN_RESPONSE, data)
    addSuccessMessage(dispatch, API.heureka.updateProductPlan.id, 'flashMessages.saveSetting')
  },
  [HEUREKA.ACTIONS.UPDATE_PLAN_AVAILABILITY_REQUEST]: async ({ dispatch, rootGetters, commit, state }, payload) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.heureka.updateAvailabilityPlan },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
        data: {
          productsInterval: state.productsInterval,
          availabilityInterval: payload.availabilityInterval,
        },
      },
    })
    commit(HEUREKA.MUTATIONS.GET_PLAN_RESPONSE, data)
    addSuccessMessage(dispatch, API.heureka.updateAvailabilityPlan.id, 'flashMessages.saveSetting')
  },
  [HEUREKA.ACTIONS.GET_PARTNER_REQUEST]: async ({ dispatch, commit, rootGetters }) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.heureka.getPartner },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
      },
    })
    commit(HEUREKA.MUTATIONS.GET_PARTNER_RESPONSE, data)
    return data
  },
  [HEUREKA.ACTIONS.PARTNER_RESET_DATA]: async ({ dispatch, rootGetters }, payload) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.heureka.resetPartner },
        params: {
          partnerId: getSelectedPartnerId(rootGetters),
          data: {
            ...payload,
          },
        },
        throwError: true,
      })
      addSuccessMessage(dispatch, API.heureka.updatePartner.id, 'flashMessages.resetData')

      return true
    } catch {
      return false
    }
  },
  [HEUREKA.ACTIONS.RESET_HASH_REQUEST]: async ({ dispatch }) => {
    try {
      await callCpapiV2GraphQL(dispatch, {
        requestData: { ...API.heureka.resetHash },
        throwError: true,
      })

      return true
    } catch {
      return false
    }
  },
  [HEUREKA.ACTIONS.CATEGORY_SUMMARY_REQUEST]: async ({ dispatch, commit }) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.heureka.categorySummary },
    })
    commit(HEUREKA.MUTATIONS.CATEGORY_SUMMARY_RESPONSE, data)
  },
  [HEUREKA.ACTIONS.BRAND_SUMMARY_REQUEST]: async ({ dispatch, commit, rootGetters }) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.heureka.partnerBrandList },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
      },
    })
    commit(HEUREKA.MUTATIONS.BRAND_SUMMARY_RESPONSE, data)
  },
  [HEUREKA.ACTIONS.PRODUCT_SUMMARY_REQUEST]: async ({ dispatch, commit }) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.heureka.productSummary },
    })
    commit(HEUREKA.MUTATIONS.PRODUCT_SUMMARY_RESPONSE, data)
  },
  [HEUREKA.ACTIONS.SILENT_PRODUCT_SUMMARY_REQUEST]: async ({ dispatch, commit }) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.heureka.silentProductSummary },
    })
    commit(HEUREKA.MUTATIONS.PRODUCT_SUMMARY_RESPONSE, data)
  },
  [HEUREKA.ACTIONS.AVAILABILITY_SUMMARY_REQUEST]: async ({ dispatch, commit }) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.heureka.availabilitySummary },
    })
    commit(HEUREKA.MUTATIONS.AVAILABILITY_SUMMARY_RESPONSE, data)
  },
  [HEUREKA.ACTIONS.UPDATE_FEED_REQUEST]: async ({ dispatch, rootGetters, commit, state }, payload) => {
    const payloadData = removeKeys(state.partner, ['id', 'shopId'])
    payloadData.productFeed = payload.productFeed
    payloadData.availabilityFeed = payload.availabilityFeed

    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.heureka.updatePartner },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
        data: payloadData,
      },
    })
    commit(HEUREKA.MUTATIONS.GET_PARTNER_RESPONSE, data)
    commit(HEUREKA.MUTATIONS.PRODUCT_FEED_IS_VALIDATING, true)
    commit(HEUREKA.MUTATIONS.AVAILABILITY_FEED_IS_VALIDATING, true)

    const values = await Promise.all([
      callPortalGraphQL(dispatch, {
        requestData: { ...API.heureka.validateXmlProducts },
      })
        .then(res => {
          dispatch(HEUREKA.ACTIONS.IMPORT_PRODUCTS_REQUEST)

          return res
        })
        .catch(() => {
          return false
        }),

      callPortalGraphQL(dispatch, {
        requestData: { ...API.heureka.validateXmlAvailability },
      })
        .then(res => {
          return res
        })
        .catch(() => {
          return false
        }),
    ])
    if (values.every(res => !res.error)) {
      addSuccessMessage(dispatch, API.heureka.updatePartner.id, 'flashMessages.saveSetting')
    }
  },
  [HEUREKA.ACTIONS.PRODUCT_VALIDATE_STATUS_REQUEST]: ({ commit }, payload) => {
    switch (payload.type) {
      case 'BEGIN':
        commit(HEUREKA.MUTATIONS.PRODUCT_FEED_IS_VALIDATING, true)
        break

      case 'END':
        commit(HEUREKA.MUTATIONS.PRODUCT_FEED_IS_VALIDATING, false)
        commit(HEUREKA.MUTATIONS.PRODUCT_SUMMARY_RESPONSE, {
          vat: payload.vat,
          error: payload.error,
          errorLog: payload.errorLog,
          stats: payload.stats,
        })
        break

      default:
        break
    }
  },
  [HEUREKA.ACTIONS.AVAILABILITY_VALIDATE_STATUS_REQUEST]: ({ commit }, payload) => {
    switch (payload.type) {
      case 'BEGIN':
        commit(HEUREKA.MUTATIONS.AVAILABILITY_FEED_IS_VALIDATING, true)
        break

      case 'END':
        commit(HEUREKA.MUTATIONS.AVAILABILITY_FEED_IS_VALIDATING, false)
        commit(HEUREKA.MUTATIONS.AVAILABILITY_SUMMARY_RESPONSE, { error: payload.error, errorLog: payload.errorLog })
        break

      default:
        break
    }
  },
  [HEUREKA.ACTIONS.UPDATE_VAT_REQUEST]: async ({ dispatch, commit, rootGetters, state }, payload) => {
    const payloadData = removeKeys(state.partner, ['id', 'shopId'])
    payloadData.vat = payload.vat

    try {
      const data = await callPortalGraphQL(dispatch, {
        requestData: { ...API.heureka.updatePartner },
        params: {
          partnerId: getSelectedPartnerId(rootGetters),
          data: payloadData,
        },
        throwError: true,
      })

      commit(HEUREKA.MUTATIONS.GET_PARTNER_RESPONSE, data)
      addSuccessMessage(dispatch, API.heureka.updatePartner.id, 'flashMessages.heurekaOverrides.update')
      return true
    } catch {
      return false
    }
  },
  [HEUREKA.ACTIONS.UPDATE_ROUND_PRICE_REQUEST]: async ({ dispatch, commit, rootGetters, state }, payload) => {
    const payloadData = removeKeys(state.partner, ['id', 'shopId'])
    payloadData.roundPrice = payload.roundPrice

    try {
      const data = await callPortalGraphQL(dispatch, {
        requestData: { ...API.heureka.updatePartner },
        params: {
          partnerId: getSelectedPartnerId(rootGetters),
          data: payloadData,
        },
        throwError: true,
      })

      commit(HEUREKA.MUTATIONS.GET_PARTNER_RESPONSE, data)
      addSuccessMessage(dispatch, API.heureka.updatePartner.id, 'flashMessages.heurekaOverrides.update')

      return true
    } catch {
      return false
    }
  },
  [HEUREKA.ACTIONS.UPDATE_AUTO_DESCRIPTION_REQUEST]: async ({ dispatch, commit, rootGetters, state }, payload) => {
    const payloadData = removeKeys(state.partner, ['id', 'shopId'])
    payloadData.autoDescription = payload.autoDescription

    try {
      const data = await callPortalGraphQL(dispatch, {
        requestData: { ...API.heureka.updatePartner },
        params: {
          partnerId: getSelectedPartnerId(rootGetters),
          data: payloadData,
        },
        throwError: true,
      })
      commit(HEUREKA.MUTATIONS.GET_PARTNER_RESPONSE, data)
      addSuccessMessage(dispatch, API.heureka.updatePartner.id, 'flashMessages.heurekaOverrides.update')

      return true
    } catch {
      return false
    }
  },
  [HEUREKA.ACTIONS.IMPORT_AVAILABILITY_REQUEST]: ({ dispatch, rootGetters, commit }) => {
    commit(HEUREKA.MUTATIONS.SYNC_AVAILABILITY, true)

    return callPortalGraphQL(dispatch, {
      requestData: { ...API.heureka.importAvailability },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
      },
      throwError: true,
    }).catch(() => {
      commit(HEUREKA.MUTATIONS.SYNC_AVAILABILITY, false)
    })
  },
  [HEUREKA.ACTIONS.IMPORT_PRODUCTS_REQUEST]: ({ dispatch, rootGetters, commit }) => {
    commit(HEUREKA.MUTATIONS.SYNC_PRODUCT, true)

    return callPortalGraphQL(dispatch, {
      requestData: { ...API.heureka.importProducts },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
      },
      throwError: true,
    }).catch(() => {
      commit(HEUREKA.MUTATIONS.SYNC_PRODUCT, false)
    })
  },
  [HEUREKA.ACTIONS.MALL_BRANDS_REQUEST]: async ({ dispatch, commit }) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.heureka.mallBrands },
    })
    commit(HEUREKA.MUTATIONS.MALL_BRANDS_RESPONSE, data)
  },
  [HEUREKA.ACTIONS.RECOMMEND_MALL_BRAND_REQUEST]: ({ dispatch }, payload) => {
    return callPortalGraphQL(dispatch, {
      requestData: { ...API.heureka.recommendMallBrands },
      params: {
        partnerBrandId: payload.partnerBrandId,
      },
    })
  },
  [HEUREKA.ACTIONS.PARTNER_BRAND_PAIR_REQUEST]: ({ dispatch, rootGetters }, payload) => {
    callPortalGraphQL(dispatch, {
      requestData: { ...API.heureka.partnerBrandPair },
      params: {
        partnerBrandId: payload.partnerBrandId,
        partnerId: getSelectedPartnerId(rootGetters),
        data: {
          mallBrandId: payload.mallBrandId,
        },
      },
    })
  },
  [HEUREKA.ACTIONS.GET_PRODUCT_LOG_REQUEST]: async ({ dispatch, rootGetters, commit }) => {
    const data = await callApi(dispatch, {
      requestData: { ...API.heureka.getProductLog },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
      },
      throwError: true,
    })
    commit(HEUREKA.MUTATIONS.GET_PRODUCT_LOG_RESPONSE, data)
  },
  [HEUREKA.ACTIONS.GET_AVAILABILITY_LOG_REQUEST]: async ({ dispatch, rootGetters, commit }) => {
    const data = await callApi(dispatch, {
      requestData: { ...API.heureka.getAvailabilityLog },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
      },
      throwError: true,
    })
    commit(HEUREKA.MUTATIONS.GET_AVAILABILITY_LOG_REQUEST, data)
  },
  [HEUREKA.ACTIONS.PARTNER_CATEGORY_TREE_REQUEST]: async ({ dispatch, commit }) => {
    commit(HEUREKA.MUTATIONS.PARTNER_CATEGORY_TREE_RESPONSE, null)

    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.heureka.partnerCategoryTree },
    })
    commit(HEUREKA.MUTATIONS.PARTNER_CATEGORY_TREE_RESPONSE, JSON.parse(data.tree))
  },
  [HEUREKA.ACTIONS.MALL_CATEGORY_LIST_REQUEST]: async ({ dispatch, commit }) => {
    commit(HEUREKA.MUTATIONS.MALL_CATEGORY_LIST_RESPONSE, [])

    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.heureka.mallCategoryList },
    })
    commit(HEUREKA.MUTATIONS.MALL_CATEGORY_LIST_RESPONSE, data)
  },
  [HEUREKA.ACTIONS.PAIR_CATEGORY_REQUEST]: async ({ dispatch }, payload) => {
    await callPortalGraphQL(dispatch, {
      requestData: { ...API.heureka.pairCategory },
      params: {
        partnerCategoryId: payload.partnerCategoryId,
        id: payload.id,
      },
      throwError: true,
    })
    dispatch(HEUREKA.ACTIONS.PARTNER_CATEGORY_TREE_REQUEST, { partnerId: payload.partnerId })
  },
  [HEUREKA.ACTIONS.RECOMMENDED_CATEGORIES_REQUEST]: ({ dispatch }, payload) => {
    return callPortalGraphQL(dispatch, {
      requestData: { ...API.heureka.recommended },
      params: {
        partnerCategoryId: payload.partnerCategoryId,
      },
    })
  },
  [HEUREKA.ACTIONS.GET_PARTNER_CATEGORY_REQUEST]: async ({ dispatch, commit }, payload) => {
    commit(HEUREKA.MUTATIONS.GET_PARTNER_CATEGORY_RESPONSE, null)

    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.heureka.partnerCategory },
      params: {
        partnerCategoryId: payload.partnerCategoryId,
      },
    })
    commit(HEUREKA.MUTATIONS.GET_PARTNER_CATEGORY_RESPONSE, data)
  },
  [HEUREKA.ACTIONS.GET_PARTNER_CATEGORY_REQUEST_NO_LOADING]: async ({ dispatch, commit }, payload) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.heureka.partnerCategory, id: 'NO-LOADING' },
      params: {
        partnerCategoryId: payload.partnerCategoryId,
      },
    })
    commit(HEUREKA.MUTATIONS.GET_PARTNER_CATEGORY_RESPONSE, data)
  },
  [HEUREKA.ACTIONS.PARTNER_PARAM_LIST_REQUEST]: async ({ dispatch, commit }, payload) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.heureka.partnerParamsList },
      params: {
        partnerCategoryId: payload.partnerCategoryId,
      },
    })
    commit(HEUREKA.MUTATIONS.PARTNER_PARAM_LIST_RESPONSE, data)
  },
  [HEUREKA.ACTIONS.RECOMMENDED_PARTNER_PARAMS_REQUEST]: ({ dispatch }, payload) => {
    return callPortalGraphQL(dispatch, {
      requestData: { ...API.heureka.recommendedPartnerParam },
      params: {
        mallParamId: payload.mallParamId,
        partnerCategoryId: payload.partnerCategoryId,
      },
    })
  },
  [HEUREKA.ACTIONS.PARTNER_PARAM_UNPAIR_REQUEST]: async ({ dispatch }, payload) => {
    await callPortalGraphQL(dispatch, {
      requestData: { ...API.heureka.unpairMallParam },
      params: {
        partnerCategoryId: payload.partnerCategoryId,
        partnerParamId: payload.partnerParamId,
      },
    })
    dispatch(HEUREKA.ACTIONS.PARTNER_PARAM_LIST_REQUEST, {
      partnerCategoryId: payload.partnerCategoryId,
    })
    dispatch(HEUREKA.ACTIONS.GET_PARTNER_CATEGORY_REQUEST_NO_LOADING, {
      partnerCategoryId: payload.partnerCategoryId,
    })
  },
  [HEUREKA.ACTIONS.UPDATE_PARAM_VARIANT_REQUEST]: async ({ dispatch }, payload) => {
    await callPortalGraphQL(dispatch, {
      requestData: { ...API.heureka.updatePartnerParam },
      params: {
        categoryId: payload.categoryId,
        partnerParamId: payload.partnerParamId,
        data: {
          isVariant: payload.isVariant,
          mallParamId: payload.mallParamId,
        },
      },
    })
    dispatch(HEUREKA.ACTIONS.PARTNER_PARAM_LIST_REQUEST, {
      partnerCategoryId: payload.categoryId,
    })
    dispatch(HEUREKA.ACTIONS.GET_PARTNER_CATEGORY_REQUEST_NO_LOADING, {
      partnerCategoryId: payload.categoryId,
    })
  },
  [HEUREKA.ACTIONS.RECOMMENDED_PARTNER_OPTIONS_REQUEST]: ({ dispatch }, payload) => {
    return callPortalGraphQL(dispatch, {
      requestData: { ...API.heureka.recommendedMallOptions },
      params: {
        partnerParamOptionId: payload.partnerParamOptionId,
      },
    })
  },
  [HEUREKA.ACTIONS.PARTNER_PAIR_OPTION_REQUEST]: async ({ dispatch, state }, payload) => {
    await callPortalGraphQL(dispatch, {
      requestData: { ...API.heureka.pairOption },
      params: {
        categoryId: payload.categoryId,
        paramId: payload.paramId,
        partnerParamOptionId: payload.partnerParamOptionId,
        data: {
          mallParamOptionId: payload.mallParamOptionId,
        },
      },
    })
    dispatch(HEUREKA.ACTIONS.GET_PARTNER_CATEGORY_REQUEST_NO_LOADING, {
      partnerCategoryId: state.partnerCategoryDetail.id,
    })
  },
  [HEUREKA.ACTIONS.UPLOAD_PRODUCT_CSV_REQUEST]: async ({ dispatch, rootGetters }, payload) => {
    try {
      const data = await callFileSystemApi(dispatch, {
        requestData: { ...API.heureka.uploadProductCsv },
        params: {
          partnerId: getSelectedPartnerId(rootGetters),
          data: payload.data,
        },
        throwError: true,
      })
      dispatchRoot(dispatch, withNamespace(DATA_GRIDS.HEUREKA_OVERRIDES, GRID.ACTIONS.GRID_FETCH), {
        namespace: DATA_GRIDS.HEUREKA_OVERRIDES,
        params: { partnerId: getSelectedPartnerId(rootGetters), paging: { page: 1, itemsPerPage: 50 } },
      })

      addSuccessMessage(dispatch, API.heureka.uploadProductCsv.id, 'flashMessages.uploadFile')

      return data
    } catch {
      return false
    }
  },
  [HEUREKA.ACTIONS.DELETE_OVERRIDE_REQUEST]: async ({ dispatch, rootGetters }, payload) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.heureka.deleteCategoriesOverride },
        params: {
          ids: payload.ids,
        },
        throwError: true,
      })

      dispatchRoot(dispatch, withNamespace(DATA_GRIDS.HEUREKA_OVERRIDES, GRID.ACTIONS.GRID_FETCH), {
        namespace: DATA_GRIDS.HEUREKA_OVERRIDES,
        params: { partnerId: getSelectedPartnerId(rootGetters), paging: { page: 1, itemsPerPage: 50 } },
      })

      addSuccessMessage(dispatch, API.heureka.uploadProductCsv.id, 'flashMessages.heurekaOverrides.delete')

      return true
    } catch {
      return false
    }
  },
}
