import { PBI } from '@/store/modules/pbi/types'
import { callPortalGraphQL, getSelectedSapId } from '@/store/utils'
import { API } from '@/api'

export default {
  [PBI.ACTIONS.GET_CURRENT_PBI_REQUEST]: ({ dispatch, rootGetters }) => {
    return callPortalGraphQL(dispatch, {
      requestData: { ...API.pbi.getCurrentPbi },
      params: {
        partnerId: getSelectedSapId(rootGetters),
      },
    })
  },
}
