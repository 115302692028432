import { PROMOTION_SOURCES } from '@/enums/PromotionSources'

export function revertPromotions(productPromotions) {
  return productPromotions
    .filter(promotion => promotion.source !== PROMOTION_SOURCES.CAMPAIGN)
    .map(promotion => {
      const fromDate = promotion.from.split(' ')[0]
      const toDate = promotion.to.split(' ')[0]

      return {
        price: parseFloat(promotion.price),
        from: `${fromDate} 00:00:00`,
        to: `${toDate} 00:00:00`,
      }
    })
}

export function stripPromotionsDates(productPromotions) {
  return productPromotions
    .filter(promotion => promotion.source !== PROMOTION_SOURCES.CAMPAIGN)
    .map(promotion => {
      const fromDate = promotion.from.split(' ')[0]
      const toDate = promotion.to.split(' ')[0]

      return {
        price: promotion.price,
        from: fromDate,
        to: toDate,
      }
    })
}
