import { NOTIFICATIONS } from '@/store/modules/notifications/types'
import createState from '@/store/modules/notifications/state'
import { resetState } from '@/store/utils'

export default {
  [NOTIFICATIONS.MUTATIONS.NEW_APP_VERSION]: (state, payload) => {
    state.newAppVersion = payload
  },
  [NOTIFICATIONS.MUTATIONS.ADD_FROM_STREAM]: state => {
    state.unreadCount++
  },
  [NOTIFICATIONS.MUTATIONS.GET_SETTINGS_RESPONSE]: (state, payload) => {
    state.settings = payload
  },
  [NOTIFICATIONS.MUTATIONS.UPDATE_SETTINGS_RESPONSE]: (state, payload) => {
    state.settings = state.settings.map(item => {
      if (item.eventType === payload.eventType) {
        return payload
      } else {
        return item
      }
    })
  },
  [NOTIFICATIONS.MUTATIONS.RESET]: state => {
    resetState(state, createState())
  },
}
