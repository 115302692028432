export default {
  version: process.env.VUE_APP_VERSION || 'dev',
  router: {
    baseUrl: '/',
  },
  chatbot: {
    en: {
      src: '%chatbot-en-src-placeholder%',
    },
    cs: {
      src: '%chatbot-cs-src-placeholder%',
    },
  },
  backend: {
    apiBaseUrl: '%api-base-url-placeholder%/v1',
    graphqlBaseUrl: '%api-base-url-placeholder%/graphql',
    graphqlBaseUrlV2: '%graphql-v2-base-url-placeholder%',
    imageProxyUrl: '%image-proxy-url-placeholder%',
    feflUrl: '%fefl-url-placeholder%',
  },
  checkLogged: {
    refreshTime: 30,
  },
  sentry: {
    endpoint: '%sentry-endpoint-url-placeholder%',
  },
  traslateId: '%translate-id-placeholder%',
  stream: {
    url: '%stream-base-url-placeholder%',
    websocket: '%stream-websocket-url-placeholder%',
    namespace: '/stream',
    refreshTime: 30,
  },
  knowledgeBaseUrl: 'https://knowledgebase.mallgroup.com',
  landingPageBaseUrl: '%landing-page-base-url-placeholder%',
}
