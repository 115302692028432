import { BILLINGS } from '@/store/modules/billings/types'

export default {
  exports: {
    createExport: {
      id: 'CREATE_EXPORT',
      request: ({ partnerId, from, to }) => ({
        query: `
          mutation ($partnerId: String!, $from: String!, $to: String!) {
            invoices {
              export (input: { partnerId: $partnerId, from: $from, to: $to }) {
                location
              }
            }
          }
        `,
        variables: {
          partnerId,
          from,
          to,
        },
      }),
      reduce: data => {
        return data.invoices.export
      },
    },
    downloadExport: {
      id: 'DOWNLOAD_EXPORT',
      request: ({ exportId }) => ({
        url: `/portal/invoices/export/${exportId}/zip`,
        method: 'GET',
        responseType: 'blob',
      }),
    },
  },
  status: {
    id: 'BILLINGS_STATUS',
    request: () => ({
      query: `
        query {
          billings {
            status {
              status
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data.billings.status.status
    },
  },
  requestActivation: {
    id: 'BILLINGS_REQUEST_ACTIVATION',
    request: () => ({
      query: `
        mutation {
          billings {
            requestActivation {
              success
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data.billings.requestActivation.success
    },
  },
  invoices: {
    id: 'INVOICES',
    useCpapiV2: true,
    request: ({ filter, sorter, paging, search }) => ({
      query: `
        query ($filter: [[PartnerInvoicesFilterInput]], $sorter: [PartnerInvoicesSorterInput], $paging: PaginationInput, $search: String) {
          invoices {
            grid (input: {filter: $filter, sorter: $sorter, paging: $paging, search: $search}) {
              items {
                partner
                invoiceNumber
                originDocumentId
                invoiceTypeTag
                purchNoC
                documentType
                createdAt
                deliveryAt
                dueDate
                currency
                total
                note
                supplier {
                  name
                  registrationNumber
                  taxIdentification
                  vatNumber
                  note
                  address {
                    city
                    street
                    zip
                    country
                  }
                  bank {
                    bankAccount
                    bankName
                    iban
                    swift
                  }
                }
                customer {
                  name
                  registrationNumber
                  taxIdentification
                  vatNumber
                  note
                  address {
                    city
                    street
                    zip
                    country
                  }
                }
                items {
                  articleId
                  id
                  orderId
                  title
                  priceWithoutVat
                  quantity
                  totalPrice
                  unit
                  unitPrice
                  vatRate
                }
                taxRecap
                offsets {
                  documentNumber
                  diffPrice
                }
              }
              filter {
                column
                operator
                value
              }
              sorter {
                column
                direction
              }
              paging {
                page
                total
                itemsPerPage
                lastPage
                nextPage
                previousPage
              }
            }
          }
        }
      `,
      variables: {
        filter,
        sorter,
        paging,
        search,
      },
    }),
    reduce: data => {
      return data.invoices.grid
    },
  },
  invoiceFile: {
    id: 'INVOICE_FILE',
    request: ({ id, fileType }) => ({
      url: `/portal/${fileType === BILLINGS.FILE_TYPES.PDF ? 'invoices' : 'overviews'}/${id}/${fileType}`,
      method: 'GET',
      responseType: 'blob',
    }),
  },
  overviewFile: {
    id: 'OVERVIEW_FILE',
    request: ({ id, invoiceNumber }) => ({
      url: `/portal/overviews/${id}/xlsx?invoiceNumber=${invoiceNumber}`,
      method: 'GET',
      responseType: 'blob',
    }),
  },
  invoiceDetail: {
    id: 'INVOICE_DETAIL',
    request: ({ invoiceNumber }) => ({
      query: `
        query ($invoiceNumber: String!) {
          invoices {
            get (input: {invoiceNumber: $invoiceNumber}) {
              partner
              invoiceNumber
              originDocumentId
              referenceId
              documentType
              invoiceTypeTag
              purchNoC
              createdAt
              deliveryAt
              dueDate
              currency
              total
              note
              supplier {
                name
                registrationNumber
                taxIdentification
                vatNumber
                note
                address {
                  city
                  street
                  zip
                  country
                }
                bank {
                  bankAccount
                  bankName
                  iban
                  swift
                }
              }
              customer {
                name
                registrationNumber
                taxIdentification
                vatNumber
                note
                address {
                  city
                  street
                  zip
                  country
                }
              }
              items {
                articleId
                id
                orderId
                title
                priceWithoutVat
                quantity
                totalPrice
                unit
                unitPrice
                vatRate
              }
              taxRecap
              offsets {
                documentNumber
                diffPrice
              }
            }
          }
        }
      `,
      variables: {
        invoiceNumber,
      },
    }),
    reduce: data => {
      return data.invoices.get
    },
  },
  offsets: {
    id: 'OFFSETS',
    useCpapiV2: true,
    request: ({ filter, sorter, paging, search }) => ({
      query: `
        query ($filter: [[PartnerOffsetsFilterInput]], $sorter: [PartnerOffsetsSorterInput], $paging: PaginationInput, $search: String) {
          offsets {
            grid(input: {filter: $filter, sorter: $sorter, paging: $paging, search: $search}) {
              items {
                partner
                documentNumber
                createdAt
                dueDate
                currency
                diffPrice
                variableSymbol
                supplier {
                  name
                  registrationNumber
                  taxIdentification
                  vatNumber
                  note
                  address {
                    city
                    street
                    zip
                    country
                  }
                }
                customer {
                  name
                  registrationNumber
                  taxIdentification
                  vatNumber
                  note
                  address {
                    city
                    street
                    zip
                    country
                  }
                }
                attachment {
                  filename
                  mime
                }
                invoices {
                  id
                  created
                  dueDate
                  sumPrice
                  offsetPrice
                  remainPrice
                  currency
                }
                orders {
                  id
                  created
                  dueDate
                  sumPrice
                  offsetPrice
                  remainPrice
                  currency
                }
              }
              filter {
                column
                operator
                value
              }
              sorter {
                column
                direction
              }
              paging {
                page
                total
                itemsPerPage
                lastPage
                nextPage
                previousPage
              }
            }
          }
        }      
      `,
      variables: {
        filter,
        sorter,
        paging,
        search,
      },
    }),
    reduce: data => {
      return data.offsets.grid
    },
  },
  offsetPdf: {
    id: 'OFFSET_PDF',
    request: ({ documentNumber }) => ({
      url: `/portal/offsets/${documentNumber}/pdf`,
      method: 'GET',
      responseType: 'blob',
    }),
  },
  offsetDetail: {
    id: 'OFFSET_DETAIL',
    request: ({ documentNumber }) => ({
      query: `
        query ($documentNumber: String!) {
          offsets {
            get (input: {
              documentNumber: $documentNumber
            }) {
              partner
              documentNumber
              createdAt
              dueDate
              currency
              diffPrice
              variableSymbol
              supplier {
                name
                registrationNumber
                taxIdentification
                vatNumber
                note
                address {
                  city
                  street
                  zip
                  country
                }
              }
              customer {
                name
                registrationNumber
                taxIdentification
                vatNumber
                note
                address {
                  city
                  street
                  zip
                  country
                }
              }
              attachment {
                filename
                mime
              }
              invoices {
                id
                created
                dueDate
                sumPrice
                offsetPrice
                remainPrice
                currency
              }
              orders {
                id
                created
                dueDate
                sumPrice
                offsetPrice
                remainPrice
                currency
              }
            }
          }
        }
      `,
      variables: {
        documentNumber,
      },
    }),
    reduce: data => {
      return data.offsets.get
    },
  },
}
