export default {
  getCampaignList: {
    id: 'CAMPAIGN_LIST',
    request: ({ paging, partnerId }) => {
      return {
        query: `
            query ($paging: PagingInput, $partnerId: String!) { 
                campaigns {
                  all (input: {paging: $paging, partnerId: $partnerId}) {
                    items {
                      id
                      name 
                      status
                      min_discount
                      min_discount_rrp
                      product_range:article_count {
                          min
                          max
                      }
                      duration {
                          from
                          to
                      } 
                    }
                    
                    paging {
                      page
                      total
                      itemsPerPage
                      lastPage
                      nextPage
                      previousPage
                    }
                    filter {
                      column
                      operator
                      value
                    }
                    sorter {
                      column
                      direction
                    }
                    search
                  }
                }
            }
              `,
        variables: {
          paging,
          partnerId,
        },
      }
    },
    reduce: data => {
      // todo: solve sorting, search
      return data.campaigns.all
    },
  },
  getCampaignDetail: {
    id: 'CAMPAIGN_DETAIL',
    request: ({ id, partnerId }) => ({
      query: `
          query($campaignId: String!, $partnerId: String!) {
            campaigns {
                get ( input:{campaignId: $campaignId, partnerId: $partnerId} )
                {
                  id
                  name
                  description
                  labels
                  locked
                  status
                  partner_blacklist
                  min_discount
                  min_discount_rrp
                  article_count {min, max}
                  categories
                  divisions
                  shops
                  duration {from, to}
                }
            }
          }
        `,
      variables: {
        campaignId: id,
        partnerId: partnerId,
      },
    }),
    reduce: data => {
      return data.campaigns.get
    },
  },
  getUploadedCsv: {
    id: 'UPLOADED_CSV',
    request: ({ paging, partnerId, params }) => {
      return {
        query: `
          query ($paging: PagingInput, $campaignId: String!, $partnerId: String!) {
           campaignImports { 
            all (input: {paging: $paging, campaignId: $campaignId, partnerId: $partnerId}) {
              items {
                id 
                status
                products_count 
                structured_errors {
                  message
                  type
                  data {
                    key
                    value
                  }
                }
                created_at
                confirm_error
              }
              
              paging {
                page
                total
                itemsPerPage
                lastPage
                nextPage
                previousPage
              }
              filter {
                column
                operator
                value
              }
              sorter {
                column
                direction
              }
              search
            }
          }
        }
              `,
        variables: {
          campaignId: params.id,
          partnerId: partnerId,
          paging: paging,
        },
      }
    },
    reduce: data => {
      return data.campaignImports.all
    },
  },
  uploadCsv: {
    id: 'CSV_UPLOAD',
    request: ({ formData }) => ({
      url: '/portal/campaign-import',
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    }),
    reduce: data => {
      return data
    },
  },
  setImportToProcess: {
    id: 'SET_IMPORT_TO_PROCESS',
    request: ({ importId, partnerId, campaignId, status, allowedToImport }) => ({
      url: '/portal/allow-process-campaign-import',
      method: 'POST',
      data: {
        importId: importId,
        partnerId: partnerId,
        campaignId: campaignId,
        status: status,
        allowToImport: allowedToImport,
      },
    }),
  },
  lastImport: {
    id: 'LAST_IMPORT',
    request: ({ campaignId, partnerId }) => {
      return {
        query: `
          query ($campaignId: String!, $partnerId: String!) {
             campaignImports { 
                last (input: {campaignId: $campaignId, partnerId: $partnerId}) {
                  id
                  partner_id
                  created_at
                }
             }
          }
        `,
        variables: {
          campaignId,
          partnerId,
        },
      }
    },
  },
  downloadImport: {
    id: 'DOWNLOAD_IMPORT',
    request: ({ campaignId, importId, partnerId }) => ({
      url: `/portal/campaign/${campaignId}/import/${importId}/download?partnerId=${partnerId}`,
      method: 'GET',
      responseType: 'blob',
    }),
  },
}
