import { SHOP_ID } from '@/enums/shopId'

export const COUNTRY = {
  CZ: 'CZ',
  SK: 'SK',
  HU: 'HU',
  SI: 'SI',
}

export const COUNTRY_TO_LANG = {
  [COUNTRY.CZ]: 'cs',
  [COUNTRY.SK]: 'sk',
  [COUNTRY.HU]: 'en',
  [COUNTRY.SI]: 'sl',
}

export const COUNTRY_TO_SHOP_ID = {
  [COUNTRY.CZ]: SHOP_ID.CZ10MA,
  [COUNTRY.SK]: SHOP_ID.SK10MA,
  [COUNTRY.HU]: SHOP_ID.HU20MA,
  [COUNTRY.SI]: SHOP_ID.SI10SI,
}
