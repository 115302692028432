<template>
  <confirm-dialog
    :value="!adminEmail && !passwordValid"
    :title="$t('profile.changePassword.dialog.title')"
    cancel-btn-text="profile.changePassword.dialog.cancel"
    confirm-btn-text="profile.changePassword.dialog.confirm"
    :on-confirm="onConfirm"
    :on-cancel="logout"
    :is-sending="state.isSending"
  >
    <template slot="body">
      <p class="body-2">
        {{ $t('profile.changePassword.dialog.body') }}
      </p>
      <change-password-form
        ref="form"
        :show-button="false"
        :on-submit="onPasswordChange"
        :is-sending="state.isSending"
      />
    </template>
  </confirm-dialog>
</template>

<script>
import ChangePasswordForm from '@/components/profile/password/ChangePasswordForm'
import { mapActions, mapGetters, mapState } from 'vuex'
import { PROFILE } from '@/store/modules/profile/types'
import { REQUESTS_STATE } from '@/store/modules/api/types'
import { API } from '@/api'
import ConfirmDialog from '@/components/commons/modal/ConfirmDialog'
import { AUTH } from '@/store/modules/auth/types'

export default {
  name: 'WeakPasswordDialog',
  components: { ConfirmDialog, ChangePasswordForm },
  computed: {
    ...mapGetters(REQUESTS_STATE.NAMESPACE, [REQUESTS_STATE.GETTERS.GET_STATE]),
    ...mapState(AUTH.NAMESPACE, ['passwordValid', 'adminEmail']),
    state() {
      return this[REQUESTS_STATE.GETTERS.GET_STATE](API.account.changePassword.id)
    },
  },
  created() {
    if (this.adminEmail) {
      this[AUTH.ACTIONS.SET_PASSWORD_VALID](true)
    }
  },
  methods: {
    ...mapActions(PROFILE.NAMESPACE, [PROFILE.ACTIONS.CHANGE_PASSWORD_REQUEST]),
    ...mapActions(AUTH.NAMESPACE, [AUTH.ACTIONS.LOGOUT_REQUEST, AUTH.ACTIONS.SET_PASSWORD_VALID]),
    async onPasswordChange(form) {
      const response = await this[PROFILE.ACTIONS.CHANGE_PASSWORD_REQUEST](form)
      if (response?.success) {
        this[AUTH.ACTIONS.SET_PASSWORD_VALID](true)
      }
    },
    onConfirm() {
      return this.$refs.form.submit()
    },
    logout() {
      this[AUTH.ACTIONS.LOGOUT_REQUEST]()
    },
  },
}
</script>
