export const SERVICES_LABEL = {
  ORDERS: 'ORDERS',
  LISTING: 'LISTING',
  AVAILABILITY: 'AVAILABILITY',
  TRANSPORT: 'TRANSPORT',
  STATS: 'STATS',
  INVOICES: 'INVOICES',
  MALL_DELIVERY: 'MALL_DELIVERY',
  MALL_FEED: 'MALL_FEED',
}

export const SERVICES_ESHOP = {
  SHOPTET: 'SHOPTET',
  MAGENTO: 'MAGENTO',
  PRESTASHOP: 'PRESTASHOP',
  WOOCOMMERCE: 'WOOCOMMERCE',
  SHOPIFY: 'SHOPIFY',
  SHOPSYS: 'SHOPSYS',
  ALTUS_VARIO: 'ALTUS_VARIO',
  BASELINKER: 'BASELINKER',
  SW_POHODA: 'SW_POHODA',
  HELIOS: 'HELIOS',
  BINARGON: 'BINARGON',
  FAST_CENTRIK: 'FAST_CENTRIK',
  MAILSTEP: 'MAILSTEP',
  CUSTOM: 'CUSTOM',
  REST: 'REST',
}

export const GQL_SERVICE_ERRORS = {
  MPAPI_PRICE_DIFFERENCE_VALIDATION: 'MPAPI_PRICE_DIFFERENCE_VALIDATION',
}
