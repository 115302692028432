export const blobToUtf8 = data => {
  return [
    new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM - https://en.wikipedia.org/wiki/Byte_order_mark
    data,
  ]
}

export const stringToUtf8 = data => {
  const bom = '\ufeff' // UTF-8 BOM - https://en.wikipedia.org/wiki/Byte_order_mark

  return bom + data
}

export const base64ToBlob = data => {
  return Uint8Array.from(atob(data), c => c.charCodeAt(0))
}

export const downloader = (data, fileName, type = 'text/plain') => {
  let parts = data
  if (!Array.isArray(data)) {
    parts = [data]
  }

  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(new Blob(parts, { type }))
  link.target = '_blank'
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}

export const urlDownloader = (url, fileName = null) => {
  const link = document.createElement('a')
  link.href = url
  link.target = '_blank'
  if (fileName) {
    link.download = fileName
  }
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
