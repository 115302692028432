export const SERVICE_QUALITY = {
  NAMESPACE: 'serviceQuality',
  ACTIONS: {
    GET_SERVICE_QUALITY_REQUEST: 'GET_SERVICE_QUALITY_REQUEST',
    GET_SERVICE_QUALITY_EXPORT_REQUEST: 'GET_SERVICE_QUALITY_EXPORT_REQUEST',
  },
  MUTATIONS: {
    GET_SERVICE_QUALITY_RESPONSE: 'GET_SERVICE_QUALITY_RESPONSE',
    RESET: 'RESET',
  },
}
