import App from '@/views/App'
import ordersRouter from '@/router/routes/orders'
import brandsRouter from '@/router/routes/brands'
import { ACL } from '@/enums'
import productRouter from '@/router/routes/products'
import serviceQualityRouter from '@/router/routes/service-quality'
import Router from '@/router/Router'
import userRouter from '@/router/routes/user'
import partnerRouter from '@/router/routes/partner'
import heurekaRouter from '@/router/routes/heureka'
import mallFeedRouter from '@/router/routes/mall-feed'
import marketingRouter from '@/router/routes/marketing'
import deliveriesRouter from '@/router/routes/deliveries'
import faqRouter from '@/router/routes/faq'
import billingsRouter from '@/router/routes/billings'
import servicesRouter from '@/router/routes/services'
import { ROUTES, SECURITY } from '@/router/routes/index'

export default [
  {
    path: '/',
    component: App,
    children: [
      {
        path: 'not-allowed',
        name: ROUTES.NOT_ALLOWED,
        component: () => import('../../views/app/NotAllowedPage'),
      },
      {
        path: '',
        name: ROUTES.HOME,
        component: () =>
          import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard" */ '../../views/app/DashboardPage'),
      },
      {
        path: 'switch-partner/:partnerId',
        name: ROUTES.SWITCH_PARTNER,
        component: () =>
          import(
            /* webpackPrefetch: true */ /* webpackChunkName: "switch-partner" */ '../../views/app/SwitchPartnerPage'
          ),
        meta: {
          backLink: false,
        },
      },
      {
        path: 'workflow/:step?',
        name: ROUTES.WORKFLOW,
        component: () =>
          import(/* webpackPrefetch: true */ /* webpackChunkName: "workflow" */ '../../views/app/WorkflowPage'),
      },
      {
        path: 'sla',
        component: () => import('../../views/app/ServiceQualityPage'),
        children: serviceQualityRouter,
      },
      {
        path: 'orders-beta',
        redirect: 'orders',
      },
      {
        path: 'orders',
        component: () =>
          import(/* webpackPrefetch: true */ /* webpackChunkName: "orders" */ '../../views/app/OrdersPage'),
        children: ordersRouter,
      },
      {
        path: 'orders/:orderId',
        name: ROUTES.ORDER_DETAIL,
        component: () =>
          import(/* webpackPrefetch: true */ /* webpackChunkName: "orders" */ '../../views/app/OrderDetailPage'),
        meta: {
          resource: ACL.ORDERS,
        },
      },
      {
        path: 'categories',
        name: ROUTES.CATEGORIES,
        component: () =>
          import(/* webpackPrefetch: true */ /* webpackChunkName: "categories" */ '../../views/app/CategoriesPage'),
      },
      {
        path: 'products-beta',
        component: () =>
          import(/* webpackPrefetch: true */ /* webpackChunkName: "products" */ '../../views/app/ProductsPage'),
        children: productRouter,
      },
      {
        path: 'brands',
        component: () =>
          import(
            /* webpackPrefetch: true */ /* webpackChunkName: "brands" */ '../../views/app/products/ProductsBrandsPage'
          ),
        children: brandsRouter,
      },
      {
        path: 'products-beta/detail/:id?',
        name: ROUTES.PRODUCT_DETAIL,
        component: () =>
          import(/* webpackPrefetch: true */ /* webpackChunkName: "products" */ '../../views/app/ProductDetailPage'),
      },
      {
        path: 'user',
        component: Router,
        children: userRouter,
      },
      {
        path: 'partner',
        component: () =>
          import(/* webpackPrefetch: true */ /* webpackChunkName: "partner" */ '../../views/app/PartnerPage'),
        children: partnerRouter,
      },
      {
        path: 'heureka-feed',
        component: () =>
          import(/* webpackPrefetch: true */ /* webpackChunkName: "heureka" */ '../../views/app/HeurekaFeedPage'),
        children: heurekaRouter,
      },
      {
        path: 'mall-feed',
        component: () =>
          import(/* webpackPrefetch: true */ /* webpackChunkName: "mall-feed" */ '../../views/app/MallFeedPage'),
        children: mallFeedRouter,
      },
      {
        path: 'mall-feed/stats/feed/:feedId/job/:jobId/error-log',
        name: ROUTES.MALL_FEED_PRODUCT_ERROR_LOG,
        component: () =>
          import(
            /* webpackPrefetch: true */ /* webpackChunkName: "mall-feed" */ '../../views/app/mall-feed/MallFeedProductErrorLogPage'
          ),
        meta: {
          resource: ACL.MALL_FEED,
        },
      },
      {
        path: 'marketing',
        component: Router,
        children: marketingRouter,
      },
      {
        path: 'deliveries',
        component: () =>
          import(/* webpackPrefetch: true */ /* webpackChunkName: "deliveries" */ '../../views/app/DeliveriesPage'),
        children: deliveriesRouter,
      },
      {
        path: 'faq',
        component: Router,
        children: faqRouter,
      },
      {
        path: 'billings',
        component: () =>
          import(/* webpackPrefetch: true */ /* webpackChunkName: "billings" */ '../../views/app/BillingsPage'),
        children: billingsRouter,
      },
      {
        path: 'billings/invoice-detail/:id',
        name: ROUTES.INVOICE_DETAIL,
        component: () =>
          import(
            /* webpackPrefetch: true */ /* webpackChunkName: "billings" */ '../../views/app/BillingsInvoiceDetailPage'
          ),
      },
      {
        path: 'billings/offset-detail/:id',
        name: ROUTES.OFFSET_DETAIL,
        component: () =>
          import(
            /* webpackPrefetch: true */ /* webpackChunkName: "billings" */ '../../views/app/BillingsOffsetDetailPage'
          ),
      },
      {
        path: 'self-billing',
        redirect: { name: ROUTES.BILLINGS.BILLINGS_SELF },
      },
      {
        path: 'statistics',
        name: ROUTES.STATISTICS,
        component: () =>
          import(
            /* webpackPrefetch: true */ /* webpackChunkName: "statistics" */ '../../views/app/statistics/StatisticsPage'
          ),
      },
      {
        path: 'services',
        component: Router,
        children: servicesRouter,
      },
      {
        path: 'financing',
        name: ROUTES.FINANCING,
        component: () =>
          import(
            /* webpackPrefetch: true */ /* webpackChunkName: "financing" */ '../../views/app/financing/FinancingPage'
          ),
      },
    ],
  },
  {
    path: '/',
    component: () =>
      import(/* webpackPrefetch: true */ /* webpackChunkName: "business-terms" */ '../../views/AppContent'),
    children: [
      {
        path: 'business-terms',
        redirect: { name: ROUTES.BUSINESS_TERMS, params: { country: 'cz' } },
        meta: {
          auth: SECURITY.PUBLIC,
        },
      },
      {
        path: 'business-terms/cs',
        redirect: { name: ROUTES.BUSINESS_TERMS, params: { country: 'cz' } },
        meta: {
          auth: SECURITY.PUBLIC,
        },
      },
      {
        path: 'business-terms/sk',
        redirect: { name: ROUTES.BUSINESS_TERMS, params: { country: 'sk' } },
        meta: {
          auth: SECURITY.PUBLIC,
        },
      },
      {
        path: 'business-terms/en',
        redirect: { name: ROUTES.BUSINESS_TERMS, params: { country: 'si' } },
        meta: {
          auth: SECURITY.PUBLIC,
        },
      },
      {
        path: 'business-terms/:country/history',
        name: ROUTES.HISTORY,
        component: () => import('../../views/content/HistoryPage'),
        meta: {
          auth: SECURITY.PUBLIC,
        },
      },
      {
        path: 'business-terms/:country/:lang?',
        name: ROUTES.BUSINESS_TERMS,
        component: () => import('../../views/content/ContentPage'),
        meta: {
          auth: SECURITY.PUBLIC,
        },
      },
    ],
  },
  {
    path: '/login',
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "login" */ '../../views/Login'),
    children: [
      {
        path: '',
        name: ROUTES.LOGIN,
        component: () =>
          import(
            /* webpackPrefetch: true */ /* webpackChunkName: "login" */ '../../components/login/LoginFormHandler.vue'
          ),
        meta: {
          auth: SECURITY.PUBLIC,
          backLink: false,
        },
      },
      {
        path: 'token/:token',
        name: ROUTES.LOGIN_WITH_TOKEN,
        component: () =>
          import(
            /* webpackPrefetch: true */ /* webpackChunkName: "login" */ '../../components/login/LoginWithToken.vue'
          ),
        meta: {
          auth: SECURITY.PUBLIC,
          backLink: false,
        },
      },
      {
        path: 'forgot-password',
        name: ROUTES.FORGOT_PASSWORD,
        component: () =>
          import(
            /* webpackPrefetch: true */ /* webpackChunkName: "login" */ '../../components/login/ForgotPasswordFormHandler.vue'
          ),
        meta: {
          auth: SECURITY.PUBLIC,
        },
      },
      {
        path: 'reset-password/:token',
        name: ROUTES.RESET_PASSWORD,
        component: () =>
          import(
            /* webpackPrefetch: true */ /* webpackChunkName: "login" */ '../../components/login/ResetPasswordHandler.vue'
          ),
        meta: {
          auth: SECURITY.PUBLIC,
          backLink: false,
        },
      },
    ],
  },
  {
    path: '/registration',
    name: ROUTES.REGISTRATION,
    component: () =>
      import(/* webpackPrefetch: true */ /* webpackChunkName: "registration" */ '../../views/Registration'),
    meta: {
      auth: SECURITY.PUBLIC,
      backLink: false,
    },
  },
  {
    path: '/registration-submit',
    name: ROUTES.REGISTRATION_SUBMIT,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "registration-submit" */ '../../views/RegistrationSubmit'
      ),
    meta: {
      auth: SECURITY.PUBLIC,
      backLink: false,
    },
  },
  {
    path: '/registration-complete/:passwordToken/:loginToken',
    name: ROUTES.REGISTRATION_COMPLETE,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "registration-complete" */ '../../views/RegistrationComplete'
      ),
    meta: {
      auth: SECURITY.PUBLIC,
      backLink: false,
    },
  },
  {
    path: '/registration-confirm/:token',
    name: ROUTES.REGISTRATION_CONFIRM,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "registration-confirm" */ '../../views/RegistrationConfirm'
      ),
    meta: {
      auth: SECURITY.PUBLIC,
      backLink: false,
    },
  },
  {
    path: '/orders-stock-export/:ids',
    name: ROUTES.ORDERS_STOCK_EXPORT,
    component: () =>
      import(/* webpackPrefetch: true */ /* webpackChunkName: "orders" */ '../../views/OrdersStockExport'),
  },
  {
    path: '*',
    name: ROUTES.NOT_FOUND,
    component: () => import('../../views/NotFound'),
    meta: {
      auth: SECURITY.PUBLIC,
      backLink: false,
    },
  },
]
