export default {
  standardPriceListDetail: {
    id: 'STANDARD_PRICE_LIST_DETAIL',
    request: ({ partnerId }) => ({
      query: `
        fragment priceListItem on CategoryTreePriceListItem {
          priceListItemId
          id
          name
          level
          commission
          brands {
            priceListItemId
            id
            commission
          }
        }
        
        query ($partnerId: String!) {
          priceList {
            partner {
              get (input: { partnerId: $partnerId }) {
                id
                name
                validFrom
                validTo
                state
                type
                commission
                shopId
                partners {
                  id
                  sapId
                  name
                  mallName
                }
                items {
                  ...priceListItem
                  items {
                    ...priceListItem
                    items {
                      ...priceListItem
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        partnerId,
      },
    }),
    reduce: data => {
      return data.priceList.partner.get
    },
  },
}
