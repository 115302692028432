<template>
  <div class="loader">
    <slot></slot>
    <div v-show="isLoading" class="loader__overlay">
      <div class="loader__circle">
        <div class="d-flex">
          <img :src="require(`@/assets/img/loader/loader-circle.svg`)" width="47" height="47">
        </div>
      </div>
      <div class="loader__circle loader__circle--2">
        <div class="d-flex">
          <img :src="require(`@/assets/img/loader/loader-circle.svg`)" width="47" height="47">
        </div>
      </div>
      <div class="loader__logo">
        <img :src="require(`@/assets/img/loader/mp-logo.svg`)">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style scoped lang="sass">
.loader
  @keyframes rotate
    to
      transform: rotate(360deg)

  @keyframes rotate-2
    from
      transform: rotate(180deg)
    to
      transform: rotate(540deg)

  position: relative

  &__overlay
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    display: flex
    align-items: center
    justify-content: center
    z-index: 1
    background: rgba(255, 255, 255, .5)

  &__logo
    width: 62px
    height: 62px

  &__circle
    $size: 94px

    position: absolute
    top: 50%
    left: 50%
    margin-top: $size / -2
    margin-left: $size / -2
    width: $size
    height: $size
    animation: rotate 1.5s infinite

    &--2
      animation: rotate-2 1.5s infinite
</style>
