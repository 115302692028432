import { CONTRACT } from '@/store/modules/contract/types'
import createState from '@/store/modules/contract/state'
import { resetState } from '@/store/utils'
import { i18nCountries } from '@/localization'

export default {
  [CONTRACT.MUTATIONS.CONTRACT_RESPONSE]: (state, payload) => {
    state.contract = payload
  },
  [CONTRACT.MUTATIONS.CONTRACT_UPDATE_RESPONSE]: (state, payload) => {
    state.contract = payload
  },
  [CONTRACT.MUTATIONS.UPDATE_VALID_CONTRACT_FORM]: (state, payload) => {
    state.isValidContractForm = payload
  },
  [CONTRACT.MUTATIONS.RESET]: state => {
    resetState(state, createState())
  },
  [CONTRACT.MUTATIONS.ARES_RESPONSE]: (state, payload) => {
    state.contract.company = payload.company
    state.contract.in = payload.in
    state.contract.vatId = payload.vatId
    state.contract.street = payload.street
    state.contract.city = payload.city
    state.contract.state = payload.state ? i18nCountries.getAlpha2Code(payload.state, 'cs') : null
    state.contract.zip = payload.zip
  },
}
