import { CAMPAIGNS } from '@/store/modules/campaigns/types'
import { resetState } from '@/store/utils'
import createState from '@/store/modules/contract/state'

export default {
  [CAMPAIGNS.MUTATIONS.DETAIL_RESPONSE]: (state, data) => {
    state.campaign = data
  },
  [CAMPAIGNS.MUTATIONS.CSV_IMPORT_RESPONSE]: (state, data) => {
    state.csvImport = data
  },
  [CAMPAIGNS.MUTATIONS.CSV_IMPORT_FAILED]: (state, data) => {
    state.csvImportFailed = data
  },
  [CAMPAIGNS.MUTATIONS.LAST_IMPORT_DATA]: (state, data) => {
    state.lastImport = data
  },
  [CAMPAIGNS.MUTATIONS.RESET]: state => {
    resetState(state, createState())
  },
}
