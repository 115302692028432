<template>
  <span>
    <auth-loading v-if="checkingAuth" />
    <router-view />
  </span>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { AUTH } from '@/store/modules/auth/types'
import { MDP_STATUS } from '@/enums'
import AuthLoading from '@/components/app/AuthLoading'
import { FEFL } from '@/store/modules/fefl/types'

export default {
  name: 'App',
  components: { AuthLoading },
  data() {
    return {
      feflInterval: null,
    }
  },
  computed: {
    ...mapState(AUTH.NAMESPACE, ['selectedPartner', 'account', 'adminEmail', 'checkingAuth']),
  },
  watch: {
    selectedPartner() {
      this.submitAnalysis()
    },
  },
  created() {
    clearInterval(this.feflInterval)
    this.feflInterval = setInterval(async () => {
      this[FEFL.ACTIONS.GET_FEFL_REQUEST]()
    }, 300_000)
    this[FEFL.ACTIONS.GET_FEFL_REQUEST]()
    this.submitAnalysis()
  },
  methods: {
    ...mapActions(FEFL.NAMESPACE, [FEFL.ACTIONS.GET_FEFL_REQUEST]),
    submitAnalysis() {
      if (this.selectedPartner && this.account) {
        const googleAnalyticsObj = {
          user: {
            employee: this.adminEmail !== null,
            id: `${this.selectedPartner.sapId}:${this.selectedPartner.mallName}:${this.account.id}`,
            provider: this.selectedPartner.provider,
            mdp: this.selectedPartner.mdpStatus === MDP_STATUS.ACTIVE,
            workflowStatus: this.selectedPartner.workflow.status,
            billing: this.selectedPartner.billingStatus === 'ACTIVE',
            shopLangRank: `${this.selectedPartner.shopId}:${this.$i18n.locale}:${this.selectedPartner.rating}`,
          },
        }
        //TODO nechce se to poslat!!
        this.$gtm.trackEvent(googleAnalyticsObj)
      }
    },
  },
}
</script>
