export const COUNTRIES = {
  CZ: 'CZ',
  SK: 'SK',
  PL: 'PL',
  HU: 'HU',
  SI: 'SI',
  HR: 'HR',
}

export const LANG_TO_COUNTRIES = {
  cs: 'CZ',
  sk: 'SK',
  pl: 'PL',
  sl: 'SI',
  hu: 'HU',
  hr: 'HR',
}
