export const SERVICES_ROUTES = {
  SERVICES: 'services',
  SERVICES_DETAIL: 'services-detail',
  MALL_PAY: 'mall-pay',
}

export default [
  {
    path: '',
    name: SERVICES_ROUTES.SERVICES,
    component: () =>
      import(/* webpackPrefetch: true */ /* webpackChunkName: "services" */ '../../../views/app/services/ServicesPage'),
  },
  {
    path: 'detail/:serviceName',
    name: SERVICES_ROUTES.SERVICES_DETAIL,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "services" */ '../../../views/app/services/ServiceDetailPage'
      ),
  },
  {
    path: 'mall-pay',
    name: SERVICES_ROUTES.MALL_PAY,
    component: () =>
      import(/* webpackPrefetch: true */ /* webpackChunkName: "services" */ '../../../views/app/services/MallPayPage'),
  },
]
