import { MALL_DELIVERY_TYPE } from '@/enums'

export default {
  getDip: {
    id: 'MDP_GET_SPECTRUM',
    request: ({ partnerId }) => ({
      query: `
        query($partnerId: String!) {
          dip {
            config(input: { partnerId: $partnerId }) {
              status
              fee
              labelType
              collectionsEnabled
              collectionPlaceId
              warehouseAddress {
                street
                city
                zip
                countryCode
              }
              contact {
                firstName
                lastName
                phoneNumber
                email
              }
            }
          }
        }
      `,
      variables: {
        partnerId,
      },
    }),
    reduce: data => {
      return data.dip.config
    },
  },
  dipGrid: {
    id: 'DIP_GRID',
    request: ({ partnerId }) => ({
      query: `
        query($partnerId: String!) {
          dip {
            deliveryGrid(input: { partnerId: $partnerId }) {
              items {
                transport_name
                pricing {
                  type
                  price
                  cod_price
                  limit
                }
                price_levels {
                  type
                  is_consistent
                  consistency_error
                  transport {
                    limit
                    price
                  }
                  payment {
                    limit
                    price
                  }
                }
                code
                title
                delivery_method_id
                price
                cod_price
                free_limit
                delivery_delay
                is_pickup_point
                height {
                  min
                  max
                }
                width {
                  min
                  max
                }
                length {
                  min
                  max
                }
                weight {
                  min
                  max
                }
                priority
                package_size
                mall_delivery_type
              }
              filter {
                column
                operator
                value
              }
              sorter {
                column
                direction
              }
              paging {
                page
                total
                itemsPerPage
                lastPage
                nextPage
                previousPage
              }
              search
            }
          }
        }
      `,
      variables: {
        partnerId,
      },
    }),
    reduce: data => {
      // TODO: move isActive & isDisabled to BE
      data.dip.deliveryGrid.items = data.dip.deliveryGrid.items.map(item => {
        item.isActive = item.mall_delivery_type === MALL_DELIVERY_TYPE.MDP_SPECTRUM
        item.isDisabled = item.mall_delivery_type === MALL_DELIVERY_TYPE.MDP_CLASSIC
        return item
      })

      return data.dip.deliveryGrid
    },
  },
}
