export const API_LIMIT = {
  NAMESPACE: 'apiLimit',
  ACTIONS: {
    GET_PARTNER_DETAIL_API_LIMIT_REQUEST: 'GET_PARTNER_DETAIL_API_LIMIT_REQUEST',
    GET_PARTNER_DETAIL_API_LIMIT_HISTORY_REQUEST: 'GET_PARTNER_DETAIL_API_LIMIT_HISTORY_REQUEST',
    GET_PARTNER_DETAIL_API_LIMIT_CURRENT_REQUEST: 'GET_PARTNER_DETAIL_API_LIMIT_CURRENT_REQUEST',
  },
  MUTATIONS: {
    GET_PARTNER_DETAIL_API_LIMIT_RESPONSE: 'GET_PARTNER_DETAIL_API_LIMIT_RESPONSE',
    GET_PARTNER_DETAIL_API_LIMIT_HISTORY_RESPONSE: 'GET_PARTNER_DETAIL_API_LIMIT_HISTORY_RESPONSE',
    GET_PARTNER_DETAIL_API_LIMIT_CURRENT_RESPONSE: 'GET_PARTNER_DETAIL_API_LIMIT_CURRENT_RESPONSE',
    RESET: 'RESET',
  },
}
