export function arrayContainsItemsFromArray(items, arrayToLookIn) {
  const arrayToLookInUppercase = arrayToLookIn.map(item => item.toUpperCase())
  const itemsInUppercase = items.map(item => item.toUpperCase())
  return itemsInUppercase.some(item => arrayToLookInUppercase.includes(item))
}

export function arrayContainsAllItemsFromArray(items, arrayToLookIn) {
  const arrayToLookInUppercase = arrayToLookIn.map(item => item.toUpperCase())
  const itemsInUppercase = items.map(item => item.toUpperCase())
  return itemsInUppercase.every(item => arrayToLookInUppercase.includes(item))
}

export function mainAdditionalImage(images) {
  const mainImage = images.find(image => image.main)
  const energyLabel = images.find(image => image.energy_label)
  const informationList = images.find(image => image.information_list)
  return energyLabel?.url === mainImage?.url || informationList?.url === mainImage?.url
}
