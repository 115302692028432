export const DIP = {
  NAMESPACE: 'dip',
  ACTIONS: {
    GET_DIP_REQUEST: 'GET_DIP_REQUEST',
  },
  MUTATIONS: {
    GET_DIP_RESPONSE: 'GET_DIP_RESPONSE',
    RESET: 'RESET',
  },
}
