import { DELIVERIES } from '@/store/modules/deliveries/types'
import { callCpapiV2GraphQL, callPortalGraphQL, dispatchRoot, getSelectedPartnerId, withNamespace } from '@/store/utils'
import { API } from '@/api'
import { addErrorMessage, addSuccessMessage } from '@/services/flashMessages'
import { DATA_GRIDS } from '@/store/grid/grids'
import { GRID } from '@/store/grid/store/types'
import { SHOP_ID } from '@/enums'
import { DIP } from '@/store/modules/dip/types'

export default {
  [DELIVERIES.ACTIONS.DELETE_DELIVERY_REQUEST]: async ({ dispatch }, payload) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.deliveries.deliveryDelete },
        params: {
          deliveryCode: payload.deliveryCode,
        },
        throwError: true,
      })

      dispatchRoot(dispatch, withNamespace(DATA_GRIDS.DELIVERIES, GRID.ACTIONS.GRID_FETCH), {
        namespace: DATA_GRIDS.DELIVERIES,
      })
      addSuccessMessage(dispatch, API.deliveries.deliveryDelete.id, 'flashMessages.deliveries.delete')

      return true
    } catch {
      return false
    }
  },
  [DELIVERIES.ACTIONS.GET_DELIVERY_REQUEST]: async ({ dispatch, commit }, payload) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.deliveries.getDelivery },
      params: {
        deliveryCode: payload.deliveryCode,
      },
    })
    commit(DELIVERIES.MUTATIONS.GET_DELIVERY_RESPONSE, data)
  },
  [DELIVERIES.ACTIONS.GET_AVAILABLE_DELIVERIES_REQUEST]: async ({ dispatch, commit }, payload) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.deliveries.getAvailableDeliveries },
      params: {
        deliveryCode: payload.deliveryCode,
      },
    })
    commit(DELIVERIES.MUTATIONS.GET_AVAILABLE_DELIVERIES_RESPONSE, data)
  },
  [DELIVERIES.ACTIONS.UPDATE_DELIVERY_REQUEST]: async ({ dispatch }, payload) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.deliveries.updateDelivery },
        params: {
          deliveryCode: payload.deliveryCode,
          data: payload.data,
        },
        throwError: true,
      })

      addSuccessMessage(dispatch, API.deliveries.updateDelivery.id, 'flashMessages.deliveries.update')
      return true
    } catch {
      return false
    }
  },
  [DELIVERIES.ACTIONS.UPDATE_DELIVERY_MDP_REQUEST]: async ({ dispatch }, payload) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.deliveries.updateDeliveryMdp },
        params: {
          deliveryCode: payload.deliveryCode,
          data: payload.data,
        },
        throwError: true,
      })

      addSuccessMessage(dispatch, API.deliveries.updateDeliveryMdp.id, 'flashMessages.deliveries.update')
      return true
    } catch {
      return false
    }
  },
  [DELIVERIES.ACTIONS.CREATE_DELIVERY_REQUEST]: async ({ dispatch }, payload) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.deliveries.createDelivery },
        params: {
          data: payload.data,
        },
        throwError: true,
      })

      addSuccessMessage(dispatch, API.deliveries.deliveryDelete.id, 'flashMessages.deliveries.create')
      return true
    } catch {
      return false
    }
  },
  [DELIVERIES.ACTIONS.GET_TRANSPORT_LIST_REQUEST]: async ({ dispatch, commit }) => {
    const data = await callCpapiV2GraphQL(dispatch, {
      requestData: { ...API.deliveries.transportList },
    })
    commit(DELIVERIES.MUTATIONS.GET_TRANSPORT_LIST_RESPONSE, data)
  },
  [DELIVERIES.ACTIONS.GET_DELIVERIES_ALERTS_REQUEST]: async ({ dispatch, commit }) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.deliveries.getAlerts },
    })
    commit(DELIVERIES.MUTATIONS.GET_DELIVERIES_ALERTS_RESPONSE, data)
  },
  [DELIVERIES.ACTIONS.UPDATE_MALL_DELIVERY_STATUS_REQUEST]: async ({ dispatch }, data) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.deliveries.requestMallDelivery },
        params: {
          data,
        },
        throwError: true,
      })
      dispatchRoot(dispatch, withNamespace(DELIVERIES.NAMESPACE, DELIVERIES.ACTIONS.GET_MALL_DELIVERY_STATUS_REQUEST), {
        namespace: DELIVERIES.NAMESPACE,
      })
      dispatchRoot(dispatch, withNamespace(DIP.NAMESPACE, DIP.ACTIONS.GET_DIP_REQUEST), {
        namespace: DIP.NAMESPACE,
      })
      return true
    } catch {
      return false
    }
  },
  [DELIVERIES.ACTIONS.GET_MALL_DELIVERY_STATUS_REQUEST]: async ({ dispatch, rootGetters, commit }, payload) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.deliveries.getMallDeliveryState },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
      },
      throwError: payload ? payload.throwError : true,
    })
    commit(DELIVERIES.MUTATIONS.GET_MALL_DELIVERY_STATUS_RESPONSE, data)
  },
  [DELIVERIES.ACTIONS.GET_MALL_DELIVERY_ACTIVE_REQUEST]: async ({ dispatch, commit }) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.deliveries.getMallDeliveryActive },
    })
    commit(DELIVERIES.MUTATIONS.GET_MALL_DELIVERY_ACTIVE_RESPONSE, data.state)
  },
  [DELIVERIES.ACTIONS.GET_DESTINATION_BRANCHES_REQUEST]: async ({ dispatch, commit }, payload) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.deliveries.getDestinationBranches },
      params: {
        orderId: payload.orderId,
      },
    })
    commit(DELIVERIES.MUTATIONS.GET_DESTINATION_BRANCHES_RESPONSE, data)
  },
  [DELIVERIES.ACTIONS.GET_ULOZENKA_REGISTRATION_BRANCHES_REQUEST]: async (
    { dispatch, rootGetters, commit },
    payload = { shopId: SHOP_ID.CZ10MA },
  ) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.deliveries.getUlozenkaRegisterBranches },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
        shopId: payload.shopId,
      },
    })
    commit(DELIVERIES.MUTATIONS.GET_ULOZENKA_REGISTRATION_BRANCHES_RESPONSE, data)
  },
  [DELIVERIES.ACTIONS.GET_WAREHOUSE_CALENDAR_REQUEST]: async ({ dispatch, commit }) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.deliveries.getWarehouseSettings },
    })
    commit(DELIVERIES.MUTATIONS.GET_WAREHOUSE_CALENDAR_RESPONSE, data)
  },
  [DELIVERIES.ACTIONS.UPDATE_WAREHOUSE_CALENDAR_REQUEST]: async ({ dispatch, commit }, data) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.deliveries.updateWarehouseSettings },
        params: {
          data,
        },
        throwError: true,
      })
      addSuccessMessage(dispatch, API.deliveries.updateWarehouseSettings.id, 'flashMessages.warehouse.saved')
      commit(DELIVERIES.MUTATIONS.GET_WAREHOUSE_CALENDAR_RESPONSE, data)
      return true
    } catch {
      addErrorMessage(dispatch, API.deliveries.updateWarehouseSettings.id, 'flashMessages.warehouse.error')
      return false
    }
  },
}
