import { LOCAL_STORAGE } from '@/enums'

const createKey = partnerId => {
  return `${LOCAL_STORAGE.BACK_LINK}-${partnerId}`
}

export const saveBackLink = (partnerId, url) => {
  if (!partnerId) {
    return
  }

  localStorage.setItem(createKey(partnerId), url)
}

export const getBackLink = partnerId => {
  if (!partnerId) {
    return
  }

  const backLink = localStorage.getItem(createKey(partnerId))
  localStorage.removeItem(createKey(partnerId))

  return backLink
}

export const clearBackLink = partnerId => {
  if (!partnerId) {
    return
  }

  localStorage.removeItem(createKey(partnerId))
}
