export default {
  productFeedGrid: {
    useCpapiV2: true,
    id: 'PRODUCT_FEED_GRID',
    request: ({ filter, sorter, paging }) => ({
      query: `
        query ($filter: [[MalFeedGridFilterInput]], $sorter: [MalFeedGridSorterInput], $paging: PaginationInput) {
          feeds {
            grid(input: { filter: $filter, sorter: $sorter, paging: $paging }) {
              items {
                jobId
                feedId
                datetimeStarted
                datetimeEnded
                updatedItems
                processedItems
                errorsCount
                feedType
                status
              }
              filter {
                column
                operator
                value
              }
              sorter {
                column
                direction
              }
              paging {
                page
                total
                itemsPerPage
                lastPage
                nextPage
                previousPage
              }
            }
          }
        }
      `,
      variables: {
        filter,
        sorter,
        paging,
      },
    }),
    reduce: data => {
      return data.feeds.grid
    },
  },
  productFeedErrorLog: {
    id: 'PRODUCT_FEED_ERROR_LOG',
    request: ({ jobId, feedId }) => ({
      query: `
        query ($feedId: String!, $jobId: String!) {
          feeds {
            result(input: { feedId: $feedId, jobId: $jobId }) {
              errors
            }
          }
        }
      `,
      variables: {
        jobId: jobId,
        feedId: feedId,
      },
    }),
    reduce: data => {
      return data.feeds.result.errors
    },
  },
  productFeedErrorLogGrid: {
    id: 'PRODUCT_FEED_ERROR_LOG_GRID',
    request: ({ params, paging }) => ({
      query: `
        query ($feedId: String!, $jobId: String!, $paging: PagingInput) {
          feeds {
            result(input: { feedId: $feedId, jobId: $jobId, paging: $paging }) {
              errors {
                items
                paging {
                  page
                  total
                  itemsPerPage
                  lastPage
                  nextPage
                  previousPage
                }
              }
            }
          }
        }
      `,
      variables: {
        jobId: params.jobId,
        feedId: params.feedId,
        paging,
      },
    }),
    reduce: data => {
      return data.feeds.result.errors
    },
  },
  updateAvailabilityFeed: {
    id: 'UPDATE_AVAILABILITY_FEED',
    request: ({ partnerId, data }) => ({
      query: `
        mutation($data: MalFeedUpdateInputData!) {
          feeds {
            updateAvailabilityFeed(input: { data: $data }) {
              enabled
              url
              basicAuthName
              basicAuthPwd
              schedule
            }
          }
        }
      `,
      variables: {
        partnerId,
        data,
      },
    }),
    reduce: data => {
      return data.feeds.updateAvailabilityFeed
    },
  },
  syncAvailabilityFeed: {
    id: 'SYNC_AVAILABILITY_FEED',
    request: ({ partnerId }) => ({
      query: `
        query {
          feeds {
            runAvailabilityFeed {
              success
            }
          }
        }
      `,
      variables: {
        partnerId,
      },
    }),
    reduce: data => {
      return data.feeds.runAvailabilityFeed
    },
  },
  getAvailabilityFeed: {
    id: 'GET_AVAILABILITY_FEED',
    request: ({ partnerId }) => ({
      query: `
        query {
          feeds {
            availabilityFeed {
              enabled
              url
              basicAuthName
              basicAuthPwd
              schedule
            }
          }
        }
      `,
      variables: {
        partnerId,
      },
    }),
    reduce: data => {
      return data.feeds.availabilityFeed
    },
  },
  updateProductFeed: {
    id: 'UPDATE_PRODUCT_FEED',
    request: ({ partnerId, data }) => ({
      query: `
        mutation($data: MalFeedUpdateInputData!) {
          feeds {
            updateProductFeed(input: { data: $data }) {
              enabled
              url
              basicAuthName
              basicAuthPwd
              schedule
            }
          }
        }
      `,
      variables: {
        partnerId,
        data,
      },
    }),
    reduce: data => {
      return data.feeds.updateProductFeed
    },
  },
  syncProductFeed: {
    id: 'SYNC_PRODUCT_FEED',
    request: ({ partnerId }) => ({
      query: `
        query {
          feeds {
            runProductFeed {
              success
            }
          }
        }
      `,
      variables: {
        partnerId,
      },
    }),
    reduce: data => {
      return data.feeds.runProductFeed
    },
  },
  getProductFeed: {
    id: 'GET_PRODUCT_FEED',
    request: ({ partnerId, data }) => ({
      query: `
        query {
          feeds {
            productFeed {
              enabled
              url
              basicAuthName
              basicAuthPwd
              schedule
            }
          }
        }
      `,
      variables: {
        partnerId,
        data,
      },
    }),
    reduce: data => {
      return data.feeds.productFeed
    },
  },
  resetHash: {
    id: 'RESET_HASH',
    request: () => ({
      query: `
        query {
          feeds {
            hashReset {
              ok
              availability
              product
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data.feeds.hashReset
    },
  },
  getStats: {
    id: 'GET_STATS',
    request: ({ data }) => ({
      query: `
        query($days: Int!) {
          feeds {
            stats(input: { days: $days }) {
              productsInFeed
              productsDeltaInFeed
              productsWithErrors
              productsDeltaWithErrors
              availabilityInFeed
              availabilityDeltaInFeed
              availabilityWithErrors
              availabilityDeltaWithErrors
              dailyStats {
                date
                productsInFeed
                productsProcessed
                productsSuccessPercent
                productsWithErrors
                availabilityInFeed
                availabilitySuccessPercent
                availabilityProcessed
                availabilityWithErrors
            }
          }
        }
      }
      `,
      variables: {
        days: data.days,
      },
    }),
    reduce: data => {
      return data.feeds
    },
  },
}
