import { AUTH } from '@/store/modules/auth/types'
import { LOCAL_STORAGE } from '@/enums'
import createState from '@/store/modules/auth/state'
import { resetState } from '@/store/utils'

const updateAuth = (state, payload) => {
  state.account = payload.account || null
  state.passwordValid = payload.account.passwordValid
  state.partners = payload.account.partners || []
  state.selectedPartner = payload.account.partners.find(partner => partner.id === payload.selectedPartnerId)
  state.selectedPartnerId = payload.selectedPartnerId
  state.refreshTime = payload.refreshTime
  state.authToken = payload.authToken || null
  state.adminEmail = payload.adminEmail || null
  state.workflowRules = payload.workflowRules ? JSON.parse(payload.workflowRules) : {}
}

const updateToken = (state, payload) => {
  localStorage.setItem(LOCAL_STORAGE.USER_TOKEN, payload.token || null)
  state.token = payload.token || null
}

export default {
  [AUTH.MUTATIONS.LOGOUT_RESPONSE]: () => {
    localStorage.removeItem(LOCAL_STORAGE.USER_TOKEN)
  },
  [AUTH.MUTATIONS.CHECK_LOGGED_RESPONSE]: (state, payload) => {
    updateToken(state, payload)
    updateAuth(state, payload)
    state.checked = true
  },
  [AUTH.MUTATIONS.SET_TOKEN]: (state, payload) => {
    updateToken(state, payload)
  },
  [AUTH.MUTATIONS.SET_CHECKING_AUTH]: (state, payload) => {
    state.checkingAuth = payload
  },
  [AUTH.MUTATIONS.REVOKE_CLIENT_ID_TOKEN]: (state, payload) => {
    const selectedPartner = {
      ...state.selectedPartner,
      clientIds: state.selectedPartner.clientIds.filter(clientId => {
        return clientId.clientId !== payload.clientId
      }),
    }

    state.selectedPartner = selectedPartner
  },
  [AUTH.MUTATIONS.ADD_CLIENT_ID_TOKEN]: (state, payload) => {
    const selectedPartner = {
      ...state.selectedPartner,
      clientIds: [...state.selectedPartner.clientIds, payload],
    }

    state.selectedPartner = selectedPartner
  },
  [AUTH.MUTATIONS.LOGIN_AS_GIVEN_PARTNER]: (state, payload) => {
    state.loginAsGivenPartner = payload
  },
  [AUTH.MUTATIONS.GET_CLIENT_ID_RESPONSE]: (state, payload) => {
    state.clientId = payload
  },
  [AUTH.MUTATIONS.LOGIN_RESPONSE]: (state, payload) => {
    updateToken(state, payload)
    updateAuth(state, payload)
    state.checked = false
  },
  [AUTH.MUTATIONS.WORKFLOW_RULES_RESPONSE]: (state, payload) => {
    state.workflowRules = payload ? JSON.parse(payload) : {}
  },
  [AUTH.MUTATIONS.SET_REGISTRATION_STEP]: (state, payload) => {
    state.regStep = payload
  },
  [AUTH.MUTATIONS.ALLEGRO_REGISTER]: (state, payload) => {
    state.allegroRegisterSuccess = payload.success
  },
  [AUTH.MUTATIONS.SET_PASSWORD_VALID]: (state, payload) => {
    state.passwordValid = payload
  },
  [AUTH.MUTATIONS.SET_REGISTRATION_EMAIL]: (state, payload) => {
    state.regEmail = payload
  },
  [AUTH.MUTATIONS.SET_PASSWORD_CONFIRM_RESPONSE]: (state, payload) => {
    state.passwordSet = payload.success
  },
  [AUTH.MUTATIONS.REGISTRATION]: (state, payload) => {
    state.passwordToken = payload.token
    state.regSuccess = payload.success
  },
  [AUTH.MUTATIONS.UPDATE_PARTNER_RESPONSE]: (state, payload) => {
    state.selectedPartner = payload

    state.partners = state.partners.map(partner => {
      if (partner.id === payload.id) {
        return payload
      }

      return partner
    })
  },
  [AUTH.MUTATIONS.GET_PARTNER_REGISTRATION_INFO_RESPONSE]: (state, payload) => {
    state.registrationInfo = payload
  },
  [AUTH.MUTATIONS.CONFIRM_REGISTRATION_RESPONSE]: (state, payload) => {
    state.registrationToken = payload.token
  },
  [AUTH.MUTATIONS.GET_PARTNER_STATUS_RESPONSE]: (state, payload) => {
    state.partnerHasPassword = payload.account.hasPassword
    state.partnerIsActive = payload.account.isActive
    state.partnerIsDisabled = payload.partnerIsDisabled || false
    state.loginToken = payload.token
  },
  [AUTH.MUTATIONS.ACL_RESPONSE]: (state, payload) => {
    state.acl = payload
  },
  [AUTH.MUTATIONS.SET_SELECTED_PARTNER]: (state, payload) => {
    state.selectedPartner = state.account.partners.find(partner => partner.id === payload.partnerId)
    state.selectedPartnerId = payload.partnerId
    localStorage.setItem(LOCAL_STORAGE.SELECTED_PARTNER, payload.partnerId)
  },
  [AUTH.MUTATIONS.UPDATE_TOKEN]: (state, payload) => {
    updateToken(state, payload)
  },
  [AUTH.MUTATIONS.UPDATE_ACCOUNT_RESPONSE]: (state, payload) => {
    state.account = { ...state.account, lang: payload.lang, fistName: payload.firstName, lastName: payload.lastName }
  },
  [AUTH.MUTATIONS.PASSWORD_RESET_RESPONSE]: (state, payload) => {
    state.passwordReset = payload.success
  },
  [AUTH.MUTATIONS.RESET]: state => {
    resetState(state, createState())
  },
}
