import { PRICE_LIST } from '@/store/modules/priceList/types'
import { callCockpitGraphQL } from '@/store/utils'
import { API } from '@/api'

export default {
  [PRICE_LIST.ACTIONS.GET_STANDARD_PRICE_LIST_REQUEST]: async ({ dispatch, commit }, payload) => {
    try {
      const data = await callCockpitGraphQL(dispatch, {
        requestData: { ...API.priceList.standardPriceListDetail },
        params: {
          partnerId: payload.partnerId,
        },
        throwError: true,
      })
      data.items = data.items.filter(item => item.id !== -1)
      commit(PRICE_LIST.MUTATIONS.GET_STANDARD_PRICE_LIST_RESPONSE, data)
      return true
    } catch {
      commit(PRICE_LIST.MUTATIONS.GET_STANDARD_PRICE_LIST_RESPONSE, null)
      return false
    }
  },
}
