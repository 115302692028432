import { WORKFLOW } from '@/store/modules/workflow/types'
import createState from '@/store/modules/workflow/state'
import { resetState } from '@/store/utils'

export default {
  [WORKFLOW.MUTATIONS.WORKFLOW_RESPONSE]: (state, payload) => {
    state.workflow = payload
  },
  [WORKFLOW.MUTATIONS.WORKFLOW_UPDATE_RESPONSE]: (state, payload) => {
    state.workflow = payload
  },
  [WORKFLOW.MUTATIONS.GET_TERMS_RESPONSE]: (state, payload) => {
    state.terms = payload
  },
  [WORKFLOW.MUTATIONS.GET_FEES_RESPONSE]: (state, payload) => {
    state.fees = payload
  },
  [WORKFLOW.MUTATIONS.GET_COMMISSION_FEES_RESPONSE]: (state, payload) => {
    state.commissionFees = payload
  },
  [WORKFLOW.MUTATIONS.GET_CHECK_LIST_RESPONSE]: (state, payload) => {
    state.checklist = payload
  },
  [WORKFLOW.MUTATIONS.RESET]: state => {
    resetState(state, createState())
  },
}
