<template>
  <btn v-if="isSending" :color="color" type="submit" :text="flat" :class="buttonClass" :action="action" loading>
    <v-icon v-if="icon" left>
      {{ icon }}
    </v-icon>
    {{ sendingTitle }}
  </btn>
  <btn v-else :color="color" :disabled="disabled" :text="flat" :class="buttonClass" :action="action" @click="onClick">
    <v-icon v-if="icon" left>
      {{ icon }}
    </v-icon>
    {{ buttonTitle }}
  </btn>
</template>

<script>
export default {
  name: 'SendingButton',
  props: {
    isSending: {
      type: Boolean,
      required: true,
    },
    sendingTitle: {
      type: String,
      required: true,
    },
    buttonTitle: {
      type: String,
      required: true,
    },
    onClick: {
      type: Function,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    disabled: Boolean,
    color: {
      type: String,
      default: 'primary',
    },
    flat: Boolean,
    buttonClass: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
  },
}
</script>
