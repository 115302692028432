import { FINANCING } from '@/store/modules/financing/types'
import { resetState } from '@/store/utils'
import createState from '@/store/modules/financing/state'

export default {
  [FINANCING.MUTATIONS.GET_FINANCING_RESPONSE]: (state, data) => {
    state.financingUrl = data
  },
  [FINANCING.MUTATIONS.RESET]: state => {
    resetState(state, createState())
  },
}
