export const CAMPAIGNS = {
  NAMESPACE: 'campaigns',
  GETTERS: {},
  ACTIONS: {
    LOAD_DETAIL: 'LOAD_DETAIL',
    IMPORT_CSV: 'IMPORT_CSV',
    SET_IMPORT_TO_PROCESS: 'SET_IMPORT_TO_PROCESS',
    LAST_IMPORT: 'LAST_IMPORT',
    DOWNLOAD_IMPORT: 'DOWNLOAD_IMPORT',
  },
  MUTATIONS: {
    DETAIL_RESPONSE: 'DETAIL_RESPONSE',
    CSV_IMPORT_RESPONSE: 'CSV_IMPORT_RESPONSE',
    CSV_IMPORT_FAILED: 'CSV_IMPORT_FAILED',
    LAST_IMPORT_DATA: 'LAST_IMPORT_DATA',
    RESET: 'RESET',
  },
}
