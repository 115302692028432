import Vue from 'vue'
import VueI18n from 'vue-i18n'
import defaultCs from '@/localization/default'
import { LOCAL_STORAGE, SHOP_ID_LANG } from '@/enums'
import countries from 'i18n-iso-countries'
import csCountries from 'i18n-iso-countries/langs/cs.json'
import enCountries from 'i18n-iso-countries/langs/en.json'
import plCountries from 'i18n-iso-countries/langs/pl.json'
import huCountries from 'i18n-iso-countries/langs/hu.json'
import axios from 'axios'
import { localize } from 'vee-validate'

Vue.use(VueI18n)

const currencyOptions = {
  style: 'currency',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
}

export const getBrowserLanguage = (countryCodeOnly = true) => {
  let lang

  if (navigator.languages && navigator.languages.length) {
    lang = navigator.languages[0]
  } else {
    lang = navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en'
  }

  if (countryCodeOnly) {
    lang = lang.trim().split(/-|_/)[0]
  }

  return lang
}

export const mapLanguages = country => {
  switch (country) {
    case 'cs':
      return 'cs'

    case 'sk':
      return 'cs'

    case 'en':
      return 'en'

    case 'pl':
      return 'pl'

    case 'si':
      return 'en'

    default:
      return 'cs'
  }
}

export const getDefaultLang = () => {
  return mapLanguages(getBrowserLanguage(true))
}

// pluralization - http://docs.translatehouse.org/projects/localization-guide/en/latest/l10n/pluralforms.html

export const pluralization = count => {
  if (!count) {
    return 0
  }
  const absCount = Math.abs(count)
  if (absCount < 2) {
    return absCount
  } else if (absCount >= 2 && absCount <= 4) {
    return 2
  }
  return 3
}

countries.registerLocale(csCountries)
countries.registerLocale(enCountries)
countries.registerLocale(plCountries)
countries.registerLocale(huCountries)

export const i18nCountries = countries

const fallbackLanguage = 'cs'

const locale =
  localStorage !== undefined
    ? localStorage.getItem(LOCAL_STORAGE.LANG)
      ? localStorage.getItem(LOCAL_STORAGE.LANG)
      : fallbackLanguage
    : fallbackLanguage

export const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: fallbackLanguage,
  messages: {
    cs: defaultCs,
  },
  pluralizationRules: {
    cs: pluralization,
    en: pluralization,
  },
  numberFormats: {
    [SHOP_ID_LANG.CZ10MA]: {
      currency: {
        currency: 'CZK',
        ...currencyOptions,
      },
    },
    [SHOP_ID_LANG.SK10MA]: {
      currency: {
        currency: 'EUR',
        ...currencyOptions,
      },
    },
    [SHOP_ID_LANG.PL20MA]: {
      currency: {
        currency: 'PLN',
        ...currencyOptions,
      },
    },
    [SHOP_ID_LANG.HR10MA]: {
      currency: {
        currency: 'HRK',
        ...currencyOptions,
      },
    },
    [SHOP_ID_LANG.HU20MA]: {
      currency: {
        currency: 'HUF',
        ...currencyOptions,
      },
    },
    [SHOP_ID_LANG.SI10SI]: {
      currency: {
        currency: 'EUR',
        ...currencyOptions,
      },
    },
  },
})

const loadedLanguages = []

const setTitle = titleKey => {
  document.title = `${i18n.t('title.' + titleKey)} – ${i18n.t('title.page')}`
}

const setI18nLanguage = (lang, titleKey) => {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  localiseVeeValidate(lang)
  setTitle(titleKey)
  return lang
}

const localiseVeeValidate = lang => {
  const validateLanguage = loadedLanguages.find(language => language.lang === lang)
  localize(lang, validateLanguage.locale)
}

export const loadLanguageAsync = async (lang, titleKey, request, projectId) => {
  // If the language was already loaded
  if (loadedLanguages.some(language => language.lang === lang)) {
    return setI18nLanguage(lang, titleKey)
  }

  const response = await request({ projectId, lang })

  const messages = {
    ...response.default,
    $vuetify: response.default.vuetify,
  }

  i18n.mergeLocaleMessage(lang, messages)
  loadedLanguages.push({ lang, locale: messages.validations })
  return setI18nLanguage(lang, titleKey)
}

export const fallbackExists = key => {
  return i18n._te(key, i18n.fallbackLocale, i18n._getMessages())
}
