<template>
  <v-list-group
    v-if="item.visible === undefined || item.visible === true"
    :prepend-icon="item.icon"
    no-action
    class="nav-list-item-dropdown__action" :class="`wdio-menu-drop-${getShortItemTitle(item.title)}`"
  >
    <template #activator>
      <v-list-item-title>
        <badge v-if="item.newFeature">
          {{ $t(item.title) }}
        </badge>
        <template v-else>
          {{ $t(item.title) }}
        </template>
      </v-list-item-title>
    </template>
    <nav-item v-for="(children, key) in item.children" :key="key" :item="children" />
  </v-list-group>
</template>

<script>
import NavItem from '@/components/layout/components/nav/NavItem'
import Badge from '@/components/badge/Badge'

export default {
  name: 'NavItemDropdown',
  components: { Badge, NavItem },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getShortItemTitle(keyName) {
      return keyName.split('.').slice(1, 2)
    },
  },
}
</script>
