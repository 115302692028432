export const PARTNER = {
  NAMESPACE: 'partner',
  ACTIONS: {
    GET_PARTNER_CONTACT_REQUEST: 'GET_PARTNER_CONTACT_REQUEST',
    CREATE_PARTNER_CONTACT_REQUEST: 'CREATE_PARTNER_CONTACT_REQUEST',
    UPDATE_PARTNER_CONTACT_REQUEST: 'UPDATE_PARTNER_CONTACT_REQUEST',
    DELETE_PARTNER_CONTACT_REQUEST: 'DELETE_PARTNER_CONTACT_REQUEST',
    CREATE_PARTNER_ADDRESS_REQUEST: 'CREATE_PARTNER_ADDRESS_REQUEST',
    UPDATE_PARTNER_ADDRESS_REQUEST: 'UPDATE_PARTNER_ADDRESS_REQUEST',
    DELETE_PARTNER_ADDRESS_REQUEST: 'DELETE_PARTNER_ADDRESS_REQUEST',
    UPDATE_PARTNER_ACL_REQUEST: 'UPDATE_PARTNER_ACL_REQUEST',
    GET_ACCOUNTS_REQUEST: 'GET_ACCOUNTS_REQUEST',
    ADD_ACCOUNT_REQUEST: 'ADD_ACCOUNT_REQUEST',
    UNLINK_PARTNER_ACCOUNT: 'UNLINK_PARTNER_ACCOUNT',
    CHANGE_MASTER_ACCOUNT: 'CHANGE_MASTER_ACCOUNT',
    GET_ALL_CONTACTS_REQUEST: 'GET_ALL_CONTACTS_REQUEST',
    GET_AGREEMENT_REQUEST: 'GET_AGREEMENT_REQUEST',
    CREATE_AGREEMENT_REQUEST: 'CREATE_AGREEMENT_REQUEST',
    GET_PARTNER_WEBHOOK_REQUEST: 'GET_PARTNER_WEBHOOK_REQUEST',
    DELETE_PARTNER_WEBHOOK_REQUEST: 'DELETE_PARTNER_WEBHOOK_REQUEST',
    SET_PARTNER_WEBHOOK: 'SET_PARTNER_WEBHOOK',
    UPDATE_PARTNER_WEBHOOK_REQUEST: 'UPDATE_PARTNER_WEBHOOK_REQUEST',
    CREATE_PARTNER_WEBHOOK_REQUEST: 'CREATE_PARTNER_WEBHOOK_REQUEST',
    GET_PARTNER_SANDBOX_ACCOUNTS_REQUEST: 'GET_PARTNER_SANDBOX_ACCOUNTS_REQUEST',
    GET_DOCUMENTS_LIST_REQUEST: 'GET_DOCUMENTS_LIST_REQUEST',
    GET_LOGO_REQUEST: 'GET_LOGO_REQUEST',
    DELETE_DOCUMENT_REQUEST: 'DELETE_DOCUMENT_REQUEST',
    GET_DOCUMENT_REQUEST: 'GET_DOCUMENT_REQUEST',
    UPLOAD_FILE: 'UPLOAD_FILE',
    ADD_DOCUMENT_REQUEST: 'ADD_DOCUMENT_REQUEST',
    SET_LOGO_REQUEST: 'SET_LOGO_REQUEST',
    IS_STATE_ALLOWED_REQUEST: 'IS_STATE_ALLOWED_REQUEST',
  },
  MUTATIONS: {
    GET_PARTNER_CONTACT_RESPONSE: 'GET_PARTNER_CONTACT_RESPONSE',
    GET_ACCOUNTS_RESPONSE: 'GET_ACCOUNTS_RESPONSE',
    GET_ALL_CONTACTS_RESPONSE: 'GET_ALL_CONTACTS_RESPONSE',
    GET_AGREEMENT_RESPONSE: 'GET_AGREEMENT_RESPONSE',
    SET_PARTNER_WEBHOOK: 'SET_PARTNER_WEBHOOK',
    GET_PARTNER_WEBHOOK_RESPONSE: 'GET_PARTNER_WEBHOOK_RESPONSE',
    GET_PARTNER_SANDBOX_ACCOUNTS_RESPONSE: 'GET_PARTNER_SANDBOX_ACCOUNTS_RESPONSE',
    GET_DOCUMENTS_LIST_RESPONSE: 'GET_DOCUMENTS_LIST_RESPONSE',
    GET_LOGO_RESPONSE: 'GET_LOGO_RESPONSE',
    RESET: 'RESET',
  },
}
