export const atleastOneUppercaseChar = value => {
  const isUpperCase = string => /^[A-Z]*$/.test(string)

  return value.split('').some(char => isUpperCase(char))
}

export const atleastOneLowercaseChar = value => {
  const isLowerCase = string => /^[a-z]*$/.test(string)

  return value.split('').some(char => isLowerCase(char))
}

export const atleastOneNumber = value => {
  return value.match(/.*[0-9].*/)
}

export const atleastOneSpecialChar = value => {
  return value.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/)
}

export const hasMinCharacters = value => {
  return value.length >= 12
}
