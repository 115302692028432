import createState from '@/store/modules/api/state'
import getters from '@/store/modules/api/getters'
import mutations from '@/store/modules/api/mutations'

export default {
  namespaced: true,
  state: createState(),
  getters,
  mutations,
}
