export const PORTAL_DIALOG = {
  NAMESPACE: 'portalDialog',
  ACTIONS: {
    GET_DIALOG_REQUEST: 'GET_DIALOG_REQUEST',
    RESET: 'RESET',
  },
  MUTATIONS: {
    GET_DIALOG_RESPONSE: 'GET_PRICE_LIST_RESPONSE',
    RESET: 'RESET',
  },
}
