import { LOCALIZATION } from '@/store/modules/localization/types'
import { callCpapiV2Api } from '@/store/utils'
import { API } from '@/api'

export default {
  [LOCALIZATION.ACTIONS.GET_LOCALIZATION_REQUEST]: async ({ dispatch, commit }, payload) => {
    const data = await callCpapiV2Api(dispatch, {
      requestData: { ...API.localization.getLocalization },
      params: {
        ...payload,
      },
    })
    commit(LOCALIZATION.MUTATIONS.GET_LOCALIZATION_RESPONSE, data)
    return data
  },
}
