export const REQUESTS_STATE = {
  NAMESPACE: 'requests',
  GETTERS: {
    GET_GLOBAL_ERRORS: 'GET_GLOBAL_ERRORS',
    GET_STATE: 'GET_STATE',
    GET_REQUESTS: 'GET_REQUESTS',
  },
  ACTIONS: {
    CALL_REQUEST: 'CALL_REQUEST',
    CALL_REQUEST_GRAPHQL: 'CALL_REQUEST_GRAPHQL',
  },
  MUTATIONS: {
    START_SENDING: 'START_SENDING',
    STOP_SENDING: 'STOP_SENDING',
    ADD_ERROR: 'ADD_ERROR',
    REMOVE_ERROR: 'REMOVE_ERROR',
    CLEAR_ERRORS: 'CLEAR_ERRORS',
    RESET: 'RESET',
  },
}

export const ERROR_TYPE = {
  NONE: 'NONE',
}
