export default {
  getAccounts: {
    id: 'PARTNERS_GET_ACCOUNTS',
    request: ({ partnerId, data }) => ({
      query: `
        query ($partnerId: String!) {
          partner {
            accounts (input: { partnerId: $partnerId }) {
              id
              firstName
              lastName
              email
              isMaster
            }
          }
        }
        `,
      variables: {
        partnerId,
        data,
      },
    }),
    reduce: data => {
      return data.partner.accounts
    },
  },
  addAccount: {
    id: 'PARTNERS_ADD_ACCOUNT',
    request: ({ partnerId, data }) => ({
      query: `
        mutation ($partnerId: String!, $data: AddAccountInputData!) {
          partner {
            addAccount (input: { partnerId: $partnerId, data: $data }) {
              success
            }
          }
        }
        `,
      variables: {
        partnerId,
        data,
      },
    }),
    reduce: data => {
      return data.partner.addAccount
    },
  },
  contactsGrid: {
    id: 'CONTACTS_GRID',
    request: ({ filter, sorter, paging, search, partnerId }) => ({
      query: `
        query (
          $partnerId: String!
          $filter: [[PartnerContactsFilterInput]]
          $sorter: [PartnerContactsSorterInput]
          $paging: PagingInput
          $search: String
        ) {
          contact {
            grid(
              input: {
                partnerId: $partnerId
                filter: $filter
                sorter: $sorter
                paging: $paging
                search: $search
              }
            ) {
              items {
                id
                firstName
                lastName
                email
                phone
                roles
              }
              filter {
                column
                operator
                value
              }
              sorter {
                column
                direction
              }
              paging {
                page
                total
                itemsPerPage
                lastPage
                nextPage
                previousPage
              }
            }
          }
        }
      `,
      variables: {
        filter,
        sorter,
        paging,
        search,
        partnerId,
      },
    }),
    reduce: data => {
      return data.contact.grid
    },
  },
  allContactsGrid: {
    id: 'ALL_CONTACTS_GRID',
    request: ({ partnerId }) => ({
      query: `
        query (
          $partnerId: String!
        ) {
          contact {
            all(
              input: {
                partnerId: $partnerId
              }
            ) {
              items {
                id
                firstName
                lastName
                email
                phone
                roles
              }
            }
          }
        }
      `,
      variables: {
        partnerId,
      },
    }),
    reduce: data => {
      return data.contact.all
    },
  },
  addressesGrid: {
    id: 'ADDRESSES_GRID',
    request: ({ filter, sorter, paging, search, partnerId }) => ({
      query: `
        query (
        $partnerId: String!
        $filter: [[PartnerAddressesFilterInput]]
        $sorter: [PartnerAddressesSorterInput]
        $paging: PagingInput
        $search: String
      ) {
        address {
          grid(
            input: {
              partnerId: $partnerId
              filter: $filter
              sorter: $sorter
              paging: $paging
              search: $search
            }
          ) {
            items {
              id
              street
              city
              state
              zip
              types
            }
            filter {
              column
              operator
              value
            }
            sorter {
              column
              direction
            }
            paging {
              page
              total
              itemsPerPage
              lastPage
              nextPage
              previousPage
            }
          }
        }
      }
      `,
      variables: {
        filter,
        sorter,
        paging,
        search,
        partnerId,
      },
    }),
    reduce: data => {
      return data.address.grid
    },
  },
  getPartnerContact: {
    id: 'GET_PARTNER_CONTACT',
    request: ({ partnerId, contactId }) => ({
      query: `
        query ($partnerId: String!, $contactId: String!) {
          contact {
            get (input: {
              partnerId: $partnerId
              contactId: $contactId
            }) {
              id
              firstName
              lastName
              email
              phone
              roles
            }
          }
        }
      `,
      variables: {
        partnerId,
        contactId,
      },
    }),
    reduce: data => {
      return data.contact.get
    },
  },
  createPartnerContact: {
    id: 'CREATE_PARTNER_CONTACT',
    request: ({ partnerId, data }) => ({
      query: `
        mutation ($partnerId: String!, $data: PartnerContactInputData!) {
          contact {
            create(input: { partnerId: $partnerId, data: $data }) {
              id
              firstName
              lastName
              email
              phone
              roles
            }
          }
        }
      `,
      variables: {
        partnerId,
        data,
      },
    }),
    reduce: data => {
      return data.contact.create
    },
  },
  updatePartnerContact: {
    id: 'CREATE_PARTNER_CONTACT',
    request: ({ partnerId, contactId, data }) => ({
      query: `
        mutation (
          $partnerId: String!
          $contactId: String!
          $data: PartnerContactInputData!
        ) {
          contact {
            update(
              input: { partnerId: $partnerId, contactId: $contactId, data: $data }
            ) {
              id
              firstName
              lastName
              email
              phone
              roles
            }
          }
        }
      `,
      variables: {
        partnerId,
        contactId,
        data,
      },
    }),
    reduce: data => {
      return data.contact.update
    },
  },
  uniquePublicTitleByPartner: {
    id: 'UNIQUE_PUBLIC_TITLE_BY_PARTNER',
    request: ({ partnerId, data }) => ({
      query: `
        query ($partnerId: String!, $data: PartnerPublicNameValidationInputData) {
          validations {
            partnerPublicNameByPartner(input: {partnerId: $partnerId, data: $data }) {
              isUnique
            }
          }
        }
      `,
      variables: {
        partnerId,
        data,
      },
    }),
    reduce: data => {
      return data.validations.partnerPublicNameByPartner
    },
  },
  updatePartner: {
    id: 'UPDATE_PARTNER',
    request: ({ partnerId, data }) => ({
      query: `
        mutation ($partnerId: String!, $data: PortalPartnerUpdateInputData!) {
          partner {
            update(input: { partnerId: $partnerId, data: $data }) {
              id
              partnerName
              sapId
              provider
              rating
              mallName
              shopId
              urlSlug
              workflow {
                updated
                status
                ticks {
                  text
                  checked
                }
              }
            }
          }
        }
      `,
      variables: {
        partnerId,
        data,
      },
    }),
    reduce: data => {
      return data.partner.update
    },
  },
  deletePartnerContact: {
    id: 'DELETE_PARTNER_CONTACT',
    request: ({ partnerId, contactId }) => ({
      query: `
        mutation ($partnerId: String!, $contactId: String!) {
          contact {
            delete(input: {
              partnerId:  $partnerId,
              contactId: $contactId
            }) {
              success
            }
          }
        }
      `,
      variables: {
        partnerId,
        contactId,
      },
    }),
    reduce: data => {
      return data.contact
    },
  },
  createPartnerAddress: {
    id: 'CREATE_PARTNER_ADDRESS',
    request: ({ partnerId, data }) => ({
      query: `
        mutation ($partnerId: String!, $data: PartnerAddressInputData!) {
          address {
            create(input: { partnerId: $partnerId, data: $data }) {
              id
              street
              city
              state
              zip
              types
            }
          }
        }
      `,
      variables: {
        partnerId,
        data,
      },
    }),
    reduce: data => {
      return data.address
    },
  },
  updatePartnerAddress: {
    id: 'UPDATE_PARTNER_ADDRESS',
    request: ({ partnerId, addressId, data }) => ({
      query: `
        mutation (
          $partnerId: String!
          $addressId: String!
          $data: PartnerAddressInputData!
        ) {
          address {
            update(
              input: { partnerId: $partnerId, addressId: $addressId, data: $data }
            ) {
              id
              street
              city
              state
              zip
              types
            }
          }
        }
      `,
      variables: {
        partnerId,
        addressId,
        data,
      },
    }),
    reduce: data => {
      return data.address.update
    },
  },
  deletePartnerAddress: {
    id: 'DELETE_PARTNER_ADDRESS',
    request: ({ partnerId, addressId }) => ({
      query: `
        mutation ($partnerId: String!, $addressId: String!) {
          address {
            delete(input: { partnerId: $partnerId, addressId: $addressId }) {
              success
            }
          }
        }
      `,
      variables: {
        partnerId,
        addressId,
      },
    }),
    reduce: data => {
      return data.address
    },
  },
  unlinkAccount: {
    id: 'UNLINK_ACCOUNT',
    request: ({ partnerId, accountId }) => ({
      query: `
        mutation($partnerId: String!, $accountId: String!) {
          partner {
            unlinkAccount(input: {
              partnerId: $partnerId
              accountId: $accountId
            }) {
              success
            }
          }
        }
      `,
      variables: {
        partnerId,
        accountId,
      },
    }),
    reduce: data => {
      return data.partner
    },
  },
  changeMasterAccount: {
    id: 'CHANGE_MASTER_ACCOUNT',
    request: ({ partnerId, accountId }) => ({
      query: `
        mutation($accountId: String!, $partnerId: String!) {
          partner {
            changeMasterAccount(
              input: { partnerId: $partnerId, accountId: $accountId }
            ) {
              success
            }
          }
        }
      `,
      variables: {
        partnerId,
        accountId,
      },
    }),
    reduce: data => {
      return data.partner.changeMasterAccount
    },
  },
  getAgreement: {
    id: 'GET_AGREEMENT',
    request: ({ partnerId }) => ({
      query: `
        query($partnerId: String!) {
          businessTerms {
            agreement(input: { partnerId: $partnerId }) {
              firstName
              lastName
              email
              phone
              in
              signed
              version
            }
          }
        }
      `,
      variables: {
        partnerId,
      },
    }),
    reduce: data => {
      return data.businessTerms.agreement
    },
  },
  createAgreement: {
    id: 'PARTNER_CREATE_AGREEMENT',
    request: ({ partnerId, businessTermsContentId }) => ({
      query: `
        mutation ($partnerId: String!, $businessTermsContentId: String!) {
          businessTerms {
            agreement(input: { partnerId: $partnerId, businessTermsContentId: $businessTermsContentId }) {
              firstName
              lastName
              email
              phone
              in
              signed
            }
          }
        }
      `,
      variables: {
        partnerId,
        businessTermsContentId,
      },
    }),
    reduce: data => {
      return data.businessTerms.agreement
    },
  },
  getPartnerWebhookLogs: {
    useCpapiV2: true,
    id: 'GET_PARTNER_WEBHOOK_LOGS',
    request: ({ filter, sorter, paging, params }) => ({
      query: `
        query (
          $filter: [[WebhookLogsFilterInput]]
          $sorter: [WebhookLogsSorterInput]
          $paging: PaginationInput
          $webhookId: String!
        ) {
          webhooks {
            logsGrid(input: { filter: $filter, sorter: $sorter, paging: $paging, webhookId: $webhookId }) {
              items {
                timestamp
                response_code
                is_timeout
                timing
                err
              }
              filter {
                column
                operator
                value
              }
              sorter {
                column
                direction
              }
              paging {
                page
                total
                itemsPerPage
                lastPage
                nextPage
                previousPage
              }
            }
          }
        }
      `,
      variables: {
        filter,
        sorter,
        paging,
        webhookId: params.webhookId,
      },
    }),
    reduce: data => {
      return data.webhooks.logsGrid
    },
  },
  getPartnerWebhook: {
    useCpapiV2: true,
    id: 'GET_PARTNER_WEBHOOK',
    request: ({ filter, sorter, paging }) => ({
      query: `
        query (
          $filter: [[WebhookFilterInput]]
          $sorter: [WebhookSorterInput]
          $paging: PaginationInput
        ) {
          webhooks {
            grid(input: { filter: $filter, sorter: $sorter, paging: $paging }) {
              items {
                id
                url
                event
                is_enabled
                logs {
                  timestamp
                  response_code
                  is_timeout
                  timing
                  err
                }
              }
              filter {
                column
                operator
                value
              }
              sorter {
                column
                direction
              }
              paging {
                page
                total
                itemsPerPage
                lastPage
                nextPage
                previousPage
              }
            } 
          }
        }
      `,
      variables: {
        filter,
        sorter,
        paging,
      },
    }),
    reduce: data => {
      return data.webhooks.grid
    },
  },
  getPartnerWebhookDetail: {
    id: 'GET_PARTNER_WEBHOOK_DETAIL',
    request: ({ id }) => ({
      query: `
        query ($id: String!) {
          webhooks {
            detail(input: { id: $id }) {
              id
              url
              event
              is_enabled
              logs {
                timestamp
                response_code
                is_timeout
                timing
                err
              }
            }
          }
        }
      `,
      variables: {
        id,
      },
    }),
    reduce: data => {
      return data.webhooks.detail
    },
  },
  deletePartnerWebhook: {
    id: 'DELETE_PARTNER_WEBHOOK',
    request: ({ id }) => ({
      query: `
        mutation ($id: String!) {
          webhooks {
            delete(input: { id: $id })
          }
        }
      `,
      variables: {
        id,
      },
    }),
    reduce: data => {
      return data.webhooks.delete
    },
  },
  createPartnerWebhook: {
    id: 'CREATE_PARTNER_WEBHOOK',
    request: ({ url, is_enabled }) => ({
      query: `
        mutation ($url: String!, $is_enabled: Boolean!) {
          webhooks {
            create(input: { url: $url, is_enabled: $is_enabled }) {
              id
              url
              event
              is_enabled
              logs {
                timestamp
                response_code
                is_timeout
                timing
                err
              }
            }
          }
        }
      `,
      variables: {
        url,
        is_enabled,
      },
    }),
    reduce: data => {
      return data.webhooks.create
    },
  },
  updatePartnerWebhook: {
    id: 'UPDATE_PARTNER_WEBHOOK',
    request: ({ id, partnerId, data }) => ({
      query: `
        mutation ($id: String!, $partnerId: String!, $data: PortalWebHookUpdateInputData!) {
          webHook {
            update(input: {id: $id, partnerId: $partnerId, data: $data}) {
              id
              url
              event
              is_enabled
              logs {
                timestamp
                response_code
                is_timeout
                timing
                err
              }
            }
          }
        }
      `,
      variables: {
        id,
        partnerId,
        data,
      },
    }),
    reduce: data => {
      return data.webHook.update
    },
  },
  getPartnerSandboxAccount: {
    id: 'GET_PARTNER_SANDBOX_ACCOUNT',
    request: () => ({
      query: `
        query {
          partner {
            sandboxAccount {
              email
              password
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data.partner.sandboxAccount
    },
  },
  getDocumentsList: {
    id: 'GET_DOCUMENTS_LIST',
    request: ({ partnerId }) => ({
      query: `
        query ($partnerId: String!) {
          documents {
            list (input: {partnerId: $partnerId}) {
              id
              name
              url
              documentType
              validFrom
              uploadedBy
              uploadedAt
              deletedAt
              isDeleted
            }
          }
        }
      `,
      variables: {
        partnerId,
      },
    }),
    reduce: data => {
      return data.documents.list
    },
  },
  getLogo: {
    id: 'GET_LOGO',
    request: ({ partnerId }) => ({
      query: `
        query ($partnerId: String!) {
          documents {
            logo(input: {partnerId: $partnerId}) {
              name
              url
            }
          }
        }
      `,
      variables: {
        partnerId,
      },
    }),
    reduce: data => {
      return data.documents.logo
    },
  },
  deleteDocument: {
    id: 'DELETE_DOCUMENT',
    request: ({ partnerId, documentId }) => ({
      query: `
        mutation ($partnerId: String!, $documentId: String!) {
          documents {
            delete(input: {partnerId: $partnerId, documentId: $documentId}) {
              success
            }
          }
        }
      `,
      variables: {
        partnerId,
        documentId,
      },
    }),
    reduce: data => {
      return data
    },
  },
  getDocument: {
    id: 'GET_DOCUMENT',
    request: ({ partnerId, documentId }) => ({
      query: `
        query ($partnerId: String!, $documentId: String!) {
          documents {
            get(input: {partnerId: $partnerId, documentId: $documentId}) {
              name
              url
            }
          }
        }
      `,
      variables: {
        partnerId,
        documentId,
      },
    }),
    reduce: data => {
      return data.documents.get
    },
  },
  addDocument: {
    id: 'ADD_DOCUMENT',
    request: ({ partnerId, data }) => ({
      query: `
        mutation ($partnerId: String!, $data: PartnerAddDocumentInputData!) {
          documents {
            addDocument(input: {partnerId: $partnerId, data: $data}) {
              name
              url
            }
          }
        }
      `,
      variables: {
        partnerId,
        data,
      },
    }),
    reduce: data => {
      return data
    },
  },
  setLogo: {
    id: 'SET_LOGO',
    request: ({ partnerId, data }) => ({
      query: `
        mutation ($partnerId: String!, $data: PartnerLogoInputData!) {
          documents {
            setLogo(input: {partnerId: $partnerId, data: $data}) {
              name
              url
            }
          }
        }
      `,
      variables: {
        partnerId,
        data,
      },
    }),
    reduce: data => {
      return data
    },
  },
  isStateAllowed: {
    id: 'IS_STATE_ALLOWED',
    request: ({ stateCode, types }) => ({
      query: `
        query ($stateCode: String!, $types: [String!]) {
          address {
            isStateAllowed (input: {stateCode: $stateCode, types: $types})
          }
        }
      `,
      variables: {
        stateCode,
        types,
      },
    }),
    reduce: data => {
      return data.address.isStateAllowed
    },
  },
  getStreamToken: {
    id: 'GET_STREAM_TOKEN',
    request: () => ({
      query: `
        query {
          notification {
           streamLogin {
             token
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data.notification.streamLogin
    },
  },
  addClientIdToken: {
    id: 'ADD_CLIENT_ID_TOKEN',
    request: ({ validFrom, validTo }) => ({
      query: `
        mutation($validFrom: String!, $validTo: String) {
          mpauth {
            createToken(input: { validFrom: $validFrom, validTo: $validTo }) {
              validFrom
              validTo
              clientId
            }
          }
        }
      `,
      variables: {
        validFrom,
        validTo,
      },
    }),
    reduce: data => {
      return data.mpauth.createToken
    },
  },
  revokeClientIdToken: {
    id: 'REVOKE_CLIENT_ID_TOKEN',
    request: ({ clientId, validFrom, validTo }) => ({
      query: `
        mutation($clientId: String!, $validFrom: String, $validTo: String) {
          mpauth {
           revokeToken(input: { clientId: $clientId, validFrom: $validFrom, validTo: $validTo })
          }
        }
      `,
      variables: {
        clientId,
        validFrom,
        validTo,
      },
    }),
    reduce: data => {
      return data.mpauth.revokeToken
    },
  },
}
