export default {
  uploadFile: {
    id: 'UPLOAD_FILE',
    request: ({ type, fileName, data }) => ({
      method: 'POST',
      url: '/upload',
      headers: {
        'Content-Type': type,
        'Content-Disposition': `attachment; filename*=UTF-8''${encodeURI(fileName)}`,
      },
      data: data,
    }),
    reduce: data => {
      return data
    },
  },
}
