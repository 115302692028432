import createState from '@/store/grid/store/state'
import actions from '@/store/grid/store/actions'
import mutations from '@/store/grid/store/mutations'

export default (namespace, defaultState) => ({
  namespaced: true,
  state: createState(namespace, defaultState),
  actions,
  mutations,
})
