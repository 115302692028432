<template>
  <v-badge
    color="error"
    overlap
    dot
    offset-x="0"
    offset-y="0"
  >
    <slot></slot>
  </v-badge>
</template>

<script>
export default {
  name: 'Badge',
}
</script>
