<template>
  <v-snackbar v-model="snackbar" top :color="type" :timeout="timeout">
    {{ text }}
    <template #action="{ attrs }">
      <btn v-if="type === 'error'" x-small dark text v-bind="attrs" action="button.submitToSentry" @click="submitToSentry">
        {{ $t('button.submitToSentry') }}
      </btn>
      <btn icon dark v-bind="attrs" action="button.close" @click="snackbar = false">
        <v-icon>
          close
        </v-icon>
      </btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { FLASH_MESSAGES, FLASH_MESSAGES_TYPES } from '@/store/modules/flashMessages/types'
import Raven from 'raven-js'
import { addInfoMessage } from '@/services/flashMessages'

export default {
  name: 'GlobalNotifications',
  data() {
    return {
      FLASH_MESSAGES_TYPES,
      id: null,
      text: null,
      type: null,
      snackbar: false,
      timeout: 6000,
    }
  },
  computed: {
    ...mapState(FLASH_MESSAGES.NAMESPACE, ['flashMessages']),
  },
  watch: {
    flashMessages() {
      const last = this.flashMessages[this.flashMessages.length - 1]
      if (last) {
        this.setSnackbar(last)
        this.snackbar = true
      }
    },
    snackbar(value) {
      if (value === false) {
        this[FLASH_MESSAGES.ACTIONS.REMOVE]({ id: this.id })
        this.clearSnackbar()
      }
    },
  },
  methods: {
    ...mapActions(FLASH_MESSAGES.NAMESPACE, [FLASH_MESSAGES.ACTIONS.REMOVE]),
    getType(type) {
      switch (type) {
        case FLASH_MESSAGES_TYPES.INFO: {
          return 'info'
        }
        case FLASH_MESSAGES_TYPES.SUCCESS: {
          return 'success'
        }
        case FLASH_MESSAGES_TYPES.ERROR: {
          return 'error'
        }
        default: {
          return undefined
        }
      }
    },
    setSnackbar(notification) {
      this.id = notification.id
      this.text = notification.message
      this.type = this.getType(notification.type)
    },
    clearSnackbar() {
      this.id = null
      this.text = null
      this.type = null
    },
    submitToSentry() {
      if (Raven.isSetup()) {
        Raven.captureException(`Nahlášené jejdátko: ${this.id}`, { extra: [this.text] })
      }
      this.snackbar = false
      addInfoMessage(this.$store.dispatch, 'SUBMIT_SENTRY_LOG', 'flashMessages.submitToSentry')
    },
  },
}
</script>
