export const ORDERS = {
  NAMESPACE: 'orders',
  ACTIONS: {
    GET_ORDER_REQUEST: 'GET_ORDER_REQUEST',
    UPDATE_ORDER_REQUEST: 'UPDATE_ORDER_REQUEST',
    UPDATE_ORDER_BRANCH_REQUEST: 'UPDATE_ORDER_BRANCH_REQUEST',
    CREATE_TEST_ORDERS_REQUEST: 'CREATE_TEST_ORDERS_REQUEST',
    IMPORT_STATUSES_REQUEST: 'IMPORT_STATUSES_REQUEST',
    BILLING_EXPORT: 'BILLING_EXPORT',
    ORDERS_STATUS_COUNT_REQUEST: 'ORDERS_STATUS_COUNT_REQUEST',
    ORDERS_STATUS_ALL_COUNT_REQUEST: 'ORDERS_STATUS_ALL_COUNT_REQUEST',
    ORDERS_MISSING_STATUS_COUNT_REQUEST: 'ORDERS_MISSING_STATUS_COUNT_REQUEST',
    ORDERS_EXPORT_TO_FILE_REQUEST: 'ORDERS_EXPORT_REQUEST',
    ORDERS_EXPORT_ZASILKOVNA_TO_FILE_REQUEST: 'ORDERS_EXPORT_ZASILKOVNA_TO_FILE_REQUEST',
    ORDERS_EXPORT_MISSING_TO_FILE_REQUEST: 'ORDERS_EXPORT_MISSING_TO_FILE_REQUEST',
    ORDERS_EXPORT_ZASILKOVNA_MISSING_TO_FILE_REQUEST: 'ORDERS_EXPORT_ZASILKOVNA_MISSING_TO_FILE_REQUEST',
    ORDERS_EXPORT_LABELS_TO_FILE_REQUEST: 'ORDERS_EXPORT_LABELS_TO_FILE_REQUEST',
    ORDERS_EXPORT_ULOZENKA_TO_FILE_REQUEST: 'ORDERS_EXPORT_ULOZENKA_TO_FILE_REQUEST',
    INVOICES_REQUEST: 'INVOICES_REQUEST',
    DELIVERY_NOTE_REQUEST: 'DELIVERY_NOTE_REQUEST',
    DOWNLOAD_DELIVERY_NOTE: 'DOWNLOAD_DELIVERY_NOTE',
    ORDERS_DASHBOARD_REQUEST: 'ORDERS_DASHBOARD_REQUEST',
  },
  MUTATIONS: {
    GET_ORDER_RESPONSE: 'GET_ORDER_RESPONSE',
    ORDERS_STATUS_COUNT_RESPONSE: 'ORDERS_STATUS_COUNT_RESPONSE',
    ORDERS_MISSING_STATUS_COUNT_RESPONSE: 'ORDERS_MISSING_STATUS_COUNT_RESPONSE',
    INVOICES_RESPONSE: 'INVOICES_RESPONSE',
    DOWNLOADING_DELIVERY_NOTE: 'DOWNLOADING_DELIVERY_NOTE',
    ORDERS_DASHBOARD_RESPONSE: 'ORDERS_DASHBOARD_RESPONSE',
    RESET: 'RESET',
  },
}
