export const GRID = {
  ACTIONS: {
    GRID_FETCH: 'GRID_FETCH',
    GRID_SEARCH: 'GRID_SEARCH',
    GRID_FILTER: 'GRID_FILTER',
    GRID_FILTER_RESET: 'GRID_FILTER_RESET',
    GRID_CHANGE_PAGING: 'GRID_CHANGE_PAGING',
    GRID_UPDATE_ROW: 'GRID_UPDATE_ROW',
    GRID_UPDATE_ROW_ITEM: 'GRID_UPDATE_ROW_ITEM',
    GRID_FILTER_SAVE: 'GRID_FILTER_SAVE',
    GRID_FILTER_DELETE: 'GRID_FILTER_DELETE',
    GRID_HEADERS_SAVE: 'GRID_HEADERS_SAVE',
    GRID_TOGGLE_EXPAND_ROW: 'GRID_TOGGLE_EXPAND_ROW',
    GRID_TOGGLE_EXPAND_FILTER: 'GRID_TOGGLE_EXPAND_FILTER',
  },
  MUTATIONS: {
    GRID_RESPONSE: 'GRID_RESPONSE',
    GRID_UPDATE_ROW: 'GRID_UPDATE_ROW',
    GRID_UPDATE_ROW_ITEM: 'GRID_UPDATE_ROW_ITEM',
    UPDATE_QUICK_FILTER: 'UPDATE_QUICK_FILTER',
    SAVE_FILTER: 'SAVE_FILTER',
    DELETE_FILTER: 'DELETE_FILTER',
    SAVE_HEADERS: 'SAVE_HEADERS',
    SAVE_ITEMS_PER_PAGE: 'SAVE_ITEMS_PER_PAGE',
    RESET: 'RESET',
    EXPAND_ROW: 'EXPAND_ROW',
    TOGGLE_FILTER: 'TOGGLE_FILTER',
  },
}
