<script>
export default {
  name: 'Hotjar',
  props: {
    trackingData: {
      type: Object,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },
  created() {
    if (window.hj) {
      window.hj('identify', this.userId, this.trackingData)
    }
  },
  render: () => null,
}
</script>
