export const CONTRACT = {
  NAMESPACE: 'contract',
  ACTIONS: {
    CONTRACT_REQUEST: 'CONTRACT_REQUEST',
    CONTRACT_UPDATE_REQUEST: 'CONTRACT_UPDATE_REQUEST',
    ARES_REQUEST: 'ARES_REQUEST',
  },
  MUTATIONS: {
    CONTRACT_RESPONSE: 'CONTRACT_RESPONSE',
    CONTRACT_UPDATE_RESPONSE: 'CONTRACT_UPDATE_RESPONSE',
    UPDATE_VALID_CONTRACT_FORM: 'UPDATE_VALID_CONTRACT_FORM',
    ARES_RESPONSE: 'ARES_RESPONSE',
    RESET: 'RESET',
  },
}
