import Vue from 'vue'
import Router from 'vue-router'
import { ROUTES, SECURITY } from '@/router/routes'
import { withNamespace } from '@/store/utils'
import { AUTH } from '@/store/modules/auth/types'
import { config, ability } from '@/config'
import { saveBackLink } from '@/services/backlink'
import { i18n, getDefaultLang } from '@/localization'
import { LOCAL_STORAGE } from '@/enums'
import routes from '@/router/routes/routes'
import { REQUESTS_STATE } from '@/store/modules/api/types'
import { FAQ_ROUTES } from '@/router/routes/faq'

Vue.use(Router)

export const loginAsAdminRoute = store => [
  {
    path: '/remote-login/:hash/token/:token/partner/:partnerId',
    name: ROUTES.LOGIN_AS_ADMIN,
    beforeEnter: to => {
      store.dispatch(withNamespace(AUTH.NAMESPACE, AUTH.ACTIONS.LOGIN_AS_GIVEN_PARTNER), to.params.partnerId)
      store.dispatch(withNamespace(AUTH.NAMESPACE, AUTH.ACTIONS.LOGIN_AS_ADMIN_REQUEST), {
        hash: to.params.hash,
        token: to.params.token,
        partnerId: to.params.partnerId,
      })
    },
    meta: {
      auth: SECURITY.PUBLIC,
      backLink: false,
    },
  },
]

export const router = new Router({
  mode: 'history',
  base: config.router.baseUrl,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  routes,
})

let timer

export const beforeEach = store => {
  return async (to, _, next) => {
    const prefersNewVersion = localStorage.getItem(LOCAL_STORAGE.PREFERS_NEW_VERSION) === 'true'
    if (prefersNewVersion) {
      window.location.replace(
        `${i18n.t('notifications.oldVersionUrl')}${window.location.pathname}${window.location.search}`,
      )
      return
    } else {
      localStorage.setItem(LOCAL_STORAGE.PREFERS_NEW_VERSION, 'false')
    }
    if (to.name === 'LOGIN_TOKEN' || to.name === 'LOGOUT_TOKEN' || to.name === 'CHANGE_PARTNER') {
      next()
      return
    }

    if (to.meta.backLink === true || to.meta.backLink === undefined) {
      const partnerId = store.getters[withNamespace(AUTH.NAMESPACE, AUTH.GETTERS.GET_SELECTED_PARTNER_ID)]
      saveBackLink(partnerId, to.path)
    }

    const isLogged = store.getters[withNamespace(AUTH.NAMESPACE, AUTH.GETTERS.IS_LOGGED)]
    const isChecked = store.getters[withNamespace(AUTH.NAMESPACE, AUTH.GETTERS.IS_CHECKED)]

    if (to.meta.auth && to.meta.auth === SECURITY.PUBLIC) {
      const lang = getDefaultLang()

      if (i18n.locale !== lang && !localStorage.getItem(LOCAL_STORAGE.PUBLIC_PAGES_LANG)) {
        i18n.locale = lang
      }

      next()
      return
    }

    // PRIVATE ROUTE
    const notLogged = () => {
      clearInterval(timer)
      localStorage.removeItem(LOCAL_STORAGE.SELECTED_PARTNER)

      next({ name: ROUTES.LOGIN })
    }

    const checkLogged = async () => {
      const response = await store.dispatch(withNamespace(AUTH.NAMESPACE, AUTH.ACTIONS.CHECK_LOGGED_REQUEST), {
        refresh: true,
      })
      if (!response) {
        notLogged()
      }
    }

    const redirect = () => {
      if (to.name === ROUTES.NOT_ALLOWED) {
        next()
        return
      }

      let canNavigate = true
      if (to.meta.resource) {
        canNavigate = ability.can(to.meta.action || 'read', to.meta.resource)
      }

      if (!canNavigate) {
        next({ name: ROUTES.NOT_ALLOWED })
        return
      }

      next()
    }

    if (!isChecked) {
      const response = await store.dispatch(withNamespace(AUTH.NAMESPACE, AUTH.ACTIONS.CHECK_LOGGED_REQUEST))
      if (response) {
        const refreshTime = store.getters[withNamespace(AUTH.NAMESPACE, AUTH.GETTERS.GET_REFRESH_TIME)]
        timer = setInterval(checkLogged, refreshTime * 1000)

        redirect()
        return
      }

      notLogged()
    }

    if (isChecked) {
      if (isLogged) {
        redirect()
        return
      }

      notLogged()
    }
  }
}

let firstLoad = true

export const afterEach = store => {
  return (to, from) => {
    if (firstLoad && to.name === FAQ_ROUTES.DEFAULT) {
      firstLoad = false
      // The website should NOT refresh
    } else if (to.name === FAQ_ROUTES.DEFAULT) {
      // The website should refresh
      window.location.reload()
    }

    const requests = store.getters[withNamespace(REQUESTS_STATE.NAMESPACE, REQUESTS_STATE.GETTERS.GET_REQUESTS)]
    requests.forEach(request => {
      request.controller.abort()
    })
    document.title = `${i18n.t('title.' + to.name)} – ${i18n.t('title.page')}`
    window.prevRoute = from
  }
}

export default router
