export const BUSINESS_TERMS = {
  NAMESPACE: 'businessTerms',
  ACTIONS: {
    GET_CURRENT_REQUEST: 'GET_CURRENT_REQUEST',
    GET_HISTORY_REQUEST: 'GET_HISTORY_REQUEST',
    GET_CONTENT_REQUEST: 'GET_CONTENT_REQUEST',
  },
  MUTATIONS: {
    GET_CURRENT_RESPONSE: 'GET_CURRENT_RESPONSE',
    GET_HISTORY_RESPONSE: 'GET_HISTORY_RESPONSE',
    GET_CONTENT_RESPONSE: 'GET_CONTENT_RESPONSE',
    RESET: 'RESET',
  },
}
