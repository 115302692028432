export const FINANCING = {
  NAMESPACE: 'financing',
  ACTIONS: {
    GET_FINANCING_REQUEST: 'GET_FINANCING_REQUEST',
  },
  MUTATIONS: {
    GET_FINANCING_RESPONSE: 'GET_FINANCING_RESPONSE',
    RESET: 'RESET',
  },
}
