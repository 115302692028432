export const FEFL = {
  NAMESPACE: 'fefl',
  ACTIONS: {
    GET_FEFL_REQUEST: 'GET_FEFL_REQUEST',
  },
  MUTATIONS: {
    GET_FEFL_RESPONSE: 'GET_FEFL_RESPONSE',
    RESET: 'RESET',
  },
}
