import createState from '@/store/modules/pbi/state'
import actions from '@/store/modules/pbi/actions'
import mutations from '@/store/modules/pbi/mutations'

export default {
  namespaced: true,
  state: createState(),
  actions,
  mutations,
}
