import { FINANCING } from '@/store/modules/financing/types'
import { callPortalGraphQL } from '@/store/utils'
import { API } from '@/api'
import { addErrorMessage } from '@/services/flashMessages'

export default {
  [FINANCING.ACTIONS.GET_FINANCING_REQUEST]: async ({ dispatch, commit }) => {
    try {
      const data = await callPortalGraphQL(dispatch, {
        requestData: { ...API.financing.getFinancing },
        params: {},
      })
      commit(FINANCING.MUTATIONS.GET_FINANCING_RESPONSE, data)
    } catch {
      addErrorMessage(dispatch, API.financing.getFinancing.id, 'flashMessages.financing.error')
    }
  },
}
