export const PLUGIN_PROVIDER = {
  MALL: 'MALL',
  PLUGIN_SHOPTET: 'PLUGIN_SHOPTET',
  PLUGIN_FAST_CENTRIK: 'PLUGIN_FAST_CENTRIK',
  APPORT_POHODA: 'APPORT_POHODA',
  NANSYS_ALTUS_VARIO: 'NANSYS_ALTUS_VARIO',
  MODULY_PRO_PRESTU: 'MODULY_PRO_PRESTU',
  BALIKOBOT: 'BALIKOBOT',
  MAILSTEP: 'MAILSTEP',
  SAPERTA: 'SAPERTA',
  SELLO_IO: 'SELLO_IO',
  KINET: 'KINET',
  SELL_CONNECT: 'SELL_CONNECT',
  BINARGON: 'BINARGON',
  CONVIU: 'CONVIU',
  BSSHOP: 'BSSHOP',
  ALMEVA: 'ALMEVA',
  FG_FOREST_EDEE_ONE: 'FG_FOREST_EDEE_ONE',
  I_SIMPLY_SELL: 'I_SIMPLY_SELL',
  BASELINKER: 'BASELINKER',
  BHIT: 'BHIT',
  XEMEL: 'XEMEL',
  ATP_SOFTWARE: 'ATP_SOFTWARE',
  RETAILYS: 'RETAILYS',
}
