import { BRANDS } from '@/store/modules/brands/types'
import { callCpapiV2GraphQL, callCpapiV2Api } from '@/store/utils'
import { API } from '@/api'
import { addSuccessMessage, addErrorMessage } from '@/services/flashMessages'

export default {
  [BRANDS.ACTIONS.GET_BRANDS_REQUEST]: async ({ dispatch, commit }, payload) => {
    const data = await callCpapiV2GraphQL(dispatch, {
      requestData: { ...API.brands.getBrands },
      params: {
        country: payload.country,
      },
    })
    commit(BRANDS.MUTATIONS.GET_BRANDS_RESPONSE, data)
  },
  [BRANDS.ACTIONS.GET_BRANDS_HISTORY_REQUEST]: async ({ dispatch, commit }) => {
    const data = await callCpapiV2GraphQL(dispatch, {
      requestData: { ...API.brands.brandRequestHistory },
    })
    commit(BRANDS.MUTATIONS.GET_BRANDS_HISTORY_RESPONSE, data)
  },
  [BRANDS.ACTIONS.UPLOAD_LOGO_REQUEST]: ({ dispatch }, formData) => {
    return callCpapiV2Api(dispatch, {
      requestData: { ...API.brands.uploadLogo },
      params: {
        formData,
      },
      throwError: true,
    }).catch(data => {
      addErrorMessage(dispatch, API.brands.uploadLogo.id, 'flashMessages.brands.logoUploadError', [data])
    })
  },
  [BRANDS.ACTIONS.CREATE_BRANDS_REQUEST]: async ({ dispatch, commit }, payload) => {
    try {
      const data = await callCpapiV2GraphQL(dispatch, {
        requestData: { ...API.brands.createBrands },
        params: {
          data: payload.data,
          force: payload.force,
        },
        throwError: true,
      })
      if (data.success) {
        commit(BRANDS.MUTATIONS.RESET_BRAND_REQUESTS)
        addSuccessMessage(dispatch, API.brands.createBrands.id, 'flashMessages.brands.create')
        return true
      } else {
        commit(BRANDS.MUTATIONS.SIMILAR_BRANDS, data)
        return false
      }
    } catch {
      return false
    }
  },
  [BRANDS.ACTIONS.CREATE_SINGLE_BRAND_REQUEST]: async ({ dispatch, commit }, payload) => {
    try {
      const data = await callCpapiV2GraphQL(dispatch, {
        requestData: { ...API.brands.createBrands },
        params: {
          data: payload.data,
          force: payload.force,
        },
        throwError: true,
      })
      if (data.success) {
        addSuccessMessage(dispatch, API.brands.createBrands.id, 'flashMessages.brands.create')
        commit(BRANDS.MUTATIONS.ADD_SINGLE_BRAND, null)
        return true
      } else {
        commit(BRANDS.MUTATIONS.SIMILAR_BRANDS, data)
        return false
      }
    } catch {
      return false
    }
  },
  [BRANDS.ACTIONS.ADD_BRAND_ROW]: ({ commit }, payload) => {
    commit(BRANDS.MUTATIONS.ADD_BRAND_ROW, payload.form)
    return true
  },
  [BRANDS.ACTIONS.ADD_SINGLE_BRAND]: ({ commit }, payload) => {
    commit(BRANDS.MUTATIONS.ADD_SINGLE_BRAND, payload)
    return true
  },
  [BRANDS.ACTIONS.EDIT_BRAND_ROW]: ({ commit }, payload) => {
    commit(BRANDS.MUTATIONS.EDIT_BRAND_ROW, payload.form)
    return true
  },
  [BRANDS.ACTIONS.DELETE_BRAND_ROW]: ({ commit }, payload) => {
    commit(BRANDS.MUTATIONS.DELETE_BRAND_ROW, payload.item)
    return true
  },
  [BRANDS.ACTIONS.SEARCH_BRANDS]: ({ commit }, payload) => {
    commit(BRANDS.MUTATIONS.SEARCH_BRANDS, payload)
  },
  [BRANDS.ACTIONS.FILTER_BRANDS]: ({ commit }, payload) => {
    commit(BRANDS.MUTATIONS.FILTER_BRANDS, payload)
  },
  [BRANDS.ACTIONS.TOGGLE_EXPAND]: ({ commit }, payload) => {
    commit(BRANDS.MUTATIONS.TOGGLE_EXPAND, payload)
  },
  [BRANDS.ACTIONS.RESET]: ({ commit }) => {
    commit(BRANDS.MUTATIONS.RESET)
  },
}
