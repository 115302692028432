export const WORKFLOW_STATUS = {
  REGISTERED: 'REGISTERED',
  DRAFT_CONTRACT: 'DRAFT_CONTRACT',
  VENDOR_CHECK_STANDARD: 'VENDOR_CHECK_STANDARD',
  VENDOR_CHECK_VIP: 'VENDOR_CHECK_VIP',
  PARTNER_ACCESS: 'PARTNER_ACCESS',
  TESTING: 'TESTING',
  PRODUCTION: 'PRODUCTION',
  HIDDEN_ON_WEB: 'HIDDEN_ON_WEB',
  DEACTIVATED: 'DEACTIVATED',
  ONBOARDING_CANCELLED: 'ONBOARDING_CANCELLED',
  CONTENT_CONTROL: 'CONTENT_CONTROL',
}

export const WORKFLOW_STEPS = {
  REGISTERED: 'step-1',
  DRAFT_CONTRACT: 'step-1',
  HIDDEN_ON_WEB: 'step-1',
  DEACTIVATED: 'step-1',
  ONBOARDING_CANCELLED: 'step-1',
  VENDOR_CHECK_STANDARD: 'step-2-standard',
  VENDOR_CHECK_VIP: 'step-2-vip',
  PARTNER_ACCESS: 'step-3',
  TESTING: 'step-4',
  TESTING_PRODUCTS_SENT: 'step-5',
  CONTENT_CONTROL: 'step-5',
  PRODUCTION: 'step-5',
}

export const WORKFLOW_STEPS_COUNT = 5

export const getWorkflowStepByStatus = status => {
  switch (status) {
    case WORKFLOW_STATUS.REGISTERED:
    case WORKFLOW_STATUS.DRAFT_CONTRACT: {
      return 1
    }
    case WORKFLOW_STATUS.VENDOR_CHECK_STANDARD:
    case WORKFLOW_STATUS.VENDOR_CHECK_VIP: {
      return 2
    }
    case WORKFLOW_STATUS.PARTNER_ACCESS: {
      return 3
    }
    case WORKFLOW_STATUS.TESTING:
    case WORKFLOW_STATUS.CONTENT_CONTROL: {
      return 4
    }
    case WORKFLOW_STATUS.PRODUCTION: {
      return 5
    }
    default: {
      return 1
    }
  }
}
