import { PORTAL_DIALOG } from '@/store/modules/portalDialog/types'
import createState from '@/store/modules/portalDialog/state'
import { resetState } from '@/store/utils'

export default {
  [PORTAL_DIALOG.MUTATIONS.GET_DIALOG_RESPONSE]: (state, payload) => {
    state.dialog = payload
  },
  [PORTAL_DIALOG.MUTATIONS.RESET]: state => {
    resetState(state, createState())
  },
}
