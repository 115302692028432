import { MALL_FEED } from '@/store/modules/mallFeed/types'
import { resetState } from '@/store/utils'
import createState from '@/store/modules/mallFeed/state'

export default {
  [MALL_FEED.MUTATIONS.UPDATE_PRODUCT_FEED_RESPONSE]: (state, payload) => {
    state.productFeed = payload
  },
  [MALL_FEED.MUTATIONS.GET_PRODUCT_FEED_RESPONSE]: (state, payload) => {
    state.productFeed = payload
  },
  [MALL_FEED.MUTATIONS.UPDATE_AVAILABILITY_FEED_RESPONSE]: (state, payload) => {
    state.availabilityFeed = payload
  },
  [MALL_FEED.MUTATIONS.GET_FEED_ERROR_LOG_RESPONSE]: (state, payload) => {
    state.feedErrorLog = payload
  },
  [MALL_FEED.MUTATIONS.GET_AVAILABILITY_FEED_RESPONSE]: (state, payload) => {
    state.availabilityFeed = payload
  },
  [MALL_FEED.MUTATIONS.GET_FEED_STATS_RESPONSE]: (state, payload) => {
    state.feedStats = payload
  },
  [MALL_FEED.MUTATIONS.RESET]: state => {
    resetState(state, createState())
  },
}
