import createState from '@/store/modules/fefl/state'
import { resetState } from '@/store/utils'
import { FEFL } from '@/store/modules/fefl/types'

export default {
  [FEFL.MUTATIONS.GET_FEFL_RESPONSE]: (state, data) => {
    state.fefl = data
  },
  [FEFL.MUTATIONS.RESET]: state => {
    resetState(state, createState())
  },
}
