export const FAQ_ROUTES = {
  DEFAULT: 'faq',
}

export default [
  {
    path: '',
    name: FAQ_ROUTES.DEFAULT,
    component: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "faq" */ '../../../views/app/faq/FaqPage'),
  },
]
