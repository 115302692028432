export default {
  getServiceQuality: {
    id: 'GET_SERVICE_QUALITY',
    request: ({ numberOfMonths, slaTypes }) => ({
      query: `
        query ($numberOfMonths: Int!, $slaTypes: [String!]!) {
          sla {
            statistics (input: { numberOfMonths: $numberOfMonths, slaTypes: $slaTypes }) {
              slaType
              lastSevenDays {
                broken
                total
                ratio
                status
              }
              currentMonth {
                broken
                total
                ratio
                status
                month
              }
              previousMonths {
                broken
                total
                ratio
                status
                month
              }
            }
          }
        }
      `,
      variables: {
        numberOfMonths,
        slaTypes,
      },
    }),
    reduce: data => {
      return data.sla.statistics
    },
  },
  exportReportToFile: {
    id: 'SERVICE_QUALITY_EXPORT_REPORT_TO_FILE',
    request: ({ filter }) => ({
      url: `/portal/export/sla?filter=${JSON.stringify(filter)}`,
      method: 'GET',
      responseType: 'blob',
    }),
  },
}
