<template>
  <v-list>
    <anchor list-item :action="account.email">
      <v-list-item-content class="font-weight-medium">
        {{ account.email }}
      </v-list-item-content>
    </anchor>

    <v-divider />

    <v-list-item :to="{ name: ROUTES.USER.PROFILE }" @click="onClick">
      <v-list-item-content>
        <v-list-item-title>
          {{ $t('topBar.userDropDown.profile') }}
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-avatar>
        <avatar small :name="`${account.firstName} ${account.lastName}`" :item="{ account: account }" size="22" />
      </v-list-item-avatar>
    </v-list-item>

    <anchor list-item action="topBar.userDropDown.logout" @click="logout">
      <v-list-item-content>
        <v-list-item-title>
          {{ $t('topBar.userDropDown.logout') }}
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-avatar>
        <v-icon>
          exit_to_app
        </v-icon>
      </v-list-item-avatar>
    </anchor>
  </v-list>
</template>

<script>
import Avatar from '@/components/profile/user/Avatar'
import { ROUTES } from '@/router/routes'
import { AUTH } from '@/store/modules/auth/types'
import { mapActions } from 'vuex'

export default {
  name: 'UserList',
  components: { Avatar },
  props: {
    account: {
      type: Object,
      required: true,
    },
    closeMenuCallback: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      ROUTES: ROUTES,
    }
  },
  methods: {
    ...mapActions(AUTH.NAMESPACE, [AUTH.ACTIONS.LOGOUT_REQUEST]),
    onClick() {
      if (this.closeMenuCallback) {
        this.closeMenuCallback()
      }
    },
    logout() {
      this[AUTH.ACTIONS.LOGOUT_REQUEST]()
    },
  },
}
</script>
