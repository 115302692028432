export const ACL = {
  DASHBOARD: 'DASHBOARD',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  TEST: 'TEST',
  ORDERS: 'ORDERS',
  CATEGORIES: 'CATEGORIES',
  MALL_FEED: 'MALL_FEED',
  HEUREKA_FEED: 'HEUREKA_FEED',
  MESSAGES: 'MESSAGES',
  PRODUCTS: 'PRODUCTS',
  DELIVERIES: 'DELIVERIES',
  PARTNER: 'PARTNER',
  PARTNER_CONTACTS: 'PARTNER_CONTACTS',
  PARTNER_ACCOUNTS: 'PARTNER_ACCOUNTS',
  PARTNER_NOTIFICATION: 'PARTNER_NOTIFICATION',
  PARTNER_WEBHOOK: 'PARTNER_WEBHOOK',
  PARTNER_SANDBOX_ACCOUNTS: 'PARTNER_SANDBOX_ACCOUNTS',
  PARTNER_PERMISSIONS: 'PARTNER_PERMISSIONS',
  WEB_ACCOUNTS: 'WEB_ACCOUNTS',
  BILLING: 'BILLING',
  CAMPAIGNS: 'CAMPAIGNS',
  FAQ: 'FAQ_PAGE',
  PRODUCT_CHANGE_STAGE: 'PRODUCT_CHANGE_STAGE',
  MALL_DELIVERIES: 'MALL_DELIVERIES',
}
