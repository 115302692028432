import { COUNTRIES } from '@/enums'
import isPostalCode from 'validator/lib/isPostalCode'

export const isPostCode = (value, country) => {
  switch (country) {
    case COUNTRIES.CZ:
      return isPostalCode(value, 'CZ')
    case COUNTRIES.SK:
      return isPostalCode(value, 'SK')
    case COUNTRIES.SI:
      return isPostalCode(value, 'SI')
    case COUNTRIES.PL:
      return isPostalCode(value, 'PL')
    case COUNTRIES.HU:
      return isPostalCode(value, 'HU')
    case COUNTRIES.HR:
      return isPostalCode(value, 'HR')
    default:
      return true
  }
}
