import { SERVICE_QUALITY } from '@/store/modules/serviceQuality/types'
import createState from '@/store/modules/serviceQuality/state'
import { resetState } from '@/store/utils'

export default {
  [SERVICE_QUALITY.MUTATIONS.GET_SERVICE_QUALITY_RESPONSE]: (state, payload) => {
    state.serviceQuality = payload
  },
  [SERVICE_QUALITY.MUTATIONS.RESET]: state => {
    resetState(state, createState())
  },
}
