<template>
  <div class="body-2">
    <div style="width: 100%;">
      <alert-bar v-if="adminEmail" :background-color="primary">
        <v-icon class="mr-1 white--text">
          warning
        </v-icon>

        <span>
          {{ $t('flashMessages.loginAsAdmin', [adminEmail]) }}
        </span>
      </alert-bar>
    </div>

    <div style="width: 100%;">
      <alert-bar :background-color="primary">
        <v-icon class="mr-1 white--text">
          info
        </v-icon>

        <i18n path="notifications.oldVersion">
          <template #link>
            <a class="white--text" :href="link">{{ $t('notifications.oldVersionHere') }}</a>
          </template>
        </i18n>
      </alert-bar>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { AUTH } from '@/store/modules/auth/types'
import AlertBar from '@/components/layout/components/AlertBar'
import { config } from '@/config'
import { ROUTES } from '@/router/routes'

export default {
  name: 'SystemAlerts',
  components: { AlertBar },
  data() {
    return {
      config,
      ROUTES,
      link: `${this.$t('notifications.oldVersionUrl')}${window.location.pathname}${window.location.search}`,
    }
  },
  computed: {
    ...mapState(AUTH.NAMESPACE, ['adminEmail']),
    theme() {
      return this.$vuetify.theme.isDark ? 'dark' : 'light'
    },
    primary() {
      return this.$vuetify.theme.themes[this.theme].primary
    },
  },
  watch: {
    i18n: {
      handler() {
        this.link = `${this.$t('notifications.oldVersionUrl')}${window.location.pathname}${window.location.search}`
      },
      deep: true,
    },
    $route() {
      this.link = `${this.$t('notifications.oldVersionUrl')}${window.location.pathname}${window.location.search}`
    },
  },
}
</script>
