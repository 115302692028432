import { USER_ROUTES } from '@/router/routes/user'
import { ORDER_ROUTES } from '@/router/routes/orders'
import { BRAND_ROUTES } from '@/router/routes/brands'
import { HEUREKA } from '@/router/routes/heureka'
import { PARTNER } from '@/router/routes/partner'
import { CAMPAIGN_ROUTES } from '@/router/routes/campaigns'
import { DELIVERIES_ROUTES } from '@/router/routes/deliveries'
import { FAQ_ROUTES } from '@/router/routes/faq'
import { PRODUCT_ROUTES } from '@/router/routes/products'
import { SERVICE_QUALITY_ROUTES } from '@/router/routes/service-quality'
import { BILLINGS_ROUTES } from '@/router/routes/billings'
import { MARKETING_ROUTES } from '@/router/routes/marketing'
import { SERVICES_ROUTES } from '@/router/routes/services'
import { MALL_FEED } from '@/router/routes/mall-feed'

export const SECURITY = {
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC',
}

export const ROUTES = {
  PRODUCTS: PRODUCT_ROUTES,
  SERVICE_QUALITY: SERVICE_QUALITY_ROUTES,
  BILLINGS: BILLINGS_ROUTES,
  PRODUCT_DETAIL: 'product-detail',
  INVOICE_DETAIL: 'invoice-detail',
  OFFSET_DETAIL: 'offset-detail',
  HOME: 'dashboard',
  ORDERS: ORDER_ROUTES,
  ORDER_DETAIL: 'order-detail',
  CATEGORIES: 'categories',
  USER: USER_ROUTES,
  HEUREKA: HEUREKA,
  MALL_FEED: MALL_FEED,
  MALL_FEED_PRODUCT_ERROR_LOG: 'mall-feed-error-log',
  ACCOUNTS: 'accounts',
  PARTNER: PARTNER,
  CAMPAIGNS: CAMPAIGN_ROUTES,
  MARKETING: MARKETING_ROUTES,
  DELIVERIES: DELIVERIES_ROUTES,
  FAQ: FAQ_ROUTES,
  LOGIN: 'login',
  SWITCH_PARTNER: 'switch-partner',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  REGISTRATION: 'registration',
  REGISTRATION_SUBMIT: 'registration-submit',
  REGISTRATION_CONFIRM: 'registration-confirm',
  REGISTRATION_COMPLETE: 'registration-complete',
  LOGIN_AS_ADMIN: 'login-as-admin',
  LOGIN_WITH_TOKEN: 'login-with-token',
  BUSINESS_TERMS: 'business-terms',
  HISTORY: 'history',
  ORDERS_STOCK_EXPORT: 'orders-stock-export',
  NOT_ALLOWED: 'not-allowed',
  NOT_FOUND: 'not-found',
  STATISTICS: 'statistics',
  BRANDS: BRAND_ROUTES,
  SERVICES: SERVICES_ROUTES,
  FINANCING: 'financing',
  WORKFLOW: 'workflow',
}
