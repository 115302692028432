export const LOCALIZATION = {
  NAMESPACE: 'localization',
  GETTERS: {
    HAS_LOCALIZATIONS: 'HAS_LOCALIZATIONS',
  },
  ACTIONS: {
    GET_LOCALIZATION_REQUEST: 'GET_LOCALIZATION_REQUEST',
  },
  MUTATIONS: {
    GET_LOCALIZATION_RESPONSE: 'GET_LOCALIZATION_RESPONSE',
    RESET: 'RESET',
  },
}
