<template>
  <v-list v-if="partners.length <= 1">
    <anchor list-item :action="selectedPartner | getPartnerTitle">
      <v-list-item-content class="font-weight-medium">
        {{ selectedPartner | getPartnerTitle }}
      </v-list-item-content>
    </anchor>
  </v-list>

  <v-list v-else>
    <anchor list-item :action="selectedPartner | getPartnerTitle">
      <v-list-item-content class="font-weight-medium">
        {{ selectedPartner | getPartnerTitle }}
      </v-list-item-content>
    </anchor>

    <v-divider />

    <template v-for="partner in partners">
      <v-list-item :key="partner.id" @click="switchPartner(partner.id)">
        <v-list-item-content>
          <v-list-item-title>
            {{ partner | getPartnerTitle }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-list>
</template>

<script>
import { mapState } from 'vuex'
import { AUTH } from '@/store/modules/auth/types'
import { getPartnerTitle } from '@/filters'
import { ROUTES } from '@/router/routes'

export default {
  name: 'PartnerList',
  filters: {
    getPartnerTitle,
  },
  props: {
    closeMenuCallback: {
      type: Function,
      default: null,
    },
  },
  computed: {
    ...mapState(AUTH.NAMESPACE, ['partners', 'selectedPartner']),
  },
  methods: {
    switchPartner(partnerId) {
      this.$router.push({ name: ROUTES.SWITCH_PARTNER, params: { partnerId } })
    },
  },
}
</script>
