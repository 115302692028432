import { NAVIGATION } from '@/store/modules/navigation/types'

export default {
  [NAVIGATION.ACTIONS.TOGGLE_NAVIGATION]: ({ commit }) => {
    commit(NAVIGATION.MUTATIONS.TOGGLE_NAVIGATION)
  },
  [NAVIGATION.ACTIONS.OPEN_NAVIGATION]: ({ commit }) => {
    commit(NAVIGATION.MUTATIONS.OPEN_NAVIGATION)
  },
  [NAVIGATION.ACTIONS.CLOSE_NAVIGATION]: ({ commit }) => {
    commit(NAVIGATION.MUTATIONS.CLOSE_NAVIGATION)
  },
}
