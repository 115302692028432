export default {
  getBrands: {
    id: 'GET_BRANDS',
    request: ({ country }) => ({
      query: `
      query($country: MallShopCountryEnum!) {
        brands {
           detailedList(input: {
            country: $country
          }) {
            id
            name
            description
            mediaId
            mediaUrl
            urlKey
          }
        }
      }
      `,
      variables: {
        country,
      },
    }),
    reduce: data => {
      return data.brands.detailedList
    },
  },
  brandRequestHistory: {
    id: 'BRAND_REQUEST_HISTORY',
    request: () => ({
      query: `
        query {
          brands {
            history {
              id
              requestedDate
              brands {
                logo
                languageData {
                  languageId
                  description
                  name
                }
                brandId
              }
              email
              brandCount
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data.brands.history
    },
  },
  createBrands: {
    id: 'CREATE_BRANDS',
    request: ({ data, force }) => ({
      query: `
        mutation($data: [RequestBrandCreationDataInput!]!, $force: Boolean!) {
          brands {
            requestBrandCreation (input: { data: $data, force: $force }) {
              success
              similarBrandsList {
                requestedBrand
                similarBrands
              }
            }
          }
        }
      `,
      variables: {
        data,
        force,
      },
    }),
    reduce: data => {
      return data.brands.requestBrandCreation
    },
  },
  uploadLogo: {
    id: 'UPLOAD_LOGO',
    request: ({ formData }) => ({
      url: '/medias/brand/images',
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    }),
    reduce: data => {
      return data
    },
  },
}
