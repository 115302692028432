export const NOTIFICATIONS = {
  NAMESPACE: 'notifications',
  ACTIONS: {
    RESET: 'RESET',
    NEW_APP_VERSION: 'NEW_APP_VERSION',
    GET_SETTINGS_REQUEST: 'GET_SETTINGS_REQUEST',
    UPDATE_SETTINGS_REQUEST: 'UPDATE_SETTINGS_REQUEST',
  },
  MUTATIONS: {
    RESET: 'RESET',
    NEW_APP_VERSION: 'NEW_APP_VERSION',
    GET_SETTINGS_RESPONSE: 'GET_SETTINGS_RESPONSE',
    UPDATE_SETTINGS_RESPONSE: 'UPDATE_SETTINGS_RESPONSE',
  },
}
