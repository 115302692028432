import { DateTime } from 'luxon'
import { i18n } from '@/localization'
import {
  SHOP_ID_MALL_URLS_TEXT,
  CATEGORY,
  ADMIN_GROUPS,
  SHOP_ID,
  SHOP_ID_LANG,
  ORDER_STATUSES,
  WORKFLOW_STATUS,
  DATE_FORMATS,
} from '@/enums'

export const toFormat = (datetime, format) => {
  if (!DateTime.fromISO(datetime).isValid) return datetime
  return DateTime.fromISO(datetime).setLocale(i18n.locale).toFormat(format)
}

export const toLocalDateTime = datetime => {
  return toFormat(datetime, DATE_FORMATS.INPUT.DATE_MONTH_YEAR_HOURS_MINUTES)
}

export const toShortLocalDate = datetime => {
  return toFormat(datetime, DATE_FORMATS.INPUT.DATE_MONTH_YEAR)
}

export const toLocalDate = datetime => {
  if (datetime) {
    const value = DateTime.fromISO(datetime.split(' ')[0])
    return toFormat(value, DATE_FORMATS.INPUT.DATE_MONTH_YEAR)
  }

  return datetime
}

export const toLocalMonth = datetime => {
  return toFormat(datetime, DATE_FORMATS.INPUT.FULL_MONTH_YEAR)
}

export const toApiMonth = datetime => {
  return toFormat(datetime, DATE_FORMATS.API.MONTH)
}

export const toBasic = datetime => {
  return toFormat(datetime, DATE_FORMATS.INPUT.YEAR_MONTH_DATE)
}

export const toLocalTime = datetime => {
  return toFormat(datetime, DATE_FORMATS.INPUT.HOURS_MINUTES)
}

export const timeAgo = datetime => {
  if (!DateTime.fromISO(datetime).isValid) return datetime

  return DateTime.fromISO(datetime).setLocale(i18n.locale).toRelative()
}

export const getSenderName = message => {
  if (message.admins && message.admins.length && message.admins[0].name) {
    return message.admins[0].name
  } else if (message.account && message.account.name) {
    return message.account.name
  } else {
    return ''
  }
}

export const getSenderEmail = message => {
  if (message.admins && message.admins.length && message.admins[0].email) {
    return message.admins[0].email
  } else if (message.account && message.account.email) {
    return message.account.email
  } else {
    return ''
  }
}

export const getInitials = fullname => {
  const data = fullname.replace(/ +/g, ' ').split(' ', 2)

  const firstChar = data[0] ? data[0].substr(0, 1).toUpperCase() : ''

  const secondChar = data[1] ? data[1].substr(0, 1).toUpperCase() : ''

  return `${firstChar}${secondChar}`
}

export const getFirstGroup = groups => {
  if (!groups || (Array.isArray(groups) && groups.length === 0)) {
    return i18n.t(`enums.groups.${ADMIN_GROUPS.ADMIN_ROLE_SUPPORT}`)
  }

  return groups[0] ? i18n.t(`enums.groups.${groups[0]}`) : ''
}

export const mallUrl = partner => {
  return `https://${mallUrlText(partner)}`
}

export const mallUrlText = partner => {
  const slug = partner.urlSlug.replace(/^\//g, '')

  return `${SHOP_ID_MALL_URLS_TEXT[partner.shopId]}/partner/${slug}`
}

export const getPartnerTitle = partner => {
  return `${partner.mallName} - ${partner.shopId.substring(0, 2)}`
}

export const getCategoryName = category => {
  return category.id === CATEGORY.OTHER ? category.name : `${category.id} – ${category.name}`
}

export const formatPercent = value => {
  return value > 0 ? value.toString().replace('.', ',') + ' %' : ''
}

export const formatNumber = (value, toPercent) => {
  return typeof value === 'number' ? value.toString().replace('.', ',') + (toPercent ? ' %' : '') : '–'
}

export const formatPriceByShopId = (value, shopID) => {
  let formattedPrice = i18n.n(value, 'currency', SHOP_ID_LANG[shopID])

  if (shopID === SHOP_ID.HR10MA) {
    // fix kn format
    formattedPrice = formattedPrice.replace('HRK', 'kn')
  }

  return formattedPrice
}

export const currencySymbolByShopId = shopID => {
  let formattedPrice = formatPriceByShopId(0, shopID)

  return formattedPrice.replace('0', '').trim()
}

export const orderStatusColor = status => {
  switch (status) {
    case ORDER_STATUSES.CANCELLED:
      return 'red'
    case ORDER_STATUSES.BLOCKED:
      return 'grey'
    case ORDER_STATUSES.OPEN:
      return 'white'
    case ORDER_STATUSES.SHIPPING:
      return 'warning'
    case ORDER_STATUSES.SHIPPED:
      return 'secondary'
    case ORDER_STATUSES.DELIVERED:
      return 'success'
    case ORDER_STATUSES.RETURNED:
      return 'error'
    default:
      return 'primary'
  }
}

export const workflowStatusColor = status => {
  switch (status) {
    case WORKFLOW_STATUS.REGISTERED:
    case WORKFLOW_STATUS.DRAFT_CONTRACT:
      return 'grey lighten-4'
    case WORKFLOW_STATUS.VENDOR_CHECK_STANDARD:
    case WORKFLOW_STATUS.VENDOR_CHECK_VIP:
      return 'grey'
    case WORKFLOW_STATUS.PARTNER_ACCESS:
      return 'green lighten-4'
    case WORKFLOW_STATUS.TESTING:
      return 'secondary'
    case WORKFLOW_STATUS.PRODUCTION:
      return 'success'
    case WORKFLOW_STATUS.HIDDEN_ON_WEB:
      return 'warning'
    case WORKFLOW_STATUS.ONBOARDING_CANCELLED:
    case WORKFLOW_STATUS.DEACTIVATED:
      return 'error'
    default:
      return 'gray'
  }
}

export const orderStatusColorText = status => {
  return `${orderStatusColor(status)}--text`
}

export const emptyPlaceholder = item => {
  return item ? item : '–'
}
