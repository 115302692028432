import { COUNTRIES } from '@/enums'
import included from 'validator/lib/isIn'

export const isIncluded = (value, options = []) => {
  if (Array.isArray(value)) {
    return value.every(val => included(val, options))
  }

  return included(value, options)
}

// Validation for CZ, SK
const validateIn = input => {
  let a = 0
  if (input.length === 0) return true
  if (input.length !== 8) return false
  let b = input.split('')
  let c = 0
  for (let i = 0; i < 7; i++) a += parseInt(b[i]) * (8 - i)
  a = a % 11
  c = 11 - a
  if (a === 1) c = 0
  if (a === 0) c = 1
  if (a === 10) c = 1
  if (parseInt(b[7]) !== c) return false

  return true
}

export const isIn = (value, country) => {
  switch (country) {
    case COUNTRIES.CZ:
    case COUNTRIES.SK:
      if (value.length !== 8) {
        return false
      }

      return validateIn(value)
    case COUNTRIES.SI:
    case COUNTRIES.HR:
    case COUNTRIES.HU:
    case COUNTRIES.PL:
      return true
    default:
      return true
  }
}
