<template>
  <v-app v-resize="resetNavBar" class="custom-app-background">
    <global-notifications />
    <new-app-version />
    <global-chatbot :lang="lang" />
    <navigation-bar
      :close-open-navigation="open => closeOpenNavigation(open)"
      :toggle-navigation="toggleNavigation"
      :current-break-point="currentBreakpoint"
    />

    <v-app-bar app class="custom-toolbar" :class="{ 'big-layout-toolbar': !smallLayout }" clipped-left flat dense>
      <v-app-bar-nav-icon v-show="smallLayout" @click.stop="() => toggleNavigation()" />
      <v-toolbar-title class="main-logo pl-0" :class="{ 'pl-sm-3': smallLayout }">
        <anchor :to="{ name: ROUTES.HOME }">
          <img class="d-flex" src="@/assets/img/mall_pp_logo.svg" alt="Mall.cz" />
        </anchor>
      </v-toolbar-title>
      <v-spacer />
      <top-bar-items />
    </v-app-bar>

    <v-main class="custom-content">
      <system-alerts />
      <router-view :key="rerenderComponentKey" class="custom-container" />
    </v-main>

    <tracking
      v-if="isProductionEnv && selectedPartner && account && mdpActive && status"
      :partner="selectedPartner"
      :account="account"
      :mdp="mdpActive"
      :billing="status"
    />
  </v-app>
</template>

<script>
import Tracking from '@/components/tracking/Tracking'
import NavigationBar from '@/components/layout/components/nav/NavigationBar'
import TopBarItems from '@/components/layout/components/TopBarItems'
import { ROUTES } from '@/router/routes'
import GlobalNotifications from '@/components/commons/notifications/GlobalNotifications'
import SystemAlerts from '@/components/layout/components/SystemAlerts'
import { AUTH } from '@/store/modules/auth/types'
import { mapActions, mapGetters, mapState } from 'vuex'
import { REQUESTS_STATE } from '@/store/modules/api/types'
import { DELIVERIES } from '@/store/modules/deliveries/types'
import { BILLINGS } from '@/store/modules/billings/types'
import { NAVIGATION } from '@/store/modules/navigation/types'
import { WORKFLOW_RULES } from '@/enums/workflowRules'
import { BREAKPOINT } from '@/enums/breakpoint'
import GlobalChatbot from '@/components/layout/components/GlobalChatbot'
import NewAppVersion from '@/components/notifications/NewAppVersion'

export default {
  name: 'AppLayout',
  components: { NewAppVersion, GlobalChatbot, Tracking, SystemAlerts, GlobalNotifications, TopBarItems, NavigationBar },
  data() {
    return {
      ROUTES,
      rerenderComponentKey: 0,
      lastBreakPoint: null,
    }
  },
  computed: {
    ...mapState(AUTH.NAMESPACE, ['selectedPartner', 'selectedPartnerId', 'account', 'workflowRules']),
    ...mapState(DELIVERIES.NAMESPACE, ['mdpActive']),
    ...mapState(BILLINGS.NAMESPACE, ['status']),
    ...mapGetters(REQUESTS_STATE.NAMESPACE, [REQUESTS_STATE.GETTERS.GET_STATE]),
    smallLayout() {
      return this.$vuetify.breakpoint.smAndDown
    },
    lang() {
      return this.$i18n.locale
    },
    currentBreakpoint() {
      if (this.smallLayout) {
        return BREAKPOINT.SM_AND_DOWN
      } else if (this.$vuetify.breakpoint.mdOnly) {
        return BREAKPOINT.MD_ONLY
      }
      return BREAKPOINT.LG_AND_UP
    },
    isProduction() {
      return this.workflowRules && this.workflowRules[WORKFLOW_RULES.PRODUCTION]
    },
    isProductionEnv() {
      return process.env.NODE_ENV === 'production'
    },
  },
  watch: {
    $route() {
      this.redirectByWorkflow()
    },
  },
  created() {
    this.redirectByWorkflow()
    this.lastBreakPoint = this.currentBreakpoint
    this[DELIVERIES.ACTIONS.GET_MALL_DELIVERY_ACTIVE_REQUEST]()
    this[BILLINGS.ACTIONS.GET_BILLINGS_STATUS_REQUEST]()
  },
  methods: {
    ...mapActions(DELIVERIES.NAMESPACE, [DELIVERIES.ACTIONS.GET_MALL_DELIVERY_ACTIVE_REQUEST]),
    ...mapActions(BILLINGS.NAMESPACE, [BILLINGS.ACTIONS.GET_BILLINGS_STATUS_REQUEST]),
    ...mapActions(NAVIGATION.NAMESPACE, [
      NAVIGATION.ACTIONS.TOGGLE_NAVIGATION,
      NAVIGATION.ACTIONS.OPEN_NAVIGATION,
      NAVIGATION.ACTIONS.CLOSE_NAVIGATION,
    ]),
    redirectByWorkflow() {
      if (this.$route.name === ROUTES.WORKFLOW && this.isProduction) {
        this.$router.replace({ name: ROUTES.ORDERS.ORDERS_OVERVIEW })
        return
      }
      if (this.$route.name === ROUTES.HOME && !this.isProduction) {
        this.$router.replace({ name: ROUTES.WORKFLOW })
      }
    },
    toggleNavigation() {
      this[NAVIGATION.ACTIONS.TOGGLE_NAVIGATION]()
    },
    closeOpenNavigation(open) {
      if (open) {
        this[NAVIGATION.ACTIONS.OPEN_NAVIGATION]()
      } else {
        this[NAVIGATION.ACTIONS.CLOSE_NAVIGATION]()
      }
    },
    resetNavBar() {
      if (this.currentBreakpoint !== this.lastBreakPoint) {
        this.closeOpenNavigation(this.currentBreakpoint === BREAKPOINT.LG_AND_UP)
      }
      this.lastBreakPoint = this.currentBreakpoint
    },
  },
}
</script>

<style scoped lang="sass">
@import "~vuetify/src/styles/settings/_variables.scss"
@import "../../assets/styles/colors"

.custom-app-background
  background: $app-bg
  padding-bottom: 50px

.custom-toolbar
  outline: $border-color solid 1px
  background: white

.main-logo
  img
    max-width: 150px

    @media #{map-get($display-breakpoints, 'sm-and-up')}
      max-width: 171px



.custom-container
  position: relative
  overflow: hidden
  flex: 1
</style>

<style lang="sass">
@import "~vuetify/src/styles/settings/_variables.scss"

.big-layout-toolbar
  .v-toolbar
    &__content
      padding: 0 $spacer * 5 !important

.custom-content > .v-main__wrap
  display: flex
  flex-direction: column
</style>
