import { MALL_FEED } from '@/store/modules/mallFeed/types'
import { callCpapiV2GraphQL, callPortalGraphQL, getSelectedPartnerId } from '@/store/utils'
import { API } from '@/api'
import { addErrorMessage, addSuccessMessage } from '@/services/flashMessages'

export default {
  [MALL_FEED.ACTIONS.GET_PRODUCT_FEED_REQUEST]: async ({ dispatch, rootGetters, commit }, payload) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.mallFeed.getProductFeed },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
        data: payload,
      },
    })
    commit(MALL_FEED.MUTATIONS.GET_PRODUCT_FEED_RESPONSE, data)
    return data
  },
  [MALL_FEED.ACTIONS.UPDATE_PRODUCT_FEED_REQUEST]: async ({ dispatch, rootGetters, commit }, payload) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.mallFeed.updateProductFeed },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
        data: {
          enabled: true,
          url: payload.url,
          basicAuthName: payload.useBasicAuth ? payload.basicAuthName : '',
          basicAuthPwd: payload.useBasicAuth ? payload.basicAuthPwd : '',
          schedule: payload.schedule,
        },
      },
    })
    commit(MALL_FEED.MUTATIONS.UPDATE_PRODUCT_FEED_RESPONSE, data)
    addSuccessMessage(dispatch, API.mallFeed.updateProductFeed.id, 'flashMessages.saveSetting')
    return true
  },
  [MALL_FEED.ACTIONS.SYNC_PRODUCT_FEED_REQUEST]: async ({ dispatch, rootGetters }) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.mallFeed.syncProductFeed },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
      },
    })
    if (data.success) {
      addSuccessMessage(dispatch, API.mallFeed.syncProductFeed.id, 'flashMessages.feedEnqueued')
    } else {
      addErrorMessage(dispatch, API.mallFeed.syncProductFeed.id, 'flashMessages.feed.feedEnqueuing')
    }
  },
  [MALL_FEED.ACTIONS.GET_AVAILABILITY_FEED_REQUEST]: async ({ dispatch, rootGetters, commit }, payload) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.mallFeed.getAvailabilityFeed },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
        data: payload,
      },
    })
    commit(MALL_FEED.MUTATIONS.GET_AVAILABILITY_FEED_RESPONSE, data)
    return data
  },
  [MALL_FEED.ACTIONS.GET_FEED_ERROR_LOG_REQUEST]: async ({ dispatch, commit }, payload) => {
    const data = await callCpapiV2GraphQL(dispatch, {
      requestData: { ...API.mallFeed.productFeedErrorLog },
      params: {
        feedId: payload.feedId,
        jobId: payload.jobId,
      },
    })
    commit(MALL_FEED.MUTATIONS.GET_FEED_ERROR_LOG_RESPONSE, data)
    return data
  },
  [MALL_FEED.ACTIONS.RESET_HASH_REQUEST]: async ({ dispatch }) => {
    try {
      await callCpapiV2GraphQL(dispatch, {
        requestData: { ...API.mallFeed.resetHash },
        throwError: true,
      })
      addSuccessMessage(dispatch, API.mallFeed.resetHash.id, 'flashMessages.resetData')

      return true
    } catch {
      return false
    }
  },
  [MALL_FEED.ACTIONS.UPDATE_AVAILABILITY_FEED_REQUEST]: async ({ dispatch, rootGetters, commit }, payload) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.mallFeed.updateAvailabilityFeed },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
        data: {
          enabled: true,
          url: payload.url,
          basicAuthName: payload.useBasicAuth ? payload.basicAuthName : '',
          basicAuthPwd: payload.useBasicAuth ? payload.basicAuthPwd : '',
          schedule: payload.schedule,
        },
      },
    })
    commit(MALL_FEED.MUTATIONS.UPDATE_AVAILABILITY_FEED_RESPONSE, data)
    addSuccessMessage(dispatch, API.mallFeed.updateAvailabilityFeed.id, 'flashMessages.saveSetting')
    return true
  },
  [MALL_FEED.ACTIONS.SYNC_AVAILABILITY_FEED_REQUEST]: async ({ dispatch, rootGetters }) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.mallFeed.syncAvailabilityFeed },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
      },
    })
    if (data.success) {
      addSuccessMessage(dispatch, API.mallFeed.syncAvailabilityFeed.id, 'flashMessages.feedEnqueued')
    } else {
      addErrorMessage(dispatch, API.mallFeed.syncAvailabilityFeed.id, 'flashMessages.feed.feedEnqueuing')
    }
  },
  [MALL_FEED.ACTIONS.GET_FEED_STATS_REQUEST]: async ({ dispatch, commit }, payload) => {
    const data = await callCpapiV2GraphQL(dispatch, {
      requestData: { ...API.mallFeed.getStats },
      params: {
        data: {
          days: payload,
        },
      },
    })
    commit(MALL_FEED.MUTATIONS.GET_FEED_STATS_RESPONSE, data)
  },
}
