export default {
  deliveriesGrid: {
    id: 'DELIVERIES_GRID',
    request: () => ({
      query: `
				query {
          delivery {
            grid {
              items {
                price
                cod_price
                pricing {
                  price
                  cod_price
                }
                price_levels {
                  type
                  is_consistent
                  transport {
                    limit
                    price
                  }
                  payment {
                    limit
                    price
                  }
                }
                mall_delivery_type
                transport_name
                code
                title
                delivery_method_id
                free_limit
                delivery_delay
                is_pickup_point
                height {
                  min
                  max
                }
                width {
                  min
                  max
                }
                length {
                  min
                  max
                }
                weight {
                  min
                  max
                }
                priority
                package_size
                mdp_allowed
              }
              filter {
                column
                operator
                value
              }
              sorter {
                column
                direction
              }
              paging {
                page
                total
                itemsPerPage
                lastPage
                nextPage
                previousPage
              }
            }
          }
        }
			`,
    }),
    reduce: data => {
      return data.delivery.grid
    },
  },
  deliveryDelete: {
    id: 'DELIVERY_DELETE',
    request: ({ deliveryCode }) => ({
      query: `
        mutation ($deliveryCode: String!) {
          delivery {
            delete(input: {deliveryCode: $deliveryCode}) {
              success
            }
          }
        }
      `,
      variables: {
        deliveryCode,
      },
    }),
    reduce: data => {
      return data.delivery.delete
    },
  },
  getDelivery: {
    id: 'GET_DELIVERY',
    request: ({ deliveryCode }) => ({
      query: `
        query ($deliveryCode: String!) {
          delivery {
            get(input: {deliveryCode: $deliveryCode}) {
              mall_delivery_type
              pricing {
                type
                limit
                price
                cod_price
              }
              price_levels {
                type
                is_consistent
                transport {
                  limit
                  price
                }
                payment {
                  limit
                  price
                }
              }
              price
              cod_price
              transport_name
              code
              title
              delivery_method_id
              free_limit
              delivery_delay
              is_pickup_point
              height {
                min
                max
              }
              width {
                min
                max
              }
              length {
                min
                max
              }
              weight {
                min
                max
              }
              priority
              package_size
              close_time {
                monday
                tuesday
                wednesday
                thursday
                friday
                saturday
                sunday
              }
            }
          }
        }
      `,
      variables: {
        deliveryCode,
      },
    }),
    reduce: data => {
      return data.delivery.get
    },
  },
  getAvailableDeliveries: {
    id: 'GET_AVAILABLE_DELIVERIES',
    request: ({ deliveryCode }) => ({
      query: `
        query ($deliveryCode: String!) {
          delivery {
            availableTransports(input: {deliveryCode: $deliveryCode}) {
              id
              isPickupPoint
              transportName
              packageSize
            }
          }
        }
      `,
      variables: {
        deliveryCode,
      },
    }),
    reduce: data => {
      return data.delivery.availableTransports
    },
  },
  updateDelivery: {
    id: 'UPDATE_DELIVERY',
    request: ({ deliveryCode, data }) => ({
      query: `
        mutation ($deliveryCode: String!, $data: DeliveryCreateInputData!) {
          delivery {
            update(input: {deliveryCode: $deliveryCode, data: $data}) {
             code
            }
          }
        }
      `,
      variables: {
        deliveryCode,
        data,
      },
    }),
    reduce: data => {
      return data
    },
  },
  updateDeliveryMdp: {
    id: 'UPDATE_DELIVERY',
    request: ({ deliveryCode, data }) => ({
      query: `
        mutation($deliveryCode: String!, $data: DeliveryUpdateInputDataForMdp!) {
          delivery {
            updateForMdp(input: { deliveryCode: $deliveryCode, data: $data }) {
              code
              delivery_method_id
              delivery_delay
              close_time {
                monday
                tuesday
                wednesday
                thursday
                friday
                saturday
                sunday
              }
            }
          }
        }
      `,
      variables: {
        deliveryCode,
        data,
      },
    }),
    reduce: data => {
      return data
    },
  },
  createDelivery: {
    id: 'CREATE_DELIVERY',
    request: ({ data }) => ({
      query: `
        mutation ($data: DeliveryCreateInputData!) {
          delivery {
            create(input: {data: $data}) {
              code
            }
          }
        }
      `,
      variables: {
        data,
      },
    }),
    reduce: data => {
      return data
    },
  },
  transportList: {
    id: 'GET_TRANSFORM_LIST',
    request: () => ({
      query: `
        query {
          delivery {
            transportsList {
              id
              transportName
              packageSize
              isPickupPoint
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data.delivery.transportsList
    },
  },
  getAlerts: {
    id: 'GET_ALERTS',
    request: () => ({
      query: `
        query {
          delivery {
            checks {
               errors {
                code
                attribute
                value
                msg
                articles
              }
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data.delivery.checks.errors
    },
  },
  requestMallDelivery: {
    id: 'UPDATE_MALL_DELIVERY_STATUS_REQUEST',
    request: ({ data }) => ({
      query: `
        mutation ($data: RequestMallDeliveryInputData!) {
          delivery {
           requestMallDelivery(input: {data: $data}) {
             success
           }
         }
       }`,
      variables: {
        data,
      },
    }),
    reduce: data => {
      return data.delivery.requestMallDelivery
    },
  },
  mdpDeliveriesGrid: {
    id: 'DELIVERIES_GRID',
    request: ({ partnerId }) => ({
      query: `
        query($partnerId: String!) {
          mdp {
            deliveryGrid(input: { partnerId: $partnerId }) {
              items {
                transport_name
                pricing {
                  type
                  price
                  cod_price
                  limit
                }
                price_levels {
                  type
                  is_consistent
                  transport {
                    limit
                    price
                  }
                  payment {
                    limit
                    price
                  }
                }
                code
                title
                delivery_method_id
                price
                cod_price
                free_limit
                delivery_delay
                is_pickup_point
                height {
                  min
                  max
                }
                width {
                  min
                  max
                }
                length {
                  min
                  max
                }
                weight {
                  min
                  max
                }
                priority
                package_size
                mall_delivery_type
              }
              filter {
                column
                operator
                value
              }
              sorter {
                column
                direction
              }
              paging {
                page
                total
                itemsPerPage
                lastPage
                nextPage
                previousPage
              }
              search
            }
          }
        }
      `,
      variables: {
        partnerId,
      },
    }),
    reduce: data => {
      return data.mdp.deliveryGrid
    },
  },
  getMallDeliveryState: {
    id: 'GET_MALL_DELIVERY_STATUS_REQUEST',
    request: ({ partnerId }) => ({
      query: `
        query ($partnerId: String!) {
          mdp {
            config (input: {partnerId: $partnerId}) {
              delivery {
                status
              }
              mdpFee {
                fee
              }
              ulozenka {
                register_branch {
                  id
                  address {
                    line_1
                    line_2
                    city
                    zip_postcode
                    country
                  }
                }
                return_branch {
                  id
                  address {
                    line_1
                    line_2
                    city
                    zip_postcode
                    country
                  }
                }
                return_pin {
                  pin
                  valid_till
                }
                warehouse {
                  collection
                  name
                  address {
                    line_1
                    line_2
                    city
                    zip_postcode
                    country
                  }
                  contact {
                    type
                    name
                    email
                    phone
                  }
                  opening_hours {
                    regular {
                      monday {
                        hours {
                          open
                          close
                        }
                      }
                      tuesday {
                        hours {
                          open
                          close
                        }
                      }
                      wednesday {
                        hours {
                          open
                          close
                        }
                      }
                      thursday {
                        hours {
                          open
                          close
                        }
                      }
                      friday {
                        hours {
                          open
                          close
                        }
                      }
                      saturday {
                        hours {
                          open
                          close
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        partnerId,
      },
    }),
    reduce: data => {
      return data.mdp.config
    },
  },
  getMallDeliveryActive: {
    id: 'GET_MALL_DELIVERY_STATUS',
    request: () => ({
      query: `
       query {
        delivery {
          mallDeliveryState {
            state
          }
        }
      }`,
    }),
    reduce: data => {
      return data.delivery.mallDeliveryState
    },
  },
  getDestinationBranches: {
    id: 'GET_DESTINATON_BRANCHES',
    request: ({ orderId }) => ({
      query: `
        query($orderId: String!) {
          orders {
            getDestinationBranches(input: { orderId: $orderId }) {
              id
              name
            }
          }
        }`,
      variables: {
        orderId: orderId.toString(),
      },
    }),
    reduce: data => {
      return data.orders.getDestinationBranches
    },
  },
  getUlozenkaRegisterBranches: {
    id: 'GET_ULOZENKA_REGISTER_BRANCHES',
    request: ({ partnerId, shopId }) => ({
      query: `
        query ($partnerId: String!, $shopId:String!) {
          ulozenka {
            registerBranches(input: { partnerId: $partnerId,  shopId: $shopId }) {
              id
              name,
              street,
              house_number,
              town,
              zip
            }
          }
        }`,
      variables: {
        partnerId,
        shopId,
      },
    }),
    reduce: data => {
      return data.ulozenka.registerBranches
    },
  },
  getWarehouseSettings: {
    id: 'GET_WAREHOUSE_SETTINGS',
    request: () => ({
      query: `
        query {
          delivery {
            warehouseSettings {
              calendar_country
              calendar_type
              supply_delay_from
              supply_delay_to
            }
          }
        }
      `,
      variables: {},
    }),
    reduce: data => {
      return data.delivery.warehouseSettings
    },
  },
  updateWarehouseSettings: {
    id: 'UPDATE_WAREHOUSE_SETTINGS',
    request: ({ data }) => ({
      query: ` 
        mutation($data: WarehouseSettingsInputData!) {
          delivery {
            updateWarehouseSettings(input: { data: $data }) {
              calendar_country
              calendar_type
              supply_delay_from
              supply_delay_to
            }
          }
        }
      `,
      variables: {
        data,
      },
    }),
    reduce: data => {
      return data.delivery.updateWarehouseSettings
    },
  },
}
