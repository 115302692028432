export const API_LIMIT = {
  BASIC_LEVELS: ['essential', 'plus', 'professional'],
  LEVEL: {
    ESSENTIAL: 'essential',
    PLUS: 'plus',
    PROFESSIONAL: 'professional',
    CUSTOM: 'custom',
  },
}

export const API_LIMIT_TYPES = {
  PRODUCTS: 'products',
  AVAILABILITY: 'availability',
  ORDERS: 'orders',
  OTHER: 'other',
}
