export default () => ({
  isExportRunning: false,
  plan: null,
  partner: null,
  categorySummary: null,
  partnerBrandList: null,
  productSummary: null,
  availabilitySummary: null,
  isSyncProduct: false,
  isSyncAvailability: false,
  mallBrands: [],
  productLog: null,
  availabilityLog: null,
  isValidatingProductFeed: false,
  isValidatingAvailabilityFeed: false,
  partnerCategoryTree: null,
  mallCategoryList: null,
  partnerCategoryDetail: null,
  paramList: [],
})
