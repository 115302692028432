<template>
  <div class="py-1 px-2 white--text d-flex align-content-center" :style="{ background: backgroundColor }">
    <div class="text-center" style="flex-grow: 1;">
      <slot></slot>
    </div>

    <btn v-if="onClose" action="button.close" class="ma-0" icon small @click="onClose">
      <v-icon class="white--text">
        close
      </v-icon>
    </btn>
  </div>
</template>

<script>
export default {
  name: 'AlertBar',
  props: {
    backgroundColor: {
      type: String,
      required: true,
    },
    onClose: {
      type: Function,
      default: null,
    },
  },
}
</script>
