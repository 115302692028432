export default {
  getCurrent: {
    id: 'BUSINESS_TERMS_GET_CURRENT',
    request: ({ shopId }) => ({
      query: `
        query ($shopId: String!) {
          businessTerms {
            current (input: {
              shopId: $shopId
            }) {
              id
              version
              shopId
              showInHistory
              validFrom
              created
              updated
              published
              contents {
                id
                lang
              }
            }
          }
        }
      `,
      variables: {
        shopId,
      },
    }),
    reduce: data => {
      return data.businessTerms.current
    },
  },
  getContent: {
    id: 'BUSINESS_TERMS_GET_CONTENT',
    request: ({ businessTermId, businessTermContentId }) => ({
      query: `
        query ($businessTermId: String!, $businessTermContentId: String!) {
          businessTerms {
            content (input: {businessTermId: $businessTermId, businessTermContentId: $businessTermContentId}) {
              id
              lang
              url
            }
          }
        }
      `,
      variables: {
        businessTermId,
        businessTermContentId,
      },
    }),
    reduce: data => {
      return data.businessTerms.content
    },
  },
  getHistory: {
    id: 'BUSINESS_TERMS_GET_HISTORY',
    request: ({ shopId }) => ({
      query: `
        query ($shopId: String!) {
          businessTerms {
            history (input: {shopId: $shopId}) {
              id
              version
              shopId
              showInHistory
              validFrom
              created
              updated
              published
              contents {
                  id
                  lang
              }
            }
          }
        }
      `,
      variables: {
        shopId,
      },
    }),
    reduce: data => {
      return data.businessTerms.history
    },
  },
}
