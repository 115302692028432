import { ACL } from '@/enums/acl'

export const ORDER_ROUTES = {
  ORDERS_OVERVIEW: 'orders-tab',
  ORDERS_OPEN_STATUS: 'orders-tab-open-status',
  ORDERS_SHIPPING_STATUS: 'orders-tab-shipping-status',
  ORDERS_SHIPPED_STATUS: 'orders-tab-shipped-status',
  ORDERS_DELIVERED_STATUS: 'orders-tab-delivered-status',
  ORDERS_MISSING_STATUS: 'orders-tab-missing-status',
}

export default [
  {
    path: '',
    name: ORDER_ROUTES.ORDERS_OVERVIEW,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "orders" */ '../../../views/app/orders/OrdersOverviewPage'
      ),
    meta: {
      resource: ACL.ORDERS,
    },
  },
  {
    path: 'open-status',
    name: ORDER_ROUTES.ORDERS_OPEN_STATUS,
    component: () =>
      import(/* webpackPrefetch: true */ /* webpackChunkName: "orders" */ '../../../views/app/orders/OrdersOpenStatus'),
    meta: {
      resource: ACL.ORDERS,
    },
  },
  {
    path: 'shipping-status',
    name: ORDER_ROUTES.ORDERS_SHIPPING_STATUS,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "orders" */ '../../../views/app/orders/OrdersShippingStatus'
      ),
    meta: {
      resource: ACL.ORDERS,
    },
  },
  {
    path: 'shipped-status',
    name: ORDER_ROUTES.ORDERS_SHIPPED_STATUS,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "orders" */ '../../../views/app/orders/OrdersShippedStatus'
      ),
    meta: {
      resource: ACL.ORDERS,
    },
  },
  {
    path: 'delivered-status',
    name: ORDER_ROUTES.ORDERS_DELIVERED_STATUS,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "orders" */ '../../../views/app/orders/OrdersDeliveredStatus'
      ),
    meta: {
      resource: ACL.ORDERS,
    },
  },
  {
    path: 'missing-statuses',
    name: ORDER_ROUTES.ORDERS_MISSING_STATUS,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "orders" */ '../../../views/app/orders/OrdersMissingStatus'
      ),
    meta: {
      resource: ACL.ORDERS,
    },
  },
]
