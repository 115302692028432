import { PRODUCTS } from '@/store/modules/products/types'
import { resetState } from '@/store/utils'
import createState from '@/store/modules/products/state'
import { convertParamsForProductForm } from '@/components/products/utils/ProductParameters'
import { convertVariantsForProductForm } from '@/components/products/utils/VariantsList'

export default {
  [PRODUCTS.MUTATIONS.GET_CATEGORY_LIST_RESPONSE]: (state, data) => {
    state.categories = data
  },
  [PRODUCTS.MUTATIONS.GET_BRAND_LIST_RESPONSE]: (state, data) => {
    state.brands = data
  },
  [PRODUCTS.MUTATIONS.GET_PRODUCT_RESPONSE]: (state, data) => {
    const {
      categoryParamsData,
      productData: { parameters, variable_parameters, variants },
    } = data
    const newProduct = data.productData

    const convertedVariants = convertVariantsForProductForm(variants, variable_parameters)
    const convertedParameters = convertParamsForProductForm(
      parameters,
      variable_parameters,
      convertedVariants,
      categoryParamsData,
    )
    newProduct.helper = {
      variants: convertedVariants,
      parameters: convertedParameters,
    }

    state.product = newProduct
  },
  [PRODUCTS.MUTATIONS.GET_VARIANTS_RESPONSE]: (state, data) => {
    state.variants = data
  },
  [PRODUCTS.MUTATIONS.GET_RECOMMENDED_RESPONSE]: (state, data) => {
    state.recommended = data
  },
  [PRODUCTS.MUTATIONS.GET_RECOMMENDED_OPTIONS_RESPONSE]: (state, data) => {
    state.recommendedOptions = [...data]
  },
  [PRODUCTS.MUTATIONS.GET_LABELS_LIST_RESPONSE]: (state, data) => {
    state.labels = data
  },
  [PRODUCTS.MUTATIONS.GET_CMT_CATEGORIES_RESPONSE]: (state, data) => {
    state.cmtCategories = {
      partnerId: data.partnerId,
      tree: JSON.parse(data.tree),
    }
  },
  [PRODUCTS.MUTATIONS.PRODUCT_ACTIVATION_PROGRESS]: (state, data) => {
    state.ui.productActivationProgress = { ...state.ui.productActivationProgress, ...data }
  },
  [PRODUCTS.MUTATIONS.GET_CATEGORY_QUERY_RULES_RESPONSE]: (state, data) => {
    state.categoryQueryRules = data
  },
  [PRODUCTS.MUTATIONS.RESET_VARIANTS]: state => {
    state.variants = []
  },
  [PRODUCTS.MUTATIONS.RESET]: state => {
    resetState(state, createState())
  },
}
