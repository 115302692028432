import createState from '@/store/modules/auth/state'
import getters from '@/store/modules/auth/getters'
import actions from '@/store/modules/auth/actions'
import mutations from '@/store/modules/auth/mutations'

export default {
  namespaced: true,
  state: createState(),
  getters,
  actions,
  mutations,
}
