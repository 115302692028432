<template>
  <v-dialog v-model="dialog" persistent :max-width="maxWidth" :disabled="disabled">
    <template #activator="{ on }">
      <span v-on="on">
        <slot name="button" :toggle="() => toggle" />
      </span>
    </template>
    <v-card flat :loading="loading">
      <v-card-title v-if="title" class="title">
        {{ title }}
      </v-card-title>

      <v-card-text>
        <slot name="body" :toggle="toggle">
          {{ body }}
        </slot>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <btn v-if="cancelBtnText" color="secondary" :action="cancelBtnText" text @click.stop="cancel">
          {{ $t(cancelBtnText) }}
        </btn>

        <btn v-bind="{ dark: confirmBtnColor === 'red' }" :color="confirmBtnColor" :action="confirmBtnText" :loading="isSending" @click.stop="confirm">
          {{ $t(confirmBtnText) }}
        </btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    title: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
    cancelBtnText: {
      type: String,
      default: '',
    },
    confirmBtnText: {
      type: String,
      required: true,
    },
    confirmBtnColor: {
      type: String,
      default: 'primary',
    },
    onConfirm: {
      type: Function,
      default: null,
    },
    onCancel: {
      type: Function,
      default: null,
    },
    async: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSending: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Number,
      default: 500,
    },
  },
  data() {
    return {
      dialog: this.value || false,
      loading: false,
    }
  },
  watch: {
    dialog() {
      this.$emit('input', this.dialog)
    },
    value(val) {
      this.dialog = val
    },
  },
  methods: {
    cancel() {
      this.dialog = false
      if (this.onCancel) {
        this.onCancel()
      }
    },
    async confirm() {
      if (!this.async) {
        // Allow programmatically setting confirm-dialog
        if (this.value === null) {
          this.dialog = false
        }
        if (this.onConfirm) {
          this.onConfirm()
        }
      } else {
        if (this.onConfirm) {
          try {
            this.loading = true
            const response = await this.onConfirm()
            if (response) {
              this.loading = false
              this.dialog = false
            }
          } catch (err) {
            this.loading = false
          }
        }
      }
    },
    toggle() {
      this.dialog = !this.dialog
    },
  },
}
</script>
