import { SHOP_ID } from '@/enums/shopId'

export function getCountry(shopID) {
  switch (shopID) {
    case SHOP_ID.CZ10MA:
      return 'CZ'
    case SHOP_ID.SK10MA:
      return 'SK'
    case SHOP_ID.HU20MA:
      return 'HU'
    case SHOP_ID.PL20MA:
      return 'PL'
    case SHOP_ID.SI10SI:
      return 'SI'
    case SHOP_ID.HR10MA:
      return 'HR'
    default:
      throw new Error(`VAT options for ${shopID} no provided`)
  }
}
