import { ACL } from '@/enums/acl'
import campaignRouter, { CAMPAIGN_ROUTES } from '@/router/routes/campaigns'
import Router from '@/router/Router'

export const MARKETING_ROUTES = {
  HOME: 'partner-home',
  PARTNER_PAGE: 'partner-page',
}

export default [
  {
    path: '',
    name: MARKETING_ROUTES.HOME,
    redirect: { name: CAMPAIGN_ROUTES.LIST },
  },
  {
    path: 'campaigns',
    component: Router,
    children: campaignRouter,
    meta: {
      resource: ACL.CAMPAIGNS,
    },
  },
  {
    path: 'partner-page',
    name: MARKETING_ROUTES.PARTNER_PAGE,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "marketing" */ '../../../views/app/marketing/MarketingPartnerPagePage'
      ),
  },
]
