import axios from 'axios'

import { config } from '@/config'
import { LOCAL_STORAGE } from '@/enums'
import {
  logout,
  onError,
  removeError,
  startSending,
  stopSending,
  getFileNameFromContentDisposition,
} from '@/services/utils'
import { addErrorMessage } from '@/services/flashMessages'
import { getSelectedPartnerId } from '@/store/utils'

const apiClient = axios.create({
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
  },
})

const createRequest =
  (store, baseURL) =>
  ({ controller, ...senderConfig }) => {
    return send({
      ...senderConfig,
      signal: controller.signal,
      baseURL,
      headers: {
        ...senderConfig.headers,
        'X-Selected-Partner': getSelectedPartnerId(store.rootGetters),
        'X-App': 'portal-ui',
      },
    })
  }

const send = config => {
  const { method, body, url, baseURL } = config

  const headers = config.headers || {}

  return new Promise((resolve, reject) => {
    if (!method) {
      reject(new Error('The request must have method.'))
    }

    if (!url) {
      reject(new Error('The request must have url.'))
    }

    const token = localStorage.getItem(LOCAL_STORAGE.USER_TOKEN)
    if (token) {
      headers['Authorization'] = `${token}`
    }

    headers['X-Origin'] = window.location.protocol + '//' + window.location.host + window.location.pathname

    resolve(apiClient.request({ ...config, url, baseURL, headers: { ...headers }, body: JSON.stringify(body) }))
  })
}

const call = ({ requestData, params = null, throwError = false, store }, sender) => {
  const { id, errorType, loadingType, request, mock, reduce } = requestData

  if (!id) {
    throw new Error('Request must have id.')
  }

  if (mock) {
    // eslint-disable-next-line
    console.log(`App using mock request id=[${id}]`)
    return mock()
  }

  return new Promise((resolve, reject) => {
    const controller = new AbortController()
    startSending(store.commit, id, errorType, loadingType, controller)

    const payload = {
      ...request(params),
      controller,
    }

    return sender(payload)
      .then(res => {
        stopSending(store.commit, id)
        removeError(store.commit, id)

        if (reduce) {
          resolve(reduce(res.data))
        }

        // File download - get filename from headers
        const contentDisposition = res.headers['content-disposition']
        if (contentDisposition) {
          resolve({
            data: res.data,
            name: getFileNameFromContentDisposition(contentDisposition),
          })
        }

        resolve(res.data)
      })
      .catch(err => {
        // eslint-disable-next-line
        console.error('Response ERROR!', err)

        if (err.response) {
          // logout
          if (err.response.status && err.response.status === 401) {
            logout(store.commit, store.dispatch)

            return
          }
          if (err.response.data.errors) {
            displayError(store.dispatch, err.response.data.errors, id)
          } else {
            // process error
            onError(
              store,
              id,
              err.response.data.code ? err.response.data.code : 'UNKNOWN',
              errorType,
              err.response.data.attributes || [],
            )
          }
        } else {
          // unknown error
          onError(store, id, 'UNKNOWN', errorType)
        }

        stopSending(store.commit, id)

        if (throwError) {
          reject(err)
        }
      })
  })
}

const displayError = (dispatch, errors, id) => {
  errors.forEach(error => {
    addErrorMessage(dispatch, id, error)
  })
}

const callApi = params => call(params, createRequest(params.store, config.backend.apiBaseUrl))
const callFileSystemApi = params => call(params, createRequest(params.store, config.backend.imageProxyUrl))
const callCpapiV2Api = params => call(params, createRequest(params.store, `${config.backend.graphqlBaseUrlV2}/v1`))
const callFefl = params => call(params, senderConfig => send({ ...senderConfig, baseURL: config.backend.feflUrl }))

export { callApi, callFileSystemApi, callCpapiV2Api, callFefl }
