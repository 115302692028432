export const BILLINGS = {
  INVOICE_TYPE_TAG: {
    SB: 'SB',
    FS: 'FS',
  },
  DOCUMENT_TYPE: {
    INVOICE: 'invoice',
    CREDIT_NOTE: 'credit-note',
    DEBIT_NOTE: 'debit-note',
  },
  STATUS: {
    ACTIVE: 'ACTIVE',
  },
}
