<template>
  <span>
    <weak-password-dialog />
    <portal-dialog />
    <app-layout />
  </span>
</template>

<script>
import AppLayout from '@/components/layout/AppLayout'
import WeakPasswordDialog from '@/components/profile/password/WeakPasswordDialog'
import PortalDialog from '@/components/portalDialog/PortalDialog'

export default {
  name: 'App',
  components: { PortalDialog, WeakPasswordDialog, AppLayout },
}
</script>
