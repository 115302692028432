import { ACL } from '@/enums/acl'

export const HEUREKA = {
  FEED_SETTINGS: 'heureka-settings',
  FEED_STATE: 'heureka-status',
  CATEGORY_PAIRING: 'heureka-categories-pairing',
  PARAMS_VALUES_PAIRING: 'heureka-params-values-pairing',
  PARAMS_VALUES_DETAIL: 'heureka-params-values-detail',
  PRODUCTS_BOXES: 'heureka-box-management',
  MEDIA_MANAGEMENT: 'heureka-media-management',
  PRODUCTS_MANAGEMENT: 'heureka-products-management',
}

export default [
  {
    path: '',
    name: HEUREKA.FEED_SETTINGS,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "heureka" */ '../../../views/app/heureka/HeurekaFeedSettingPage'
      ),
    meta: {
      resource: ACL.HEUREKA_FEED,
    },
  },
  {
    path: 'status',
    name: HEUREKA.FEED_STATE,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "heureka" */ '../../../views/app/heureka/HeurekaFeedStatusPage'
      ),
    meta: {
      resource: ACL.HEUREKA_FEED,
    },
  },
  {
    path: 'categories-pairing',
    name: HEUREKA.CATEGORY_PAIRING,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "heureka" */ '../../../views/app/heureka/HeurekaCategoryPairing'
      ),
    meta: {
      resource: ACL.HEUREKA_FEED,
    },
  },
  {
    path: 'params-values-pairing',
    name: HEUREKA.PARAMS_VALUES_PAIRING,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "heureka" */ '../../../views/app/heureka/HeurekaParamsValuesPairing'
      ),
    meta: {
      resource: ACL.HEUREKA_FEED,
    },
    children: [
      {
        path: ':partnerCategoryId',
        name: HEUREKA.PARAMS_VALUES_DETAIL,
        component: () =>
          import(
            /* webpackPrefetch: true */ /* webpackChunkName: "heureka" */ '../../../components/heureka/paramsValuesPairing/ParamsValuesDetailTabHandler.vue'
          ),
        meta: {
          resource: ACL.HEUREKA,
        },
      },
    ],
  },
  {
    path: 'box-management',
    name: HEUREKA.PRODUCTS_BOXES,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "heureka" */ '../../../views/app/products/ProductsBoxManagementTab'
      ),
    meta: {
      resource: ACL.HEUREKA_FEED,
    },
  },
  {
    path: 'media-management',
    name: HEUREKA.MEDIA_MANAGEMENT,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "heureka" */ '../../../views/app/products/ProductsMediaManagementTab'
      ),
    meta: {
      resource: ACL.HEUREKA_FEED,
    },
  },
  {
    path: 'products-management',
    name: HEUREKA.PRODUCTS_MANAGEMENT,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "heureka" */ '../../../views/app/heureka/HeurekaProductsManagement'
      ),
    meta: {
      resource: ACL.HEUREKA_FEED,
    },
  },
]
