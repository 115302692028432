import { AUTH } from '@/store/modules/auth/types'

export default {
  [AUTH.GETTERS.IS_LOGGED]: state => {
    return state.token !== null
  },
  [AUTH.GETTERS.IS_CHECKED]: state => {
    return state.checked
  },
  [AUTH.GETTERS.GET_REFRESH_TIME]: state => {
    return state.refreshTime
  },
  [AUTH.GETTERS.GET_LOGIN_AS_GIVEN_PARTNER]: state => {
    return state.loginAsGivenPartner
  },
  [AUTH.GETTERS.GET_LOGGED_ACCOUNT]: state => {
    return state.account
  },
  [AUTH.GETTERS.GET_SELECTED_PARTNER_ID]: state => {
    return state.selectedPartner && state.selectedPartner.id
  },
  [AUTH.GETTERS.GET_SELECTED_ACCOUNT_ID]: state => {
    return state.account && state.account.id
  },
  [AUTH.GETTERS.GET_SELECTED_PARTNER_SAP_ID]: state => {
    return state.selectedPartner && state.selectedPartner.sapId
  },
  [AUTH.GETTERS.GET_SELECTED_PARTNER_SHOP_ID]: state => {
    return state.selectedPartner && state.selectedPartner.shopId
  },
}
