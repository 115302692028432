import { VAT } from '@/store/modules/vat/types'
import { resetState } from '@/store/utils'
import createState from '@/store/modules/vat/state'

export default {
  [VAT.MUTATIONS.GET_VAT_RESPONSE]: (state, data) => {
    state.vatRates = data
  },
  [VAT.MUTATIONS.RESET]: state => {
    resetState(state, createState())
  },
}
