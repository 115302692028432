import { BILLINGS } from '@/store/modules/billings/types'
import { resetState } from '@/store/utils'
import createState from '@/store/modules/billings/state'

export default {
  [BILLINGS.MUTATIONS.RUNNING_EXPORT]: (state, payload) => {
    state.isExportRunning = payload
  },
  [BILLINGS.MUTATIONS.GET_BILLINGS_STATUS_RESPONSE]: (state, data) => {
    state.status = data
  },
  [BILLINGS.MUTATIONS.GET_INVOICE_RESPONSE]: (state, data) => {
    state.invoice = data
  },
  [BILLINGS.MUTATIONS.GET_OFFSET_RESPONSE]: (state, data) => {
    state.offset = data
  },
  [BILLINGS.MUTATIONS.RESET]: state => {
    resetState(state, createState())
  },
}
