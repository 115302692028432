import { PRICE_LIST } from '@/store/modules/priceList/types'
import createState from '@/store/modules/priceList/state'
import { resetState } from '@/store/utils'

export default {
  [PRICE_LIST.MUTATIONS.GET_STANDARD_PRICE_LIST_RESPONSE]: (state, payload) => {
    payload.items = payload.items.filter(item => item.id !== -1)
    state.standardPriceList = payload
  },
  [PRICE_LIST.MUTATIONS.RESET]: state => {
    resetState(state, createState())
  },
}
