import { VAT } from '@/store/modules/vat/types'
import { callPortalGraphQL } from '@/store/utils'
import { API } from '@/api'
import { OPERATOR } from '@/store/grid'

export default {
  [VAT.ACTIONS.GET_VAT_REQUEST]: async ({ dispatch, commit }, payload) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.vat.vatList },
      params: {
        filter: [
          [
            {
              column: 'shop_id',
              operator: OPERATOR.EQUAL,
              value: [payload.shopId],
            },
          ],
        ],
        paging: null,
        search: '',
        sorter: null,
      },
    })
    commit(VAT.MUTATIONS.GET_VAT_RESPONSE, data)
  },
}
