import { callPortalGraphQL, getSelectedPartnerId } from '@/store/utils'
import { API } from '@/api'
import { DIP } from '@/store/modules/dip/types'

export default {
  [DIP.ACTIONS.GET_DIP_REQUEST]: async ({ dispatch, rootGetters, commit }) => {
    try {
      const data = await callPortalGraphQL(dispatch, {
        requestData: { ...API.dip.getDip },
        params: {
          partnerId: getSelectedPartnerId(rootGetters),
        },
        throwError: true,
      })
      commit(DIP.MUTATIONS.GET_DIP_RESPONSE, data)
    } catch {
      commit(DIP.MUTATIONS.GET_DIP_RESPONSE, null)
    }
  },
}
