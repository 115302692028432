export default {
  get: {
    id: 'ACL_GET',
    request: ({ accountId, partnerId }) => ({
      query: `
        query ($accountId: String!, $partnerId: String!) {
          acl {
            get(input: { accountId: $accountId, partnerId: $partnerId }) {
              subject
              action
              isActive
              isDisabled
            }
          }
        }
      `,
      variables: {
        accountId,
        partnerId,
      },
    }),
    reduce: data => {
      return data.acl.get
    },
  },
  update: {
    id: 'ACL_UPDATE',
    request: ({ accountId, partnerId, data }) => ({
      query: `
        mutation ($accountId: String!, $partnerId: String!, $data: [AclPolicyInput]!) {
          acl {
            update(input: { accountId: $accountId, partnerId: $partnerId, data: $data }) {
              success
            }
          }
        }
      `,
      variables: {
        accountId,
        partnerId,
        data,
      },
    }),
    reduce: data => {
      return data.acl.update
    },
  },
}
