export const STORE = {
  ACTIONS: {
    RESET: 'RESET',
    CALL_AUTH_GRAPHQL: 'CALL_AUTH_GRAPHQL',
    CALL_COCKPIT_GRAPHQL: 'CALL_COCKPIT_GRAPHQL',
    CALL_PUBLIC_GRAPHQL: 'CALL_PUBLIC_GRAPHQL',
    CALL_PORTAL_GRAPHQL: 'CALL_PORTAL_GRAPHQL',
    CALL_CPAPI_V2_PUBLIC_GRAPHQL: 'CALL_CPAPI_V2_PUBLIC_GRAPHQL',
    CALL_CPAPI_V2_GRAPHQL: 'CALL_CPAPI_V2_GRAPHQL',
    CALL_CPAPI_V2_API: 'CALL_CPAPI_V2_API',
    CALL_FEFL_ENDPOINT: 'CALL_FEFL_ENDPOINT',
    CALL_API: 'CALL_API',
    CALL_FILESYSTEM_API: 'CALL_FILESYSTEM_API',
    ROUTER_PUSH: 'ROUTER_PUSH',
  },
}
