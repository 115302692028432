import createState from '@/store/modules/heureka/state'
import actions from '@/store/modules/heureka/actions'
import mutations from '@/store/modules/heureka/mutations'

// @todo better solution
export const DEFAULT_DATETIME = '1970-01-01 01:00:01'

export default {
  namespaced: true,
  state: createState(),
  actions,
  mutations,
}
