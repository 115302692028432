export default () => ({
  contact: null,
  allContacts: [],
  accounts: [],
  agreement: null,
  webhook: null,
  sandboxAccounts: null,
  documents: [],
  logo: null,
})
