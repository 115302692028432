import { PUBLIC_API } from '@/store/modules/public/types'
import { resetState } from '@/store/utils'
import createState from '@/store/modules/public/state'

export default {
  [PUBLIC_API.MUTATIONS.COUNTRIES_RESPONSE]: (state, payload) => {
    state.countries = payload
  },
  [PUBLIC_API.MUTATIONS.RESET]: state => {
    resetState(state, createState())
  },
}
