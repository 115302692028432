import { BUSINESS_TERMS } from '@/store/modules/businessTerms/types'
import { API } from '@/api'
import { callPublicGraphQL } from '@/store/utils'

export default {
  [BUSINESS_TERMS.ACTIONS.GET_CURRENT_REQUEST]: async ({ dispatch, commit }, payload) => {
    const data = await callPublicGraphQL(dispatch, {
      requestData: { ...API.businessTerms.getCurrent },
      params: {
        shopId: payload.shopId,
      },
      throwError: true,
    })

    commit(BUSINESS_TERMS.MUTATIONS.GET_CURRENT_RESPONSE, data)

    return data
  },
  [BUSINESS_TERMS.ACTIONS.GET_HISTORY_REQUEST]: async ({ dispatch, commit }, payload) => {
    const data = await callPublicGraphQL(dispatch, {
      requestData: { ...API.businessTerms.getHistory },
      params: {
        shopId: payload.shopId,
      },
    })
    commit(BUSINESS_TERMS.MUTATIONS.GET_HISTORY_RESPONSE, data)
  },
  [BUSINESS_TERMS.ACTIONS.GET_CONTENT_REQUEST]: async ({ dispatch, commit }, payload) => {
    commit(BUSINESS_TERMS.MUTATIONS.GET_CONTENT_RESPONSE, null)
    const data = await callPublicGraphQL(dispatch, {
      requestData: { ...API.businessTerms.getContent },
      params: {
        businessTermId: payload.businessTermId,
        businessTermContentId: payload.businessTermContentId,
      },
    })
    commit(BUSINESS_TERMS.MUTATIONS.GET_CONTENT_RESPONSE, data)
  },
}
