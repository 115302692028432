import { PARTNER } from '@/store/modules/partner/types'
import createState from '@/store/modules/partner/state'
import { resetState } from '@/store/utils'

export default {
  [PARTNER.MUTATIONS.GET_PARTNER_CONTACT_RESPONSE]: (state, payload) => {
    state.contact = payload
  },
  [PARTNER.MUTATIONS.GET_ACCOUNTS_RESPONSE]: (state, payload) => {
    state.accounts = payload
  },
  [PARTNER.MUTATIONS.GET_ALL_CONTACTS_RESPONSE]: (state, payload) => {
    state.allContacts = payload.items
  },
  [PARTNER.MUTATIONS.GET_AGREEMENT_RESPONSE]: (state, payload) => {
    state.agreement = payload
  },
  [PARTNER.MUTATIONS.SET_PARTNER_WEBHOOK]: (state, payload) => {
    state.webhook = payload
  },
  [PARTNER.MUTATIONS.GET_PARTNER_WEBHOOK_RESPONSE]: (state, payload) => {
    state.webhook = payload
  },
  [PARTNER.MUTATIONS.GET_PARTNER_SANDBOX_ACCOUNTS_RESPONSE]: (state, payload) => {
    state.sandboxAccounts = payload
  },
  [PARTNER.MUTATIONS.GET_DOCUMENTS_LIST_RESPONSE]: (state, payload) => {
    state.documents = payload
  },
  [PARTNER.MUTATIONS.GET_LOGO_RESPONSE]: (state, payload) => {
    state.logo = payload
  },
  [PARTNER.MUTATIONS.RESET]: state => {
    resetState(state, createState())
  },
}
