<script>
export default {
  name: 'NavItemMixin',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    to() {
      return this.item.external === false ? { name: this.item.routeName } : undefined
    },
    href() {
      return this.item.external === true ? this.item.routeName : undefined
    },
  },
}
</script>
