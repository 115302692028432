export default {
  getFinancing: {
    id: 'GET_FINANCING',
    request: () => ({
      query: `
        query {
          financing {
            getLemoneroUrl {
              url
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data.financing.getLemoneroUrl.url
    },
  },
}
