export default {
  getCurrentPbi: {
    id: 'GET_CURRENT_PBI',
    request: () => ({
      query: `
        query {
          embedReport {
            report {
              url
              token
              groupId
              reportId
            }
          }
        }
      `,
      variables: {},
    }),
    reduce: data => {
      return data.embedReport.report
    },
  },
}
