<template>
  <div
    id="msgok_chatbox_data"
    class="msgok_globalchatbox"
    :data-msgokvar1="selectedPartner ? selectedPartner.sapId : ''"
    :data-msgokvar2="account.email"
    :data-partnerName="`${account.firstName} ${account.lastName}`"
    :data-mallName="selectedPartner ? selectedPartner.mallName : ''"
    :data-status="selectedPartner ? selectedPartner.workflow.status : ''"
    :data-shopId="selectedPartner ? selectedPartner.shopId : ''"
    :data-created="selectedPartner ? selectedPartner.partnerInfo.created : ''"
    :data-partnerId="selectedPartnerId"
    :data-accountId="account.id"
    :data-email="account.email ? account.email : ''"
    :data-phone="contract ? contract.phone : ''"
    :data-firstName="account.firstName"
    :data-lastName="account.lastName"
    :data-company="contract ? contract.company : ''"
    :data-web="contract ? contract.web : ''"
    :data-street="contract ? contract.street : ''"
    :data-city="contract ? contract.city : ''"
    :data-state="contract ? contract.state : ''"
    :data-zip="contract ? contract.zip : ''"
    :data-warehouse_address_street="dip ? dip.warehouseAddress.street : ''"
    :data-warehouse_address_city="dip ? dip.warehouseAddress.city : ''"
    :data-warehouse_address_state="dip ? dip.warehouseAddress.state : ''"
    :data-warehouse_address_zip="dip ? dip.warehouseAddress.zip : ''"
    :data-type="selectedPartner.provider"
    :data-registered="registrationInfo ? registrationInfo.created : ''"
    :data-in="contract ? contract.in : ''"
    :data-kam="selectedPartner.kam"
    :data-farmer="selectedPartner.farmer"
    :data-accountant="selectedPartner.accountant"
    :data-onboarding="selectedPartner.onboarding"
    :data-active="isActive"
    :data-pluginProvider="selectedPartner.pluginProvider"
    :data-source="registrationInfo ? registrationInfo.source : ''"
    :data-provider="selectedPartner.provider"
    :data-workflowStatus="selectedPartner.workflow.status"
    :data-countries="countries"
    :data-lang="account.lang"
    :data-sapId="selectedPartner.sapId"
    :data-exportToFeed="selectedPartner.exportToFeed ? selectedPartner.exportToFeed : false"
    :data-exportToPPC="selectedPartner.exportToPPC ? selectedPartner.exportToPPC : false"
    :data-exportToAffiliate="selectedPartner.exportToAffiliate ? selectedPartner.exportToAffiliate : false"
    :data-annualTurnover="registrationInfo ? registrationInfo.annualTurnover : ''"
    :data-salesCount="registrationInfo ? registrationInfo.salesCount : ''"
    :data-offeredCount="registrationInfo ? registrationInfo.offeredCount : ''"
    :data-division="registrationInfo ? registrationInfo.division : ''"
    :data-otherDivision="registrationInfo ? registrationInfo.otherDivision : ''"
    :data-ulozenka="mdpSettings && mdpSettings.ulozenka && mdpSettings.ulozenka.warehouse && mdpSettings.ulozenka.warehouse.length > 0"
    :data-productFeed="productFeed"
    :data-availabilityFeed="availabilityFeed"
    :data-stage="selectedPartner.workflow.status"
    :data-url_slug="selectedPartner.urlSlug"
    :data-label_type="dip ? dip.labelType : ''"
    :data-collections_enabled="dip ? dip.collectionsEnabled : ''"
    :data-collection_place_id="dip ? dip.collectionPlaceId : ''"
    :data-billable="billable"
    :data-portalAccess="portalAccess"
    :data-listOfBusinessTerms="listOfBusinessTerms"
  />
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { AUTH } from '@/store/modules/auth/types'
import { CONTRACT } from '@/store/modules/contract/types'
import { DELIVERIES } from '@/store/modules/deliveries/types'
import { DIP } from '@/store/modules/dip/types'
import { PARTNER } from '@/store/modules/partner/types'
import { MALL_FEED } from '@/store/modules/mallFeed/types'
import { HEUREKA } from '@/store/modules/heureka/types'
import { PROVIDER_TYPES, WORKFLOW_STATUS } from '@/enums'
import { REQUESTS_STATE } from '@/store/modules/api/types'
import { WORKFLOW_RULES } from '@/enums/workflowRules'
import { config } from '@/config'

export default {
  name: 'GlobalChatbot',
  props: {
    lang: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      scriptElement: document.createElement('script'),
      partnerScriptElement: document.createElement('script'),
      productFeed: '',
      availabilityFeed: '',
      loading: true,
    }
  },
  computed: {
    ...mapState(AUTH.NAMESPACE, [
      'account',
      'selectedPartner',
      'selectedPartnerId',
      'workflowRules',
      'registrationInfo',
    ]),
    ...mapState(CONTRACT.NAMESPACE, ['contract']),
    ...mapState(DELIVERIES.NAMESPACE, ['mdpSettings']),
    ...mapState(DIP.NAMESPACE, ['dip']),
    ...mapState(PARTNER.NAMESPACE, ['documents']),
    ...mapGetters(REQUESTS_STATE.NAMESPACE, [REQUESTS_STATE.GETTERS.GET_STATE]),
    isActive() {
      return [WORKFLOW_STATUS.DEACTIVATED, WORKFLOW_STATUS.ONBOARDING_CANCELLED].includes(
        this.selectedPartner.workflow.status,
      )
    },
    countries() {
      return [...new Set(this.account.partners.map(partner => partner.shopId))]
    },
    billable() {
      return this.workflowRules[WORKFLOW_RULES.BILLABLE]
    },
    portalAccess() {
      return this.workflowRules[WORKFLOW_RULES.PORTAL_ACCESS]
    },
    listOfBusinessTerms() {
      return JSON.stringify(
        this.documents?.map(doc => {
          return {
            documentType: doc.documentType,
            url: doc.url,
            validFrom: doc.validFrom,
            uploadedAt: doc.uploadedAt,
          }
        }),
      )
    },
  },
  async created() {
    setTimeout(() => {
      this.scriptElement.src = config.chatbot[this.lang].src
      this.scriptElement.async = true
      this.scriptElement.defer = true
      document.head.appendChild(this.scriptElement)
      this.loading = false
    }, 500)

    this.partnerScriptElement.text = `
      var messageOkData = {
        partnerName: "${this.selectedPartner ? this.selectedPartner.partnerName : ''}"
      };
    `
    document.head.appendChild(this.partnerScriptElement)

    await this.getFeeds()
    this[AUTH.ACTIONS.GET_PARTNER_REGISTRATION_INFO_REQUEST]()
    this[CONTRACT.ACTIONS.CONTRACT_REQUEST]()
    this[DELIVERIES.ACTIONS.GET_MALL_DELIVERY_STATUS_REQUEST]({ throwError: false })
    this[DIP.ACTIONS.GET_DIP_REQUEST]()
    this[PARTNER.ACTIONS.GET_DOCUMENTS_LIST_REQUEST]()
  },
  beforeDestroy() {
    document.head.removeChild(this.scriptElement)
    document.head.removeChild(this.partnerScriptElement)
  },
  methods: {
    ...mapActions(CONTRACT.NAMESPACE, [CONTRACT.ACTIONS.CONTRACT_REQUEST]),
    ...mapActions(DELIVERIES.NAMESPACE, [DELIVERIES.ACTIONS.GET_MALL_DELIVERY_STATUS_REQUEST]),
    ...mapActions(DIP.NAMESPACE, [DIP.ACTIONS.GET_DIP_REQUEST]),
    ...mapActions(PARTNER.NAMESPACE, [PARTNER.ACTIONS.GET_DOCUMENTS_LIST_REQUEST]),
    ...mapActions(AUTH.NAMESPACE, [AUTH.ACTIONS.GET_PARTNER_REGISTRATION_INFO_REQUEST]),
    ...mapActions(MALL_FEED.NAMESPACE, [
      MALL_FEED.ACTIONS.GET_PRODUCT_FEED_REQUEST,
      MALL_FEED.ACTIONS.GET_AVAILABILITY_FEED_REQUEST,
    ]),
    ...mapActions(HEUREKA.NAMESPACE, [HEUREKA.ACTIONS.GET_PARTNER_REQUEST]),
    async getFeeds() {
      if (this.selectedPartner.provider === PROVIDER_TYPES.MALL_FEED) {
        const productFeed = await this[MALL_FEED.ACTIONS.GET_PRODUCT_FEED_REQUEST]()
        this.productFeed = productFeed.url
        const availabilityFeed = await this[MALL_FEED.ACTIONS.GET_AVAILABILITY_FEED_REQUEST]()
        this.availabilityFeed = availabilityFeed.url
      } else if (this.selectedPartner.provider === PROVIDER_TYPES.HEUREKA_FEED) {
        const feed = await this[HEUREKA.ACTIONS.GET_PARTNER_REQUEST]()
        this.productFeed = feed.productFeed
        this.availabilityFeed = feed.availabilityFeed
      }
    },
  },
}
</script>
