export const ORDER_STATUSES = {
  BLOCKED: 'blocked',
  OPEN: 'open',
  SHIPPING: 'shipping',
  SHIPPED: 'shipped',
  DELIVERED: 'delivered',
  RETURNED: 'returned',
  CANCELLED: 'cancelled',
  LOST: 'lost',
}
