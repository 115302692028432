<template>
  <div class="text-center">
    <v-snackbar
      v-model="newAppVersion"
      :timeout="null"
      bottom
      rounded
      :width="300"
      multi-line
      vertical
    >
      <div>
        {{ $t('notifications.newAppVersion') }}
      </div>

      <template #action>
        <v-btn
          color="primary"
          @click="refresh"
        >
          {{ $t('notifications.refresh') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { NOTIFICATIONS } from '@/store/modules/notifications/types'

export default {
  name: 'NewAppVersion',
  data() {
    return {
      snackbar: true,
    }
  },
  computed: {
    ...mapState(NOTIFICATIONS.NAMESPACE, ['newAppVersion']),
  },
  methods: {
    refresh() {
      window.location.reload(true)
    },
  },
}
</script>
