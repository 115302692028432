import Vue from 'vue'
import Vuex from 'vuex'
import requests from '@/store/modules/api'
import apiLimit from '@/store/modules/apiLimit'
import auth from '@/store/modules/auth'
import flashMessages from '@/store/modules/flashMessages'
import workflow from '@/store/modules/workflow'
import contract from '@/store/modules/contract'
import { STORE } from '@/store/types'
import { resetModules } from '@/store/utils'
import {
  callAuthGraphQL,
  callCockpitGraphQL,
  callPublicGraphQL,
  callPortalGraphQL,
  callCpapiV2GraphQL,
  callCpapiV2PublicGraphQL,
} from '@/services/graphql'
import { callApi, callFileSystemApi, callCpapiV2Api, callFefl } from '@/services/fetch'
import { DATA_GRIDS } from '@/store/grid/grids'
import { DIRECTION, OPERATOR } from '@/store/grid'
import createGrid from '@/store/grid/store'
import notifications from '@/store/modules/notifications'
import profile from '@/store/modules/profile'
import serviceQuality from '@/store/modules/serviceQuality'
import campaigns from '@/store/modules/campaigns'
import streamNCClient from '@/services/streamNC'
import { asyncValidation } from '@/validations'
import partner from '@/store/modules/partner'
import publicApi from '@/store/modules/public'
import heureka from '@/store/modules/heureka'
import mallFeed from '@/store/modules/mallFeed'
import vat from '@/store/modules/vat'
import businessTerms from '@/store/modules/businessTerms'
import { createWebSocketPlugin } from '@/store/streamPlugin'
import orders from '@/store/modules/orders'
import deliveries from '@/store/modules/deliveries'
import products from '@/store/modules/products'
import billings from '@/store/modules/billings'
import navigation from '@/store/modules/navigation'
import pbi from '@/store/modules/pbi'
import brands from '@/store/modules/brands'
import priceList from '@/store/modules/priceList'
import portalDialog from '@/store/modules/portalDialog'
import financing from '@/store/modules/financing'
import dip from '@/store/modules/dip'
import fefl from '@/store/modules/fefl'
import localization from '@/store/modules/localization'
import { ORDER_STATUSES } from '@/enums/orderStatuses'

Vue.use(Vuex)

export const createStore = router => {
  return new Vuex.Store({
    actions: {
      [STORE.ACTIONS.RESET]: ({ commit, state }) => {
        resetModules(commit, state)
      },
      [STORE.ACTIONS.CALL_AUTH_GRAPHQL]: (store, payload) => {
        return callAuthGraphQL({ ...payload, store })
      },
      [STORE.ACTIONS.CALL_COCKPIT_GRAPHQL]: (store, payload) => {
        return callCockpitGraphQL({ ...payload, store })
      },
      [STORE.ACTIONS.CALL_PUBLIC_GRAPHQL]: (store, payload) => {
        return callPublicGraphQL({ ...payload, store })
      },
      [STORE.ACTIONS.CALL_CPAPI_V2_PUBLIC_GRAPHQL]: (store, payload) => {
        return callCpapiV2PublicGraphQL({ ...payload, store })
      },
      [STORE.ACTIONS.CALL_PORTAL_GRAPHQL]: (store, payload) => {
        return callPortalGraphQL({ ...payload, store })
      },
      [STORE.ACTIONS.CALL_CPAPI_V2_GRAPHQL]: (store, payload) => {
        return callCpapiV2GraphQL({ ...payload, store })
      },
      [STORE.ACTIONS.CALL_CPAPI_V2_API]: (store, payload) => {
        return callCpapiV2Api({ ...payload, store })
      },
      [STORE.ACTIONS.CALL_FEFL_ENDPOINT]: (store, payload) => {
        return callFefl({ ...payload, store })
      },
      [STORE.ACTIONS.CALL_API]: (store, payload) => {
        return callApi({ ...payload, store })
      },
      [STORE.ACTIONS.CALL_FILESYSTEM_API]: (store, payload) => {
        return callFileSystemApi({ ...payload, store })
      },
      [STORE.ACTIONS.ROUTER_PUSH]: async (store, payload) => {
        await router.push(payload).catch(() => {})
      },
    },
    modules: {
      apiLimit,
      products,
      deliveries,
      orders,
      heureka,
      mallFeed,
      businessTerms,
      partner,
      publicApi,
      notifications,
      requests,
      auth,
      flashMessages,
      workflow,
      contract,
      profile,
      serviceQuality,
      campaigns,
      billings,
      vat,
      navigation,
      pbi,
      brands,
      priceList,
      portalDialog,
      financing,
      dip,
      fefl,
      localization,
      [DATA_GRIDS.CAMPAIGN_LIST]: createGrid(DATA_GRIDS.CAMPAIGN_LIST, {
        sorter: [{ column: 'starting', direction: DIRECTION.DESCENDING }],
      }),
      [DATA_GRIDS.CAMPAIGN_IMPORTS]: createGrid(DATA_GRIDS.CAMPAIGN_IMPORTS, {
        sorter: [{ column: 'created_at', direction: DIRECTION.DESCENDING }],
      }),
      [DATA_GRIDS.CONTACTS]: createGrid(DATA_GRIDS.CONTACTS, { sorter: null, paging: { itemsPerPage: 10 } }),
      [DATA_GRIDS.ADDRESSES]: createGrid(DATA_GRIDS.ADDRESSES, { sorter: null, paging: { itemsPerPage: 10 } }),
      [DATA_GRIDS.BRANDS]: createGrid(DATA_GRIDS.BRANDS, {
        sorter: [{ column: 'name', direction: DIRECTION.ASCENDING }],
      }),
      [DATA_GRIDS.ORDERS_ALL]: createGrid(DATA_GRIDS.ORDERS_ALL, {
        sorter: [
          {
            column: 'order_id',
            direction: DIRECTION.DESCENDING,
          },
        ],
      }),
      [DATA_GRIDS.ORDERS_OPEN]: createGrid(DATA_GRIDS.ORDERS_OPEN, {
        permanentFilter: true,
        sorter: [{ column: 'order_id', direction: DIRECTION.DESCENDING }],
        filter: [
          [
            {
              column: 'status',
              operator: OPERATOR.EQUAL,
              value: [ORDER_STATUSES.OPEN],
            },
          ],
        ],
      }),
      [DATA_GRIDS.ORDERS_SHIPPING]: createGrid(DATA_GRIDS.ORDERS_SHIPPING, {
        permanentFilter: true,
        sorter: [{ column: 'order_id', direction: DIRECTION.DESCENDING }],
        filter: [
          [
            {
              column: 'status',
              operator: OPERATOR.EQUAL,
              value: [ORDER_STATUSES.SHIPPING],
            },
          ],
        ],
      }),
      [DATA_GRIDS.ORDERS_SHIPPED]: createGrid(DATA_GRIDS.ORDERS_SHIPPED, {
        permanentFilter: true,
        sorter: [{ column: 'order_id', direction: DIRECTION.DESCENDING }],
        filter: [
          [
            {
              column: 'status',
              operator: OPERATOR.EQUAL,
              value: [ORDER_STATUSES.SHIPPED],
            },
          ],
        ],
      }),
      [DATA_GRIDS.ORDERS_DELIVERED]: createGrid(DATA_GRIDS.ORDERS_DELIVERED, {
        permanentFilter: true,
        sorter: [{ column: 'order_id', direction: DIRECTION.DESCENDING }],
        filter: [
          [
            {
              column: 'status',
              operator: OPERATOR.EQUAL,
              value: [ORDER_STATUSES.DELIVERED],
            },
          ],
        ],
      }),
      [DATA_GRIDS.ORDERS_MISSING_STATUS]: createGrid(DATA_GRIDS.ORDERS_MISSING_STATUS, {
        sorter: [{ column: 'order_id', direction: DIRECTION.DESCENDING }],
      }),
      [DATA_GRIDS.MALL_PRODUCT_FEED]: createGrid(DATA_GRIDS.MALL_PRODUCT_FEED, {
        sorter: [{ column: 'datetimeStarted', direction: DIRECTION.DESCENDING }],
      }),
      [DATA_GRIDS.MALL_PRODUCT_FEED_ERROR_LOG]: createGrid(DATA_GRIDS.MALL_PRODUCT_FEED_ERROR_LOG, {
        sorter: null,
      }),
      [DATA_GRIDS.DELIVERIES]: createGrid(DATA_GRIDS.DELIVERIES, {
        sorter: null,
      }),
      [DATA_GRIDS.MDP_DELIVERIES]: createGrid(DATA_GRIDS.MDP_DELIVERIES, {
        sorter: null,
      }),
      [DATA_GRIDS.WEBHOOKS]: createGrid(DATA_GRIDS.WEBHOOKS, { sorter: null }),
      [DATA_GRIDS.WEBHOOKS_LOGS]: createGrid(DATA_GRIDS.WEBHOOKS_LOGS, { sorter: null }),
      [DATA_GRIDS.PRODUCTS]: createGrid(DATA_GRIDS.PRODUCTS, { sorter: null }),
      [DATA_GRIDS.PRODUCTS_SOLD_OUT]: createGrid(DATA_GRIDS.PRODUCTS_SOLD_OUT, {
        permanentFilter: true,
        sorter: [{ column: 'title', direction: DIRECTION.DESCENDING }],
        filter: [
          [
            {
              column: 'in_stock',
              operator: OPERATOR.EQUAL,
              value: ['0'],
            },
          ],
        ],
      }),
      [DATA_GRIDS.PRODUCTS_LAST_ITEMS]: createGrid(DATA_GRIDS.PRODUCTS_LAST_ITEMS, {
        permanentFilter: true,
        sorter: [{ column: 'title', direction: DIRECTION.DESCENDING }],
        filter: [
          [
            {
              column: 'in_stock',
              operator: OPERATOR.BETWEEN,
              value: ['1', '5'],
            },
          ],
        ],
      }),
      [DATA_GRIDS.PRODUCTS_BLOCKED_ITEMS]: createGrid(DATA_GRIDS.PRODUCTS_BLOCKED_ITEMS, {
        permanentFilter: true,
        sorter: [{ column: 'title', direction: DIRECTION.DESCENDING }],
        filter: [
          [
            {
              column: 'blocked_products',
              operator: OPERATOR.IN,
            },
          ],
        ],
      }),
      [DATA_GRIDS.PRODUCTS_ERRORS]: createGrid(DATA_GRIDS.PRODUCTS_ERRORS, { sorter: null }),
      [DATA_GRIDS.PRODUCTS_BOX_OVERRIDES]: createGrid(DATA_GRIDS.PRODUCTS_BOX_OVERRIDES, {
        sorter: null,
      }),
      [DATA_GRIDS.PRODUCTS_MEDIA_OVERRIDES]: createGrid(DATA_GRIDS.PRODUCTS_MEDIA_OVERRIDES, {
        sorter: null,
      }),
      [DATA_GRIDS.HEUREKA_OVERRIDES]: createGrid(DATA_GRIDS.HEUREKA_OVERRIDES, {
        sorter: null,
      }),
      [DATA_GRIDS.INVOICES]: createGrid(DATA_GRIDS.INVOICES, {
        sorter: [{ column: 'createdAt', direction: DIRECTION.DESCENDING }],
      }),
      [DATA_GRIDS.OFFSETS]: createGrid(DATA_GRIDS.OFFSETS, {
        sorter: [{ column: 'createdAt', direction: DIRECTION.DESCENDING }],
      }),
      [DATA_GRIDS.DIP_DELIVERIES]: createGrid(DATA_GRIDS.DIP_DELIVERIES, {
        sorter: null,
      }),
    },
    plugins: [createWebSocketPlugin(streamNCClient), asyncValidation()],
  })
}
