import { GRID } from '@/store/grid/store/types'
import { resetState } from '@/store/utils'
import createState from '@/store/grid/store/state'
import {
  prepareGridHeaderStateForSave,
  prepareGridItemsPerPageStateForSave,
  createTableKey,
  createHeaderKey,
  createItemsPerPageKey,
  prepareFilterStateForSave,
} from '@/store/grid/utils'

export default {
  [GRID.MUTATIONS.GRID_RESPONSE]: (state, payload) => {
    state.filter = payload.filter
    if (Array.isArray(payload.sorter) && payload.sorter.length === 0) {
      state.sorter = null
    } else {
      state.sorter = payload.sorter
    }
    state.paging = payload.paging
    state.items = payload.items
    state.search = payload.search
  },
  [GRID.MUTATIONS.SAVE_FILTER]: (state, payload) => {
    const storageFilters = JSON.parse(localStorage.getItem(createTableKey(state.namespace))) || { personalFilters: [] }
    state.personalFilters = [...storageFilters.personalFilters, payload.filterObj]

    localStorage.setItem(createTableKey(state.namespace), JSON.stringify(prepareFilterStateForSave(state)))
  },
  [GRID.MUTATIONS.DELETE_FILTER]: (state, payload) => {
    const storageFilters = JSON.parse(localStorage.getItem(createTableKey(state.namespace))) || { personalFilters: [] }
    state.personalFilters = [...storageFilters.personalFilters.filter(filter => filter.name !== payload.filterName)]

    localStorage.setItem(createTableKey(state.namespace), JSON.stringify(prepareFilterStateForSave(state)))
  },
  [GRID.MUTATIONS.GRID_UPDATE_ROW]: (state, { index, row }) => {
    if (state.items[index]) {
      state.items[index] = row

      state.items = [...state.items]
    }
  },
  [GRID.MUTATIONS.GRID_UPDATE_ROW_ITEM]: (state, { index, key, value }) => {
    if (state.items[index] && Object.prototype.hasOwnProperty.call(state.items[index], key)) {
      state.items[index][key] = value

      state.items = [...state.items]
    }
  },
  [GRID.MUTATIONS.SAVE_HEADERS]: (state, payload) => {
    state.headersMeta = payload.map(header => ({ value: header.value, visible: header.visible }))

    localStorage.setItem(createHeaderKey(state.namespace), JSON.stringify(prepareGridHeaderStateForSave(state)))
  },
  [GRID.MUTATIONS.SAVE_ITEMS_PER_PAGE]: (state, payload) => {
    state.paging.itemsPerPage = payload

    localStorage.setItem(
      createItemsPerPageKey(state.namespace),
      JSON.stringify(prepareGridItemsPerPageStateForSave(state)),
    )
  },
  [GRID.MUTATIONS.EXPAND_ROW]: (state, payload) => {
    if (payload.expanded) {
      state.expandedItems.push(payload.item)
    } else {
      state.expandedItems = state.expandedItems.filter(obj => obj[payload.itemKey] !== payload.item[payload.itemKey])
    }
    state.expandedItems = [...state.expandedItems]
  },
  [GRID.MUTATIONS.TOGGLE_FILTER]: state => {
    state.expandedFilter = state.expandedFilter.length ? [] : [0]
  },
  [GRID.MUTATIONS.RESET]: state => {
    resetState(state, createState(state.namespace, { ...state.default }))
  },
}
