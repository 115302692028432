<template>
  <anchor
    v-if="item.visible === undefined || item.visible === true"
    list-item
    :href="href"
    :to="to"
    exact
    :disabled="item.disabled"
    dense
    class="nav-list-item"
    :action="item.title"
  >
    <v-list-item-icon v-if="(item.icon || item.customIcon) && !noIcon" class="nav-list-item__action d-flex">
      <v-icon v-if="item.icon">
        {{ item.icon }}
      </v-icon>
      <v-icon v-else>
        {{ `$${item.customIcon}` }}
      </v-icon>
    </v-list-item-icon>
    <v-list-item-title>
      <badge v-if="item.newFeature">
        {{ $t(item.title) }}
      </badge>
      <template v-else>
        {{ $t(item.title) }}
      </template>
    </v-list-item-title>
    <v-list-item-action v-if="item.disabled">
      <v-icon small>
        lock
      </v-icon>
    </v-list-item-action>
    <v-list-item-action v-else-if="item.external || item.tag">
      <v-chip v-if="item.external && !item.tag" class="text-uppercase pr-7" x-small>
        {{ $t('navigation.old') }}
      </v-chip>
      <span v-if="item.tag" class="text-uppercase font-weight-bold caption error--text">
        {{ $t(item.tag) }}
      </span>
    </v-list-item-action>
  </anchor>
</template>

<script>
import { ROUTES } from '@/router/routes'
import NavItemMixin from '@/components/layout/components/nav/NavItemMixin'
import Badge from '@/components/badge/Badge'

export default {
  name: 'NavItem',
  components: { Badge },
  mixins: [NavItemMixin],
  props: {
    noIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ROUTES,
    }
  },
}
</script>

<style lang="sass">
.v-list-item__title > .v-badge
  margin-top: 0
.v-list--dense .v-list-item .v-list-item__title
  line-height: 2rem
</style>
