import { ALPHABET } from '@/enums'
import { BRANDS_BOOKMARK_INDICES } from '@/enums/bookmarks'

const startsWithNumber = text => {
  return text.match(/^\d/)
}

const startsWithLetter = (text, letter) => {
  return text.toUpperCase().startsWith(letter)
}

const startsWithOtherCharacter = text => {
  return !startsWithNumber(text) && !ALPHABET.find(letter => startsWithLetter(text, letter))
}

const filterBrands = (brands, filter) => {
  if (filter != null) {
    if (filter === BRANDS_BOOKMARK_INDICES.NUMBER) {
      return brands.filter(brand => startsWithNumber(brand.name))
    } else if (filter === BRANDS_BOOKMARK_INDICES.OTHER) {
      return brands.filter(brand => startsWithOtherCharacter(brand.name))
    } else {
      return brands.filter(brand => startsWithLetter(brand.name, ALPHABET[filter]))
    }
  } else {
    return brands
  }
}

export const combineFilterAndSearch = (brands, filter, search) => {
  const filtered = filterBrands(brands, filter)
  if (search) {
    return filtered.filter(brand => brand.name.toUpperCase().includes(search.toUpperCase()))
  } else {
    return filtered
  }
}
