export default {
  workflow: {
    id: 'WORKFLOW',
    request: ({ partnerId }) => ({
      query: `
          query ($partnerId: String!) {
            workflow {
              get (input: {partnerId: $partnerId}) {
                status
                updated
                ticks {
                  text
                  checked
                }
                analysis
                estimatedLaunch
                availableStates
              } 
            }
          }
        `,
      variables: {
        partnerId,
      },
    }),
    reduce: data => {
      return data.workflow.get
    },
  },
  updateWorkflow: {
    id: 'WORKFLOW_UPDATE',
    request: ({ partnerId, status, ticks }) => ({
      query: `
          mutation ($status: WorkflowStatusEnum!, $partnerId: String!, $ticks: [WorkflowTicksInput]) {
            workflow {
              put (input: { partnerId: $partnerId, data: { status: $status, ticks: $ticks }}) {
                status
                updated
                ticks {
                  text
                  checked
                }
                availableStates
              } 
            }
          }
        `,
      variables: {
        partnerId,
        status,
        ticks,
      },
    }),
    reduce: data => {
      return data.workflow.put
    },
  },
  getFees: {
    id: 'GET_FEES',
    request: () => ({
      query: `
        query {
          fee {
            get {
              feeType
              fees {
                shopId
                value
              }
              provider
              billingType
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data.fee.get
    },
  },
  getCommissionFees: {
    id: 'COMMISSION_FEES',
    request: () => ({
      query: `
        query {
          commission {
            list {
              commissionId
              commissionValue
              id
              text
              name
              categories {
                commissionId
                commissionValue
                id
                text
                name
                division
                variant
              }
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data.commission.list
    },
  },
  getCheckList: {
    id: 'GET_CHECK_LIST',
    request: () => ({
      query: `
        query { 
          checklist {
            addresses {
              checks {
                type
                ok
              }
            }
            contacts {
              checks {
                type
                ok
              }
            }
            products
            transport
            products_for_approval_sent
            products_for_approval_sent_when
          }
        }
      `,
    }),
    reduce: data => {
      return data.checklist
    },
  },
  sendProductToApproval: {
    id: 'PRODUCT_TO_APPROVAL',
    request: () => ({
      query: `
        mutation {
          workflow {
            sendProductsToApproval {
              success
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data
    },
  },
}
