import { PARTNER } from '@/store/modules/partner/types'
import {
  callPortalGraphQL,
  dispatchRoot,
  callFileSystemApi,
  getLoggedAccountId,
  getSelectedPartnerId,
  withNamespace,
  callCpapiV2GraphQL,
} from '@/store/utils'
import { dynamicId } from '@/services/utils'
import { API } from '@/api'
import { addSuccessMessage } from '@/services/flashMessages'
import { DATA_GRIDS } from '@/store/grid/grids'
import { GRID } from '@/store/grid/store/types'

export default {
  [PARTNER.ACTIONS.GET_PARTNER_CONTACT_REQUEST]: async ({ dispatch, commit, rootGetters }, payload) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.partner.getPartnerContact },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
        contactId: payload.contactId,
      },
    })
    commit(PARTNER.MUTATIONS.GET_PARTNER_CONTACT_RESPONSE, data)
  },
  [PARTNER.ACTIONS.CREATE_PARTNER_CONTACT_REQUEST]: async ({ dispatch, rootGetters }, payload) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.partner.createPartnerContact },
        params: {
          partnerId: getSelectedPartnerId(rootGetters),
          data: payload.data,
        },
        throwError: true,
      })

      dispatchRoot(dispatch, withNamespace(DATA_GRIDS.CONTACTS, GRID.ACTIONS.GRID_FETCH), {
        namespace: DATA_GRIDS.CONTACTS,
      })

      addSuccessMessage(dispatch, API.partner.createPartnerContact.id, 'flashMessages.contact.create')
      return true
    } catch {
      return false
    }
  },
  [PARTNER.ACTIONS.UPDATE_PARTNER_CONTACT_REQUEST]: async ({ dispatch, commit, rootGetters }, payload) => {
    try {
      const data = await callPortalGraphQL(dispatch, {
        requestData: { ...API.partner.updatePartnerContact },
        params: {
          partnerId: getSelectedPartnerId(rootGetters),
          contactId: payload.contactId,
          data: payload.data,
        },
        throwError: true,
      })
      dispatchRoot(dispatch, withNamespace(DATA_GRIDS.CONTACTS, GRID.ACTIONS.GRID_FETCH), {
        namespace: DATA_GRIDS.CONTACTS,
      })
      addSuccessMessage(dispatch, API.partner.updatePartnerContact.id, 'flashMessages.contact.update')

      commit(PARTNER.MUTATIONS.GET_PARTNER_CONTACT_RESPONSE, data)

      return true
    } catch {
      return false
    }
  },
  [PARTNER.ACTIONS.DELETE_PARTNER_CONTACT_REQUEST]: async ({ dispatch, rootGetters }, payload) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.partner.deletePartnerContact },
        params: {
          partnerId: getSelectedPartnerId(rootGetters),
          contactId: payload.contactId,
        },
        throwError: true,
      })
      dispatchRoot(dispatch, withNamespace(DATA_GRIDS.CONTACTS, GRID.ACTIONS.GRID_FETCH), {
        namespace: DATA_GRIDS.CONTACTS,
      })
      addSuccessMessage(dispatch, API.partner.deletePartnerContact.id, 'flashMessages.contact.delete')

      return true
    } catch {
      return false
    }
  },
  [PARTNER.ACTIONS.CREATE_PARTNER_ADDRESS_REQUEST]: async ({ dispatch, rootGetters }, payload) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.partner.createPartnerAddress },
        params: {
          partnerId: getSelectedPartnerId(rootGetters),
          data: payload.data,
        },
        throwError: true,
      })
      dispatchRoot(dispatch, withNamespace(DATA_GRIDS.ADDRESSES, GRID.ACTIONS.GRID_FETCH), {
        namespace: DATA_GRIDS.ADDRESSES,
      })
      addSuccessMessage(dispatch, API.partner.createPartnerAddress.id, 'flashMessages.address.create')

      return true
    } catch {
      return false
    }
  },
  [PARTNER.ACTIONS.UPDATE_PARTNER_ADDRESS_REQUEST]: async ({ dispatch, rootGetters }, payload) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.partner.updatePartnerAddress },
        params: {
          partnerId: getSelectedPartnerId(rootGetters),
          addressId: payload.addressId,
          data: payload.data,
        },
        throwError: true,
      })

      dispatchRoot(dispatch, withNamespace(DATA_GRIDS.ADDRESSES, GRID.ACTIONS.GRID_FETCH), {
        namespace: DATA_GRIDS.ADDRESSES,
      })
      addSuccessMessage(dispatch, API.partner.updatePartnerAddress.id, 'flashMessages.address.update')
      return true
    } catch {
      return false
    }
  },
  [PARTNER.ACTIONS.DELETE_PARTNER_ADDRESS_REQUEST]: async ({ dispatch, rootGetters }, payload) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.partner.deletePartnerAddress },
        params: {
          partnerId: getSelectedPartnerId(rootGetters),
          addressId: payload.addressId,
        },
        throwError: true,
      })
      dispatchRoot(dispatch, withNamespace(DATA_GRIDS.ADDRESSES, GRID.ACTIONS.GRID_FETCH), {
        namespace: DATA_GRIDS.ADDRESSES,
      })
      addSuccessMessage(dispatch, API.partner.deletePartnerAddress.id, 'flashMessages.address.delete')

      return true
    } catch {
      return false
    }
  },
  [PARTNER.ACTIONS.UPDATE_PARTNER_ACL_REQUEST]: async ({ dispatch, rootGetters }, payload) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.acl.update },
        params: {
          accountId: getLoggedAccountId(rootGetters),
          partnerId: getSelectedPartnerId(rootGetters),
          data: payload,
        },
        throwError: true,
      })
      addSuccessMessage(dispatch, API.partner.deletePartnerAddress.id, 'flashMessages.saveSetting')
      return true
    } catch {
      return false
    }
  },
  [PARTNER.ACTIONS.GET_ACCOUNTS_REQUEST]: async ({ dispatch, commit, rootGetters }) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.partner.getAccounts },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
      },
    })

    commit(PARTNER.MUTATIONS.GET_ACCOUNTS_RESPONSE, data)
  },
  [PARTNER.ACTIONS.ADD_ACCOUNT_REQUEST]: async ({ dispatch, rootGetters }, payload) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.partner.addAccount },
        params: {
          data: { ...payload },
          partnerId: getSelectedPartnerId(rootGetters),
        },
        throwError: true,
      })

      await dispatch(PARTNER.ACTIONS.GET_ACCOUNTS_REQUEST)
      return true
    } catch {
      return false
    }
  },
  [PARTNER.ACTIONS.UNLINK_PARTNER_ACCOUNT]: async ({ dispatch, rootGetters }, payload) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.partner.unlinkAccount },
        params: {
          partnerId: getSelectedPartnerId(rootGetters),
          accountId: payload.accountId,
        },
        throwError: true,
      })

      await dispatch(PARTNER.ACTIONS.GET_ACCOUNTS_REQUEST)

      addSuccessMessage(dispatch, API.partner.unlinkAccount.id, 'flashMessages.account.unlink')

      return true
    } catch (e) {
      return false
    }
  },
  [PARTNER.ACTIONS.CHANGE_MASTER_ACCOUNT]: async ({ dispatch, rootGetters }, payload) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.partner.changeMasterAccount },
        params: {
          partnerId: getSelectedPartnerId(rootGetters),
          accountId: payload.accountId,
        },
        throwError: true,
      })

      await dispatch(PARTNER.ACTIONS.GET_ACCOUNTS_REQUEST)

      return true
    } catch (e) {
      return false
    }
  },
  [PARTNER.ACTIONS.GET_ALL_CONTACTS_REQUEST]: async ({ dispatch, commit, rootGetters }) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.partner.allContactsGrid },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
      },
    })
    commit(PARTNER.MUTATIONS.GET_ALL_CONTACTS_RESPONSE, data)
  },
  [PARTNER.ACTIONS.GET_AGREEMENT_REQUEST]: async ({ dispatch, commit, rootGetters }) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.partner.getAgreement },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
      },
    })
    commit(PARTNER.MUTATIONS.GET_AGREEMENT_RESPONSE, data)
  },
  [PARTNER.ACTIONS.CREATE_AGREEMENT_REQUEST]: async ({ dispatch, rootGetters, commit }, payload) => {
    try {
      const data = await callPortalGraphQL(dispatch, {
        requestData: { ...API.partner.createAgreement },
        params: {
          partnerId: getSelectedPartnerId(rootGetters),
          businessTermsContentId: payload.businessTermsContentId,
        },
      })
      commit(PARTNER.MUTATIONS.GET_AGREEMENT_RESPONSE, data)

      return true
    } catch {
      return false
    }
  },
  [PARTNER.ACTIONS.CREATE_PARTNER_WEBHOOK_REQUEST]: async ({ dispatch }, payload) => {
    const data = await callCpapiV2GraphQL(dispatch, {
      requestData: { ...API.partner.createPartnerWebhook },
      params: {
        url: payload.url,
        is_enabled: payload.is_enabled,
      },
    })

    return data
  },
  [PARTNER.ACTIONS.SET_PARTNER_WEBHOOK]: async ({ commit }, payload) => {
    commit(PARTNER.MUTATIONS.SET_PARTNER_WEBHOOK, payload)
  },
  [PARTNER.ACTIONS.DELETE_PARTNER_WEBHOOK_REQUEST]: async ({ dispatch }, payload) => {
    const data = await callCpapiV2GraphQL(dispatch, {
      requestData: { ...API.partner.deletePartnerWebhook },
      params: {
        id: payload.id,
      },
    })
    return data
  },
  [PARTNER.ACTIONS.GET_PARTNER_WEBHOOK_REQUEST]: async ({ dispatch, commit }, payload) => {
    const data = await callCpapiV2GraphQL(dispatch, {
      requestData: { ...API.partner.getPartnerWebhookDetail },
      params: {
        id: payload.id,
      },
    })
    commit(PARTNER.MUTATIONS.GET_PARTNER_WEBHOOK_RESPONSE, data)
  },
  [PARTNER.ACTIONS.UPDATE_PARTNER_WEBHOOK_REQUEST]: async ({ dispatch, commit, rootGetters }, payload) => {
    try {
      const data = await callPortalGraphQL(dispatch, {
        requestData: { ...API.partner.updatePartnerWebhook },
        params: {
          id: payload.id,
          partnerId: getSelectedPartnerId(rootGetters),
          data: payload.data,
        },
        throwError: true,
      })
      addSuccessMessage(dispatch, API.partner.updatePartnerWebhook.id, 'flashMessages.webhook.update')

      commit(PARTNER.MUTATIONS.GET_PARTNER_WEBHOOK_RESPONSE, data)

      return true
    } catch {
      return false
    }
  },
  [PARTNER.ACTIONS.GET_PARTNER_SANDBOX_ACCOUNTS_REQUEST]: async ({ dispatch, commit }) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.partner.getPartnerSandboxAccount },
    })
    commit(PARTNER.MUTATIONS.GET_PARTNER_SANDBOX_ACCOUNTS_RESPONSE, data)
  },
  [PARTNER.ACTIONS.GET_LOGO_REQUEST]: async ({ dispatch, rootGetters, commit }) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.partner.getLogo },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
      },
    })
    commit(PARTNER.MUTATIONS.GET_LOGO_RESPONSE, data)
  },
  [PARTNER.ACTIONS.GET_DOCUMENTS_LIST_REQUEST]: async ({ dispatch, rootGetters, commit }) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.partner.getDocumentsList },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
      },
    })
    commit(PARTNER.MUTATIONS.GET_DOCUMENTS_LIST_RESPONSE, data)
  },
  [PARTNER.ACTIONS.DELETE_DOCUMENT_REQUEST]: async ({ dispatch, rootGetters }, payload) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.partner.deleteDocument },
        params: {
          partnerId: getSelectedPartnerId(rootGetters),
          documentId: payload.documentId,
        },
        throwError: true,
      })
      addSuccessMessage(dispatch, API.partner.unlinkAccount.id, 'flashMessages.documents.delete')

      return true
    } catch {
      return false
    }
  },
  [PARTNER.ACTIONS.GET_DOCUMENT_REQUEST]: async ({ dispatch, rootGetters }, payload) => {
    return await callPortalGraphQL(dispatch, {
      requestData: { ...API.partner.getDocument },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
        documentId: payload.documentId,
      },
    })
  },
  [PARTNER.ACTIONS.UPLOAD_FILE]: async ({ dispatch }, payload) => {
    try {
      const data = await callFileSystemApi(dispatch, {
        requestData: {
          ...API.file.uploadFile,
          id: payload.requestId ? dynamicId(API.file.uploadFile.id, payload.requestId) : API.file.uploadFile.id,
        },
        params: {
          type: payload.file.type,
          fileName: payload.file.name,
          data: payload.file,
        },
        throwError: true,
      })

      return data
    } catch {
      return false
    }
  },
  [PARTNER.ACTIONS.ADD_DOCUMENT_REQUEST]: async ({ dispatch, rootGetters }, payload) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.partner.addDocument },
        params: {
          partnerId: getSelectedPartnerId(rootGetters),
          data: {
            objectId: payload.objectId,
            name: payload.name,
            // documentType: payload.documentType,
            // validFrom: payload.validFrom,
          },
        },
        throwError: true,
      })

      addSuccessMessage(dispatch, API.partner.addDocument.id, 'flashMessages.documents.add')
      return true
    } catch {
      return false
    }
  },
  [PARTNER.ACTIONS.SET_LOGO_REQUEST]: async ({ dispatch, rootGetters }, payload) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.partner.setLogo },
        params: {
          partnerId: getSelectedPartnerId(rootGetters),
          data: {
            objectId: payload.objectId,
            name: payload.name,
          },
        },
        throwError: true,
      })
      addSuccessMessage(dispatch, API.partner.setLogo.id, 'flashMessages.documents.setLogo')
      return true
    } catch {
      return false
    }
  },
  [PARTNER.ACTIONS.IS_STATE_ALLOWED_REQUEST]: async ({ dispatch }, payload) => {
    return await callPortalGraphQL(dispatch, {
      requestData: { ...API.partner.isStateAllowed },
      params: {
        stateCode: payload.stateCode,
        types: payload.types,
      },
      throwError: true,
    })
  },
}
