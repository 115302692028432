<script>
export default {
  name: 'TrackMixin',
  props: {
    action: {
      type: String,
      default: '',
    },
  },
  methods: {
    getBreadCrumbNavigation() {
      return this.$t(`title.${this.$route.name}`, 'cs')
    },
    trackEvent(action = '') {
      const googleAnalyticsObj = {
        event: 'gaEvent',
        gaEventData: {
          eCat: 'Button clicks in the Partner portal',
          eAct: this.getBreadCrumbNavigation(),
          eLab: this.$t(this.action ? this.action : action, 'cs'),
          eVal: 1,
          eInt: false,
        },
        eventCallback: () => {
          this.$gtm.trackEvent({ gaEventData: undefined })
        },
      }
      this.$gtm.trackEvent(googleAnalyticsObj)
    },
  },
}
</script>
