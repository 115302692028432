import auth from '@/api/routes/auth'
import businessTerms from '@/api/routes/businessTerms'
import workflow from '@/api/routes/workflow'
import contract from '@/api/routes/contract'
import notifications from '@/api/routes/notifications'
import account from '@/api/routes/account'
import partner from '@/api/routes/partner'
import publicApi from '@/api/routes/public-api'
import file from '@/api/routes/file'
import ares from '@/api/routes/ares'
import campaigns from '@/api/routes/campaigns'
import heureka from '@/api/routes/heureka'
import mallFeed from '@/api/routes/mallFeed'
import acl from '@/api/routes/acl'
import apiLimit from '@/api/routes/apiLimit'
import orders from '@/api/routes/orders'
import mdpOrders from '@/api/routes/mdpOrders'
import deliveries from '@/api/routes/deliveries'
import products from '@/api/routes/products'
import serviceQuality from '@/api/routes/serviceQuality'
import billings from '@/api/routes/billings'
import vat from '@/api/routes/vat'
import pbi from '@/api/routes/pbi'
import brands from '@/api/routes/brands'
import priceList from '@/api/routes/priceList'
import portalDialog from '@/api/routes/portalDialog'
import financing from '@/api/routes/financing'
import dip from '@/api/routes/dip'
import fefl from '@/api/routes/fefl'
import localization from '@/api/routes/localization'

export const API = {
  notifications,
  auth,
  businessTerms,
  workflow,
  contract,
  account,
  partner,
  file,
  ares,
  campaigns,
  heureka,
  mallFeed,
  publicApi,
  acl,
  apiLimit,
  orders,
  mdpOrders,
  deliveries,
  products,
  serviceQuality,
  billings,
  vat,
  pbi,
  brands,
  priceList,
  portalDialog,
  financing,
  dip,
  fefl,
  localization,
}
