export const SHOP_ID = {
  CZ10MA: 'CZ10MA',
  SK10MA: 'SK10MA',
  PL20MA: 'PL20MA',
  SI10SI: 'SI10SI',
  HU20MA: 'HU20MA',
  HR10MA: 'HR10MA',
}

export const SHOP_ID_MALL_URLS = {
  CZ10MA: 'https://mall.cz',
  SK10MA: 'https://mall.sk',
  PL20MA: 'https://mall.pl',
  SI10SI: 'https://mimovrste.com',
  HU20MA: 'https://mall.hu',
  HR10MA: 'https://mall.hr',
}

export const SHOP_ID_MALL_URLS_TEXT = {
  CZ10MA: 'mall.cz',
  SK10MA: 'mall.sk',
  PL20MA: 'mall.pl',
  SI10SI: 'mimovrste.com',
  HU20MA: 'mall.hu',
  HR10MA: 'mall.hr',
}

export const SHOP_ID_MALL_BRAND_URLS = {
  CZ10MA: 'https://mall.cz/znacka',
  SK10MA: 'https://mall.sk/znacka',
  PL20MA: 'https://mall.pl/marki',
  SI10SI: 'https://mimovrste.com/znamke',
  HU20MA: 'https://mall.hu/marka',
  HR10MA: 'https://mall.hr/marka',
}

export const SHOP_ID_LANG = {
  CZ10MA: 'cs',
  SK10MA: 'sk',
  PL20MA: 'pl',
  SI10SI: 'sl',
  HU20MA: 'hu',
  HR10MA: 'hr',
}

export const SHOP_ID_COUNTRY = {
  CZ10MA: 'cz',
  SK10MA: 'sk',
  PL20MA: 'pl',
  SI10SI: 'si',
  HU20MA: 'hu',
  HR10MA: 'hr',
}

export const SHOP_ID_COUNTRY_ISO2 = {
  CZ10MA: 'CZ',
  SK10MA: 'SK',
  PL20MA: 'PL',
  SI10SI: 'SI',
  HU20MA: 'HU',
  HR10MA: 'HR',
}

export const SHOP_ID_ISO3 = {
  CZ10MA: 'CZE',
  SK10MA: 'SVK',
}

export const SHOP_ID_MALL_NAMES = {
  CZ10MA: 'MALL.CZ',
  SK10MA: 'MALL.SK',
  PL20MA: 'MALL.pl',
  SI10SI: 'mimovrste.com',
  HU20MA: 'MALL.HU',
  HR10MA: 'MALL.HR',
}

export function getShopByCountry(country) {
  const shopID = Object.keys(SHOP_ID_COUNTRY).find(key => SHOP_ID_COUNTRY[key] === country)

  if (!shopID) {
    return SHOP_ID.CZ10MA
  }

  return shopID
}
