import { PROFILE } from '@/store/modules/profile/types'
import { API } from '@/api'
import { callPortalGraphQL } from '@/store/utils'
import { addSuccessMessage } from '@/services/flashMessages'

export default {
  [PROFILE.ACTIONS.CHANGE_PASSWORD_REQUEST]: async ({ dispatch }, payload) => {
    try {
      const data = await callPortalGraphQL(dispatch, {
        requestData: { ...API.account.changePassword },
        params: {
          oldPassword: payload.oldPassword,
          newPassword: payload.newPassword,
        },
        throwError: true,
      })

      addSuccessMessage(dispatch, API.account.changePassword.id, 'flashMessages.passwordChanged')
      return data
    } catch (e) {
      return false
    }
  },
}
