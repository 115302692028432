export default {
  getFefl: {
    id: 'GET_FEFL',
    request: () => {
      return {
        method: 'GET',
        url: '/fefl',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    },
    reduce: data => {
      return data
    },
  },
}
