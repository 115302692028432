import { STORE } from '@/store/types'
import { AUTH } from '@/store/modules/auth/types'

export const resetState = (state, initState) => {
  Object.keys(state).forEach(item => {
    state[item] = initState[item]
  })
}

export const resetModules = (commit, state) => {
  Object.keys(state).forEach(module => {
    commit(`${module}/RESET`)
  })
}

export const withNamespace = (namespace, module) => {
  return `${namespace}/${module}`
}

export const dispatchRoot = (dispatch, action, payload) => {
  return dispatch(action, payload, { root: true })
}

export const callAuthGraphQL = (dispatch, payload) => {
  return dispatchRoot(dispatch, STORE.ACTIONS.CALL_AUTH_GRAPHQL, payload)
}

export const callCockpitGraphQL = (dispatch, payload) => {
  return dispatchRoot(dispatch, STORE.ACTIONS.CALL_COCKPIT_GRAPHQL, payload)
}

export const callPortalGraphQL = (dispatch, payload) => {
  return dispatchRoot(dispatch, STORE.ACTIONS.CALL_PORTAL_GRAPHQL, payload)
}

export const callCpapiV2GraphQL = (dispatch, payload) => {
  return dispatchRoot(dispatch, STORE.ACTIONS.CALL_CPAPI_V2_GRAPHQL, payload)
}

export const callCpapiV2Api = (dispatch, payload) => {
  return dispatchRoot(dispatch, STORE.ACTIONS.CALL_CPAPI_V2_API, payload)
}

export const callFeflEndpoint = (dispatch, payload) => {
  return dispatchRoot(dispatch, STORE.ACTIONS.CALL_FEFL_ENDPOINT, payload)
}

export const callPublicCpapiV2GraphQL = (dispatch, payload) => {
  return dispatchRoot(dispatch, STORE.ACTIONS.CALL_CPAPI_V2_PUBLIC_GRAPHQL, payload)
}

export const callPublicGraphQL = (dispatch, payload) => {
  return dispatchRoot(dispatch, STORE.ACTIONS.CALL_PUBLIC_GRAPHQL, payload)
}

export const callApi = (dispatch, payload) => {
  return dispatchRoot(dispatch, STORE.ACTIONS.CALL_API, payload)
}

export const callFileSystemApi = (dispatch, payload) => {
  return dispatchRoot(dispatch, STORE.ACTIONS.CALL_FILESYSTEM_API, payload)
}

export const getSelectedPartnerId = rootGetters => {
  return rootGetters[withNamespace(AUTH.NAMESPACE, AUTH.GETTERS.GET_SELECTED_PARTNER_ID)]
}

export const getLoggedAccountId = rootGetters => {
  return rootGetters[withNamespace(AUTH.NAMESPACE, AUTH.GETTERS.GET_SELECTED_ACCOUNT_ID)]
}

export const getLoginAsGivenPartner = rootGetters => {
  return rootGetters[withNamespace(AUTH.NAMESPACE, AUTH.GETTERS.GET_LOGIN_AS_GIVEN_PARTNER)]
}

export const getSelectedSapId = rootGetters => {
  return rootGetters[withNamespace(AUTH.NAMESPACE, AUTH.GETTERS.GET_SELECTED_PARTNER_SAP_ID)]
}

export const getSelectedShopId = rootGetters => {
  return rootGetters[withNamespace(AUTH.NAMESPACE, AUTH.GETTERS.GET_SELECTED_PARTNER_SHOP_ID)]
}

export const removeKeys = (data, keys) => {
  const newData = { ...data }
  keys.forEach(key => {
    delete newData[key]
  })

  return newData
}
