export const GROUPS = {
  CALLCENTRUM: 'CALLCENTRUM',
  USER: 'USER',
  MANAGER: 'MANAGER',
  FINANCE: 'FINANCE',
  MARKETING: 'MARKETING',
  RMA: 'RMA',
  SALES: 'SALES',
  TECH: 'TECH',
  LOGISTICS: 'LOGISTICS',
  MALL_DELIVERY: 'MALL_DELIVERY',
}

export const ADMIN_GROUPS = {
  ADMIN_ROLE_SUPPORT: 'ADMIN_ROLE_SUPPORT',
}
