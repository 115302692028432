import createState from '@/store/modules/dip/state'
import actions from '@/store/modules/dip/actions'
import mutations from '@/store/modules/dip/mutations'

export default {
  namespaced: true,
  state: createState(),
  actions,
  mutations,
}
