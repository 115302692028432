export default {
  portalDialog: {
    id: 'PORTAL_DIALOG',
    request: () => ({
      query: `
        query {
          redis {
            dialog {
              validFrom
              validTo
              CZ10MA {
                longdesc
              }
              SK10MA {
                longdesc
              }
              PL20MA {
                longdesc
              }
              SI10SI {
                longdesc
              }
              HU20MA {
                longdesc
              }
              HR10MA {
                longdesc
              }
              id
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data.redis.dialog
    },
  },
}
