export default {
  company: {
    id: 'ARES',
    request: ({ ico }) => ({
      query: `
        query ($ico: String!) {
          ares{
            company (input: { in: $ico}) {
              company
              in
              vatId
              street
              city
              state
              zip
            }
          }
        }
      `,
      variables: {
        ico,
      },
    }),
    reduce: data => {
      return data.ares.company
    },
  },
}
