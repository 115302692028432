import { BILLINGS } from '@/store/modules/billings/types'
import { callApi, callCpapiV2GraphQL, getSelectedPartnerId } from '@/store/utils'
import { API } from '@/api'
import { downloader } from '@/services/downloader'
import { callPortalGraphQL } from '@/store/utils'
import { addErrorMessage } from '@/services/flashMessages'
import { SESSION_STORAGE } from '@/enums/sessionStorage'

export default {
  [BILLINGS.ACTIONS.EXPORT_BILLINGS_REQUEST]: async ({ dispatch, rootGetters, commit }, payload) => {
    commit(BILLINGS.MUTATIONS.RUNNING_EXPORT, true)
    let futureResult = callPortalGraphQL(dispatch, {
      requestData: { ...API.billings.exports.createExport },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
        from: payload.dateFrom,
        to: payload.dateTo,
      },
      throwError: true,
    }).catch(() => {
      commit(BILLINGS.MUTATIONS.RUNNING_EXPORT, false)
    })
    let exportResult = await futureResult
    //we receive the location as /exports/{exportId}
    sessionStorage.setItem(`${SESSION_STORAGE.PDF_EXPORT_ZIP}${exportResult['location']}`, 'true')
    return futureResult
  },
  [BILLINGS.ACTIONS.DOWNLOAD_EXPORT_REQUEST]: async ({ dispatch, commit }, payload) => {
    //we have only exportId, adding '/exports/' prefix to match the key
    if (
      payload.exportId &&
      sessionStorage.getItem(`${SESSION_STORAGE.PDF_EXPORT_ZIP}/exports/${payload.exportId}`) != 'true'
    ) {
      return
    }
    if (payload.type === 'END') {
      if (payload.exportId) {
        sessionStorage.removeItem(`${SESSION_STORAGE.PDF_EXPORT_ZIP}/exports/${payload.exportId}`)
        const data = await callApi(dispatch, {
          requestData: { ...API.billings.exports.downloadExport },
          params: {
            exportId: payload.exportId,
          },
        })

        downloader(data, `billings-${new Date().valueOf()}.zip`, 'application/zip')
      } else {
        addErrorMessage(dispatch, API.billings.exports.downloadExport.id, 'flashMessages.billing.exportNotFound')
      }

      commit(BILLINGS.MUTATIONS.RUNNING_EXPORT, false)
    }
  },
  [BILLINGS.ACTIONS.GET_BILLINGS_STATUS_REQUEST]: async ({ dispatch, commit }) => {
    const data = await callCpapiV2GraphQL(dispatch, {
      requestData: { ...API.billings.status },
      throwError: true,
    })

    commit(BILLINGS.MUTATIONS.GET_BILLINGS_STATUS_RESPONSE, data)
    return true
  },
  [BILLINGS.ACTIONS.BILLINGS_ACTIVATION_REQUEST]: async ({ dispatch }) => {
    await callPortalGraphQL(dispatch, {
      requestData: { ...API.billings.requestActivation },
      throwError: true,
    })

    dispatch(BILLINGS.ACTIONS.GET_BILLINGS_STATUS_REQUEST)
  },
  [BILLINGS.ACTIONS.INVOICE_FILE]: async ({ dispatch }, payload) => {
    const file = await callApi(dispatch, {
      requestData: { ...API.billings.invoiceFile },
      params: {
        id: payload.id,
        fileType: payload.type,
      },
      throwError: true,
    })

    if (payload.type === BILLINGS.FILE_TYPES.PDF) {
      downloader(file.data, `${file.name}.pdf`, 'application/pdf')
    } else {
      downloader(
        file.data,
        `${file.name}.xlsx`,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
      )
    }
  },
  [BILLINGS.ACTIONS.OVERVIEW_FILE]: async ({ dispatch }, payload) => {
    const file = await callApi(dispatch, {
      requestData: { ...API.billings.overviewFile },
      params: {
        id: payload.id,
        invoiceNumber: payload.invoiceNumber,
      },
      throwError: true,
    })
    downloader(file.data, file.name, file.data.type)
  },
  [BILLINGS.ACTIONS.GET_INVOICE_REQUEST]: async ({ dispatch, commit }, payload) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.billings.invoiceDetail },
      params: {
        invoiceNumber: payload.invoiceNumber,
      },
    })
    commit(BILLINGS.MUTATIONS.GET_INVOICE_RESPONSE, data)
    return true
  },
  [BILLINGS.ACTIONS.OFFSET_PDF]: async ({ dispatch }, payload) => {
    const file = await callApi(dispatch, {
      requestData: { ...API.billings.offsetPdf },
      params: {
        documentNumber: payload,
      },
      throwError: true,
    })

    downloader(file.data, `${file.name}.pdf`, 'application/pdf')
  },
  [BILLINGS.ACTIONS.GET_OFFSET_REQUEST]: async ({ dispatch, commit }, payload) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.billings.offsetDetail },
      params: {
        documentNumber: payload.documentNumber,
      },
    })
    commit(BILLINGS.MUTATIONS.GET_OFFSET_RESPONSE, data)
  },
}
