import { PUBLIC_API } from '@/store/modules/public/types'
import { API } from '@/api'
import { callPublicGraphQL } from '@/store/utils'

export default {
  [PUBLIC_API.ACTIONS.COUNTRIES_REQUEST]: async ({ dispatch, commit, state }, payload) => {
    if (state.countries.all.length) {
      return
    }
    const data = await callPublicGraphQL(dispatch, {
      requestData: { ...API.publicApi.countries },
      params: {
        data: payload,
      },
    })
    commit(PUBLIC_API.MUTATIONS.COUNTRIES_RESPONSE, data)
  },
}
