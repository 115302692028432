import createState from '@/store/modules/localization/state'
import actions from '@/store/modules/localization/actions'
import mutations from '@/store/modules/localization/mutations'
import getters from '@/store/modules/localization/getters'

export default {
  namespaced: true,
  state: createState(),
  getters,
  actions,
  mutations,
}
