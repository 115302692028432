import createState from '@/store/modules/workflow/state'
import actions from '@/store/modules/workflow/actions'
import mutations from '@/store/modules/workflow/mutations'

export default {
  namespaced: true,
  state: createState(),
  actions,
  mutations,
}
