import { API } from '@/api'
import { callPortalGraphQL } from '@/store/utils'
import { API_LIMIT } from '@/store/modules/apiLimit/types'

export default {
  [API_LIMIT.ACTIONS.GET_PARTNER_DETAIL_API_LIMIT_REQUEST]: async ({ dispatch, commit }) => {
    try {
      const data = await callPortalGraphQL(dispatch, {
        requestData: { ...API.apiLimit.partnerDetailApiLimit },
        throwError: true,
      })
      commit(API_LIMIT.MUTATIONS.GET_PARTNER_DETAIL_API_LIMIT_RESPONSE, data)
    } catch {
      commit(API_LIMIT.MUTATIONS.GET_PARTNER_DETAIL_API_LIMIT_RESPONSE, null)
    }
  },
  [API_LIMIT.ACTIONS.GET_PARTNER_DETAIL_API_LIMIT_HISTORY_REQUEST]: async ({ dispatch, commit }) => {
    try {
      const data = await callPortalGraphQL(dispatch, {
        requestData: { ...API.apiLimit.partnerDetailApiLimitHistory },
        throwError: true,
      })
      commit(API_LIMIT.MUTATIONS.GET_PARTNER_DETAIL_API_LIMIT_HISTORY_RESPONSE, data)
    } catch {
      commit(API_LIMIT.MUTATIONS.GET_PARTNER_DETAIL_API_LIMIT_HISTORY_RESPONSE, null)
    }
  },
  [API_LIMIT.ACTIONS.GET_PARTNER_DETAIL_API_LIMIT_CURRENT_REQUEST]: async ({ dispatch, commit }) => {
    try {
      const data = await callPortalGraphQL(dispatch, {
        requestData: { ...API.apiLimit.partnerDetailApiLimitCurrent },
        throwError: true,
      })
      commit(API_LIMIT.MUTATIONS.GET_PARTNER_DETAIL_API_LIMIT_CURRENT_RESPONSE, data)
    } catch {
      commit(API_LIMIT.MUTATIONS.GET_PARTNER_DETAIL_API_LIMIT_CURRENT_RESPONSE, null)
    }
  },
}
