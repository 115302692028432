import { ACL } from '@/enums/acl'

export const CAMPAIGN_ROUTES = {
  LIST: 'list-campaigns',
  DETAIL: 'campaign-detail',
}

export default [
  {
    path: '',
    name: CAMPAIGN_ROUTES.LIST,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "campaigns" */ '../../../views/app/campaigns/CampaignsListPage'
      ),
    meta: {
      resource: ACL.CAMPAIGNS,
    },
  },
  {
    path: 'detail/:id',
    name: CAMPAIGN_ROUTES.DETAIL,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "campaigns" */ '../../../views/app/campaigns/CampaignFormPage'
      ),
    meta: {
      resource: ACL.CAMPAIGNS,
    },
  },
]
