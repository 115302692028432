<template>
  <v-dialog v-model="dialog" persistent :max-width="maxWidth">
    <template #activator="{ on }">
      <span :class="buttonContainerClass" v-on="on">
        <slot name="button" :toggle="() => toggle" />
      </span>
    </template>
    <v-card flat>
      <v-card-title v-if="title" class="title">
        <span>
          {{ title }}
        </span>
        <v-spacer />
        <btn v-if="cancelBtnText" style="margin: 0;" icon action="button.close" @click.stop="cancel">
          <v-icon large>
            close
          </v-icon>
        </btn>
      </v-card-title>

      <v-progress-linear v-if="isLoading" indeterminate height="4" class="ma-0" />

      <v-card-text>
        <loader :is-loading="isSending">
          <slot />
        </loader>
      </v-card-text>

      <v-card-actions class="pt-0 pa-6">
        <v-spacer />

        <slot name="footer" />

        <btn v-if="cancelBtnText" color="black" text :action="cancelBtnText" @click.stop="cancel">
          {{ $t(cancelBtnText) }}
        </btn>

        <sending-button
          v-if="confirmBtnText"
          :button-title="$t(confirmBtnText)"
          :sending-title="$t('button.sending.sending')"
          :on-click="confirm"
          :is-sending="isSending"
          :action="confirmBtnText"
          :disabled="confirmBtnDisabled"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SendingButton from '@/components/commons/button/SendingButton'
import Loader from '@/components/commons/loader/Loader'
import { fallbackExists } from '@/localization'

export default {
  name: 'ModalDialog',
  components: { Loader, SendingButton },
  props: {
    title: {
      type: String,
      default: '',
    },
    cancelBtnText: {
      type: String,
      default: '',
      validator: value => {
        return !value || fallbackExists(value)
      },
    },
    confirmBtnText: {
      type: String,
      default: '',
      validator: value => {
        return !value || fallbackExists(value)
      },
    },
    onOpen: {
      type: Function,
      default: null,
    },
    onConfirm: {
      type: Function,
      default: null,
    },
    onCancel: {
      type: Function,
      default: null,
    },
    isSending: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
    },
    maxWidth: {
      type: Number,
      default: 500,
    },
    async: {
      type: Boolean,
      default: false,
    },
    nextStep: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    confirmBtnDisabled: {
      type: Boolean,
      default: false,
    },
    buttonContainerClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: this.value,
    }
  },
  watch: {
    dialog() {
      if (this.onOpen && this.dialog) {
        this.onOpen()
      }
      this.$emit('input', this.dialog)
    },
    value(val) {
      this.dialog = val
    },
  },
  methods: {
    cancel() {
      this.dialog = false
      if (this.onCancel) {
        this.onCancel()
      }
    },
    async confirm() {
      if (!this.async) {
        if (!this.nextStep) {
          this.dialog = false
        }
        if (this.onConfirm) {
          this.onConfirm()
        }
      } else {
        if (this.onConfirm) {
          const response = await this.onConfirm()
          if (response) {
            this.dialog = false
          }
        }
      }
    },
    toggle() {
      this.dialog = !this.dialog
    },
  },
}
</script>
