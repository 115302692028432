export const MALL_FEED = {
  NAMESPACE: 'mallFeed',
  ACTIONS: {
    UPDATE_PRODUCT_FEED_REQUEST: 'UPDATE_PRODUCT_FEED_REQUEST',
    SYNC_PRODUCT_FEED_REQUEST: 'SYNC_PRODUCT_FEED_REQUEST',
    GET_PRODUCT_FEED_REQUEST: 'GET_PRODUCT_FEED_REQUEST',
    GET_FEED_ERROR_LOG_REQUEST: 'GET_FEED_ERROR_LOG_REQUEST',
    RESET_HASH_REQUEST: 'RESET_HASH_REQUEST',
    UPDATE_AVAILABILITY_FEED_REQUEST: 'UPDATE_AVAILABILITY_FEED_REQUEST',
    SYNC_AVAILABILITY_FEED_REQUEST: 'SYNC_AVAILABILITY_FEED_REQUEST',
    GET_AVAILABILITY_FEED_REQUEST: 'GET_AVAILABILITY_FEED_REQUEST',
    GET_FEED_STATS_REQUEST: 'GET_FEED_STATS_REQUEST',
  },
  MUTATIONS: {
    UPDATE_PRODUCT_FEED_RESPONSE: 'UPDATE_PRODUCT_FEED_RESPONSE',
    SYNC_PRODUCT_FEED_RESPONSE: 'SYNC_PRODUCT_FEED_RESPONSE',
    GET_PRODUCT_FEED_RESPONSE: 'GET_PRODUCT_FEED_RESPONSE',
    GET_FEED_ERROR_LOG_RESPONSE: 'GET_FEED_ERROR_LOG_RESPONSE',
    RESET_HASH_RESPONSE: 'RESET_HASH_RESPONSE',
    UPDATE_AVAILABILITY_FEED_RESPONSE: 'UPDATE_AVAILABILITY_FEED_RESPONSE',
    SYNC_AVAILABILITY_FEED_RESPONSE: 'SYNC_AVAILABILITY_FEED_RESPONSE',
    GET_AVAILABILITY_FEED_RESPONSE: 'GET_AVAILABILITY_FEED_RESPONSE',
    GET_FEED_STATS_RESPONSE: 'GET_FEED_STATS_RESPONSE',
    RESET: 'RESET',
  },
}
