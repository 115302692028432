import createState from '@/store/modules/notifications/state'
import actions from '@/store/modules/notifications/actions'
import mutations from '@/store/modules/notifications/mutations'

export default {
  namespaced: true,
  state: createState(),
  actions,
  mutations,
}
