import { ORDERS } from '@/store/modules/orders/types'
import { callApi, callCpapiV2Api, callCpapiV2GraphQL, callPortalGraphQL, getSelectedPartnerId } from '@/store/utils'
import { API } from '@/api'
import { addSuccessMessage, addErrorMessage } from '@/services/flashMessages'
import { base64ToBlob, blobToUtf8, downloader, urlDownloader } from '@/services/downloader'
import { OPERATOR } from '@/store/grid'
import { ORDER_STATUSES, PRINT_LABEL_TYPE } from '@/enums'
import { ORDER_IMPORT_STATUSES } from '@/enums'
import { SESSION_STORAGE } from '@/enums/sessionStorage'

const labelDownloader = (dataRaw, labelsType, dataType) => {
  const fileName = `orders-labels-${new Date().valueOf()}-${labelsType}`
  const type = dataType === PRINT_LABEL_TYPE.PDF ? 'pdf;' : 'octet-stream;'
  if (dataRaw) {
    downloader(base64ToBlob(dataRaw), `${fileName}.${dataType}`, `application/${type}; charset=utf-8;`)
  }
}
export default {
  [ORDERS.ACTIONS.GET_ORDER_REQUEST]: async ({ dispatch, commit }, payload) => {
    commit(ORDERS.MUTATIONS.GET_ORDER_RESPONSE, null)

    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.orders.getOrder },
      params: {
        orderId: payload.orderId,
      },
    })
    commit(ORDERS.MUTATIONS.GET_ORDER_RESPONSE, data)
  },
  [ORDERS.ACTIONS.UPDATE_ORDER_REQUEST]: async ({ dispatch }, payload) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.orders.updateOrder },
        params: {
          orderId: payload.orderId,
          data: payload.data,
        },
        throwError: true,
      })
      addSuccessMessage(dispatch, API.orders.updateOrder, 'flashMessages.orders.update')

      return true
    } catch {
      return false
    }
  },
  [ORDERS.ACTIONS.UPDATE_ORDER_BRANCH_REQUEST]: async ({ dispatch }, payload) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.orders.updateOrderBranch },
        params: {
          orderId: payload.orderId,
          secondaryBranchId: payload.secondaryBranchId,
        },
        throwError: true,
      })

      dispatch(ORDERS.ACTIONS.GET_ORDER_REQUEST, {
        orderId: payload.orderId,
      })
      addSuccessMessage(dispatch, API.orders.updateOrderBranch, 'flashMessages.orders.updateBranch')

      return true
    } catch {
      return false
    }
  },
  [ORDERS.ACTIONS.CREATE_TEST_ORDERS_REQUEST]: async ({ dispatch }, payload) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.orders.createTestingOrders },
        params: {
          data: payload,
        },
        throwError: true,
      })
      addSuccessMessage(dispatch, API.orders.createTestingOrders, 'flashMessages.orders.createTestOrders')

      return true
    } catch {
      return false
    }
  },
  [ORDERS.ACTIONS.IMPORT_STATUSES_REQUEST]: async ({ dispatch }, payload) => {
    try {
      const data = await callPortalGraphQL(dispatch, {
        requestData: { ...API.orders.importStatuses },
        params: {
          type: payload.type,
          data: payload.data,
        },
        throwError: true,
      })
      switch (data.status) {
        case ORDER_IMPORT_STATUSES.OK:
          addSuccessMessage(dispatch, API.orders.importStatuses.id, 'flashMessages.orders.statusesUpdate')
          return true
        case ORDER_IMPORT_STATUSES.NOK:
          addErrorMessage(dispatch, API.orders.importStatuses.id, 'flashMessages.orders.statusesUpdateWithErr')
          return data.errors
        case ORDER_IMPORT_STATUSES.FAIL:
          addErrorMessage(dispatch, API.orders.importStatuses.id, 'flashMessages.orders.statusesUpdateErr')
          return data.errors
        default:
          return false
      }
    } catch {
      addErrorMessage(dispatch, API.orders.importStatuses.id, 'flashMessages.orders.statusesUpdateErr')
      return false
    }
  },
  [ORDERS.ACTIONS.BILLING_EXPORT]: async ({ dispatch }, payload) => {
    const data = await callApi(dispatch, {
      requestData: { ...API.orders.exportBillingToFile },
      params: {
        filter: payload,
      },
    })

    downloader(data, `billing-orders-${new Date().valueOf()}.xls`, 'application/vnd.ms-excel')
  },
  [ORDERS.ACTIONS.ORDERS_EXPORT_LABELS_TO_FILE_REQUEST]: async ({ dispatch, rootGetters }, payload) => {
    try {
      const data = await callPortalGraphQL(dispatch, {
        requestData: { ...API.mdpOrders.getMultiLabel },
        params: {
          partnerId: getSelectedPartnerId(rootGetters),
          first_position: parseInt(payload.data.first_position),
          labels_per_page: parseInt(payload.data.labels_per_page),
          labels: payload.labels,
          labels_type: payload.data.type,
        },
      })

      const mdpRaw = data?.mdp?.labels_raw || null
      const dipRaw = data?.dip?.labels_raw || null

      if (mdpRaw) {
        labelDownloader(mdpRaw, 'classic', payload.data.type)
      }
      if (dipRaw) {
        labelDownloader(dipRaw, 'spectrum', payload.data.type)
      }
    } catch {
      addErrorMessage(dispatch, API.mdpOrders.getMultiLabel.id, 'flashMessages.orders.labelsError')
    }
  },
  [ORDERS.ACTIONS.ORDERS_EXPORT_ULOZENKA_TO_FILE_REQUEST]: async ({ dispatch, rootGetters }, payload) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.orders.getUlozenkaExport },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
        exportItems: payload.exportItems,
      },
    })

    let fileName = `orders-ulozenka-export-${new Date().valueOf()}`
    downloader(blobToUtf8(base64ToBlob(data.data_raw)), `${fileName}.csv`, 'text/csv; charset=utf-8;')
  },
  [ORDERS.ACTIONS.ORDERS_STATUS_COUNT_REQUEST]: async ({ dispatch, commit }, payload) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.orders.ordersStatusCount },
      params: {
        filter: [
          [
            {
              column: 'status',
              operator: OPERATOR.EQUAL,
              value: [payload],
            },
          ],
        ],
        sorter: null,
        paging: {
          page: 1,
          itemsPerPage: 1,
        },
      },
      throwError: true,
    })

    commit(ORDERS.MUTATIONS.ORDERS_STATUS_COUNT_RESPONSE, { status: payload, value: !!data.items.length })
  },
  [ORDERS.ACTIONS.ORDERS_MISSING_STATUS_COUNT_REQUEST]: async ({ dispatch, commit }) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.orders.missingStatusCount },
      params: {
        filter: [],
        sorter: null,
        paging: {
          page: 1,
          itemsPerPage: 1,
        },
      },
      throwError: true,
    })

    commit(ORDERS.MUTATIONS.ORDERS_MISSING_STATUS_COUNT_RESPONSE, { value: !!data.items.length })
  },
  [ORDERS.ACTIONS.ORDERS_STATUS_ALL_COUNT_REQUEST]: async ({ dispatch }) => {
    dispatch(ORDERS.ACTIONS.ORDERS_STATUS_COUNT_REQUEST, ORDER_STATUSES.OPEN)
    dispatch(ORDERS.ACTIONS.ORDERS_MISSING_STATUS_COUNT_REQUEST)
  },
  [ORDERS.ACTIONS.ORDERS_EXPORT_TO_FILE_REQUEST]: async ({ dispatch }, payload) => {
    const data = await callApi(dispatch, {
      requestData: { ...API.orders.exportToFile },
      params: {
        filter: payload.filter,
      },
    })

    downloader(data, `orders-${new Date().valueOf()}.csv`, 'text/csv; charset=utf-8;')
  },
  [ORDERS.ACTIONS.ORDERS_EXPORT_ZASILKOVNA_TO_FILE_REQUEST]: async ({ dispatch }, payload) => {
    const data = await callCpapiV2Api(dispatch, {
      requestData: { ...API.orders.exportZasilkovnaToFile },
      params: {
        filter: payload.filter,
      },
    })

    downloader(data, `orders-zasilkovna-${new Date().valueOf()}.csv`, 'text/csv; charset=utf-8;')
  },
  [ORDERS.ACTIONS.ORDERS_EXPORT_MISSING_TO_FILE_REQUEST]: async ({ dispatch }, payload) => {
    const data = await callApi(dispatch, {
      requestData: { ...API.orders.exportMissingToFile },
      params: {
        filter: payload.filter,
      },
    })

    downloader(data, `orders-missing-${new Date().valueOf()}.csv`, 'text/csv; charset=utf-8;')
  },
  [ORDERS.ACTIONS.ORDERS_EXPORT_ZASILKOVNA_MISSING_TO_FILE_REQUEST]: async ({ dispatch }, payload) => {
    const data = await callCpapiV2Api(dispatch, {
      requestData: { ...API.orders.exportMissingZasilkovnaToFile },
      params: {
        filter: payload.filter,
      },
    })

    downloader(data, `orders-missing-zasilkovna-${new Date().valueOf()}.csv`, 'text/csv; charset=utf-8;')
  },
  [ORDERS.ACTIONS.INVOICES_REQUEST]: async ({ dispatch, commit }, payload) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.billings.invoices },
      params: {
        filter: [
          [
            {
              column: 'orderId',
              operator: OPERATOR.EQUAL,
              value: [payload.orderId],
            },
          ],
        ],
      },
    })

    commit(ORDERS.MUTATIONS.INVOICES_RESPONSE, data.items)
  },
  [ORDERS.ACTIONS.DELIVERY_NOTE_REQUEST]: async ({ commit, dispatch }, payload) => {
    commit(ORDERS.MUTATIONS.DOWNLOADING_DELIVERY_NOTE, { id: payload.orderId, status: true })
    sessionStorage.setItem(`${SESSION_STORAGE.PDF_EXPORT_ORDER_ID}:${payload.orderId}`, 'true')
    try {
      await callCpapiV2GraphQL(dispatch, {
        requestData: { ...API.orders.deliveryNote, id: `${API.orders.deliveryNote.id}-${payload.orderId}` },
        params: {
          orderId: payload.orderId,
        },
        throwError: true,
      })
    } catch (e) {
      commit(ORDERS.MUTATIONS.DOWNLOADING_DELIVERY_NOTE, { id: payload.orderId, status: false })
    }
  },
  [ORDERS.ACTIONS.DOWNLOAD_DELIVERY_NOTE]: async ({ commit, dispatch }, payload) => {
    if (sessionStorage.getItem(`${SESSION_STORAGE.PDF_EXPORT_ORDER_ID}:${payload.orderId}`) != 'true') {
      return
    }
    if (payload.type === 'END') {
      sessionStorage.removeItem(`${SESSION_STORAGE.PDF_EXPORT_ORDER_ID}:${payload.orderId}`)
      if (payload.url) {
        urlDownloader(payload.url)
      } else {
        addErrorMessage(dispatch, API.orders.deliveryNote.id, 'flashMessages.deliveryNote')
      }
      commit(ORDERS.MUTATIONS.DOWNLOADING_DELIVERY_NOTE, { id: payload.orderId, status: false })
    }
  },

  [ORDERS.ACTIONS.ORDERS_DASHBOARD_REQUEST]: async ({ commit, dispatch }, payload) => {
    const data = await callCpapiV2GraphQL(dispatch, {
      requestData: { ...API.orders.getDashboardOrders },
      params: {
        daysToDeliveryDate: payload.daysToDeliveryDate,
      },
    })
    commit(ORDERS.MUTATIONS.ORDERS_DASHBOARD_RESPONSE, data)
  },
}
