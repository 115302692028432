export const PRODUCT_STATUSES = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  NOT_FOR_RESALE: 'NOT_FOR_RESALE',
}

export const TEMP_PRODUCT_STATUSES = {
  [PRODUCT_STATUSES.ACTIVE]: PRODUCT_STATUSES.ACTIVE,
  [PRODUCT_STATUSES.INACTIVE]: PRODUCT_STATUSES.INACTIVE,
}
