import { ACL } from '@/enums/acl'

export const DELIVERIES_ROUTES = {
  DELIVERIES_OVERVIEW: 'deliveries-overview-page',
  DELIVERIES_WAREHOUSE: 'deliveries-warehouse-page',
  DELIVERY_DETAIL: 'delivery-detail',
  MALL: 'mall-delivery',
  MALL_CLASSIC: 'mall-classic',
  MALL_SPECTRUM: 'mall-spectrum',
}

export default [
  {
    path: '',
    name: DELIVERIES_ROUTES.DELIVERIES_OVERVIEW,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "deliveries" */ '../../../views/app/deliveries/DeliveriesOverviewPage'
      ),
    meta: {
      resource: ACL.DELIVERIES,
    },
  },
  {
    path: 'warehouse',
    name: DELIVERIES_ROUTES.DELIVERIES_WAREHOUSE,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "deliveries" */ '../../../views/app/deliveries/DeliveriesWarehousePage'
      ),
    meta: {
      resource: ACL.DELIVERIES,
    },
  },
  {
    path: 'detail/:id?',
    name: DELIVERIES_ROUTES.DELIVERY_DETAIL,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "deliveries" */ '../../../views/app/deliveries/DeliveriesDetailPage'
      ),
    meta: {
      resource: ACL.DELIVERIES,
    },
  },
  {
    path: 'mall',
    name: DELIVERIES_ROUTES.MALL,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "deliveries" */ '../../../views/app/deliveries/MallDeliveryPage'
      ),
    meta: {
      resource: ACL.DELIVERIES,
    },
  },
  {
    path: 'mall/classic',
    name: DELIVERIES_ROUTES.MALL_CLASSIC,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "deliveries" */ '../../../views/app/deliveries/MallDeliveryClassicPage'
      ),
    meta: {
      resource: ACL.DELIVERIES,
    },
  },
  {
    path: 'mall/spectrum',
    name: DELIVERIES_ROUTES.MALL_SPECTRUM,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "deliveries" */ '../../../views/app/deliveries/MallDeliverySpectrumPage'
      ),
    meta: {
      resource: ACL.DELIVERIES,
    },
  },
]
