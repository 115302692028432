export const DAYS = {
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
  SATURDAY: 'saturday',
  SUNDAY: 'sunday',
}

export const CALENDAR_TYPES = {
  MP_WORKING_DAYS: 'MP_WORKING_DAYS',
}
