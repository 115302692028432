export const PRINT_LABEL_TYPE = {
  PDF: 'pdf',
  ZPL: 'zpl',
}

export const PRINT_LABEL_FIRST_POSITION = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
}

export const PRINT_LABEL_LABELS_PER_PAGE = {
  1: '1',
  4: '4',
}
