import { SERVICE_QUALITY_TYPES } from '@/enums'

export const SERVICE_QUALITY_ROUTES = {
  CANCEL_RATE_TAB: 'service-quality-cancel-rate-tab',
  TRACKING_RATE_TAB: 'service-quality-tracking-rate-tab',
  STATUS_ERROR_TAB: 'service-quality-status-error-tab',
  DELIVERY_TAB: 'service-quality-delivery-tab',
}

export default [
  {
    path: '',
    redirect: 'cancel-rate',
  },
  {
    path: 'cancel-rate',
    name: SERVICE_QUALITY_ROUTES.CANCEL_RATE_TAB,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "sla" */ '../../../views/app/service-quality/ServiceQualityUniversalPage'
      ),
    meta: {
      serviceQualityType: SERVICE_QUALITY_TYPES.CANCEL_RATE,
    },
  },
  {
    path: 'tracking-rate',
    name: SERVICE_QUALITY_ROUTES.TRACKING_RATE_TAB,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "sla" */ '../../../views/app/service-quality/ServiceQualityUniversalPage'
      ),
    meta: {
      serviceQualityType: SERVICE_QUALITY_TYPES.TRACKING_RATE,
    },
  },
  {
    path: 'status-error',
    name: SERVICE_QUALITY_ROUTES.STATUS_ERROR_TAB,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "sla" */ '../../../views/app/service-quality/ServiceQualityUniversalPage'
      ),
    meta: {
      serviceQualityType: SERVICE_QUALITY_TYPES.STATUS_ERROR,
    },
  },
  {
    path: 'delivery',
    name: SERVICE_QUALITY_ROUTES.DELIVERY_TAB,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "sla" */ '../../../views/app/service-quality/ServiceQualityUniversalPage'
      ),
  },
]
