import { CONTRACT } from '@/store/modules/contract/types'
import { API } from '@/api'
import { callPortalGraphQL, getSelectedPartnerId } from '@/store/utils'

export default {
  [CONTRACT.ACTIONS.CONTRACT_REQUEST]: async ({ dispatch, commit, rootGetters }) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.contract.contract },
      params: {
        partnerId: getSelectedPartnerId(rootGetters),
      },
    })
    commit(CONTRACT.MUTATIONS.CONTRACT_RESPONSE, data)

    return data
  },
  [CONTRACT.ACTIONS.CONTRACT_UPDATE_REQUEST]: async ({ dispatch, rootGetters, commit }, payload) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.contract.updateContract },
      params: {
        data: { ...payload },
        partnerId: getSelectedPartnerId(rootGetters),
      },
    })
    commit(CONTRACT.MUTATIONS.CONTRACT_UPDATE_RESPONSE, data)
  },
  [CONTRACT.ACTIONS.ARES_REQUEST]: async ({ dispatch, commit }, payload) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.ares.company },
      params: {
        ico: payload,
      },
    })
    commit(CONTRACT.MUTATIONS.ARES_RESPONSE, data)
  },
}
