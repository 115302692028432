export const SERVICE_QUALITY_TYPES = {
  CANCEL_RATE: 'cancel-rate',
  TRACKING_RATE: 'tracking',
  STATUS_ERROR: 'status-error',
}

export const SERVICE_QUALITY_STATUSES = {
  OK: 'ok',
  WARNING: 'warning',
  CRITICAL: 'critical',
}

export const SERVICE_QUALITY_EXPORT_MONTHS_RANGES = {
  [SERVICE_QUALITY_TYPES.CANCEL_RATE]: 12,
  [SERVICE_QUALITY_TYPES.TRACKING_RATE]: 3,
  [SERVICE_QUALITY_TYPES.STATUS_ERROR]: 3,
}

export const SERVICE_QUALITY_PREVIOUS_MONTHS_COUNT = 12
