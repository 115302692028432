<template>
  <v-btn class="wdio-btn" v-bind="{ ...$props, ...$attrs }" v-on="$listeners" @click.native="trackEvent">
    <slot />
  </v-btn>
</template>

<script>
import TrackMixin from '@/components/commons/anchor/TrackMixin'

export default {
  name: 'Btn',
  mixins: [TrackMixin],
}
</script>
