import '@/assets/styles/main.sass'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import App from '@/App.vue'
import { router, beforeEach, afterEach, loginAsAdminRoute } from '@/router'
import { createStore } from '@/store'
import { i18n } from '@/localization'
import { vuetifyOptions, ability, config } from '@/config'
import VueGtm from 'vue-gtm'
import { abilitiesPlugin } from '@casl/vue'
import DatetimePicker from 'vuetify-datetime-picker'
import { ROUTES } from '@/router/routes'
import Btn from '@/components/commons/button/Btn'
import Anchor from '@/components/commons/anchor/Anchor'
import Alert from '@/components/commons/alert/Alert'
import '@openfonts/roboto_latin-ext'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.config.productionTip = false

Vue.use(Vuetify, vuetifyOptions)
Vue.use(VueGtm, {
  id: 'GTM-TG2QMZ8',
  enabled: true,
  debug: false,
  vueRouter: router,
  ignoredViews: [ROUTES.REGISTRATION, ROUTES.REGISTRATION_SUBMIT],
})
Vue.use(abilitiesPlugin, ability)
Vue.use(DatetimePicker)

Vue.component('Btn', Btn)
Vue.component('Anchor', Anchor)
Vue.component('Alert', Alert)

if (config.sentry.endpoint !== '') {
  const Raven = require('raven-js')
  const RavenVue = require('raven-js/plugins/vue')
  Raven.config(config.sentry.endpoint, {
    environment: process.env.NODE_ENV,
    release: process.env.VUE_APP_VERSION,
  })
    .addPlugin(RavenVue, Vue)
    .install()
}

const store = createStore(router)

router.beforeEach(beforeEach(store))
router.afterEach(afterEach(store))

loginAsAdminRoute(store).forEach(route => {
  router.addRoute(route)
})

new Vue({
  name: 'MainJs',
  router,
  store,
  i18n,
  vuetify: new Vuetify(vuetifyOptions),
  render: h => h(App),
}).$mount('#app')
