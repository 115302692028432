<template>
  <v-alert :icon="icon" elevation="1" class="ui-alert" v-bind="{ ...$attrs, ...$props }">
    <slot />
  </v-alert>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    type: {
      type: String,
      default: 'info',
      validator: value => ['success', 'info', 'warning', 'error'].includes(value),
    },
    icon: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="sass" scoped>
@import "src/assets/styles/colors"
@import "~vuetify/src/styles/settings/variables"

.v-alert.ui-alert
  &.error
    background-color: $light-error-color !important
    color: $error-color
    border-radius: 6px
    position: relative
    overflow: hidden
    padding-left: $spacer * 5

    &::v-deep::before
      background-color: $error-color
      content: ''
      height: 100%
      width: 8px
      left: 0
      top: 0
      position: absolute
      display: block

    &::v-deep .v-alert__icon.v-icon
      color: $error-color !important

  &.info
    background-color: $primary-light !important
    color: $primary
    border-radius: 6px
    position: relative
    overflow: hidden
    padding-left: $spacer * 5

    &::v-deep::before
      background-color: $primary
      content: ''
      height: 100%
      width: 8px
      left: 0
      top: 0
      position: absolute
      display: block

    &::v-deep .v-alert__icon.v-icon
      color: $primary !important
</style>
