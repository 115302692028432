export const SENTRY = {
  LOGGING_INTERVAL: 60000,
  MS_ERROR_THRESHOLD: 15000,
}

export const ENDPOINTS = {
  COCKPIT: 'cockpit',
  AUTH: 'auth',
  PUBLIC: 'public',
  PORTAL: 'portal',
}
