export const BILLINGS_ROUTES = {
  BILLINGS_INVOICES: 'billings-invoices',
  BILLINGS_OFFSETS: 'billings-offsets',
  BILLINGS_SELF: 'billings-self',
}

export default [
  {
    path: '',
    name: BILLINGS_ROUTES.BILLINGS_INVOICES,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "billings" */ '../../../views/app/billings/BillingsInvoicesPage'
      ),
  },
  {
    path: 'offsets',
    name: BILLINGS_ROUTES.BILLINGS_OFFSETS,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "billings" */ '../../../views/app/billings/BillingsOffsetsPage'
      ),
  },
  {
    path: 'self-billing',
    name: BILLINGS_ROUTES.BILLINGS_SELF,
    component: () =>
      import(/* webpackPrefetch: true */ /* webpackChunkName: "billings" */ '../../../views/app/SelfBillingPage'),
  },
]
