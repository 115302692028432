export default {
  partnerDetailApiLimit: {
    id: 'PARTNER_DETAIL_API_LIMIT',
    request: () => ({
      query: `
        query {
          rateLimit {
            partner {
              usage {
                lastUpdatedDate
                date
                domainUsage {
                  domainName
                  usedPercentage
                  usedRequests
                  maxRequests
                }
              }
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data.rateLimit.partner.usage
    },
  },
  partnerDetailApiLimitHistory: {
    id: 'PARTNER_DETAIL_API_LIMIT_HISTORY',
    request: () => ({
      query: `
        query {
          rateLimit {
            partner {
              history {
                tierName
                tierId
                date
              }
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data.rateLimit.partner.history
    },
  },
  partnerDetailApiLimitCurrent: {
    id: 'PARTNER_DETAIL_API_LIMIT_CURRENT',
    request: () => ({
      query: `
        query {
          rateLimit {
            partner {
              partnersTier {
                tierName
                tierId
              }
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data.rateLimit.partner.partnersTier
    },
  },
}
