export const PRODUCTS = {
  NAMESPACE: 'products',
  ACTIONS: {
    GET_CATEGORY_LIST_REQUEST: 'GET_CATEGORY_LIST_REQUEST',
    GET_BRAND_LIST_REQUEST: 'GET_BRAND_LIST_REQUEST',
    DELETE_PRODUCTS_REQUEST: 'DELETE_PRODUCTS_REQUEST',
    ACTIVATE_PRODUCTS_REQUEST: 'ACTIVATE_PRODUCTS_REQUEST',
    ACTIVATE_ALL_PRODUCTS_REQUEST: 'ACTIVATE_ALL_PRODUCTS_REQUEST',
    SET_STATUS_REQUEST: 'SET_STATUS_REQUEST',
    GET_PRODUCT_REQUEST: 'GET_PRODUCT_REQUEST',
    GET_RECOMMENDED_REQUEST: 'GET_RECOMMENDED_REQUEST',
    GET_RECOMMENDED_OPTIONS_REQUEST: 'GET_RECOMMENDED_OPTIONS_REQUEST',
    UPDATE_PRODUCT_REQUEST: 'UPDATE_PRODUCT_REQUEST',
    UPLOAD_PRODUCT_MEDIA_REQUEST: 'UPLOAD_PRODUCT_MEDIA_REQUEST',
    GET_LABELS_LIST_REQUEST: 'GET_LABELS_LIST_REQUEST',
    PRODUCTS_EXPORT: 'PRODUCTS_EXPORT',
    PRODUCTS_IMPORT: 'PRODUCTS_IMPORT',
    UPLOAD_PRODUCT_BOX_CSV_REQUEST: 'UPLOAD_PRODUCT_BOX_CSV_REQUEST',
    UPLOAD_PRODUCT_MEDIA_CSV_REQUEST: 'UPLOAD_PRODUCT_MEDIA_CSV_REQUEST',
    DELETE_OVERRIDE_BOX_REQUEST: 'DELETE_OVERRIDE_BOX_REQUEST',
    DELETE_OVERRIDE_MEDIA_REQUEST: 'DELETE_OVERRIDE_MEDIA_REQUEST',
    GET_CATEGORY_PARAMS_REQUEST: 'GET_CATEGORY_PARAMS_REQUEST',
    CREATE_PRODUCT_REQUEST: 'CREATE_PRODUCT_REQUEST',
    GET_CMT_CATEGORIES_REQUEST: 'GET_CMT_CATEGORIES_REQUEST',
    SET_NEW_PRODUCT: 'SET_NEW_PRODUCT',
    GET_VARIANTS_REQUEST: 'GET_VARIANTS_REQUEST',
    GET_CATEGORY_QUERY_RULES_REQUEST: 'GET_CATEGORY_QUERY_RULES_REQUEST',
    RESET_VARIANTS: 'RESET_VARIANTS',
  },
  MUTATIONS: {
    GET_CATEGORY_LIST_RESPONSE: 'GET_CATEGORY_LIST_RESPONSE',
    GET_BRAND_LIST_RESPONSE: 'GET_BRAND_LIST_RESPONSE',
    GET_PRODUCT_RESPONSE: 'GET_PRODUCT_RESPONSE',
    GET_RECOMMENDED_RESPONSE: 'GET_RECOMMENDED_RESPONSE',
    GET_RECOMMENDED_OPTIONS_RESPONSE: 'GET_RECOMMENDED_OPTIONS_RESPONSE',
    GET_LABELS_LIST_RESPONSE: 'GET_LABELS_LIST_RESPONSE',
    GET_CMT_CATEGORIES_RESPONSE: 'GET_CMT_CATEGORIES_RESPONSE',
    PRODUCT_ACTIVATION_PROGRESS: 'PRODUCT_ACTIVATION_PROGRESS',
    GET_VARIANTS_RESPONSE: 'GET_VARIANTS_RESPONSE',
    GET_CATEGORY_QUERY_RULES_RESPONSE: 'GET_CATEGORY_QUERY_RULES_RESPONSE',
    RESET_VARIANTS: 'RESET_VARIANTS',
    RESET: 'RESET',
  },
}
