export const CASH_ON_DELIVERY = {
  A: 'A',
}

export const BANK_TRANSFER = {
  B: 'B',
}

export const INSTALLMENT_PAYMENT = {
  D: 'D',
  N: 'N',
  6: '6',
}

export const ONLINE_PAYMENT = {
  1: '1',
  C: 'C',
  E: 'E',
  F: 'F',
  H: 'H',
  J: 'J',
  L: 'L',
  U: 'U',
  W: 'W',
}

export const INACTIVE_PAYMENT = {
  G: 'G',
  V: 'V',
}

export const PAYMENT_TYPES = {
  ...CASH_ON_DELIVERY,
  ...BANK_TRANSFER,
  ...INSTALLMENT_PAYMENT,
  ...ONLINE_PAYMENT,
}

export const PAYMENT_GROUPS = {
  CASH_ON_DELIVERY: 'CASH_ON_DELIVERY',
  BANK_TRANSFER: 'BANK_TRANSFER',
  INSTALLMENT_PAYMENT: 'INSTALLMENT_PAYMENT',
  ONLINE_PAYMENT: 'ONLINE_PAYMENT',
}

export const DELIVERY_IDS = {}
