import { dispatchRoot, withNamespace } from '@/store/utils'
// import { MESSAGES } from '@/store/modules/messages/types'
import { config } from '@/config'
import { NOTIFICATIONS } from '@/store/modules/notifications/types'
import { HEUREKA } from '@/store/modules/heureka/types'
import { BILLINGS } from '@/store/modules/billings/types'
import { ORDERS } from '@/store/modules/orders/types'
import { DATA_GRIDS } from '@/store/grid/grids'
import { GRID } from '@/store/grid/store/types'

export const createWebSocketPlugin = streamNC => {
  return store => {
    streamNC.addCallback('broadcast', msg => {
      store.commit(withNamespace(NOTIFICATIONS.NAMESPACE, NOTIFICATIONS.MUTATIONS.ADD_FROM_STREAM), msg)
      // @todo try optimistic update
      store.dispatch(withNamespace(NOTIFICATIONS.NAMESPACE, NOTIFICATIONS.ACTIONS.NOTIFICATION_NEWEST_REQUEST))
    })

    // For future reference if messages are still used. Otherwise, cleanup.
    /*stream.addCallback('NEW_MESSAGE_FROM_COCKPIT', msg => {
      store.commit(withNamespace(MESSAGES.NAMESPACE, MESSAGES.MUTATIONS.ADD_FROM_STREAM), msg.content)
      // @todo try optimistic update
      store.dispatch(withNamespace(MESSAGES.NAMESPACE, MESSAGES.ACTIONS.MESSAGES_NEWEST_REQUEST))
    })

    stream.addCallback('THREAD_MESSAGE_FROM_COCKPIT', msg => {
      store.commit(withNamespace(MESSAGES.NAMESPACE, MESSAGES.MUTATIONS.ADD_FROM_STREAM), msg.content)
      // @todo try optimistic update
      store.dispatch(withNamespace(MESSAGES.NAMESPACE, MESSAGES.ACTIONS.CONVERSATION_REQUEST), msg.content.parentId)
      store.dispatch(withNamespace(MESSAGES.NAMESPACE, MESSAGES.ACTIONS.MESSAGES_NEWEST_REQUEST))
    })

    stream.addCallback('THREAD_MESSAGE_FROM_PORTAL', msg => {
      // @todo try optimistic update
      store.dispatch(withNamespace(MESSAGES.NAMESPACE, MESSAGES.ACTIONS.CONVERSATION_REQUEST), msg.content.parentId)
      store.dispatch(withNamespace(MESSAGES.NAMESPACE, MESSAGES.ACTIONS.MESSAGES_NEWEST_REQUEST))
    })
    */

    streamNC.addCallback('portal_new_invoice_export', msg => {
      store.dispatch(withNamespace(BILLINGS.NAMESPACE, BILLINGS.ACTIONS.DOWNLOAD_EXPORT_REQUEST), msg)
    })

    streamNC.addCallback('hci_product_import', msg => {
      store.commit(withNamespace(HEUREKA.NAMESPACE, HEUREKA.MUTATIONS.IMPORT_PRODUCT_FROM_STREAM), msg)
    })

    streamNC.addCallback('hci_availability_import', msg => {
      store.commit(withNamespace(HEUREKA.NAMESPACE, HEUREKA.MUTATIONS.IMPORT_AVAILABILITY_FROM_STREAM), msg)
    })

    streamNC.addCallback('hci_product_import_validate', msg => {
      store.dispatch(withNamespace(HEUREKA.NAMESPACE, HEUREKA.ACTIONS.PRODUCT_VALIDATE_STATUS_REQUEST), msg)
    })

    streamNC.addCallback('hci_availability_import_validate', msg => {
      store.dispatch(withNamespace(HEUREKA.NAMESPACE, HEUREKA.ACTIONS.AVAILABILITY_VALIDATE_STATUS_REQUEST), msg)
    })

    streamNC.addCallback('portal_delivery_note_pdf', msg => {
      store.dispatch(withNamespace(ORDERS.NAMESPACE, ORDERS.ACTIONS.DOWNLOAD_DELIVERY_NOTE), msg)
    })

    streamNC.addCallback('update_campaign', msg => {
      dispatchRoot(store.dispatch, withNamespace(DATA_GRIDS.CAMPAIGN_IMPORTS, GRID.ACTIONS.GRID_FETCH), {
        namespace: DATA_GRIDS.CAMPAIGN_IMPORTS,
        params: {
          id: msg.campaignId,
        },
      })
    })

    streamNC.addCallback('app_new_version', msg => {
      const version = msg.version
      if (config.version !== 'dev' && version !== config.version) {
        store.commit(withNamespace(NOTIFICATIONS.NAMESPACE, NOTIFICATIONS.MUTATIONS.NEW_APP_VERSION), true)
      }
    })
    streamNC.addCallback('app_version', msg => {
      if (config.version !== 'dev' && msg !== config.version) {
        store.commit(withNamespace(NOTIFICATIONS.NAMESPACE, NOTIFICATIONS.MUTATIONS.NEW_APP_VERSION), true)
      }
    })
  }
}
