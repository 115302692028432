export const FLASH_MESSAGES = {
  NAMESPACE: 'flashMessages',
  ACTIONS: {
    ADD: 'ADD',
    REMOVE: 'REMOVE',
  },
  MUTATIONS: {
    ADD: 'ADD',
    REMOVE: 'REMOVE',
    RESET: 'RESET',
  },
}

export const FLASH_MESSAGES_TYPES = {
  INFO: 'INFO',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
}
