<template>
  <v-menu
    value
    offset-x
    open-on-hover
    close-on-click
    close-on-content-click
    transition="scale-transition"
    content-class="elevation-1"
    color="white"
    min-width="200"
  >
    <template #activator="{ on }">
      <anchor
        v-if="item.visible === undefined || item.visible === true"
        list-item
        class="nav-list-item-dropdown__action"
        :href="href"
        :to="to"
        exact
        v-on="on"
      >
        <v-list-item-icon>
          <v-icon>
            {{ item.icon }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          {{ $t(item.title) }}
        </v-list-item-title>
      </anchor>
    </template>
    <v-list dense subheader class="py-1">
      <template v-if="item.children">
        <v-subheader class="ml-2">
          {{ $t(item.title) }}
        </v-subheader>
        <v-divider />
        <nav-item v-for="(children, key) in item.children" :key="key" :item="children" />
      </template>
      <nav-item v-else :item="item" no-icon />
    </v-list>
  </v-menu>
</template>

<script>
import NavItem from '@/components/layout/components/nav/NavItem'
import NavItemMixin from '@/components/layout/components/nav/NavItemMixin'

export default {
  name: 'NavItemMenu',
  components: { NavItem },
  mixins: [NavItemMixin],
}
</script>
