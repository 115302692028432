export default {
  getLocalization: {
    id: 'GET_LOCALIZATION',
    request: ({ projectId, lang }) => {
      return {
        method: 'GET',
        url: `/translations/localization/${projectId}/${lang}`,
        headers: {
          'Content-Type': 'application/json',
        },
      }
    },
    reduce: data => {
      return data
    },
  },
}
