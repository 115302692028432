<template>
  <btn v-if="isSending" color="primary" type="submit" :class="buttonClass" disabled :action="sendingTitle" loading>
    {{ sendingTitle }}
  </btn>
  <btn v-else-if="isDisabled" color="primary" type="submit" :class="buttonClass" disabled :action="buttonTitle">
    {{ buttonTitle }}
  </btn>
  <btn v-else color="primary" type="submit" :class="buttonClass" :action="buttonTitle">
    <v-icon v-if="iconName && iconPosition === 'left'" left>
      {{ iconName }}
    </v-icon>
    {{ buttonTitle }}
    <v-icon v-if="iconName && iconPosition === 'right'" right>
      {{ iconName }}
    </v-icon>
  </btn>
</template>

<script>
export default {
  name: 'SendingFormButton',
  props: {
    isSending: {
      type: Boolean,
      required: true,
    },
    sendingTitle: {
      type: String,
      required: true,
    },
    buttonTitle: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
    },
    buttonClass: {
      type: String,
      default: '',
    },
    iconName: {
      type: String,
      default: '',
    },
    iconPosition: {
      type: String,
      default: 'left',
    },
  },
}
</script>
