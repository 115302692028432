import { downloader } from '@/services/downloader'
import { SERVICE_QUALITY } from '@/store/modules/serviceQuality/types'
import { API } from '@/api'
import { callApi, callCpapiV2GraphQL } from '@/store/utils'

export default {
  [SERVICE_QUALITY.ACTIONS.GET_SERVICE_QUALITY_REQUEST]: async ({ dispatch, commit }, payload) => {
    const data = await callCpapiV2GraphQL(dispatch, {
      requestData: { ...API.serviceQuality.getServiceQuality },
      params: {
        numberOfMonths: payload.numberOfMonths,
        slaTypes: payload.slaTypes,
      },
      throwError: true,
    })
    commit(SERVICE_QUALITY.MUTATIONS.GET_SERVICE_QUALITY_RESPONSE, data)
  },
  [SERVICE_QUALITY.ACTIONS.GET_SERVICE_QUALITY_EXPORT_REQUEST]: async ({ dispatch }, payload) => {
    const file = await callApi(dispatch, {
      requestData: { ...API.serviceQuality.exportReportToFile },
      params: {
        filter: payload,
      },
      throwError: true,
    })

    downloader(
      file.data,
      `${file.name}.xlsx`,
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
    )
  },
}
