export const MDP_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  REQUESTED: 'requested',
}

export const MDP_REQUEST_TYPE = {
  DEPOSIT: 'deposit',
  PICKUP: 'pickup',
}

export const MALL_DELIVERY_TYPE = {
  MDP_SPECTRUM: 'MDP_SPECTRUM',
  MDP_CLASSIC: 'MDP_CLASSIC',
  NONE: 'NONE',
}
