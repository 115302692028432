import { ACL } from '@/enums/acl'

export const PARTNER = {
  PARTNER_INFO_TAB: 'partner-info-tab',
  PARTNER_CONTACTS_TAB: 'contacts-tab',
  PARTNER_ACCOUNTS_TAB: 'accounts-tab',
  PARTNER_NOTIFICATION_TAB: 'notifications-tab',
  PARTNER_WEBHOOK_TAB: 'webhook-tab',
  PARTNER_WEBHOOK_LOGS_TAB: 'webhook-logs-tab',
  PARTNER_SANDBOX_ACCOUNTS_TAB: 'sandbox-accounts-tab',
  PARTNER_PERMISSIONS_TAB: 'permissions-tab',
  PARTNER_API_LIMIT_TAB: 'api-limit-tab',
}

export default [
  {
    path: '',
    name: PARTNER.PARTNER_INFO_TAB,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "partner" */ '../../../views/app/partner/PartnerInfoPage'
      ),
    meta: {
      resource: ACL.PARTNER,
    },
  },
  {
    path: 'contacts',
    name: PARTNER.PARTNER_CONTACTS_TAB,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "partner" */ '../../../views/app/partner/PartnerContactsPage'
      ),
    meta: {
      resource: ACL.PARTNER_CONTACTS,
    },
  },
  {
    path: 'accounts',
    name: PARTNER.PARTNER_ACCOUNTS_TAB,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "partner" */ '../../../views/app/partner/PartnerAccountsPage'
      ),
    meta: {
      resource: ACL.PARTNER_ACCOUNTS,
    },
  },
  {
    path: 'notifications',
    name: PARTNER.PARTNER_NOTIFICATION_TAB,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "partner" */ '../../../views/app/partner/PartnerNotificationsPage'
      ),
    meta: {
      resource: ACL.PARTNER_NOTIFICATION,
    },
  },
  {
    path: 'webhook',
    name: PARTNER.PARTNER_WEBHOOK_TAB,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "partner" */ '../../../views/app/partner/PartnerWebhookPage'
      ),
    meta: {
      resource: ACL.PARTNER_WEBHOOK,
    },
  },
  {
    path: 'webhook/:webhookId/logs',
    name: PARTNER.PARTNER_WEBHOOK_LOGS_TAB,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "partner" */ '../../../views/app/partner/PartnerWebhookLogsPage'
      ),
    meta: {
      resource: ACL.PARTNER_WEBHOOK,
    },
  },
  {
    path: 'sandbox-accounts',
    name: PARTNER.PARTNER_SANDBOX_ACCOUNTS_TAB,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "partner" */ '../../../views/app/partner/PartnerSandboxAccountsPage'
      ),
    meta: {
      resource: ACL.PARTNER_SANDBOX_ACCOUNTS,
    },
  },
  {
    path: 'permissions',
    name: PARTNER.PARTNER_PERMISSIONS_TAB,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "partner" */ '../../../views/app/partner/PartnerPermissionsPage'
      ),
    meta: {
      resource: ACL.PARTNER_PERMISSIONS,
    },
  },
  {
    path: 'api-limit',
    name: PARTNER.PARTNER_API_LIMIT_TAB,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "partner" */ '../../../views/app/partner/PartnerApiLimitPage'
      ),
    meta: {
      resource: ACL.PARTNER,
    },
  },
]
