import { BUSINESS_TERMS } from '@/store/modules/businessTerms/types'
import { resetState } from '@/store/utils'
import createState from '@/store/modules/businessTerms/state'

export default {
  [BUSINESS_TERMS.MUTATIONS.GET_CURRENT_RESPONSE]: (state, data) => {
    state.currentBusinessTerms = data
    state.languages = data.contents.map(item => item.lang)
  },
  [BUSINESS_TERMS.MUTATIONS.GET_HISTORY_RESPONSE]: (state, data) => {
    state.historyBusinessTerms = data
  },
  [BUSINESS_TERMS.MUTATIONS.GET_CONTENT_RESPONSE]: (state, data) => {
    state.contentBusinessTerms = data
  },
  [BUSINESS_TERMS.MUTATIONS.RESET]: state => {
    resetState(state, createState())
  },
}
