import { PORTAL_DIALOG } from '@/store/modules/portalDialog/types'
import { callCpapiV2GraphQL } from '@/store/utils'
import { API } from '@/api'

export default {
  [PORTAL_DIALOG.ACTIONS.GET_DIALOG_REQUEST]: async ({ dispatch, commit }) => {
    try {
      const data = await callCpapiV2GraphQL(dispatch, {
        requestData: { ...API.portalDialog.portalDialog },
        throwError: false,
      })
      commit(PORTAL_DIALOG.MUTATIONS.GET_DIALOG_RESPONSE, data)
    } catch {
      commit(PORTAL_DIALOG.MUTATIONS.GET_DIALOG_RESPONSE, null)
    }
  },
  [PORTAL_DIALOG.ACTIONS.RESET]: ({ commit }) => {
    commit(PORTAL_DIALOG.MUTATIONS.RESET)
  },
}
