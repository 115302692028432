import { COUNTRIES } from '@/enums'
import isLength from 'validator/lib/isLength'

export const isVatId = (value, country) => {
  switch (country) {
    case COUNTRIES.CZ:
      return isLength(value, { min: 8, max: 12 })
    case COUNTRIES.SK:
      return isLength(value, { min: 10, max: 10 })
    case COUNTRIES.SI:
      return isLength(value, { min: 0, max: 8 })
    case COUNTRIES.PL:
    case COUNTRIES.HU:
    case COUNTRIES.HR:
      return true
    default:
      return true
  }
}
