import title from '@/localization/default/title'
import navigation from '@/localization/default/navigation'
import apiErrors from '@/localization/default/apiErrors'
import apiLimit from '@/localization/default/apiLimit'
import topBar from '@/localization/default/topBar'
import login from '@/localization/default/login'
import workflow from '@/localization/default/workflow'
import button from '@/localization/default/button'
import enums from '@/localization/default/enums'
import notFound from '@/localization/default/notFound'
import profile from '@/localization/default/profile'
import registrationConfirm from '@/localization/default/registrationConfirm'
import registration from '@/localization/default/registration'
import forgotPassword from '@/localization/default/forgotPassword'
import serviceQuality from '@/localization/default/serviceQuality'
import notifications from '@/localization/default/notifications'
import messages from '@/localization/default/messages'
import flashMessages from '@/localization/default/flashMessages'
import vuetify from '@/localization/default/vuetify'
import heureka from '@/localization/default/heureka'
import knowledgeBase from '@/localization/default/knowledgeBase'
import campaigns from '@/localization/default/campaigns'
import partnerPage from '@/localization/default/partnerPage'
import deliveries from '@/localization/default/deliveries'
import faq from '@/localization/default/faq'
import status from '@/localization/default/status'
import upload from '@/localization/default/upload'
import dataGrid from '@/localization/default/dataGrid'
import partner from '@/localization/default/partner'
import partners from '@/localization/default/partners'
import acl from '@/localization/default/acl'
import businessTerms from '@/localization/default/businessTerms'
import fee from '@/localization/default/fee'
import orders from '@/localization/default/orders'
import products from '@/localization/default/products'
import billings from '@/localization/default/billings'
import categories from '@/localization/default/categories'
import brands from '@/localization/default/brands'
import services from '@/localization/default/services'
import expansionCard from '@/localization/default/expansionCard'
import mallPay from '@/localization/default/mallPay'
import mallFeed from '@/localization/default/mallFeed'
import validations from '@/localization/default/validations'
import dip from '@/localization/default/dip'
import portalDialog from '@/localization/default/portalDialog'
import dashboard from '@/localization/default/dashboard'
// eslint-disable-next-line vue-webpack-src-import/only-src-relative-imports
import defaultValidations from 'vee-validate/dist/locale/cs'

export default Object.assign(
  title,
  categories,
  navigation,
  apiErrors,
  apiLimit,
  { $vuetify: vuetify.vuetify },
  topBar,
  login,
  workflow,
  button,
  enums,
  notFound,
  profile,
  registrationConfirm,
  registration,
  forgotPassword,
  serviceQuality,
  notifications,
  messages,
  flashMessages,
  heureka,
  knowledgeBase,
  campaigns,
  partnerPage,
  deliveries,
  faq,
  status,
  upload,
  dataGrid,
  partner,
  partners,
  acl,
  businessTerms,
  fee,
  orders,
  products,
  billings,
  brands,
  services,
  expansionCard,
  mallPay,
  mallFeed,
  dip,
  portalDialog,
  dashboard,
  {
    validations: {
      code: defaultValidations.code,
      messages: { ...defaultValidations.messages, ...validations.validations.messages },
    },
  },
)
