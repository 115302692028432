import createState from '@/store/modules/localization/state'
import { resetState } from '@/store/utils'
import { LOCALIZATION } from '@/store/modules/localization/types'

export default {
  [LOCALIZATION.MUTATIONS.GET_LOCALIZATION_RESPONSE]: (state, data) => {
    state.localization = data
    state.isLoaded = true
  },
  [LOCALIZATION.MUTATIONS.RESET]: state => {
    resetState(state, createState())
  },
}
