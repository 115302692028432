import { BRANDS } from '@/store/modules/brands/types'
import { resetState } from '@/store/utils'
import createState from '@/store/modules/brands/state'
import { combineFilterAndSearch } from '@/store/modules/brands/utils'
import cloneDeep from 'lodash/cloneDeep'
import { sortByKey } from '@/services/sorter'

export default {
  [BRANDS.MUTATIONS.GET_BRANDS_RESPONSE]: (state, data) => {
    state.brands = sortByKey(cloneDeep(data), 'id')
    state.brandsToSend = []
    state.filteredBrands = {
      ...state.filteredBrands,
      items: state.brands,
    }
  },
  [BRANDS.MUTATIONS.GET_BRANDS_HISTORY_RESPONSE]: (state, data) => {
    state.brandsRequestHistory = {
      ...state.brandsRequestHistory,
      items: data,
    }
  },
  [BRANDS.MUTATIONS.ADD_BRAND_ROW]: (state, data) => {
    state.brandsToSend = [...state.brandsToSend, data]
  },
  [BRANDS.MUTATIONS.ADD_SINGLE_BRAND]: (state, data) => {
    state.singleBrandToSend = data
  },
  [BRANDS.MUTATIONS.EDIT_BRAND_ROW]: (state, data) => {
    state.brandsToSend = state.brandsToSend.map(brand => (brand.guid === data.guid ? data : brand))
  },
  [BRANDS.MUTATIONS.DELETE_BRAND_ROW]: (state, data) => {
    state.brandsToSend = state.brandsToSend.filter(brand => brand.guid !== data.guid)
  },
  [BRANDS.MUTATIONS.RESET_BRAND_REQUESTS]: state => {
    state.brandsToSend = []
    state.similarBrands = []
  },
  [BRANDS.MUTATIONS.SEARCH_BRANDS]: (state, data) => {
    state.filteredBrands = {
      ...state.filteredBrands,
      search: data.search,
      items: combineFilterAndSearch(state.brands, state.filteredBrands.filter, data.search),
    }
  },
  [BRANDS.MUTATIONS.FILTER_BRANDS]: (state, data) => {
    state.filteredBrands = {
      ...state.filteredBrands,
      filter: data.filter,
      items: combineFilterAndSearch(state.brands, data.filter, state.filteredBrands.search),
    }
  },
  [BRANDS.MUTATIONS.TOGGLE_EXPAND]: (state, data) => {
    state.filteredBrands = {
      ...state.filteredBrands,
      items: state.filteredBrands.items.map(brand => (brand.id === data.item.id ? data.item : brand)),
    }
  },
  [BRANDS.MUTATIONS.SIMILAR_BRANDS]: (state, data) => {
    state.similarBrands = data.similarBrandsList
  },
  [BRANDS.MUTATIONS.UPLOAD_LOGO_RESPONSE]: (state, data) => {
    state.brandsToSend = state.brandsToSend.map(brand => {
      const fileId = data[brand.guid]
      return {
        ...brand,
        mediaId: fileId ? fileId : brand.mediaId,
      }
    })
  },
  [BRANDS.MUTATIONS.RESET]: state => {
    resetState(state, createState())
  },
}
