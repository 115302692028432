<template>
  <validation-observer ref="form">
    <v-form @submit.prevent="submit">
      <validation-provider
        v-slot="{ errors }"
        :rules="fields.oldPassword.validations"
        :name="$t('profile.changePassword.form.oldPassword.name')"
      >
        <v-text-field
          v-model="form.oldPassword"
          outlined
          dense
          :name="fields.oldPassword.id"
          :error-messages="errors[0]"
          :label="$t('profile.changePassword.form.oldPassword.label')"
          :append-icon="fields.oldPassword.show ? 'visibility_off' : 'visibility'"
          :type="fields.oldPassword.show ? 'text' : 'password'"
          @click:append="fields.oldPassword.show = !fields.oldPassword.show"
        />
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        :rules="fields.newPassword.validations"
        :name="$t('profile.changePassword.form.newPassword.name')"
        :vid="fields.newPassword.id"
      >
        <v-text-field
          :ref="fields.newPassword.id"
          v-model="form.newPassword"
          outlined
          dense
          :name="fields.newPassword.id"
          :error-messages="errors[0]"
          :label="$t('profile.changePassword.form.newPassword.label')"
          :hint="$t('profile.changePassword.form.newPassword.hint')"
          persistent-hint
          :append-icon="fields.newPassword.show ? 'visibility_off' : 'visibility'"
          :type="fields.newPassword.show ? 'text' : 'password'"
          @click:append="fields.newPassword.show = !fields.newPassword.show"
        />

        <password-strength-bar class="mb-4" :value="form.newPassword || ''" />
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        :rules="fields.confirmNewPassword.validations"
        :name="$t('profile.changePassword.form.confirmNewPassword.name')"
      >
        <v-text-field
          v-model="form.confirmNewPassword"
          outlined
          dense
          :name="fields.confirmNewPassword.id"
          :error-messages="errors[0]"
          :label="$t('profile.changePassword.form.confirmNewPassword.label')"
          :append-icon="fields.confirmNewPassword.show ? 'visibility_off' : 'visibility'"
          :type="fields.confirmNewPassword.show ? 'text' : 'password'"
          @click:append="fields.confirmNewPassword.show = !fields.confirmNewPassword.show"
        />
      </validation-provider>

      <div v-if="showButton" class="text-right mt-4">
        <sending-form-button
          :button-title="$t('button.save')"
          :sending-title="$t('button.sending.saving')"
          :is-sending="isSending"
        />
      </div>
    </v-form>
  </validation-observer>
</template>

<script>
import FormMixin from '@/components/commons/form/FormMixin'
import SendingFormButton from '@/components/commons/button/SendingFormButton'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import PasswordStrengthBar from '@/components/register/components/PasswordStrengthBar'

export default {
  name: 'ChangePasswordForm',
  components: { PasswordStrengthBar, SendingFormButton, ValidationProvider, ValidationObserver },
  mixins: [FormMixin],
  props: {
    showButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fields: {
        oldPassword: {
          id: 'oldPassword',
          show: false,
          validations: {
            required: true,
          },
        },
        newPassword: {
          id: 'newPassword',
          show: false,
          validations: {
            required: true,
            atleastOneUppercaseCharacter: true,
            atleastOneLowercaseCharacter: true,
            atleastOneSpecialCharacter: true,
            atleastOneNumber: true,
            min: 12,
            max: 50,
          },
        },
        confirmNewPassword: {
          id: 'confirmNewPassword',
          show: false,
          validations: {
            required: true,
            confirmed: 'newPassword',
          },
        },
      },
      form: { ...this.initForm() },
    }
  },
  methods: {
    initForm() {
      return {
        oldPassword: null,
        newPassword: null,
        confirmNewPassword: null,
      }
    },
    async submit() {
      if (await this.$refs.form.validate()) {
        if (await this.onSubmit(this.form)) {
          this.resetForm()
        }
      }
    },
    resetForm() {
      this.form = {
        oldPassword: null,
        newPassword: null,
        confirmNewPassword: null,
      }
      this.$refs.form.reset()
    },
  },
}
</script>
