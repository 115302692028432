import { getParamsFromVariants } from '@/components/products/utils/VariantsList'

export function convertParamsForProductForm(productParams, variable_parameters, convertedVariants, categoryParams) {
  if (!categoryParams.length) return {}

  let mergedParams = {}

  const parsedProductParams = JSON.parse(productParams)
  categoryParams.forEach(param => {
    mergedParams[param.name] = {
      text: param.text,
      unit: param.unit,
      variantParam: variable_parameters.includes(param.name),
      multiple: param.multiple,
      class: param.class,
      mandatoryForListing: param.mandatory_for_listing,
      options: param.options,
      values: (() => {
        if (variable_parameters.includes(param.name)) {
          return [...getParamsFromVariants(param.name, convertedVariants)]
        } else {
          return parsedProductParams[param.name] ? [...parsedProductParams[param.name]] : []
        }
      })(),
    }
  })

  return mergedParams
}

export function revertParamsForProduct(productParams) {
  const revertedParams = {}

  Object.keys(productParams).forEach(paramName => {
    if (productParams[paramName].values.length && !productParams[paramName].variantParam) {
      revertedParams[paramName] = [...productParams[paramName].values]
    }
  })

  return JSON.stringify(revertedParams)
}

export function revertVariantParamsForProduct(productParams) {
  return Object.keys(productParams).filter(paramName => productParams[paramName].variantParam)
}
