<template>
  <v-avatar :color="userColor($options.filters.getSenderEmail(item))" :size="size">
    <span class="white--text font-weight-regular" :class="!small ? 'title' : 'body-2'">
      <template v-if="name">
        {{ name | getInitials }}
      </template>
      <template v-else>
        {{ item | getSenderName | getInitials }}
      </template>
    </span>
  </v-avatar>
</template>

<script>
import { getSenderName, getSenderEmail, getInitials } from '@/filters'

export default {
  name: 'Avatar',
  filters: { getSenderName, getSenderEmail, getInitials },
  props: {
    item: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
  },
  methods: {
    userColor(userId) {
      // https://stackoverflow.com/questions/3426404/create-a-hexadecimal-colour-based-on-a-string-with-javascript
      const hashCode = str => {
        let hash = 0
        for (let i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash)
        }
        return hash
      }

      const intToRGB = i => {
        let c = (i & 0x00ffffff).toString(16).toUpperCase()
        return '#' + '00000'.substring(0, 6 - c.length) + c
      }

      return intToRGB(hashCode(userId))
    },
  },
}
</script>
