export default () => ({
  brands: [],
  brandsToSend: [],
  brandsRequestHistory: {
    items: [],
    search: null,
    filter: null,
    paging: {
      itemsPerPage: 5,
    },
  },
  filteredBrands: {
    items: [],
    search: null,
    filter: null,
    paging: {
      itemsPerPage: 5,
    },
  },
  similarBrands: [],
  singleBrandToSend: null,
})
