const ORDERS_GRID_BODY = {
  request: ({ filter, sorter, paging, search }) => ({
    query: `
      query (
        $filter: [[OrdersFilterInput]]
        $sorter: [OrdersSorterInput]
        $paging: PaginationInput
        $search: String
      ) {
        orders {
          grid(
            input: {
              filter: $filter
              sorter: $sorter
              paging: $paging
              search: $search
            }
          ) {
            items {
              order_id
              purchase_id
              payment_type
              delivery_id
              external_id
              status
              ready_to_return
              invoice
              currency
              payment_type
              test
              customer {
                name
                email
                phone
                shipping_address {
                  company
                  street
                  city
                  zip
                  country
                }
              }
              items {
                id
                marketplace_sap_id
                partner_article_id
                article_id
                title
                item_position
                quantity
                price
                vat
                commission
                discount
                serial_numbers
                references {
                  type
                  reference
                }
                purchase_price
              }
              prices {
                total
                total_discount
                cod
                items {
                  delivery
                  cod_fee
                }
              }
              tracking {
                number
                url
              }
              transport {
                id
                first_delivery_attempt
                is_mall_delivery
                is_mdp
                is_dip
                ship_date
                delivery_date
                delivered_at
                branch_id
                branch_address {
                  branch_type
                  street
                  city
                  zip
                  country
                }
                branches {
                  secondary_branch_id
                }
              }
              mdp_info {
                parcel_count
              }
              consignment_info {
                parcel_count
              }
              status_history {
                blocked
                shipped
                open
              }
            }
            filter {
              column
              operator
              value
            }
            sorter {
              column
              direction
            }
            paging {
              page
              total
              itemsPerPage
              lastPage
              nextPage
              previousPage
            }
          }
        }
      }
    `,
    variables: {
      filter,
      sorter,
      paging,
      search,
    },
  }),
  reduce: data => {
    return data.orders.grid
  },
}

const MISSING_STATUS_GRID_BODY = {
  request: ({ filter, sorter, paging, search }) => ({
    query: `
      query (
        $filter: [[OrdersReportFilterInput]]
        $sorter: [OrdersSorterInput]
        $paging: PaginationInput
        $search: String
      ) {
        orders {
          gridMissingStatus (
            input: {
              filter: $filter
              sorter: $sorter
              paging: $paging
              search: $search
            }
          ) {
            items {
              order_id
              purchase_id
              payment_type
              delivery_id
              external_id
              status
              ready_to_return
              invoice
              currency
              payment_type
              customer {
                name
                email
                phone
                shipping_address {
                  company
                  street
                  city
                  zip
                  country
                }
              }
              items {
                id
                marketplace_sap_id
                partner_article_id
                article_id
                title
                item_position
                quantity
                price
                vat
                commission
                discount
                serial_numbers
                references {
                  type
                  reference
                }
                purchase_price
              }
              prices {
                total
                total_discount
                cod
                items {
                  delivery
                  cod_fee
                }
              }
              tracking {
                number
                url
              }
              transport {
                id
                first_delivery_attempt
                is_mall_delivery
                is_mdp
                is_dip
                ship_date
                delivery_date
                delivered_at
                branch_id
                branch_address {
                  branch_type
                  street
                  city
                  zip
                  country
                }
                branches {
                  secondary_branch_id
                }
              }
              mdp_info {
                parcel_count
              }
              consignment_info {
                parcel_count
              }
              status_history {
                blocked
                shipped
                open
              }
            }
            filter {
              column
              operator
              value
            }
            sorter {
              column
              direction
            }
            paging {
              page
              total
              itemsPerPage
              lastPage
              nextPage
              previousPage
            }
          }
        }
      }
    `,
    variables: {
      filter,
      sorter,
      paging,
      search,
    },
  }),
  reduce: data => {
    return data.orders.gridMissingStatus
  },
}

export default {
  ordersGrid: {
    id: 'ORDERS_GRID',
    useCpapiV2: true,
    ...ORDERS_GRID_BODY,
  },
  ordersStatusCount: {
    id: 'ORDERS_STATUS_COUNT',
    useCpapiV2: true,
    ...ORDERS_GRID_BODY,
  },
  missingStatusGrid: {
    id: 'ORDERS_MISSING_STATUS_GRID',
    useCpapiV2: true,
    ...MISSING_STATUS_GRID_BODY,
  },
  missingStatusCount: {
    id: 'ORDERS_MISSING_STATUS_COUNT',
    useCpapiV2: true,
    ...MISSING_STATUS_GRID_BODY,
  },
  getOrder: {
    id: 'GET_ORDER',
    request: ({ orderId }) => ({
      query: `
        query ($orderId: String!) {
          orders {
            get(input: { orderId: $orderId }) {
              order_id
              purchase_id
              external_id
              payment_type
              status
              currency
              invoice
              test
              customer {
                name
                email
                phone
                shipping_address {
                  company
                  street
                  city
                  zip
                  country
                }
              }
              prices {
                total
                cod
                total_discount
                items {
                  delivery
                  cod_fee
                }
              }
              tracking {
                number
                url
              }
              transport {
                id
                first_delivery_attempt
                ship_date
                delivery_date
                delivered_at
                is_mall_delivery
                is_mdp
                is_dip
                branch_id
                branch_address {
                  branch_type
                  street
                  city
                  zip
                  country
                }
                branches {
                  secondary_branch_id
                }
              }
              items {
                id
                marketplace_sap_id
                partner_article_id
                article_id
                title
                item_position
                quantity
                price
                vat
                commission
                discount
                serial_numbers
                references {
                  type
                  reference
                }
                purchase_price
              }
              status_history {
                shipped
                open
                blocked
                lost
                returned
                cancelled
                delivered
                shipping
              }
              shipment_status_history {
                status
                tracking_number
                status_history_items {
                  date
                  code
                  name
                  flags
                }
              }
              mdp_info {
                parcel_count
              }
              consignment_info {
                parcel_count
              }
            }
          }
        }
      `,
      variables: {
        orderId: orderId.toString(),
      },
    }),
    reduce: data => {
      return data.orders.get
    },
    /*mock() {
      return {
        order_id: '10083804502',
        purchase_id: '100838045',
        payment_type: '1',
        status: 'delivered',
        currency: 'CZK',
        invoice: '3064000108',
        test: false,
        customer: {
          name: 'MS TB',
          email: 'ms_t2@volny.cz',
          phone: '737472402',
          shipping_address: {
            company: null,
            street: 'u garáží 1',
            city: 'Praha 7',
            zip: '17000',
            country: 'CZ',
          },
        },
        prices: {
          total: 10,
          cod: 0,
          total_discount: 0,
          items: {
            delivery: 0,
            cod_fee: 0,
          },
        },
        tracking: {
          number: '',
          url: '',
        },
        transport: {
          id: '16',
          first_delivery_attempt: null,
          ship_date: '2021-11-10',
          delivery_date: '2021-11-15',
          delivered_at: '2021-11-11',
          is_mall_delivery: true,
          is_mdp: false,
          is_dip: true,
          branch_id: null,
          branch_address: {
            branch_type: '',
            street: '',
            city: '',
            zip: '',
            country: '',
          },
          branches: {
            secondary_branch_id: null,
          },
        },
        items: [
          {
            id: '118',
            marketplace_sap_id: '1045094',
            partner_article_id: null,
            article_id: '100001531792',
            title: 'Covim Gold Arabica zrnková káva 1Kg',
            item_position: 20,
            quantity: 1,
            price: 10,
            vat: 15,
            commission: 6.66,
            discount: 0,
            serial_numbers: [],
            references: [],
            purchase_price: 0,
          },
        ],
        status_history: {
          shipped: '2021-11-11T09:49:37+01:00',
          open: '2021-11-11T09:42:22+01:00',
          blocked: '2021-11-10T16:36:30+01:00',
          lost: null,
          returned: null,
          cancelled: null,
          delivered: '2021-11-11T09:49:47+01:00',
          shipping: null,
        },
        shipment_status_history: [
          {
            status: 'doručeno',
            tracking_number: 2131321,
            status_history_items: [
              {
                code: '',
                date: '2022-06-13T13:11:57+02:00',
                flags: [],
                name: 'čekáme na doručení',
              },
              {
                code: '',
                date: '2023-06-13T13:11:57+02:00',
                flags: [],
                name: 'doručeno',
              },
            ],
          },
          {
            status: 'posílá se',
            tracking_number: 654674,
            status_history_items: [
              {
                code: '',
                date: '2022-07-13T13:11:57+02:00',
                flags: [],
                name: 'vytvořeno',
              },
              {
                code: '',
                date: '2023-08-13T13:11:57+02:00',
                flags: [],
                name: 'posílá se',
              },
            ],
          },
        ],
        mdp_info: {
          parcel_count: 0,
        },
        consignment_info: {
          parcel_count: 0,
        },
      }
    },*/
  },
  updateOrder: {
    id: 'UPDATE_ORDER',
    request: ({ orderId, data }) => ({
      query: `
        mutation ($orderId: String! $data: OrdersUpdateStatusInputData!) {
          orders {
            update(
              input: {
                orderId: $orderId
                data: $data
              }
            ) {
              delivered_at
              status
              confirmed
              tracking {
                number
                url
              }
              first_delivery_attempt
            }
          }
        }
      `,
      variables: {
        orderId: orderId.toString(),
        data,
      },
    }),
    reduce: data => {
      return data.orders.update
    },
  },
  updateOrderBranch: {
    id: 'UPDATE_ORDER_BRANCH',
    request: ({ orderId, secondaryBranchId }) => ({
      query: `
        mutation ($orderId: String! $secondaryBranchId: Int64!) {
          orders {
            updateBranches(
              input: {
                orderId: $orderId
                secondary_branch_id: $secondaryBranchId
              }
            ) {
              success
            }
          }
        }
      `,
      variables: {
        orderId: orderId.toString(),
        secondaryBranchId,
      },
    }),
    reduce: data => {
      return data.orders.updateBranches
    },
  },
  createTestingOrders: {
    id: 'CREATE_TESTING_ORDERS',
    request: ({ data }) => ({
      query: `
        mutation ($data: TestOrderInputData) {
          orders {
            createTestOrders(input: { data: $data }) {
              success
            }
          }
        }
      `,
      variables: {
        data,
      },
    }),
    reduce: data => {
      return data.orders.createTestOrders
    },
  },
  importStatuses: {
    id: 'IMPORT_STATUSES',
    request: ({ type, data }) => ({
      query: `
        mutation ($type: OrdersStatusImportEnumType!, $data: OrdersStatusImportData!) {
          orders {
            importStatus (input: {
              type: $type,
              data: $data
            }) {
              status
              errors {
                message
                code
                attributes
              }
            }
          }
        }
      `,
      variables: {
        type,
        data,
      },
    }),
    reduce: data => {
      return data.orders.importStatus
    },
  },
  getUlozenkaExport: {
    id: 'GET_ULOZENKA_EXPORT',
    request: ({ partnerId, exportItems }) => ({
      query: `
        query ($partnerId: String!, $exportItems: [UloExportInputItem!]) {
          orders {
            getUloExport (input: { partnerId: $partnerId, export_items: $exportItems}) {
              data_raw
            }
          }
        }
      `,
      variables: {
        partnerId,
        exportItems,
      },
    }),
    reduce: data => {
      return data.orders.getUloExport
    },
  },
  exportBillingToFile: {
    id: 'PARTNERS_EXPORT_BILLING_TO_FILE',
    request: ({ filter }) => ({
      url: `/portal/export/billing-orders?filter=${JSON.stringify(filter)}`,
      method: 'GET',
      responseType: 'blob',
    }),
  },
  exportToFile: {
    id: 'PARTNERS_EXPORT_TO_FILE',
    request: ({ filter }) => ({
      url: `/portal/export/orders?filter=${JSON.stringify(filter)}`,
      method: 'GET',
      responseType: 'blob',
    }),
  },
  exportZasilkovnaToFile: {
    id: 'PARTNERS_EXPORT_ZASILKOVNA_TO_FILE',
    request: ({ filter }) => ({
      url: `/portal/export/orders/zasilkovna?filter=${JSON.stringify(filter)}`,
      method: 'GET',
      responseType: 'blob',
    }),
  },
  exportMissingZasilkovnaToFile: {
    id: 'PARTNERS_EXPORT_MISSING_ZASILKOVNA_TO_FILE',
    request: ({ filter }) => ({
      url: `/portal/export/orders-missing/zasilkovna?filter=${JSON.stringify(filter)}`,
      method: 'GET',
      responseType: 'blob',
    }),
  },
  exportMissingToFile: {
    id: 'PARTNERS_EXPORT_MISSING_TO_FILE',
    request: ({ filter }) => ({
      url: `/portal/export/orders-missing?filter=${JSON.stringify(filter)}`,
      method: 'GET',
      responseType: 'blob',
    }),
  },
  deliveryNote: {
    id: 'DOWNLOAD_DELIVERY_NOTE',
    request: ({ orderId }) => ({
      query: `
        mutation ($orderId: String!) {
          orders {
            requestDeliveryNotePdf (input: { orderId: $orderId }) {
              success
            }
          }
        }
      `,
      variables: {
        orderId: orderId.toString(),
      },
    }),
    reduce: data => {
      return data.orders.requestDeliveryNotePdf
    },
  },
  getDashboardOrders: {
    id: 'GET_ULOZENKA_EXPORT',
    request: ({ daysToDeliveryDate }) => ({
      query: `
        query ($daysToDeliveryDate: Int!){
          orders {
            getOrderStatistics(input: {days_to_delivery_date: $daysToDeliveryDate }) {
              open
              shipping
              shipped
              non_final
            }
          }
        }
      `,
      variables: {
        daysToDeliveryDate,
      },
    }),
    reduce: data => {
      return data.orders.getOrderStatistics
    },
  },
}
