import { i18n } from '@/localization'

export const vuetifyOptions = {
  iconfont: 'md',
  icons: {},
  theme: {
    themes: {
      light: {
        mall: '#C4031A',
        primary: '#00A8A9',
        primaryLight: '#E6F7F7',
        secondary: '#4D9FD4',
        accent: '#00A8A9',
        appBg: '#E8E8E8',
        soloInputBg: '#F7F7F7',
        secondaryAppBg: '#FBFBFB',
        warning: '#E08D00',
        red: '#CC0000',
        error: '#CC0000',
        success: '#15C05C',
        green: '#15C05C',
        textGray: '#747474',
        textGrayOpacity: '#BABABA',
      },
    },
  },
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
}
