<script>
import initHelpHero from 'helphero'
import { helpHero } from '@/config/helpHero'
import { mapState } from 'vuex'
import { FEFL } from '@/store/modules/fefl/types'

export default {
  name: 'HelpHero',
  props: {
    trackingData: {
      type: Object,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(FEFL.NAMESPACE, ['fefl']),
  },
  created() {
    if (this.fefl && this.fefl.helpHeroActive === true) {
      const init = initHelpHero(helpHero.appId)
      init.identify(this.userId, this.trackingData)
    }
  },
  render: () => null,
}
</script>
