export const isDecimal = (value, args) => {
  if (value !== undefined && value !== null && args) {
    if (
      (!Number.isInteger(args[0]) && !Array.isArray(value)) ||
      (typeof value === 'string' && (value.endsWith(',') || value.endsWith('.')))
    ) {
      return false
    }
    const digits = Math.abs(args[0])
    const expression = '^-?\\d*([.,]\\d{0,digits})?$'.replace(
      'digits',
      Number.isInteger(digits) && digits > 0 ? digits : '',
    )
    if (Array.isArray(value)) {
      return !value.some(singleValue => !new RegExp(expression, 'gm').test(singleValue))
    } else {
      return new RegExp(expression, 'gm').test(value)
    }
  }
  return false
}

export const isUrl = value => {
  if (value === null || value === undefined) {
    return false
  }
  return new RegExp(
    '(^$|^(https?://(www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)))',
    'i',
  ).test(value)
}

export const zipCode = value => {
  if (value === null || value === undefined) {
    return false
  }
  return /(^$|^[0-9 -]{4,6}$)/g.test(value)
}
