import { SHOP_ID } from '@/enums'
import isCurrency from 'validator/lib/isCurrency'

export const getPriceConfig = shopId => {
  switch (shopId) {
    case SHOP_ID.CZ10MA:
    case SHOP_ID.HU20MA:
      return {
        separator: '.',
        min: 1,
        max: 999999999,
        decimal: 0,
      }
    case SHOP_ID.SK10MA:
    case SHOP_ID.SI10SI:
    case SHOP_ID.PL20MA:
    case SHOP_ID.HR10MA:
      return {
        separator: '.',
        min: 0.01,
        max: 999999999.99,
        decimal: 2,
      }
    default:
      throw new Error(`Unsupported shop ID [${shopId}].`)
  }
}

export const isPrice = (value, shopId) => {
  switch (shopId) {
    case SHOP_ID.CZ10MA:
    case SHOP_ID.HU20MA:
      return isCurrency(value.toString(), {
        allow_space_after_symbol: false,
        allow_negatives: false,
        thousands_separator: '',
        decimal_separator: '.',
        allow_decimal: false,
        allow_space_after_digits: false,
      })
    case SHOP_ID.SK10MA:
    case SHOP_ID.SI10SI:
    case SHOP_ID.PL20MA:
    case SHOP_ID.HR10MA:
      return isCurrency(value.toString(), {
        allow_space_after_symbol: false,
        allow_negatives: false,
        thousands_separator: '',
        decimal_separator: '.',
        allow_decimal: true,
        digits_after_decimal: [1, 2],
        allow_space_after_digits: false,
      })
    default:
      throw new Error(`Unsupported shop ID [${shopId}].`)
  }
}
