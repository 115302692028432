<template>
  <v-tooltip max-width="400" :top="!positionRight" :right="positionRight">
    <template #activator="{ on }">
      <span v-on="on">
        <slot>
          <v-hover v-slot="{ hover }">
            <v-icon
              :style="pointerEventNone ? '' : 'pointer-events: auto !important;'"
              :color="hover ? 'textGray white' : 'textGrayOpacity white'"
              small
              class="custom-tooltip"
              :class="iconClass"
            >
              help
            </v-icon>
          </v-hover>
        </slot>
      </span>
    </template>

    <span>
      {{ text }}
    </span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    text: {
      type: String,
      required: true,
    },
    pointerEventNone: {
      type: Boolean,
      default: false,
    },
    positionRight: {
      type: Boolean,
      default: false,
    },
    iconClass: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="sass" scoped>
@import "~vuetify/src/styles/settings/variables"

.custom-tooltip
  margin-top: $spacer / 2
</style>
