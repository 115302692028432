import { config } from '@/config'
import Raven from 'raven-js'

class StreamNC {
  constructor(url, translateId, debug = false) {
    this.id = translateId
    this.url = url
    this.debug = debug
    this.callbacks = new Map()
    this.reconnectCallbacks = []
  }

  /**
   * @param {String} key
   * @param {Function} callback
   */
  addCallback(key, callback) {
    this.callbacks.set(key, callback)
  }

  /**
   * @param {Function} callback
   */
  addReconnectCallback(callback) {
    this.reconnectCallbacks.push(callback)
  }

  /**
   * @param {string} token
   */
  connect(token) {
    if (this.socket && this.socket.OPEN) {
      return
    }

    this.socket = new WebSocket(`${this.url}/v1/stream-api/${token}/ws`)
    this.socket.onopen = async () => {
      await this.socket.send(JSON.stringify({ action: 'login' }))
      await this.socket.send(JSON.stringify({ action: 'app_version', application: this.id }))

      this.pingTimer = setInterval(() => {
        if (this.socket) {
          this.socket.send(JSON.stringify({ action: 'ping' }))
        }
      }, 15000)
    }

    this.socket.onmessage = messageEvent => {
      if (this.debug) {
        // eslint-disable-next-line
        console.log(`StreamNC Message: ${messageEvent}`)
      }

      const messageEventData = JSON.parse(messageEvent.data)
      if (this.callbacks.has(messageEventData.event)) {
        this.callbacks.get(messageEventData.event)(messageEventData.content)
      }
    }

    this.socket.onclose = messageEvent => {
      // eslint-disable-next-line
      clearInterval(this.pingTimer)
      console.log(`StreamNC CloseEvent code: ${messageEvent.code}`)
    }

    this.socket.onerror = messageEvent => {
      // eslint-disable-next-line
      console.error(`StreamNC Error: ${messageEvent.message}`)
      console.error({ messageEvent })
    }

    // Ping
    this.addCallback('ping', msg => {
      if (msg !== 'pong') {
        if (Raven.isSetup()) {
          Raven.captureException(`StreamNC ping invalid response: ${msg}`).setTagsContext({ level: 'soft' })
        }
        console.error(`StreamNC Error: Invalid ping response, consult console or event log for possible errors`)
      }
    })
  }

  disconnect() {
    if (!this.socket || !this.socket.OPEN) {
      return
    }

    clearInterval(this.pingTimer)
    this.socket.close()
    this.socket = null
    this.pingTimer = null
  }
}

export default new StreamNC(config.stream.websocket, config.traslateId)
