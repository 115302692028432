import { NAVIGATION } from '@/store/modules/navigation/types'
import { LOCAL_STORAGE } from '@/enums'
import { resetState } from '@/store/utils'
import createState from '@/store/modules/navigation/state'

export default {
  [NAVIGATION.MUTATIONS.TOGGLE_NAVIGATION]: state => {
    state.navigationOpen = !state.navigationOpen
    localStorage.setItem(LOCAL_STORAGE.NAV_BAR, state.navigationOpen)
  },
  [NAVIGATION.MUTATIONS.OPEN_NAVIGATION]: state => {
    state.navigationOpen = true
    localStorage.setItem(LOCAL_STORAGE.NAV_BAR, true)
  },
  [NAVIGATION.MUTATIONS.CLOSE_NAVIGATION]: state => {
    state.navigationOpen = false
    localStorage.setItem(LOCAL_STORAGE.NAV_BAR, false)
  },
  [NAVIGATION.MUTATIONS.RESET]: state => {
    resetState(state, createState())
  },
}
