<template>
  <modal-dialog
    :max-width="680"
    :value="computedDialog !== null && computedDialog.longdesc !== '' && localStorageId !== dialog.id"
    confirm-btn-text="portalDialog.dialog.confirm"
    :on-confirm="onConfirm"
    :is-sending="state.isSending"
  >
    <div :id="`editor-${id}`" class="pt-4" />
  </modal-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { REQUESTS_STATE } from '@/store/modules/api/types'
import { API } from '@/api'
import { PORTAL_DIALOG } from '@/store/modules/portalDialog/types'
import ModalDialog from '@/components/commons/modal/ModalDialog'
import Quill from 'quill'
import { LOCAL_STORAGE } from '@/enums'
import { AUTH } from '@/store/modules/auth/types'

export default {
  name: 'PortalDialog',
  components: { ModalDialog },
  data() {
    return {
      localStorageId: localStorage.getItem(LOCAL_STORAGE.PORTAL_DIALOG),
      id: null,
      options: {
        modules: {
          toolbar: false,
        },
        readOnly: true,
        theme: 'snow',
      },
    }
  },
  computed: {
    ...mapGetters(REQUESTS_STATE.NAMESPACE, [REQUESTS_STATE.GETTERS.GET_STATE]),
    ...mapState(PORTAL_DIALOG.NAMESPACE, ['dialog']),
    ...mapState(AUTH.NAMESPACE, ['selectedPartner']),
    state() {
      return this[REQUESTS_STATE.GETTERS.GET_STATE](API.portalDialog.portalDialog.id)
    },
    computedDialog() {
      if (this.dialog && this.dialog[this.selectedPartner.shopId]) {
        return this.dialog[this.selectedPartner.shopId]
      }
      return null
    },
  },
  watch: {
    dialog(dialog) {
      if (dialog) {
        // if (this.localStorageId !== dialog.id) {
        setTimeout(() => {
          this.editor = new Quill(`#editor-${this.id}`, this.options)
          this.updateContent(this.computedDialog?.longdesc)
          this.editor.disable()
        }, 100)
        // }
      }
    },
  },
  async created() {
    this.id = this._uid
    await this[PORTAL_DIALOG.ACTIONS.GET_DIALOG_REQUEST]()
  },
  methods: {
    ...mapActions(PORTAL_DIALOG.NAMESPACE, [PORTAL_DIALOG.ACTIONS.GET_DIALOG_REQUEST]),
    updateContent(content) {
      if (content) {
        this.editor.root.innerHTML = content
      }
    },
    onConfirm() {
      localStorage.setItem(LOCAL_STORAGE.PORTAL_DIALOG, this.dialog.id)
    },
  },
}
</script>

<style lang="sass">
@import '~quill/dist/quill.snow.css'

.ql-container.ql-snow
  border: none !important

  p
    margin-bottom: 0
</style>
