export const DATE_FORMATS = {
  API: {
    MONTH: 'yyyy-MM',
  },
  INPUT: {
    DATE_MONTH: 'dd. MM.',
    DATE_MONTH_YEAR: 'dd. MM. yyyy',
    DATE_MONTH_YEAR_HOURS_MINUTES: 'dd.MM.yyyy HH:mm',
    DATE_MONTH_YEAR_HOURS_MINUTES_SECONDS: 'dd.MM.yyyy HH:mm:ss',
    DAY_MONTH_YEAR: 'd.M.yyyy',
    YEAR_MONTH_DATE: 'yyyy-MM-dd',
    YEAR_FULL_MONTH: 'yyyy MMMM',
    FULL_MONTH_YEAR: 'MMMM yyyy',
    HOURS_MINUTES: 'HH:mm',
    HOURS_MINUTES_SECONDS: 'HH:mm:ss',
    YEAR_MONTH_DATE_HOUR_MINUTES: 'yyyy-MM-dd HH:mm',
  },
}
