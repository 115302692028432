import { DIP } from '@/store/modules/dip/types'
import createState from '@/store/modules/dip/state'
import { resetState } from '@/store/utils'

export default {
  [DIP.MUTATIONS.GET_DIP_RESPONSE]: (state, data) => {
    state.dip = data
  },
  [DIP.MUTATIONS.RESET]: state => {
    resetState(state, createState())
  },
}
