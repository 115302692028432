<template>
  <v-menu bottom left offset-y>
    <template #activator="{ on }">
      <btn class="wdio-lang-switcher-btn" text action="button.set" v-on="on">
        <country-flag v-if="flag" :iso="currentFlag.flag" :title="currentFlag.title" :mode="iconMode" style="width: 24px; height: 24px;" class="mr-3" />
        {{ currentFlag.shortTitle }}
        <v-icon right small>
          keyboard_arrow_down
        </v-icon>
      </btn>
    </template>
    <v-list dense>
      <anchor v-for="(item, i) in languages" :key="i" list-item :action="item.title" @click="changeLang(item.lang)">
        <v-list-item-icon class="mr-3">
          <country-flag :iso="item.flag" :title="item.title" :mode="iconMode" style="width: 24px; height: 24px;" />
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-subtitle>
            {{ item.title }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </anchor>
    </v-list>
  </v-menu>
</template>

<script>
import { loadLanguageAsync } from '@/localization'
import { LOCAL_STORAGE } from '@/enums'
import { LOCALIZATION } from '@/store/modules/localization/types'
import { mapActions } from 'vuex'
import CountryFlag from '@dzangolab/vue-country-flag-icon'
import { config } from '@/config'

const LANGUAGES = {
  CS: 'cs',
  EN: 'en',
  PL: 'pl',
  HU: 'hu',
}

export default {
  name: 'LangSwitcher',
  components: {
    CountryFlag,
  },
  props: {
    onChange: {
      type: Function,
      default: null,
    },
    lang: {
      type: String,
      default: 'cs',
    },
    flag: {
      type: Boolean,
      default: false,
    },
    iconMode: {
      type: String,
      default: 'rounded',
    },
    notLogged: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const csLang = { title: 'Čeština', shortTitle: 'CS', flag: 'cz', lang: LANGUAGES.CS }
    const enLang = { title: 'English', shortTitle: 'EN', flag: 'gb', lang: LANGUAGES.EN }
    const plLang = { title: 'Polskie', shortTitle: 'PL', flag: 'pl', lang: LANGUAGES.PL }
    const huLang = { title: 'Hungary', shortTitle: 'HU', flag: 'hu', lang: LANGUAGES.HU }

    return {
      languages: [csLang, enLang, ...(this.notLogged ? [plLang, huLang] : [])],
      languageFallbacks: {
        hr: enLang,
        ...(!this.notLogged && { pl: enLang, hu: enLang }),
        sk: csLang,
        sl: enLang,
      },
    }
  },
  computed: {
    currentFlag() {
      const i = this.languages.findIndex(item => item.lang === this.lang)

      if (i !== -1) {
        return this.languages[i]
      } else if (this.languageFallbacks[this.lang]) {
        return this.languageFallbacks[this.lang]
      } else {
        return this.languages[0]
      }
    },
  },
  watch: {
    lang(val) {
      this.changeLang(val)
    },
    '$i18n.locale': {
      handler(val) {
        localStorage.setItem(LOCAL_STORAGE.LANG, val)
      },
      deep: true,
    },
  },
  created() {
    if (this.isOkLang(this.lang)) {
      const request = this[LOCALIZATION.ACTIONS.GET_LOCALIZATION_REQUEST]
      loadLanguageAsync(this.lang, this.$route.name, request, config.traslateId)
    }
  },
  methods: {
    ...mapActions(LOCALIZATION.NAMESPACE, [LOCALIZATION.ACTIONS.GET_LOCALIZATION_REQUEST]),
    isOkLang(lang) {
      return Object.values(LANGUAGES).includes(lang)
    },
    changeLang(lang) {
      if (this.isOkLang(lang)) {
        const request = this[LOCALIZATION.ACTIONS.GET_LOCALIZATION_REQUEST]
        loadLanguageAsync(lang, this.$route.name, request, config.traslateId)

        if (this.onChange) {
          this.onChange(lang)
        }
      }
    },
  },
}
</script>
