export const HEUREKA = {
  NAMESPACE: 'heureka',
  ACTIONS: {
    CATEGORY_SHORTCUT: 'CATEGORY_SHORTCUT',
    AVAILABILITY_SUMMARY_REQUEST: 'AVAILABILITY_SUMMARY_REQUEST',
    AVAILABILITY_VALIDATE_STATUS_REQUEST: 'AVAILABILITY_VALIDATE_STATUS_REQUEST',
    BRAND_SUMMARY_REQUEST: 'BRAND_SUMMARY_REQUEST',
    CATEGORY_SUMMARY_REQUEST: 'CATEGORY_SUMMARY_REQUEST',
    DELETE_OVERRIDE_REQUEST: 'DELETE_OVERRIDE_REQUEST',
    DOWNLOAD_CATEGORY_DETAIL_REQUEST: 'DOWNLOAD_CATEGORY_DETAIL_REQUEST',
    GET_AVAILABILITY_LOG_REQUEST: 'GET_AVAILABILITY_LOG_REQUEST',
    GET_PARTNER_CATEGORY_REQUEST: 'GET_PARTNER_CATEGORY_REQUEST',
    GET_PARTNER_CATEGORY_REQUEST_NO_LOADING: 'GET_PARTNER_CATEGORY_REQUEST_NO_LOADING',
    GET_PARTNER_REQUEST: 'GET_PARTNER_REQUEST',
    GET_PLAN_REQUEST: 'GET_PLAN_REQUEST',
    GET_PRODUCT_LOG_REQUEST: 'GET_PRODUCT_LOG_REQUEST',
    IMPORT_AVAILABILITY_REQUEST: 'IMPORT_AVAILABILITY_REQUEST',
    IMPORT_PRODUCTS_REQUEST: 'IMPORT_PRODUCTS_REQUEST',
    MALL_BRANDS_REQUEST: 'MALL_BRANDS_REQUEST',
    MALL_CATEGORY_LIST_REQUEST: 'MALL_CATEGORY_LIST_REQUEST',
    PAIR_CATEGORY_REQUEST: 'PAIR_CATEGORY_REQUEST',
    PARTNER_BRAND_PAIR_REQUEST: 'PARTNER_BRAND_PAIR_REQUEST',
    PARTNER_CATEGORY_TREE_REQUEST: 'PARTNER_CATEGORY_TREE_REQUEST',
    PARTNER_PAIR_OPTION_REQUEST: 'PARTNER_PAIR_OPTION_REQUEST',
    PARTNER_PARAM_LIST_REQUEST: 'PARTNER_PARAM_LIST_REQUEST',
    PARTNER_PARAM_UNPAIR_REQUEST: 'PARTNER_PARAM_UNPAIR_REQUEST',
    PARTNER_RESET_DATA: 'PARTNER_RESET_DATA',
    RESET_HASH_REQUEST: 'RESET_HASH_REQUEST',
    PRODUCT_SUMMARY_REQUEST: 'PRODUCT_SUMMARY_REQUEST',
    SILENT_PRODUCT_SUMMARY_REQUEST: 'SILENT_PRODUCT_SUMMARY_REQUEST',
    PRODUCT_VALIDATE_STATUS_REQUEST: 'PRODUCT_VALIDATE_STATUS_REQUEST',
    RECOMMENDED_CATEGORIES_REQUEST: 'RECOMMENDED_CATEGORIES_REQUEST',
    RECOMMENDED_PARTNER_OPTIONS_REQUEST: 'RECOMMENDED_PARTNER_OPTIONS_REQUEST',
    RECOMMENDED_PARTNER_PARAMS_REQUEST: 'RECOMMENDED_PARTNER_PARAMS_REQUEST',
    RECOMMEND_MALL_BRAND_REQUEST: 'RECOMMEND_MALL_BRAND_REQUEST',
    UPDATE_AUTO_DESCRIPTION_REQUEST: 'UPDATE_AUTO_DESCRIPTION_REQUEST',
    UPDATE_FEED_REQUEST: 'UPDATE_FEED_REQUEST',
    UPDATE_PARAM_VARIANT_REQUEST: 'UPDATE_PARAM_VARIANT_REQUEST',
    UPDATE_PLAN_AVAILABILITY_REQUEST: 'UPDATE_PLAN_AVAILABILITY_REQUEST',
    UPDATE_PLAN_PRODUCT_REQUEST: 'UPDATE_PLAN_PRODUCT_REQUEST',
    UPDATE_ROUND_PRICE_REQUEST: 'UPDATE_ROUND_PRICE_REQUEST',
    UPDATE_VAT_REQUEST: 'UPDATE_VAT_REQUEST',
    UPLOAD_PRODUCT_CSV_REQUEST: 'UPLOAD_PRODUCT_CSV_REQUEST',
  },
  MUTATIONS: {
    AVAILABILITY_FEED_IS_VALIDATING: 'AVAILABILITY_FEED_IS_VALIDATING',
    AVAILABILITY_SUMMARY_RESPONSE: 'AVAILABILITY_SUMMARY_RESPONSE',
    BRAND_SUMMARY_RESPONSE: 'BRAND_SUMMARY_RESPONSE',
    CATEGORY_SUMMARY_RESPONSE: 'CATEGORY_SUMMARY_RESPONSE',
    GET_AVAILABILITY_LOG_REQUEST: 'GET_AVAILABILITY_LOG_REQUEST',
    GET_PARTNER_CATEGORY_RESPONSE: 'GET_PARTNER_CATEGORY_RESPONSE',
    GET_PARTNER_RESPONSE: 'GET_PARTNER_RESPONSE',
    GET_PLAN_RESPONSE: 'GET_PLAN_RESPONSE',
    RESET_HASH_RESPONSE: 'RESET_HASH_RESPONSE',
    GET_PRODUCT_LOG_RESPONSE: 'GET_PRODUCT_LOG_RESPONSE',
    IMPORT_AVAILABILITY_FROM_STREAM: 'IMPORT_AVAILABILITY_FROM_STREAM',
    IMPORT_PRODUCT_FROM_STREAM: 'IMPORT_PRODUCT_FROM_STREAM',
    MALL_BRANDS_RESPONSE: 'MALL_BRANDS_RESPONSE',
    MALL_CATEGORY_LIST_RESPONSE: 'MALL_CATEGORY_LIST_RESPONSE',
    PARTNER_CATEGORY_TREE_RESPONSE: 'PARTNER_CATEGORY_TREE_RESPONSE',
    PARTNER_PARAM_LIST_RESPONSE: 'PARTNER_PARAM_LIST_RESPONSE',
    PRODUCT_FEED_IS_VALIDATING: 'PRODUCT_FEED_IS_VALIDATING',
    PRODUCT_SUMMARY_RESPONSE: 'PRODUCT_SUMMARY_RESPONSE',
    RESET: 'RESET',
    RUNNING_EXPORT: 'RUNNING_EXPORT',
    SYNC_AVAILABILITY: 'SYNC_AVAILABILITY',
    SYNC_PRODUCT: 'SYNC_PRODUCT',
  },
}
