export const PUBLIC_API = {
  NAMESPACE: 'publicApi',
  GETTERS: {
    COUNTRIES: 'COUNTRIES',
  },
  MUTATIONS: {
    COUNTRIES_RESPONSE: 'COUNTRIES_RESPONSE',
    RESET: 'RESET',
  },
  ACTIONS: {
    COUNTRIES_REQUEST: 'COUNTRIES_REQUEST',
  },
}
