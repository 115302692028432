export function revertLabels(labels) {
  return labels.map(label => {
    const fromDate = label.from.split(' ')[0]
    const toDate = label.to.split(' ')[0]

    return {
      ...label,
      from: `${fromDate} 00:00:00`,
      to: `${toDate} 00:00:00`,
    }
  })
}

export function stripLabelDates(productLabels) {
  return productLabels.map(label => {
    const fromDate = label.from.split(' ')[0]
    const toDate = label.to.split(' ')[0]

    return {
      ...label,
      from: fromDate,
      to: toDate,
    }
  })
}
