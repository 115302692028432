import Raven from 'raven-js'
import { withNamespace } from '@/store/utils'
import { ENDPOINTS, LOCAL_STORAGE, SENTRY } from '@/enums'
import { REQUESTS_STATE } from '@/store/modules/api/types'
import { AUTH } from '@/store/modules/auth/types'
import { i18n } from '@/localization'
import { addErrorMessage } from '@/services/flashMessages'
import { STORE } from '@/store/types'
import { ROUTES } from '@/router/routes'
// import stream from './stream'

const RAW_ERROR_MESSAGE_CODES = ['ORDERS_API_ULOZENKA_API_ERROR']

export const REQUEST_MAP = {
  [ENDPOINTS.AUTH]: [],
  [ENDPOINTS.PORTAL]: [],
  [ENDPOINTS.COCKPIT]: [],
  [ENDPOINTS.PUBLIC]: [],
}

export const sendResponseTimeData = () => {
  Object.keys(REQUEST_MAP).forEach(resolver => {
    if (REQUEST_MAP[resolver].length > 0) {
      sendAverageResponseTime(REQUEST_MAP, getAverageResponseTime(REQUEST_MAP, resolver), resolver)
    }
  })
}

export function getAverageResponseTime(map, resolver) {
  if (map[resolver].length > 0) {
    return (map[resolver].reduce((a, b) => a + b, 0) / map[resolver].length).toFixed(2)
  }
  return null
}

export function sendAverageResponseTime(map, averageResponseTime, resolver) {
  const interval = `${SENTRY.LOGGING_INTERVAL / 1000}s`
  Raven.captureMessage(
    `Average response time ${averageResponseTime}ms on ${resolver}/graphql for ${map[resolver].length} requests in interval of ${interval}`,
    {
      level: 'info',
    },
  )
  map[resolver] = []
}

export const getTextFromUlozenkaErrorMessage = string => {
  const match = string.match(/\[[0-9]+\] (.+)\./)
  return match[1]
}

export const startSending = (commit, id, errorType, loadingType, controller) => {
  commit(withNamespace(REQUESTS_STATE.NAMESPACE, REQUESTS_STATE.MUTATIONS.START_SENDING), {
    id,
    errorType,
    loadingType,
    controller,
  })
}

export const removeError = (commit, id) => {
  commit(withNamespace(REQUESTS_STATE.NAMESPACE, REQUESTS_STATE.MUTATIONS.REMOVE_ERROR), { id })
}

export const addError = (commit, id, error) => {
  commit(withNamespace(REQUESTS_STATE.NAMESPACE, REQUESTS_STATE.MUTATIONS.ADD_ERROR), {
    id,
    error,
  })
}

export const stopSending = (commit, id) => {
  commit(withNamespace(REQUESTS_STATE.NAMESPACE, REQUESTS_STATE.MUTATIONS.STOP_SENDING), { id })
}

export const onError = (store, id, errorCode, errorType, values = []) => {
  if (!errorType) {
    const apiTranslateKey = RAW_ERROR_MESSAGE_CODES.includes(errorCode)
      ? `apiErrors.${getTextFromUlozenkaErrorMessage(values.message)}`
      : `apiErrors.${errorCode}`
    addErrorMessage(store.dispatch, id, apiTranslateKey, values)
  }

  addError(store.commit, id, i18n.t(`apiErrors.${errorCode}`))
}

export const logout = async (commit, dispatch) => {
  Raven.clearContext()
  dispatch(withNamespace(AUTH.NAMESPACE, AUTH.ACTIONS.LOGOUT_FROM_STREAM), null, { root: true })
  commit(withNamespace(AUTH.NAMESPACE, AUTH.MUTATIONS.LOGOUT_RESPONSE), null, { root: true })
  // stream.disconnect()
  await dispatch(STORE.ACTIONS.ROUTER_PUSH, { name: ROUTES.LOGIN }, { root: true })
  localStorage.removeItem(LOCAL_STORAGE.SELECTED_PARTNER)
  dispatch(STORE.ACTIONS.RESET, null, { root: true })
}

export const dynamicId = (requestId, id) => {
  return `${requestId}_${id}`
}

export const origin = () => {
  // IE10 Doesn't have window.location.origin :(
  if (!window.location.origin) {
    const port = window.location.port ? ':' + window.location.port : ''
    return window.location.protocol + '//' + window.location.hostname + port
  } else {
    return window.location.origin
  }
}

export const guid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

/**
 * Rules flow
 * 1. Replace white characters with '-'
 * 2. Replace multiple '-' with only one '-'
 * 3. Replace multiple '_' with only one '_'
 * 4. Normalize string
 * 5. Remove unwanted characters and convert special letters with diacritics
 * 6. Remove '-' and '_' from the begging and the ens of the string
 * 7. Convert to upper case
 *
 * @param brandName
 * @returns {string}
 */
export const getBrandId = brandName => {
  if (!brandName) return ''

  //
  return brandName
    .replace(/\s+/g, '-')
    .replace(/[-]+/g, '-')
    .replace(/[_]+/g, '_')
    .normalize('NFKD')
    .replace(/[^\w-]/g, '')
    .replace(/^[-_]+|[_-]+$/gm, '')
    .toUpperCase()
}

// via https://stackoverflow.com/questions/23054475/javascript-regex-for-extracting-filename-from-content-disposition-header
export const getFileNameFromContentDisposition = contentDisposition => {
  return /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition)[1]
}

// dateObj = { ...other, from: String | Null | Undefined, to: String | Null | Undefined }
export const getDateArray = dateObj => {
  if ((dateObj.from === null || dateObj.from === undefined) && (dateObj.to === null || dateObj.to === undefined)) {
    return []
  }
  if (dateObj.to === null || dateObj.to === undefined) {
    return [dateObj.from]
  }
  return [dateObj.from, dateObj.to]
}

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) {
    console.error('Fallback: unable to copy', err)
  }

  document.body.removeChild(textArea)
}
export const copyToClipboard = async text => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  await navigator.clipboard.writeText(text)
}

export const openWindow = url => {
  window.open(url, '_blank')
}
