import { PRODUCTS } from '@/store/modules/products/types'
import {
  callApi,
  callFileSystemApi,
  callPortalGraphQL,
  callCpapiV2GraphQL,
  getSelectedShopId,
  getSelectedPartnerId,
  dispatchRoot,
  withNamespace,
} from '@/store/utils'
import { API } from '@/api'
import { addErrorMessage, addSuccessMessage } from '@/services/flashMessages'
import { OPERATOR } from '@/store/grid'
import { downloader } from '@/services/downloader'
import { getCountry, GQL_SERVICE_ERRORS } from '@/enums'
import { DATA_GRIDS } from '@/store/grid/grids'
import { GRID } from '@/store/grid/store/types'
import { dynamicId } from '@/services/utils'

export default {
  [PRODUCTS.ACTIONS.GET_CATEGORY_LIST_REQUEST]: async ({ dispatch, commit }) => {
    const data = await callCpapiV2GraphQL(dispatch, {
      requestData: { ...API.products.categoryList },
    })
    commit(PRODUCTS.MUTATIONS.GET_CATEGORY_LIST_RESPONSE, data)
  },
  [PRODUCTS.ACTIONS.DELETE_PRODUCTS_REQUEST]: async ({ dispatch }, payload) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.products.deleteProducts },
        params: {
          productIds: [...payload.productIds],
        },
        throwError: true,
      })
      addSuccessMessage(dispatch, API.products.deleteProducts, 'flashMessages.products.delete')

      return true
    } catch {
      return false
    }
  },
  [PRODUCTS.ACTIONS.ACTIVATE_PRODUCTS_REQUEST]: async ({ dispatch, commit }, payload) => {
    const batchSize = 1000
    let batchOffset = 0
    let done = 0
    let success = true

    commit(PRODUCTS.MUTATIONS.PRODUCT_ACTIVATION_PROGRESS, {
      running: true,
      total: payload.productIds.length,
      done: done,
    })

    try {
      while (success && payload.productIds.length > batchOffset) {
        let batch = payload.productIds.slice(batchOffset, batchOffset + batchSize)
        success = await callPortalGraphQL(dispatch, {
          requestData: { ...API.products.activateProducts },
          params: {
            productIds: batch,
          },
          throwError: true,
        })

        done += batch.length
        commit(PRODUCTS.MUTATIONS.PRODUCT_ACTIVATION_PROGRESS, {
          done: done,
        })

        batchOffset += batchSize
      }
    } finally {
      commit(PRODUCTS.MUTATIONS.PRODUCT_ACTIVATION_PROGRESS, {
        running: false,
        done: done,
      })
    }

    return success
  },
  [PRODUCTS.ACTIONS.ACTIVATE_ALL_PRODUCTS_REQUEST]: async ({ dispatch }) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.products.activateAllProducts },
        throwError: true,
      })

      addSuccessMessage(dispatch, API.products.activateAllProducts, 'flashMessages.products.activated')
      return true
    } catch {
      return false
    }
  },
  [PRODUCTS.ACTIONS.SET_STATUS_REQUEST]: async ({ dispatch }, payload) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.products.setStatus },
        params: {
          productId: payload.productId,
          status: payload.status,
        },
        throwError: true,
      })

      addSuccessMessage(dispatch, API.products.activateProducts, 'flashMessages.products.update')
      return true
    } catch {
      return false
    }
  },
  [PRODUCTS.ACTIONS.GET_BRAND_LIST_REQUEST]: async ({ dispatch, commit }) => {
    const data = await callCpapiV2GraphQL(dispatch, {
      requestData: { ...API.products.brandList },
    })
    commit(PRODUCTS.MUTATIONS.GET_BRAND_LIST_RESPONSE, data)
  },
  [PRODUCTS.ACTIONS.GET_CATEGORY_PARAMS_REQUEST]: async ({ dispatch, rootGetters }, payload) => {
    return await callPortalGraphQL(dispatch, {
      requestData: { ...API.products.getCategoryParams },
      params: {
        categoryId: payload,
        language: getCountry(getSelectedShopId(rootGetters)),
      },
    })
  },
  [PRODUCTS.ACTIONS.GET_PRODUCT_REQUEST]: async ({ dispatch, commit }, payload) => {
    const productData = await callCpapiV2GraphQL(dispatch, {
      requestData: { ...API.products.getProduct },
      params: {
        productId: payload.productId,
      },
    })

    let categoryParamsData = []

    if (productData.category_id) {
      categoryParamsData = await dispatch(PRODUCTS.ACTIONS.GET_CATEGORY_PARAMS_REQUEST, productData.category_id)
    }

    commit(PRODUCTS.MUTATIONS.GET_PRODUCT_RESPONSE, {
      productData,
      categoryParamsData,
    })
  },
  [PRODUCTS.ACTIONS.GET_VARIANTS_REQUEST]: async ({ dispatch, commit }, payload) => {
    const data = await callCpapiV2GraphQL(dispatch, {
      requestData: { ...API.products.getVariants },
      params: {
        productId: payload.productId,
      },
    })
    commit(PRODUCTS.MUTATIONS.GET_VARIANTS_RESPONSE, data)
    return data
  },
  [PRODUCTS.ACTIONS.GET_RECOMMENDED_REQUEST]: async ({ dispatch, commit }, payload) => {
    const data = await callCpapiV2GraphQL(dispatch, {
      requestData: { ...API.products.getRecommended },
      params: {
        filter: [
          [
            {
              column: 'id',
              operator: OPERATOR.IN,
              value: payload.recommended,
            },
          ],
        ],
      },
    })
    commit(PRODUCTS.MUTATIONS.GET_RECOMMENDED_RESPONSE, data)
    return data
  },
  [PRODUCTS.ACTIONS.GET_RECOMMENDED_OPTIONS_REQUEST]: async ({ dispatch, commit }, payload) => {
    const data = await callCpapiV2GraphQL(dispatch, {
      requestData: { ...API.products.getRecommendedOptions },
      params: {
        paging: {
          page: 1,
          itemsPerPage: 20,
        },
        search: payload.search,
      },
    })
    commit(PRODUCTS.MUTATIONS.GET_RECOMMENDED_OPTIONS_RESPONSE, data)
    return data
  },
  [PRODUCTS.ACTIONS.UPDATE_PRODUCT_REQUEST]: async ({ dispatch }, payload) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.products.updateProduct },
        params: {
          data: payload.product,
          productId: payload.productId,
          forceToken: payload.forceToken ?? '',
        },
        throwError: true,
      })
      addSuccessMessage(dispatch, API.products.activateProducts, 'flashMessages.products.update')

      if (payload.refresh) {
        dispatch(`${payload.namespace}/${GRID.ACTIONS.GRID_FETCH}`, { namespace: payload.namespace }, { root: true })
      }

      return { success: true }
    } catch (err) {
      if (err.length && err[0].code !== GQL_SERVICE_ERRORS.MPAPI_PRICE_DIFFERENCE_VALIDATION && err[0].message) {
        addErrorMessage(dispatch, API.products.updateProduct.id, 'flashMessages.products.error', [err[0].message])
      }

      return { success: false, errors: err }
    }
  },
  [PRODUCTS.ACTIONS.UPLOAD_PRODUCT_MEDIA_REQUEST]: async ({ dispatch }, payload) => {
    try {
      const data = await callFileSystemApi(dispatch, {
        requestData: {
          ...API.products.uploadProductMedia,
          id: payload.requestId
            ? dynamicId(API.products.uploadProductMedia.id, payload.requestId)
            : API.products.uploadProductMedia.id,
        },
        params: {
          formData: payload.formData,
        },
        throwError: true,
      })
      addSuccessMessage(dispatch, API.products.uploadProductMedia.id, 'flashMessages.products.uploadImage', [data])

      return data
    } catch (data) {
      addErrorMessage(dispatch, API.products.uploadProductMedia.id, 'flashMessages.products.uploadImageFailed', [data])
    }
  },
  [PRODUCTS.ACTIONS.GET_LABELS_LIST_REQUEST]: async ({ dispatch, commit }) => {
    const data = await callCpapiV2GraphQL(dispatch, {
      requestData: { ...API.products.labelsList },
    })
    commit(PRODUCTS.MUTATIONS.GET_LABELS_LIST_RESPONSE, data)
  },
  [PRODUCTS.ACTIONS.PRODUCTS_EXPORT]: async ({ dispatch }) => {
    const data = await callApi(dispatch, {
      requestData: { ...API.products.productsExport },
    })

    downloader(data, `products-${new Date().valueOf()}.xml`, 'application/xml')
  },
  [PRODUCTS.ACTIONS.PRODUCTS_IMPORT]: async ({ dispatch }, payload) => {
    try {
      await callApi(dispatch, {
        requestData: { ...API.products.productsImport },
        params: payload.data,
        throwError: true,
      })

      addSuccessMessage(dispatch, API.products.activateProducts, 'flashMessages.products.import')
      return true
    } catch {
      return false
    }
  },
  [PRODUCTS.ACTIONS.CREATE_PRODUCT_REQUEST]: async ({ dispatch }, payload) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.products.createProduct },
        params: {
          data: payload.product,
        },
        throwError: true,
      })

      addSuccessMessage(dispatch, API.products.createProduct.id, 'flashMessages.products.create')
      return true
    } catch (err) {
      if (err.length && err[0].message) {
        addErrorMessage(dispatch, API.products.updateProduct.id, 'flashMessages.products.error', [err[0].message])
      }
      return false
    }
  },
  [PRODUCTS.ACTIONS.GET_CMT_CATEGORIES_REQUEST]: async ({ dispatch, commit, rootGetters }) => {
    const selectedPartnerId = getSelectedPartnerId(rootGetters)

    const data = await callCpapiV2GraphQL(dispatch, {
      requestData: { ...API.products.getCmtCategories },
    })
    commit(PRODUCTS.MUTATIONS.GET_CMT_CATEGORIES_RESPONSE, {
      partnerId: selectedPartnerId,
      tree: data,
    })
  },
  [PRODUCTS.ACTIONS.UPLOAD_PRODUCT_BOX_CSV_REQUEST]: async ({ dispatch, rootGetters }, payload) => {
    try {
      await callFileSystemApi(dispatch, {
        requestData: { ...API.products.uploadProductBoxCsv },
        params: {
          data: payload.data,
          partnerId: getSelectedPartnerId(rootGetters),
        },
        throwError: true,
      })
      dispatchRoot(dispatch, withNamespace(DATA_GRIDS.PRODUCTS_BOX_OVERRIDES, GRID.ACTIONS.GRID_FETCH), {
        namespace: DATA_GRIDS.PRODUCTS_BOX_OVERRIDES,
        params: { partnerId: payload.partnerId },
      })

      addSuccessMessage(dispatch, API.products.uploadProductBoxCsv.id, 'flashMessages.uploadFile')
      return true
    } catch {
      return false
    }
  },
  [PRODUCTS.ACTIONS.UPLOAD_PRODUCT_MEDIA_CSV_REQUEST]: async ({ dispatch, rootGetters }, payload) => {
    try {
      await callFileSystemApi(dispatch, {
        requestData: { ...API.products.uploadMediaCsv },
        params: {
          data: payload.data,
          partnerId: getSelectedPartnerId(rootGetters),
        },
        throwError: true,
      })
      dispatchRoot(dispatch, withNamespace(DATA_GRIDS.PRODUCTS_MEDIA_OVERRIDES, GRID.ACTIONS.GRID_FETCH), {
        namespace: DATA_GRIDS.PRODUCTS_MEDIA_OVERRIDES,
        params: { partnerId: payload.partnerId },
      })

      addSuccessMessage(dispatch, API.products.uploadMediaCsv.id, 'flashMessages.uploadFile')
      return true
    } catch {
      return false
    }
  },
  [PRODUCTS.ACTIONS.DELETE_OVERRIDE_BOX_REQUEST]: async ({ dispatch }, payload) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.products.deleteProductBoxOverride },
        params: {
          ids: payload.ids,
        },
        throwError: true,
      })
      dispatchRoot(dispatch, withNamespace(DATA_GRIDS.PRODUCTS_BOX_OVERRIDES, GRID.ACTIONS.GRID_FETCH), {
        namespace: DATA_GRIDS.PRODUCTS_BOX_OVERRIDES,
        params: { partnerId: payload.partnerId },
      })

      addSuccessMessage(dispatch, API.products.uploadProductBoxCsv.id, 'flashMessages.productOverrides.delete')
      return true
    } catch {
      return false
    }
  },
  [PRODUCTS.ACTIONS.DELETE_OVERRIDE_MEDIA_REQUEST]: async ({ dispatch }, payload) => {
    try {
      await callPortalGraphQL(dispatch, {
        requestData: { ...API.products.deleteMediaOverride },
        params: {
          ids: payload.ids,
        },
        throwError: true,
      })
      dispatchRoot(dispatch, withNamespace(DATA_GRIDS.PRODUCTS_MEDIA_OVERRIDES, GRID.ACTIONS.GRID_FETCH), {
        namespace: DATA_GRIDS.PRODUCTS_MEDIA_OVERRIDES,
        params: { partnerId: payload.partnerId },
      })

      addSuccessMessage(dispatch, API.products.uploadProductBoxCsv.id, 'flashMessages.productOverrides.delete')
      return true
    } catch {
      return false
    }
  },
  [PRODUCTS.ACTIONS.SET_NEW_PRODUCT]: ({ commit }, payload) => {
    commit(PRODUCTS.MUTATIONS.GET_PRODUCT_RESPONSE, {
      categoryParamsData: payload.categoryParamsData,
      productData: payload.product,
    })
    return true
  },
  [PRODUCTS.ACTIONS.GET_CATEGORY_QUERY_RULES_REQUEST]: async ({ dispatch, commit }, payload) => {
    try {
      const data = await callPortalGraphQL(dispatch, {
        requestData: { ...API.products.getCategoryQueryRules },
        params: {
          query: payload.query,
          language: payload.language,
        },
        throwError: true,
      })
      commit(PRODUCTS.MUTATIONS.GET_CATEGORY_QUERY_RULES_RESPONSE, data)
    } catch {
      commit(PRODUCTS.MUTATIONS.GET_CATEGORY_QUERY_RULES_RESPONSE, null)
    }
  },

  [PRODUCTS.ACTIONS.RESET_VARIANTS]: ({ commit }) => {
    commit(PRODUCTS.MUTATIONS.RESET_VARIANTS)
  },
}
