<template>
  <v-navigation-drawer
    v-model="menuShown"
    class="custom-navigation-drawer"
    :clipped="!mobileLayout"
    app
    :mini-variant="miniVariant"
    :mobile-breakpoint="$vuetify.breakpoint.thresholds.sm"
    :width="mobileLayout ? 264 : 210"
    @input="onNavigationChange"
  >
    <div class="h-100">
      <div v-show="mobileLayout">
        <v-list class="hidden-lg-and-up">
          <anchor list-item>
            <v-list-item-content>
              <img class="sidebar-logo" src="@/assets/img/mall_pp_logo.svg" alt="Mall.cz" />
            </v-list-item-content>
          </anchor>
        </v-list>
        <v-divider class="hidden-lg-and-up" />
      </div>
      <navigation :mini-variant="miniVariant" :mobile-layout="mobileLayout" />
    </div>
    <template #append>
      <page-footer :mobile-layout="mobileLayout" :toggle-navigation="toggleNavigation" :mini-variant="miniVariant" />
    </template>
  </v-navigation-drawer>
</template>

<script>
import { NAVIGATION } from '@/store/modules/navigation/types'
import PageFooter from '@/components/layout/components/PageFooter'
import Navigation from '@/components/layout/components/nav/Navigation'
import { mapState } from 'vuex'
import { BREAKPOINT } from '@/enums'

export default {
  name: 'NavigationBar',
  components: { Navigation, PageFooter },
  props: {
    closeOpenNavigation: {
      type: Function,
      required: true,
    },
    currentBreakPoint: {
      type: String,
      required: true,
    },
    toggleNavigation: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      menuShown: null,
    }
  },
  computed: {
    /*
    navigationOpen shows when navigation is open... it means:
    when false, it depends on current layout:
      * on mobile, there is none navigationBar at all
      * on bigger layout false means miniVariant of navigationBar
    when its true it means at all layout is show and on biggler layout is fully open
    */
    ...mapState(NAVIGATION.NAMESPACE, ['navigationOpen']),
    miniVariant() {
      return !this.mobileLayout && this.navigationOpen === false
    },
    mobileLayout() {
      return this.currentBreakPoint === BREAKPOINT.SM_AND_DOWN
    },
  },
  watch: {
    mobileLayout(newVal) {
      this.setMenuShown(newVal, this.navigationOpen)
    },
    navigationOpen(newVal) {
      this.reloadNavBar()
      this.setMenuShown(this.mobileLayout, newVal)
    },
  },
  created() {
    this.reloadNavBar()
  },
  methods: {
    reloadNavBar() {
      if (this.navigationOpen === null) {
        this.closeOpenNavigation(this.currentBreakPoint === BREAKPOINT.LG_AND_UP)
      } else {
        this.setMenuShown(this.mobileLayout, this.navigationOpen)
      }
    },
    onNavigationChange(open) {
      if (!open) {
        this.closeOpenNavigation(false)
      }
    },
    setMenuShown(mobileLayout, navigationOpen) {
      this.menuShown = (mobileLayout && navigationOpen) || !mobileLayout
    },
  },
}
</script>

<style scoped lang="sass">
.sidebar-logo
  align-self: center
  max-width: 100%
</style>
