export const NAVIGATION = {
  NAMESPACE: 'navigation',
  ACTIONS: {
    TOGGLE_NAVIGATION: 'TOGGLE_NAVIGATION',
    OPEN_NAVIGATION: 'OPEN_NAVIGATION',
    CLOSE_NAVIGATION: 'CLOSE_NAVIGATION',
  },
  MUTATIONS: {
    TOGGLE_NAVIGATION: 'TOGGLE_NAVIGATION',
    OPEN_NAVIGATION: 'OPEN_NAVIGATION',
    CLOSE_NAVIGATION: 'CLOSE_NAVIGATION',
    RESET: 'RESET',
  },
}
