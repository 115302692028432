<template>
  <v-toolbar-items>
    <!--    MOBILE MENU     -->
    <v-menu
      v-if="$vuetify.breakpoint.smAndDown"
      v-model="mobileMenuOpen"
      left
      bottom
      offset-y
      :close-on-content-click="false"
    >
      <template #activator="{ on }">
        <btn action="button.menu" icon v-on="on">
          <v-icon medium>
            apps
          </v-icon>
        </btn>
      </template>

      <div class="custom-tabs pa-2">
        <v-tabs>
          <v-tab v-if="workflow && shouldShowButton">
            <v-icon>
              playlist_add_check
            </v-icon>
          </v-tab>

          <v-tab>
            <v-icon>
              account_circle
            </v-icon>
          </v-tab>

          <v-tab>
            <avatar small :name="`${account.firstName} ${account.lastName}`" :item="{ account: account }" size="22" />
          </v-tab>


          <v-tab-item v-if="workflow && shouldShowButton">
            <finish-workflow-button
              :workflow-number="workflowNumber"
              :on-click="toWorkflowStep"
              active-class="col-12 mt-3"
            />
          </v-tab-item>

          <v-tab-item>
            <partner-list :close-menu-callback="closeMobileMenu" />
          </v-tab-item>

          <v-tab-item>
            <user-list :close-menu-callback="closeMobileMenu" :account="account" />
          </v-tab-item>
        </v-tabs>
      </div>
    </v-menu>

    <!--    PARTNER MENU DESKTOP     -->
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <div class="mr-2 font-weight-medium d-flex align-center">
        <finish-workflow-button
          v-if="workflow && shouldShowButton"
          :workflow-number="workflowNumber"
          :on-click="toWorkflowStep"
          active-class="mr-3"
        />
      </div>
      <v-menu v-if="partners.length > 1" bottom left offset-y>
        <template #activator="{ on }">
          <btn action="button.user" text class="text-none" v-on="on">
            <span v-if="selectedPartner">
              {{ selectedPartner | getPartnerTitle }}
            </span>
            <spinner v-if="!selectedPartner" :size="23" />
          </btn>
        </template>

        <partner-list />
      </v-menu>

      <div v-else class="mr-2 font-weight-medium d-flex align-center">
        {{ selectedPartner | getPartnerTitle }}
      </div>
    </template>

    <!--    USER MENU DESKTOP     -->
    <v-menu v-if="$vuetify.breakpoint.mdAndUp" bottom left offset-y>
      <template #activator="{ on }">
        <btn action="button.user" text icon v-on="on">
          <avatar small :name="`${account.firstName} ${account.lastName}`" :item="{ account: account }" size="24" />
        </btn>
      </template>

      <user-list :account="account" />
    </v-menu>

    <!--    LANGUAGES DESKTOP + MOBILE     -->
    <lang-switcher :lang="account.lang" :on-change="onLangChange" />
  </v-toolbar-items>
</template>

<script>
import LangSwitcher from '@/components/commons/locazation/LangSwitcher'
import { mapActions, mapState, mapGetters } from 'vuex'
import { AUTH } from '@/store/modules/auth/types'
import PartnerList from '@/components/layout/components/topBar/PartnerList'
import Avatar from '@/components/profile/user/Avatar'
import { getPartnerTitle } from '@/filters'
import Spinner from '@/components/commons/spinner/Spinner'
import { REQUESTS_STATE } from '@/store/modules/api/types'
import { WORKFLOW } from '@/store/modules/workflow/types'
import { WORKFLOW_STATUS, getWorkflowStepByStatus, WORKFLOW_STEPS } from '@/enums'
import { ROUTES } from '@/router/routes'
import UserList from '@/components/layout/components/topBar/UserList'
import FinishWorkflowButton from '@/components/layout/components/topBar/FinishWorkflowButton'
import { FAQ_ROUTES } from '@/router/routes/faq'

export default {
  name: 'TopBarItems',
  components: {
    FinishWorkflowButton,
    LangSwitcher,
    PartnerList,
    Avatar,
    UserList,
    Spinner,
  },
  filters: {
    getPartnerTitle,
  },
  data() {
    return {
      ROUTES,
      mobileMenuOpen: false,
    }
  },
  computed: {
    ...mapState(WORKFLOW.NAMESPACE, ['workflow']),
    ...mapState(AUTH.NAMESPACE, ['account', 'selectedPartner', 'partners']),
    ...mapGetters(REQUESTS_STATE.NAMESPACE, [REQUESTS_STATE.GETTERS.GET_STATE]),
    workflowNumber() {
      return this.getWorkflowStepByStatus(this.workflow.status)
    },
    shouldShowButton() {
      if (this.workflow) {
        return [WORKFLOW_STATUS.TESTING, WORKFLOW_STATUS.PARTNER_ACCESS, WORKFLOW_STATUS.CONTENT_CONTROL].includes(
          this.workflow.status,
        )
      }
      return false
    },
  },
  created() {
    this[WORKFLOW.ACTIONS.WORKFLOW_REQUEST]()
  },
  methods: {
    ...mapActions(WORKFLOW.NAMESPACE, [WORKFLOW.ACTIONS.WORKFLOW_REQUEST]),
    ...mapActions(AUTH.NAMESPACE, [AUTH.ACTIONS.LOGOUT_REQUEST, AUTH.ACTIONS.UPDATE_ACCOUNT_REQUEST]),
    getWorkflowStepByStatus,
    toWorkflowStep() {
      this.closeMobileMenu()
      if (this.$route.params.step !== WORKFLOW_STEPS[this.workflow.status]) {
        this.$router.replace({ name: ROUTES.WORKFLOW, params: { step: WORKFLOW_STEPS[this.workflow.status] } })
      }
    },
    closeMobileMenu() {
      this.mobileMenuOpen = false
    },
    async onLangChange(lang) {
      await this[AUTH.ACTIONS.UPDATE_ACCOUNT_REQUEST]({
        lang,
        firstName: this.account.firstName,
        lastName: this.account.lastName,
      })
      if (this.$route.name === FAQ_ROUTES.DEFAULT) {
        window.location.reload()
      }
    },
  },
}
</script>

<style scoped lang="sass">
@import '../../../assets/styles/colors'

.custom-tabs
  background: $background

  .v-tab
    min-width: 0
</style>
