export const PRODUCT_ROUTES = {
  PRODUCTS_OVERVIEW: 'products-overview',
  PRODUCTS_SOLD_OUT: 'products-sold-out',
  PRODUCTS_LAST_ITEMS: 'products-last-items',
  PRODUCTS_ERRORS: 'products-errors',
  PRODUCTS_BLOCKED: 'products-blocked',
}

export const PRODUCT_URL_VARIANTS = 'variants'

export default [
  {
    path: '',
    name: PRODUCT_ROUTES.PRODUCTS_OVERVIEW,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "products" */ '../../../views/app/products/ProductsOverviewPage'
      ),
  },
  {
    path: 'sold-out',
    name: PRODUCT_ROUTES.PRODUCTS_SOLD_OUT,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "products" */ '../../../views/app/products/ProductsSoldOutPage'
      ),
  },
  {
    path: 'last-items',
    name: PRODUCT_ROUTES.PRODUCTS_LAST_ITEMS,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "products" */ '../../../views/app/products/ProductsLastItemsPage'
      ),
  },
  // TODO: MAR-13760
  // {
  //   path: 'errors',
  //   name: PRODUCT_ROUTES.PRODUCTS_ERRORS,
  //   component: () =>
  //     import(
  //       /* webpackPrefetch: true */ /* webpackChunkName: "products" */ '../../../views/app/products/ProductsErrorsPage'
  //     ),
  // },
  {
    path: 'blocked',
    name: PRODUCT_ROUTES.PRODUCTS_BLOCKED,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "products" */ '../../../views/app/products/ProductsBlockedItemsPage'
      ),
  },
]
