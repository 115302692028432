export default {
  getMultiLabel: {
    id: 'MDP_GET_MULTI_LABEL',
    request: ({ partnerId, first_position, labels_per_page, labels, labels_type }) => ({
      query: `
        query ($partnerId: String!, $first_position: Int!, $labels_per_page: Int!, $labels: [MultiLabelsInputItem!], $labels_type: String!) {
          mdpOrders {
            getMultiLabel (input: { partnerId: $partnerId, first_position: $first_position, labels_per_page: $labels_per_page, labels: $labels, labels_type: $labels_type} ) {
              mdp {
                orders
                labels_type
                labels_raw
              }
              dip {
                orders
                labels_type
                labels_raw
              }
            }
          }
        }
      `,
      variables: {
        partnerId,
        first_position: parseInt(first_position),
        labels_per_page,
        labels,
        labels_type,
      },
    }),
    reduce: data => {
      return data.mdpOrders.getMultiLabel
    },
  },
}
