import { i18n } from '@/localization'
import { extend } from 'vee-validate'
import { AUTH } from '@/store/modules/auth/types'
import { withNamespace } from '@/store/utils'
import isEmail from 'validator/lib/isEmail'
import isURL from 'validator/lib/isURL'
import { DateTime } from 'luxon'
import { isPrice } from '@/validations/price'
import { isIn, isIncluded } from '@/validations/in'
import { isVatId } from '@/validations/vatId'
import { isPhone } from '@/validations/phone'
import { isPostCode } from '@/validations/postCode'
import {
  atleastOneLowercaseChar,
  atleastOneNumber,
  atleastOneSpecialChar,
  atleastOneUppercaseChar,
} from '@/validations/password'
import { isDecimal, isUrl, zipCode } from '@/validations/defaultRules'
import { brandNotExists } from '@/validations/brandNotExists'
import { getBrandId } from '@/services/utils'
import * as rules from 'vee-validate/dist/rules'
import { isDateSameOrAfter } from '@/validations/date'
import { arrayContainsItemsFromArray, arrayContainsAllItemsFromArray, mainAdditionalImage } from '@/validations/array'
import { PARTNER } from '@/store/modules/partner/types'
import { getShopByCountry } from '@/enums'

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

extend('decimal', {
  validate: (value, args) => {
    return isDecimal(value, args)
  },
})

extend('url', {
  validate: value => {
    return isUrl(value)
  },
})

extend('included', {
  validate: (input, values) => {
    return isIncluded(input, values)
  },
  message: name => i18n.t('validations.messages.included', { name }),
})

extend('zipCode', {
  validate: value => {
    return zipCode(value)
  },
  message: name => i18n.t('validations.messages.zipCode', { name }),
})

extend('urlProtocolOptional', {
  validate: (input, options) => {
    return isURL(input, options)
  },
  params: ['require_protocol', 'protocols'],
  message: name => i18n.t('validations.messages.url', { name }),
})

extend('includedCaseInsensitive', {
  validate: (value, args) => {
    const lowerCaseValues = value.map(item => item.toLowerCase())
    const lowerCaseOptions = args.map(item => item.toLowerCase())

    return lowerCaseValues.every(item => lowerCaseOptions.includes(item))
  },
})

extend('atleastOneUppercaseCharacter', {
  validate: value => {
    return atleastOneUppercaseChar(value)
  },
  message: () => i18n.t('validations.messages.atleastOneUppercaseCharacter'),
})

extend('atleastOneLowercaseCharacter', {
  validate: value => {
    return atleastOneLowercaseChar(value)
  },
  message: () => i18n.t('validations.messages.atleastOneLowercaseCharacter'),
})

extend('atleastOneNumber', {
  validate: value => {
    return atleastOneNumber(value)
  },
  message: () => i18n.t('validations.messages.atleastOneNumber'),
})

extend('atleastOneSpecialCharacter', {
  validate: value => {
    return atleastOneSpecialChar(value)
  },
  message: () => i18n.t('validations.messages.atleastOneSpecialCharacter'),
})

extend('mustContainOneOf', {
  validate: (value, args) => {
    const conditionValues = args[0].split(',')

    return conditionValues.some(item => value.includes(item))
  },
})

extend('mustContainInsensitive', {
  validate: (value, args) => {
    const lowerCaseValues = value.map(item => item.toLowerCase())
    const lowerCaseOptions = args.map(item => item.toLowerCase())

    return lowerCaseValues.includes(lowerCaseOptions[0])
  },
})

extend('mustContain', {
  validate: (value, args) => {
    return value.includes(args[0])
  },
})

extend('canNotContainInsensitive', {
  validate: (value, args) => {
    const lowerCaseValues = value.map(item => item.toLowerCase())
    const lowerCaseOptions = args.map(item => item.toLowerCase())

    return !lowerCaseValues.includes(lowerCaseOptions[0])
  },
})

extend('canNotContain', {
  validate: (value, args) => {
    return !value.includes(args[0])
  },
})

extend('canNotContainArray', {
  validate: (items, args) => {
    return !arrayContainsItemsFromArray(items, args)
  },
})

extend('mustContainArray', {
  validate: (items, args) => {
    return arrayContainsAllItemsFromArray(items, args)
  },
})

extend('rangeDateRequired', {
  validate: (value, dates) => {
    if (dates[0] && dates[1]) {
      return true
    } else {
      return false
    }
  },
})

extend('maxThreeMonths', {
  validate: (value, args) => {
    const selectedFrom = args[0]
    const selectedTo = args[1]
    const maxTo = DateTime.fromISO(selectedFrom).plus({ months: 3 })

    if (
      DateTime.fromISO(selectedTo).toMillis() >= DateTime.fromISO(selectedFrom).toMillis() &&
      maxTo.toMillis() >= DateTime.fromISO(selectedTo).toMillis()
    ) {
      return true
    } else {
      return false
    }
  },
})

extend('emailList', {
  validate: value => {
    let allValid = true
    const emails = value.split(/[,;]/).map(email => email.trim().toLocaleLowerCase())

    emails.forEach(email => {
      if (!isEmail(email)) allValid = false
    })

    return allValid
  },
})

extend('barcode', {
  // https://stackoverflow.com/questions/13605340/how-to-validate-a-ean-gtin-barcode-in-javascript/18762640
  validate: value => {
    let isValid = true

    for (let counter = value.length - 1; counter >= 0; counter--) {
      isValid = isValid + parseInt(value.charAt(counter)) * (1 + 2 * (counter % 2))
    }

    return (10 - (isValid % 10)) % 10
  },
})

extend('productId', {
  validate: value => {
    const regex = /^[\w.-]+$/
    const match = regex.exec(value)

    if (match) {
      return true
    } else {
      return false
    }
  },
})

extend('productImage', {
  validate: (_value, args) => {
    const images = args[0]

    return images.filter(item => item.main).length > 0
  },
})

extend('notMainAdditionalImage', {
  validate: (_value, args) => {
    const images = args[0]

    return !mainAdditionalImage(images)
  },
})

extend('brandNotExists', {
  validate: (value, args) => {
    return brandNotExists(value, args)
  },
})

extend('brandIdCheck', {
  validate: value => {
    return getBrandId(value) !== ''
  },
})

extend('in', {
  validate: (input, [shopId]) => {
    return isIn(input, shopId)
  },
})

extend('vatId', {
  validate: (input, [shopId]) => {
    return isVatId(input, shopId)
  },
})

extend('phone', {
  validate: (input, [shopId]) => {
    return isPhone(input, shopId)
  },
})

extend('price', {
  validate: (value, [shopId]) => {
    return isPrice(value, shopId)
  },
})

extend('postCode', {
  validate: (value, [shopId]) => {
    return isPostCode(value, shopId)
  },
})

extend('dateSameAfter', {
  validate: (value, [date]) => {
    return isDateSameOrAfter(value, date)
  },
})

export const asyncValidation = () => {
  return store => {
    extend('uniqueAccountEmail', {
      validate: input => {
        return store.dispatch(withNamespace(AUTH.NAMESPACE, AUTH.ACTIONS.UNIQUE_EMAIL_REQUEST), {
          email: input,
        })
      },
      message: () => i18n.t('validations.messages.uniqueAccountEmail'),
    })
    extend('uniqueCompanyName', {
      validate: (input, [country]) => {
        return store.dispatch(withNamespace(AUTH.NAMESPACE, AUTH.ACTIONS.UNIQUE_COMPANY_NAME_REQUEST), {
          partnerName: input,
          shopId: getShopByCountry(country),
        })
      },
      message: () => i18n.t('validations.messages.uniqueCompanyName'),
    })
    extend('publicTitle', {
      validate: input => {
        return store.dispatch(withNamespace(AUTH.NAMESPACE, AUTH.ACTIONS.UNIQUE_PUBLIC_TITLE_BY_PARTNER_REQUEST), {
          partnerName: input,
        })
      },
    })
    extend('stateAllowed', {
      validate: (value, [types, stateCode]) => {
        if (stateCode) {
          return store.dispatch(withNamespace(PARTNER.NAMESPACE, PARTNER.ACTIONS.IS_STATE_ALLOWED_REQUEST), {
            stateCode,
            types,
          })
        }
        return Promise.resolve(true)
      },
      message: () => i18n.t('validations.messages.stateAllowed'),
    })
  }
}
