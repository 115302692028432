export default {
  exports: {
    downloadCategoryDetail: {
      id: 'DOWNLOAD_CATEGORY_DETAIL',
      request: ({ categoryId, menuItemId, type, language }) => ({
        url: `/portal/export/category/${categoryId}?menuItemId=${menuItemId}&type=${type}&language=${language}`,
        method: 'GET',
        responseType: 'blob',
      }),
      throwError: true,
    },
  },
  getPlan: {
    id: 'GET_PLAN',
    request: ({ partnerId }) => ({
      query: `
				query($partnerId: String!) {
				  hci {
				    getPlan(input: { partnerId: $partnerId }) {
				      id
				      productsInterval
				      productsStarted
				      productsFinished
				      availabilityInterval
				      availabilityStarted
				      availabilityFinished
				    }
				  }
				}
			`,
      variables: {
        partnerId,
      },
    }),
    reduce: data => {
      return data.hci.getPlan
    },
  },
  updateProductPlan: {
    id: 'UPDATE_PRODUCT_PLAN',
    request: ({ partnerId, data }) => ({
      query: `
        mutation($partnerId: String!, $data: FeedPlanInputData!) {
          hci {
            updatePlan(input: { partnerId: $partnerId, data: $data }) {
              id
              productsInterval
              productsStarted
              productsFinished
              availabilityInterval
              availabilityStarted
              availabilityFinished
            }
          }
        }
      `,
      variables: {
        partnerId,
        data,
      },
    }),

    reduce: data => {
      return data.hci.updatePlan
    },
  },
  updateAvailabilityPlan: {
    id: 'UPDATE_AVAILABILITY_PLAN',
    request: ({ partnerId, data }) => ({
      query: `
        mutation($partnerId: String!, $data: FeedPlanInputData!) {
          hci {
            updatePlan(input: { partnerId: $partnerId, data: $data }) {
              id
              productsInterval
              productsStarted
              productsFinished
              availabilityInterval
              availabilityStarted
              availabilityFinished
            }
          }
        }
      `,
      variables: {
        partnerId,
        data,
      },
    }),
    reduce: data => {
      return data.hci.updatePlan
    },
  },
  getPartner: {
    id: 'GET_PARTNER',
    request: ({ partnerId }) => ({
      query: `
        query($partnerId: String!) {
          hci {
            getPartner(input: { partnerId: $partnerId }) {
              id
              shopId
              vat
              productFeed
              availabilityFeed
              boxType
              boxParam {
                param
                bigBoxValue
                smallBoxValue
              }
              roundPrice
              autoDescription
            }
          }
        }
      `,
      variables: {
        partnerId,
      },
    }),
    reduce: data => {
      return data.hci.getPartner
    },
  },
  updatePartner: {
    id: 'UPDATE_PARTNER',
    request: ({ partnerId, data }) => ({
      query: `
        mutation updatePartner($partnerId: String!, $data: HciPartnerUpdateInputData!) {
          hci {
            updatePartner(input: {
              partnerId: $partnerId,
              data: $data
            }) {
              id
              shopId
              vat
              productFeed
              availabilityFeed
              boxType
              boxParam {
                param
                bigBoxValue
                smallBoxValue
              }
              roundPrice
              autoDescription
            }
          }
        }      
      `,
      variables: {
        partnerId,
        data,
      },
    }),
    reduce: data => {
      return data.hci.updatePartner
    },
  },
  resetHash: {
    id: 'RESET_HASH',
    request: () => ({
      query: `
        query {
          hci {
            hashReset {
              ok
              availability
              product
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data.hci.hashReset
    },
  },
  resetPartner: {
    id: 'RESET_PARTNER',
    request: ({ partnerId, data }) => ({
      query: `
        mutation($partnerId: String!, $data: HciPartnerResetInputData!) {
          hci {
            resetPartner(input: { partnerId: $partnerId, data: $data }) {
              success
            }
          }
        }
      `,
      variables: {
        partnerId,
        data,
      },
    }),
    reduce: data => {
      return data.hci.resetPartner
    },
  },
  categorySummary: {
    id: 'HEUREKA_CATEGORY',
    request: () => ({
      query: `
        query {
          hci {
            categorySummary {
              total
              paired
              unpaired
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data.hci.categorySummary
    },
  },
  // TODO duplicity with partnerBrandListDownload
  partnerBrandList: {
    id: 'HEUREKA_BRAND_LIST',
    request: ({ partnerId }) => ({
      query: `
        query ($partnerId: String!) { 
          hci {
            partnerBrandList (input: { partnerId: $partnerId } ) {
              id
              name
              accepted
              claimed
              mallBrand {
                id
                name
              }
            }
          }
        }
      `,
      variables: {
        partnerId,
      },
    }),
    reduce: data => {
      return data.hci.partnerBrandList
    },
  },
  // TODO duplicity with partnerBrandList
  partnerBrandListDownload: {
    id: 'HEUREKA_BRAND_LIST_DOWNLOAD',
    request: ({ partnerId }) => ({
      query: `
        query ($partnerId: String!) { 
          hci {
            partnerBrandList (input: { partnerId: $partnerId } ) {
              id
              name
              accepted
              claimed
              mallBrand {
                id
                name
              }
            }
          }
        }
      `,
      variables: {
        partnerId,
      },
    }),
    reduce: data => {
      return data.hci.partnerBrandList
    },
  },
  productSummary: {
    id: 'HEUREKA_PRODUCT_SUMMARY',
    request: () => ({
      query: `
        query {
          hci {
            productsSummary {
              isValidated
              vat
              error
              errorLog
              stats {
                uniqueProducts
                variantProducts
                deletedProducts
                incompleteProducts
                productsWithoutVAT
                productsWithoutRoundPrice
                productsWithoutEAN
                productsCategoryTooLong
                importedProducts
                invalidTitles
                invalidDescriptions
                invalidPictures
                unknownErrors
              }
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data.hci.productsSummary
    },
  },
  silentProductSummary: {
    id: 'SILENT_HEUREKA_PRODUCT_SUMMARY',
    request: () => ({
      query: `
        query {
          hci {
            productsSummary {
              isValidated
              vat
              error
              errorLog
              stats {
                uniqueProducts
                variantProducts
                deletedProducts
                incompleteProducts
                productsWithoutVAT
                productsWithoutRoundPrice
                productsWithoutEAN
                productsCategoryTooLong
                importedProducts
                invalidTitles
                invalidDescriptions
                invalidPictures
                unknownErrors
              }
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data.hci.productsSummary
    },
  },
  availabilitySummary: {
    id: 'HEUREKA_AVAILABILITY_SUMMARY',
    request: () => ({
      query: `
        query {
          hci {
            getXmlAvailabilityReport {
              error
              errorLog
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data.hci.getXmlAvailabilityReport
    },
  },
  importAvailability: {
    id: 'IMPORT_AVAILABILITY',
    request: ({ partnerId }) => ({
      query: `
        mutation ($partnerId: String!) {
          hci {
            importAvailability(input: { partnerId: $partnerId }) {
              success
            }
          }
        }
      `,
      variables: {
        partnerId,
      },
    }),
    reduce: data => {
      return data
    },
  },
  importProducts: {
    id: 'IMPORT_PRODUCTS',
    request: ({ partnerId }) => ({
      query: `
        mutation ($partnerId: String!) {
          hci {
            importProducts(input: { partnerId: $partnerId }) {
              success
            }
          }
        }
      `,
      variables: {
        partnerId,
      },
    }),
    reduce: data => {
      return data
    },
  },
  // TODO duplicity with getProductsLastUpdate
  getAvailabilityLastUpdate: {
    id: 'GET_AVAILABILITY_LAST_UPDATE',
    request: ({ partnerId }) => ({
      query: `
        query ($partnerId: String!) {
          hci {
            getPlan(input: { partnerId: $partnerId }) {
              id
				      productsInterval
				      productsStarted
				      productsFinished
				      availabilityInterval
				      availabilityStarted
				      availabilityFinished
            }
          }
        }
      `,
      variables: {
        partnerId,
      },
    }),
    reduce: data => {
      return data.hci.getPlan
    },
  },
  // TODO duplicity with getAvailabilityLastUpdate
  getProductsLastUpdate: {
    id: 'GET_PRODUCTS_LAST_UPDATE',
    request: ({ partnerId }) => ({
      query: `
        query ($partnerId: String!) {
          hci {
            getPlan(input: { partnerId: $partnerId }) {
              id
				      productsInterval
				      productsStarted
				      productsFinished
				      availabilityInterval
				      availabilityStarted
				      availabilityFinished
            }
          }
        }
      `,
      variables: {
        partnerId,
      },
    }),
    reduce: data => {
      return data.hci.getPlan
    },
  },
  brandsGrid: {
    id: 'BRANDS_GRID',
    request: ({ partnerId, filter, sorter, paging, search }) => ({
      query: `
        query (
          $partnerId: String!
          $filter: [[BrandsFilterInput]]
          $sorter: [BrandsSorterInput]
          $paging: PagingInput
          $search: String
        ) {
          hci {
            brandsGrid(
              input: {
                partnerId: $partnerId
                filter: $filter
                sorter: $sorter
                paging: $paging
                search: $search
              }
            ) {
              items {
                id
                name
                accepted
                claimed
                mallBrand {
                  id
                  name
                }
              }
              filter {
                column
                operator
                value
              }
              sorter {
                column
                direction
              }
              paging {
                page
                total
                itemsPerPage
                lastPage
                nextPage
                previousPage
              }
              search
            }
          }
        }
      `,
      variables: {
        partnerId,
        filter,
        sorter,
        paging,
        search,
      },
    }),
    reduce: data => {
      return data.hci.brandsGrid
    },
  },
  mallBrands: {
    id: 'HEUREKA_MALL_BRANDS',
    request: () => ({
      query: `
        query {
          hci {
            mallBrandList {
              id
              name
            }
          }
        }
        `,
    }),
    reduce: data => {
      return data.hci.mallBrandList
    },
  },
  recommendMallBrands: {
    id: 'HEUREKA_RECOMENDED_MALL_BRANDS',
    request: ({ partnerBrandId }) => ({
      query: `
        query ($partnerBrandId: String!) {
          hci {
            recommendBrand (input: { partnerBrandId: $partnerBrandId }) {
              id
              name
              fullName
              score
            }
          }
        }
      `,
      variables: {
        partnerBrandId: partnerBrandId.toString(),
      },
    }),
    reduce: data => {
      return data.hci.recommendBrand
    },
  },
  partnerBrandPair: {
    id: 'HEUREKA_PARTNER_BRAND_PAIR',
    request: ({ partnerBrandId, partnerId, data }) => ({
      query: `
        mutation ($partnerBrandId: String!, $partnerId: String!, $data: PairBrandInputData!) {
          hci {
            pairBrand (input: { partnerBrandId: $partnerBrandId, partnerId: $partnerId, data: $data }) {
              id
              name
              accepted
              mallBrand {
                id
                name
              }
            }
          }
        }
      `,
      variables: {
        partnerBrandId: partnerBrandId.toString(),
        partnerId,
        data,
      },
    }),
    reduce: data => {
      return data.hci.pairBrand
    },
  },
  validateXmlProducts: {
    id: 'HEUREKA_VALIDATE_XML',
    request: () => ({
      query: `
        mutation {
          hci {
            validateXmlProducts {
              success
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data.hci.validateXmlProducts
    },
  },
  validateXmlAvailability: {
    id: 'VALIDATE_XML_AVAILABILITY',
    request: () => ({
      query: `
        mutation {
          hci {
            validateXmlAvailability {
              success
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data.hci.validateXmlAvailability
    },
  },
  getClaims: {
    id: 'HEUREKA_GET_CLAIMS',
    request: () => ({
      query: `
        query {
          hci {
            getRequirement {
              id
              type
              refId
              deleted
              refName
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data.hci.getRequirement
    },
  },
  getProductLog: {
    id: 'HEUREKA_DOWNLOAD_PRODUCT_LOG',
    request: ({ partnerId }) => ({
      method: 'GET',
      url: `/portal/partners/${partnerId}/product-log`,
    }),
  },
  getAvailabilityLog: {
    id: 'HEUREKA_DOWNLOAD_AVAILABILITY_LOG',
    request: ({ partnerId }) => ({
      method: 'GET',
      url: `/portal/partners/${partnerId}/availability-log`,
    }),
  },
  partnerCategoryTree: {
    id: 'HEUREKA_PARTNER_CATEGORY_TREE',
    request: () => ({
      query: `
        query {
          hci {
            partnerCategoryTree {
              tree
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data.hci.partnerCategoryTree
    },
  },
  mallCategoryList: {
    id: 'HEUREKA_MALL_CATEGORY_LIST',
    request: () => ({
      query: `
        query {
          hci {
            mallCategoryList {
              id
              parentId
              sapId
              name
              fullName
            }
          }
        }
      `,
    }),
    reduce: data => {
      return data.hci.mallCategoryList
    },
  },
  pairCategory: {
    id: 'HEUREKA_PAIR_CATEGORY',
    request: ({ partnerCategoryId, id }) => ({
      query: `
        mutation ($partnerCategoryId: String!, $mallId: String) {
          hci {
            pairCategory( input: { partnerCategoryId: $partnerCategoryId, data: { mallId: $mallId } } ) {
              success
            }
          }
        }
      `,
      variables: {
        partnerCategoryId: partnerCategoryId.toString(),
        mallId: id.toString(),
      },
    }),
    reduce: data => {
      return data.hci.pairCategory
    },
  },
  recommended: {
    id: 'HEUREKA_MALL_RECOMMENDED',
    request: ({ partnerCategoryId }) => ({
      query: `
        query ($partnerCategoryId: String!) {
          hci {
            recommendCategory( input: { partnerCategoryId: $partnerCategoryId } ) {
              id
              name
              fullName
              score
            }
          }
        }
      `,
      variables: {
        partnerCategoryId: partnerCategoryId.toString(),
      },
    }),
    reduce: data => {
      return data.hci.recommendCategory
    },
  },
  partnerCategory: {
    id: 'PARTNER_CATEGORY',
    request: ({ partnerCategoryId }) => ({
      query: `
        query ($partnerCategoryId: String!) {
          hci {
            partnerCategoryDetail(input: { partnerCategoryId: $partnerCategoryId }) {
              id
              name
              fullName
              params {
                mallParam {
                  id
                  text
                  class
                  unit
                  name
                  options {
                    id
                    mallParamId
                    text
                    name
                  }
                }
                partnerParam {
                  id
                  partnerCategoryId
                  name
                  mallParamId
                  main
                  isVariant
                  options {
                    id
                    name
                    partnerParamId
                    mallParamOptionId
                    mallParamOption {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        partnerCategoryId: partnerCategoryId.toString(),
      },
    }),
    reduce: data => {
      return data.hci.partnerCategoryDetail
    },
  },
  partnerParamsList: {
    id: 'HEUREKA_PARTNER_PARAM_LIST',
    request: ({ partnerId, partnerCategoryId }) => ({
      query: `
        query ($partnerCategoryId: String!) {
          hci {
            partnerParameterList( input: { partnerCategoryId: $partnerCategoryId } ) {
              id
              name
              mallParamId
              isVariant
            }
          }
        }
      `,
      variables: {
        partnerId,
        partnerCategoryId: partnerCategoryId.toString(),
      },
    }),
    reduce: data => {
      return data.hci.partnerParameterList
    },
  },
  recommendedPartnerParam: {
    id: 'HEUREKA_RECOMMENDED_PARTNER_PARAM',
    request: ({ mallParamId, partnerCategoryId }) => ({
      query: `
        query ($mallParamId: String!, $partnerCategoryId: String!) {
          hci {
            recommendParameter( input: { mallParamId: $mallParamId, partnerCategoryId: $partnerCategoryId } ) {
              id
              name
              fullName
              score
            }
          }
        }
      `,
      variables: {
        mallParamId: mallParamId.toString(),
        partnerCategoryId: partnerCategoryId.toString(),
      },
    }),
    reduce: data => {
      return data.hci.recommendParameter
    },
  },
  unpairMallParam: {
    id: 'HEUREKA_UNPAIR_MALL_PARAM',
    request: ({ partnerCategoryId, partnerParamId }) => ({
      query: `
        mutation ($partnerCategoryId: Int!, $partnerParamId: Int!) {
          hci {
            unpairParameter( input: { partnerCategoryId: $partnerCategoryId, partnerParamId: $partnerParamId } ) {
              success
            }
          }
        }
      `,
      variables: {
        partnerCategoryId: parseInt(partnerCategoryId),
        partnerParamId: parseInt(partnerParamId),
      },
    }),
    reduce: data => {
      return data.hci.unpairParameter
    },
  },
  updatePartnerParam: {
    id: 'HEUREKA_UPDATE_PARTNER_PARAM',
    request: ({ categoryId, partnerParamId, data }) => ({
      query: `
        mutation ( $categoryId: String!, $partnerParamId: String!, $data: UpdatePartnerParamInputData!) {
          hci {
            updatePartnerParameter( input: { categoryId: $categoryId, partnerParamId: $partnerParamId, data: $data } ) {
              id
              name
              mallParamId
              isVariant
            }
          }
        }
      `,
      variables: {
        categoryId: categoryId.toString(),
        partnerParamId: partnerParamId.toString(),
        data: {
          isVariant: data.isVariant,
          mallParamId: parseInt(data.mallParamId),
        },
      },
    }),
    reduce: data => {
      return data.hci.updatePartnerParameter
    },
  },
  recommendedMallOptions: {
    id: 'HEUREKA_RECOMMENDED_MALL_OPTIONS',
    request: ({ partnerParamOptionId }) => ({
      query: `
        query ($partnerParamOptionId: Int!) {
          hci {
            recommendOption( input: { partnerParamOptionId: $partnerParamOptionId } ) {
              id
              name
              fullName
              score
            }
          }
        }
      `,
      variables: {
        partnerParamOptionId,
      },
    }),
    reduce: data => {
      return data.hci.recommendOption
    },
  },
  pairOption: {
    id: 'PAIR_OPTION',
    request: ({ categoryId, paramId, partnerParamOptionId, data }) => ({
      query: `
        mutation ($categoryId: String!, $paramId: String!, $partnerParamOptionId: String!, $data: OptionPairInputData!) {
          hci {
            pairOption(input: {
              categoryId: $categoryId,
              paramId: $paramId,
              partnerParamOptionId: $partnerParamOptionId,
              data: $data
            }) {
              success
            }
          }
        }
      `,
      variables: {
        categoryId: categoryId.toString(),
        paramId: paramId.toString(),
        partnerParamOptionId: partnerParamOptionId.toString(),
        data,
      },
    }),
    reduce: data => {
      return data.hci
    },
  },
  categoriesOverrideList: {
    useCpapiV2: true,
    id: 'HEUREKA_CATEGORIES_OVERRIDE_LIST',
    request: ({ paging, filter }) => ({
      query: `
        query(
          $filter: [[CategoryOverridesGridFilterInput]]
          $paging: PaginationInput
        ) {
          hci {
            categoryOverrides(input: { paging: $paging, filter: $filter }) {
              items {
                id
                partnerId
                partnerProductId
                sapCategoryId
                param
                value
              }
              paging {
                page
                total
                itemsPerPage
                lastPage
                nextPage
                previousPage
              }
              filter {
                column
                operator
                value
              }
            }
          }
        }
      `,
      variables: {
        paging,
        filter,
      },
    }),
    reduce: data => {
      return data.hci.categoryOverrides
    },
  },
  uploadProductCsv: {
    id: 'HEUREKA_UPLOAD_PRODUCT_CSV',
    request: ({ partnerId, data }) => ({
      url: `/hci-v2/partners/${partnerId}/category-overrides`,
      method: 'POST',
      headers: {
        'Content-Type': 'text/csv',
      },
      data: data,
    }),
  },
  deleteCategoriesOverride: {
    id: 'HEUREKA_DELETE_CATEGORIES_OVERRIDE',
    request: ({ ids }) => ({
      query: `
        mutation ($ids: [String!]!) {
          hci {
            deleteCategoryOverrides(input: {
              ids: $ids
            }) {
              success
            }
          }
        }
      `,
      variables: {
        ids,
      },
    }),
    reduce: data => {
      return data.hci.deleteCategoryOverrides
    },
  },
}
