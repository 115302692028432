export const PRICE_LIST = {
  NAMESPACE: 'priceList',
  ACTIONS: {
    GET_STANDARD_PRICE_LIST_REQUEST: 'GET_STANDARD_PRICE_LIST_REQUEST',
    RESET: 'RESET',
  },
  MUTATIONS: {
    GET_STANDARD_PRICE_LIST_RESPONSE: 'GET_STANDARD_PRICE_LIST_RESPONSE',
    RESET: 'RESET',
  },
}
