export const WORKFLOW = {
  NAMESPACE: 'workflow',
  ACTIONS: {
    WORKFLOW_REQUEST: 'WORKFLOW_REQUEST',
    WORKFLOW_UPDATE_REQUEST: 'WORKFLOW_UPDATE_REQUEST',
    WORKFLOW_UPDATE_TICK_REQUEST: 'WORKFLOW_UPDATE_TICK_REQUEST',
    GET_FEES_REQUEST: 'GET_FEES_REQUEST',
    GET_COMMISSION_FEES_REQUEST: 'GET_COMMISSION_FEES_REQUEST',
    GET_CHECK_LIST_REQUEST: 'GET_CHECK_LIST_REQUEST',
    PRODUCT_TO_APPROVAL_REQUEST: 'PRODUCT_TO_APPROVAL_REQUEST',
  },
  MUTATIONS: {
    WORKFLOW_RESPONSE: 'WORKFLOW_RESPONSE',
    WORKFLOW_UPDATE_RESPONSE: 'WORKFLOW_UPDATE_REQUEST',
    GET_FEES_RESPONSE: 'GET_FEES_RESPONSE',
    GET_COMMISSION_FEES_RESPONSE: 'GET_COMMISSION_FEES_RESPONSE',
    GET_CHECK_LIST_RESPONSE: 'GET_CHECK_LIST_RESPONSE',
    RESET: 'RESET',
  },
}
