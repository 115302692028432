import Raven from 'raven-js'
import { AUTH } from '@/store/modules/auth/types'
import { API } from '@/api'
import {
  callAuthGraphQL,
  callCpapiV2GraphQL,
  callPortalGraphQL,
  callPublicCpapiV2GraphQL,
  getLoggedAccountId,
  getLoginAsGivenPartner,
  getSelectedPartnerId,
} from '@/store/utils'
import { ROUTES } from '@/router/routes'
import { ability } from '@/config'
import { logout } from '@/services/utils'
import { clearBackLink, getBackLink } from '@/services/backlink'
import { ERROR_TYPE } from '@/store/modules/api/types'
import streamNC from '@/services/streamNC'
import { STORE } from '@/store/types'
import { addErrorMessage, addInfoMessage } from '@/services/flashMessages'
import { LOCAL_STORAGE } from '@/enums'

const afterLogin = async (dispatch, commit, data) => {
  commit(AUTH.MUTATIONS.LOGIN_RESPONSE, data)

  const backLink = getBackLink(data.selectedPartnerId)

  if (backLink) {
    await dispatch(STORE.ACTIONS.ROUTER_PUSH, backLink, { root: true })
    return
  }

  await dispatch(STORE.ACTIONS.ROUTER_PUSH, { name: ROUTES.HOME }, { root: true })
}

export default {
  [AUTH.ACTIONS.CHECK_LOGGED_REQUEST]: async ({ dispatch, state, commit, rootGetters }, payload) => {
    try {
      commit(AUTH.MUTATIONS.SET_CHECKING_AUTH, true)
      const loggedData = await callAuthGraphQL(dispatch, {
        requestData: { ...API.auth.checkLogged },
        params: {
          token: payload?.token ? payload.token : state.token,
        },
        throwError: true,
      })
      let selectedPartnerId = getSelectedPartnerId(rootGetters)
      commit(AUTH.MUTATIONS.UPDATE_TOKEN, loggedData)

      commit(AUTH.MUTATIONS.CHECK_LOGGED_RESPONSE, loggedData)

      const selectedPartnerFromLocalStorage = localStorage.getItem(LOCAL_STORAGE.SELECTED_PARTNER)
      const loginAsGivenPartner = getLoginAsGivenPartner(rootGetters)

      if (
        loginAsGivenPartner !== null &&
        loggedData.account.partners.some(partner => partner.id === loginAsGivenPartner)
      ) {
        await dispatch(AUTH.ACTIONS.SET_SELECTED_PARTNER, { partnerId: loginAsGivenPartner })
        dispatch(AUTH.ACTIONS.LOGIN_AS_GIVEN_PARTNER, null)
      } else if (
        selectedPartnerFromLocalStorage &&
        loggedData.account.partners.some(partner => partner.id === selectedPartnerFromLocalStorage)
      ) {
        await dispatch(AUTH.ACTIONS.SET_SELECTED_PARTNER, { partnerId: selectedPartnerFromLocalStorage })
      } else if (payload && payload.refresh) {
        await dispatch(AUTH.ACTIONS.SET_SELECTED_PARTNER, { partnerId: selectedPartnerId })
      } else {
        await dispatch(AUTH.ACTIONS.SET_SELECTED_PARTNER, { partnerId: loggedData.selectedPartnerId })
      }
      // MAR-13576
      // localStorage.removeItem(LOCAL_STORAGE.SELECTED_PARTNER)

      selectedPartnerId = getSelectedPartnerId(rootGetters)

      await dispatch(AUTH.ACTIONS.ACL_GET_REQUEST, {
        accountId: loggedData.account.id,
        partnerId: selectedPartnerId,
      })

      const partner = loggedData.account.partners.find(partner => partner.id === selectedPartnerId)
      Raven.setUserContext({
        email: loggedData.account.email,
        accountId: loggedData.account.id,
        partnerId: selectedPartnerId,
        sapId: partner?.sapId,
      })

      commit(AUTH.MUTATIONS.SET_CHECKING_AUTH, false)
      return loggedData
    } catch (e) {
      // eslint-disable-next-line
      console.error('Check logged error:', e)
      commit(AUTH.MUTATIONS.SET_CHECKING_AUTH, false)
      logout(commit, dispatch)

      return null
    }
  },
  [AUTH.ACTIONS.SET_TOKEN]: ({ commit }, payload) => {
    commit(AUTH.MUTATIONS.SET_TOKEN, { token: payload })
  },
  [AUTH.ACTIONS.WORKFLOW_RULES_REQUEST]: async ({ dispatch, commit }) => {
    const data = await callCpapiV2GraphQL(dispatch, {
      requestData: { ...API.auth.workflowRules },
    })

    commit(AUTH.MUTATIONS.WORKFLOW_RULES_RESPONSE, data)
  },
  [AUTH.ACTIONS.GET_CLIENT_ID_REQUEST]: async ({ dispatch, commit }, payload) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.auth.getClientId },
      params: {
        partnerId: payload.partnerId,
      },
    })

    commit(AUTH.MUTATIONS.GET_CLIENT_ID_RESPONSE, data)
  },
  [AUTH.ACTIONS.SET_SELECTED_PARTNER]: async ({ dispatch, commit, rootGetters }, payload) => {
    commit(AUTH.MUTATIONS.SET_SELECTED_PARTNER, payload)
    await dispatch(AUTH.ACTIONS.LOGOUT_FROM_STREAM)
    const streamToken = await dispatch(AUTH.ACTIONS.GET_STREAM_TOKEN_REQUEST)
    await dispatch(AUTH.ACTIONS.LOGIN_TO_STREAM, {
      accountId: getLoggedAccountId(rootGetters),
      partnerId: getSelectedPartnerId(rootGetters),
      streamToken: streamToken.token,
    })
    await dispatch(AUTH.ACTIONS.WORKFLOW_RULES_REQUEST)
  },
  [AUTH.ACTIONS.LOGIN_REQUEST]: async ({ dispatch, commit }, payload) => {
    localStorage.removeItem(LOCAL_STORAGE.PORTAL_DIALOG)
    const data = await callAuthGraphQL(dispatch, {
      requestData: { ...API.auth.login },
      params: {
        email: payload.email,
        password: payload.password,
      },
    })

    await afterLogin(dispatch, commit, data)
  },
  [AUTH.ACTIONS.LOGIN_AS_ADMIN_REQUEST]: async ({ commit, dispatch }, payload) => {
    localStorage.removeItem(LOCAL_STORAGE.PORTAL_DIALOG)
    commit(AUTH.MUTATIONS.SET_CHECKING_AUTH, true)
    const data = await callAuthGraphQL(dispatch, {
      requestData: { ...API.auth.loginAsAdmin },
      params: {
        hash: payload.hash,
        token: payload.token,
      },
    })

    await afterLogin(dispatch, commit, data)
    commit(AUTH.MUTATIONS.SET_CHECKING_AUTH, false)
  },
  [AUTH.ACTIONS.LOGIN_AS_GIVEN_PARTNER]: async ({ commit }, payload) => {
    commit(AUTH.MUTATIONS.LOGIN_AS_GIVEN_PARTNER, payload)
  },
  [AUTH.ACTIONS.SET_PASSWORD_CONFIRM_REQUEST]: async ({ dispatch, commit }, payload) => {
    try {
      const data = await callAuthGraphQL(dispatch, {
        requestData: { ...API.auth.passwordSet, errorType: ERROR_TYPE.NONE },
        params: { password: payload.password, token: payload.token },
        throwError: true,
      })
      commit(AUTH.MUTATIONS.SET_PASSWORD_CONFIRM_RESPONSE, data)
    } catch {
      commit(AUTH.MUTATIONS.SET_PASSWORD_CONFIRM_RESPONSE, { success: false })
    }
  },
  [AUTH.ACTIONS.LOGOUT_REQUEST]: async ({ commit, dispatch, state }) => {
    try {
      await callAuthGraphQL(dispatch, {
        requestData: { ...API.auth.logout },
        params: {
          token: state.token,
        },
        throwError: true,
      })
    } finally {
      clearBackLink(state.selectedPartnerId)
      logout(commit, dispatch)
    }
  },
  [AUTH.ACTIONS.GET_PARTNER_REGISTRATION_INFO_REQUEST]: async ({ dispatch, commit }) => {
    const data = await callCpapiV2GraphQL(dispatch, {
      requestData: { ...API.auth.registrationInfo },
      throwError: true,
    })
    commit(AUTH.MUTATIONS.GET_PARTNER_REGISTRATION_INFO_RESPONSE, data)
  },
  [AUTH.ACTIONS.SET_PASSWORD_VALID]: ({ commit }, payload) => {
    commit(AUTH.MUTATIONS.SET_PASSWORD_VALID, payload)
  },
  [AUTH.ACTIONS.PASSWORD_RESET_REQUEST]: async ({ dispatch, commit }, payload) => {
    try {
      const data = await callAuthGraphQL(dispatch, {
        requestData: { ...API.auth.passwordReset },
        params: { email: payload.email },
        throwError: true,
      })
      commit(AUTH.MUTATIONS.PASSWORD_RESET_RESPONSE, data)
      return true
    } catch {
      commit(AUTH.MUTATIONS.PASSWORD_RESET_RESPONSE, { success: false })
      return false
    }
  },
  [AUTH.ACTIONS.UNIQUE_EMAIL_REQUEST]: async ({ dispatch }, payload) => {
    const data = await callAuthGraphQL(dispatch, {
      requestData: { ...API.auth.uniqueAccountEmail },
      params: {
        data: payload,
      },
    })
    return data.isUnique
  },
  [AUTH.ACTIONS.UNIQUE_COMPANY_NAME_REQUEST]: async ({ dispatch }, payload) => {
    const data = await callAuthGraphQL(dispatch, {
      requestData: { ...API.auth.uniqueCompanyName, errorType: ERROR_TYPE.NONE },
      params: {
        partnerName: payload.partnerName,
        shopId: payload.shopId,
      },
    })
    return data.isUnique
  },
  [AUTH.ACTIONS.SET_REGISTRATION_STEP]: ({ commit }, payload) => {
    commit(AUTH.MUTATIONS.SET_REGISTRATION_STEP, payload)
  },
  [AUTH.ACTIONS.SET_REGISTRATION_EMAIL]: ({ commit }, payload) => {
    commit(AUTH.MUTATIONS.SET_REGISTRATION_EMAIL, payload)
  },
  [AUTH.ACTIONS.REGISTRATION]: async ({ dispatch, commit }, payload) => {
    try {
      const data = await callAuthGraphQL(dispatch, {
        requestData: { ...API.auth.registration, errorType: ERROR_TYPE.NONE },
        params: payload,
        throwError: true,
      })
      commit(AUTH.MUTATIONS.REGISTRATION, data)
      return true
    } catch {
      addErrorMessage(dispatch, API.auth.registration.id, 'flashMessages.registrationError')
      return false
    }
  },
  [AUTH.ACTIONS.ALLEGRO_REGISTER]: async ({ dispatch, commit }, payload) => {
    try {
      const data = await callPublicCpapiV2GraphQL(dispatch, {
        requestData: { ...API.auth.allegroRegistration, errorType: ERROR_TYPE.NONE },
        params: payload,
        throwError: true,
      })
      commit(AUTH.MUTATIONS.ALLEGRO_REGISTER, data)
      if (data.success) {
        addInfoMessage(dispatch, API.auth.allegroRegistration.id, 'flashMessages.allegroRegistration')
      } else {
        addErrorMessage(dispatch, API.auth.allegroRegistration.id, 'flashMessages.allegroRegistrationError')
      }
      return data
    } catch {
      addErrorMessage(dispatch, API.auth.allegroRegistration.id, 'flashMessages.allegroRegistrationError')
      return false
    }
  },
  [AUTH.ACTIONS.CONFIRM_REGISTRATION_REQUEST]: async ({ dispatch, commit }, payload) => {
    const data = await callAuthGraphQL(dispatch, {
      requestData: { ...API.auth.registrationConfirm, errorType: ERROR_TYPE.NONE },
      params: payload,
    })
    commit(AUTH.MUTATIONS.CONFIRM_REGISTRATION_RESPONSE, data)
  },
  [AUTH.ACTIONS.GET_PARTNER_STATUS_REQUEST]: async ({ dispatch, commit }, payload) => {
    try {
      const data = await callAuthGraphQL(dispatch, {
        requestData: { ...API.auth.partnerStatus, errorType: ERROR_TYPE.NONE },
        params: { token: payload.token },
        throwError: true,
      })
      commit(AUTH.MUTATIONS.GET_PARTNER_STATUS_RESPONSE, data)
    } catch (e) {
      const partnerIsDisabled = e.some(err => err.code === 'NO_ACTIVE_PARTNER')
      commit(AUTH.MUTATIONS.GET_PARTNER_STATUS_RESPONSE, {
        token: null,
        account: { hasPassword: false, isActive: false },
        partnerIsDisabled,
      })
    }
  },
  [AUTH.ACTIONS.ACL_GET_REQUEST]: async ({ dispatch, commit }, payload) => {
    try {
      const data = await callPortalGraphQL(dispatch, {
        requestData: { ...API.acl.get },
        params: {
          accountId: payload.accountId,
          partnerId: payload.partnerId,
        },
        throwError: true,
      })

      ability.update(data.map(item => ({ action: item.action, subject: item.subject })))
      commit(AUTH.MUTATIONS.ACL_RESPONSE, data)
    } catch (e) {
      logout(commit, dispatch)
    }
  },
  [AUTH.ACTIONS.GET_STREAM_TOKEN_REQUEST]: async ({ dispatch }) => {
    return await callCpapiV2GraphQL(dispatch, {
      requestData: { ...API.partner.getStreamToken },
      throwError: true,
    })
  },
  [AUTH.ACTIONS.LOGIN_TO_STREAM]: (_, payload) => {
    streamNC.connect(payload.streamToken)
  },
  [AUTH.ACTIONS.LOGOUT_FROM_STREAM]: () => {
    streamNC.disconnect()
  },
  // SELECTED PARTNER
  [AUTH.ACTIONS.UNIQUE_PUBLIC_TITLE_BY_PARTNER_REQUEST]: async ({ dispatch, state }, payload) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.partner.uniquePublicTitleByPartner },
      params: {
        partnerId: state.selectedPartner.id,
        data: {
          partnerName: payload.partnerName,
          shopId: state.selectedPartner.shopId,
        },
      },
      throwError: true,
    })
    return data.isUnique
  },
  [AUTH.ACTIONS.UPDATE_PARTNER_REQUEST]: async ({ dispatch, commit }, payload) => {
    try {
      const data = await callPortalGraphQL(dispatch, {
        requestData: { ...API.partner.updatePartner },
        params: {
          partnerId: payload.partnerId,
          data: { ...payload.data },
        },
        throwError: true,
      })
      commit(AUTH.MUTATIONS.UPDATE_PARTNER_RESPONSE, data)

      return true
    } catch {
      return false
    }
  },
  [AUTH.ACTIONS.UPDATE_ACCOUNT_REQUEST]: async ({ dispatch, commit }, payload) => {
    const data = await callPortalGraphQL(dispatch, {
      requestData: { ...API.account.update },
      params: {
        ...payload,
      },
    })

    commit(AUTH.MUTATIONS.UPDATE_ACCOUNT_RESPONSE, data)
  },
  [AUTH.ACTIONS.REVOKE_CLIENT_ID_TOKEN]: async ({ dispatch, commit }, payload) => {
    await callCpapiV2GraphQL(dispatch, {
      requestData: { ...API.partner.revokeClientIdToken },
      params: { ...payload },
      throwError: true,
    })
    commit(AUTH.MUTATIONS.REVOKE_CLIENT_ID_TOKEN, payload)
    return payload
  },
  [AUTH.ACTIONS.ADD_CLIENT_ID_TOKEN]: async ({ dispatch, commit }, payload) => {
    const data = await callCpapiV2GraphQL(dispatch, {
      requestData: { ...API.partner.addClientIdToken },
      throwError: true,
      params: { ...payload },
    })
    commit(AUTH.MUTATIONS.ADD_CLIENT_ID_TOKEN, data)
    return data
  },
  [AUTH.ACTIONS.RESET]: ({ commit }) => {
    commit(AUTH.MUTATIONS.RESET)
  },
}
