export default {
  checkLogged: {
    id: 'CHECK_LOGGED',
    request: ({ token }) => ({
      query: `
        query ($token: String!) {
          partner {
            logged (input: {token: $token}) {
              token
              refreshTime
              authToken
              adminEmail
              selectedPartnerId
              workflowRules
              account {
                id
                isConfirmed
                passwordValid
                firstName
                lastName
                email
                lang
                partners {
                  id
                  partnerName
                  mallName
                  sapId
                  provider
                  pluginProvider
                  rating
                  exportToFeed
                  exportToPPC
                  exportToAffiliate
                  kam
                  farmer
                  accountant
                  onboarding
                  mdpStatus
                  mallDelivery
                  billingStatus
                  shopId
                  urlSlug
                  clientIds {
                    clientId
                    validFrom
                    validTo
                  }
                  note
                  workflow {
                    status
                  }
                  partnerInfo {
                    carrierCloseTime
                    created
                    updated
                    postponementEnabled
                    codPaymentIsDisabled
                  }
                  transportV2
                }
              }
            }
          }
        }
       `,
      variables: {
        token,
      },
    }),
    reduce: data => {
      return data.partner.logged
    },
  },
  workflowRules: {
    id: 'WORKFLOW_RULES',
    request: () => ({
      query: `
        query {
          partner {
            workflowRules
          }
        }
      `,
    }),
    reduce: data => data.partner.workflowRules,
  },
  getClientId: {
    id: 'GET_CLIENT_ID',
    request: ({ partnerId }) => ({
      query: `
        query ($partnerId: String!) {
          partner {
            clientId(input: { partnerId: $partnerId })
          }
        }
      `,
      variables: {
        partnerId,
      },
    }),
    reduce: data => data.partner.clientId,
  },
  allegroRegistration: {
    id: 'ALLEGRO_REGISTRATION',
    request: data => ({
      query: `
          mutation ($data: AllegroPartnerRegistrationInputData!) {
            publicPartner {
              allegroRegistration(input: { data: $data }) {
                success
              }
            }
          }
        `,
      variables: {
        data,
      },
    }),
    reduce: data => data.publicPartner.allegroRegistration,
  },
  registration: {
    id: 'REGISTRATION',
    request: data => ({
      query: `
          mutation ($data: PartnerRegistrationInputData!) {
            partner {
              registration(input: { data: $data }) {
                success
                token
              }
            }
          }
        `,
      variables: {
        data,
      },
    }),
    reduce: data => data.partner.registration,
  },
  uniqueAccountEmail: {
    id: 'UNIQUE_ACCOUNT_EMAIL',
    request: ({ data }) => ({
      query: `
        query ($data: AccountEmailValidationInputData!) {
          validations {
            isAccountEmailUnique(input: { data: $data }) {
              isUnique
            }
          }
        }
      `,
      variables: {
        data,
      },
    }),
    reduce: data => {
      return data.validations.isAccountEmailUnique
    },
  },
  uniqueCompanyName: {
    id: 'UNIQUE_COMPANY_NAME',
    request: ({ partnerName, shopId }) => ({
      query: `
        query ($partnerName: String!, $shopId: String!) {
          validations {
            partnerPublicName(input: { data: { partnerName: $partnerName, shopId: $shopId } }) {
              isUnique
            }
          }
        }
      `,
      variables: {
        partnerName,
        shopId,
      },
    }),
    reduce: data => {
      return data.validations.partnerPublicName
    },
  },
  login: {
    id: 'LOGIN',
    request: ({ email, password }) => ({
      query: `
        mutation ($email: String!, $password: String!) {
          partner {
            login(input: { data: { email: $email, password: $password }}) {
              token
              refreshTime
              authToken
              adminEmail
              selectedPartnerId
              workflowRules
              account {
                id
                firstName
                lastName
                email
                lang
                partners {
                  id
                  partnerName
                  mallName
                  sapId
                  provider
                  pluginProvider
                  rating
                  exportToFeed
                  exportToPPC
                  exportToAffiliate
                  kam
                  farmer
                  accountant
                  onboarding
                  mdpStatus
                  mallDelivery
                  billingStatus
                  shopId
                  clientIds {
                    clientId
                    validFrom
                    validTo
                  }
                  urlSlug
                  note
                  workflow {
                    status
                  }
                  partnerInfo {
                    carrierCloseTime
                    created
                    updated
                    postponementEnabled
                    codPaymentIsDisabled
                  }
                  transportV2
                }
              }
            }
          }
        }
       `,
      variables: {
        email,
        password,
      },
    }),
    reduce: data => {
      return data.partner.login
    },
  },
  loginAsAdmin: {
    id: 'LOGIN_AS_ADMIN',
    request: ({ hash, token }) => ({
      query: `
        mutation ($hash: String!, $token: String!) {
          partner {
            loginAsAdmin(input: { data: { hash: $hash, token: $token }}) {
              token
              refreshTime
              authToken
              adminEmail
              selectedPartnerId
              workflowRules
              account {
                id
                firstName
                lastName
                email
                lang
                partners {
                  id
                  partnerName
                  sapId
                  provider
                  pluginProvider
                  rating
                  exportToFeed
                  exportToPPC
                  exportToAffiliate
                  kam
                  farmer
                  accountant
                  onboarding
                  mdpStatus
                  billingStatus
                  shopId
                  clientIds {
                    clientId
                    validFrom
                    validTo
                  }
                  urlSlug
                  workflow {
                    status
                  }
                  partnerInfo {
                    carrierCloseTime
                    created
                    updated
                    postponementEnabled
                    codPaymentIsDisabled
                  }
                  transportV2
                }
              }
            }
          }
        }
      `,
      variables: {
        hash,
        token,
      },
    }),
    reduce: data => {
      return data.partner.loginAsAdmin
    },
  },
  partnerStatus: {
    id: 'PARTNER_REGISTER_STATUS',
    request: ({ token }) => ({
      query: `
        mutation ($token: String!) {
          partner {
            loginWithToken(input: { data: { token: $token } }) {
              token
              account {
                isConfirmed
                hasPassword
              }
            }
          }
        }
       `,
      variables: {
        token,
      },
    }),
    reduce: data => {
      return data.partner.loginWithToken
    },
  },
  registrationConfirm: {
    id: 'REGISTRATION_CONFIRM',
    request: ({ token }) => ({
      query: `
          mutation ($token: String!) {
            partner {
              confirmedRegistration(input: {token: $token}) {
                token
                account {
                  id
                  email
                }
              }
            }
          }
        `,
      variables: {
        token,
      },
    }),
    reduce: data => {
      return data.partner.confirmedRegistration
    },
  },
  passwordSet: {
    id: 'PASSWORD_SET',
    request: data => ({
      query: `
          mutation ($password: String!, $token: String!) {
            partner {
              passwordSet(input: { token: $token, data: { password: $password }}) {
                success
              }
            }
          }
        `,
      variables: {
        ...data,
      },
    }),
    reduce: data => {
      return data.partner.passwordSet
    },
  },
  passwordReset: {
    id: 'PASSWORD_RESET',
    request: ({ email }) => ({
      query: `
          mutation ($email: String!) {
            partner {
              passwordReset(input: { data: { email: $email }}) {
                success
              }
            }
          }
        `,
      variables: {
        email,
      },
    }),
    reduce: data => {
      return data.partner.passwordReset
    },
  },
  registrationInfo: {
    id: 'REGISTRATION_INFO',
    request: () => ({
      query: `
          query {
            partner {
              registrationInfo {
                id
                created
                session
                source
                provider
                firstName
                lastName
                company
                street
                city
                zip
                country
                language
                web
                phone
                email
                in
                vatId
                annualTurnover
                salesCount
                offeredCount
                otherDivisions
                divisions
                estimatedSales
                countries
                ulozenka
                productFeed
                availabilityFeed
              }
            }
          }
        `,
    }),
    reduce: data => {
      return data.partner.registrationInfo
    },
  },
  logout: {
    id: 'LOGOUT',
    request: ({ token }) => ({
      query: `
          mutation ($token: String!){
            partner {
              logout(input: {token: $token}) {
                success
              }
            }
          }
        `,
      variables: {
        token,
      },
    }),
    reduce: data => {
      return data.partner.logout
    },
  },
}
