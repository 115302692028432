import { config } from '@/config'
import { LOCAL_STORAGE } from '@/enums'

export default () => ({
  allegroRegisterSuccess: false,
  token: localStorage.getItem(LOCAL_STORAGE.USER_TOKEN) || null,
  checkingAuth: false,
  account: null,
  clientId: null,
  registrationInfo: null,
  loginAsGivenPartner: null,
  passwordValid: false,
  partnerHasPassword: false,
  partnerIsActive: false,
  partnerIsDisabled: false,
  loginToken: null,
  passwordSet: false,
  registrationToken: null,
  regStep: 1,
  regEmail: '',
  regSuccess: false,
  verifyToken: null,
  refreshTime: config.checkLogged.refreshTime,
  checked: false,
  partners: [],
  selectedPartner: null,
  selectedPartnerId: null,
  acl: [],
  workflowRules: {},
  passwordReset: false,
})
