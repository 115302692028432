export const BRAND_ROUTES = {
  BRANDS_OVERVIEW: 'brands-tab',
  BRANDS_HISTORY: 'brands-tab-history',
}

export default [
  {
    path: '',
    name: BRAND_ROUTES.BRANDS_OVERVIEW,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "brands" */ '../../../views/app/brands/BrandsOverviewPage'
      ),
  },
  {
    path: 'history',
    name: BRAND_ROUTES.BRANDS_HISTORY,
    component: () =>
      import(
        /* webpackPrefetch: true */ /* webpackChunkName: "brands" */ '../../../views/app/brands/BrandsHistoryPage'
      ),
  },
]
