import { PRODUCT_STATUSES } from '@/enums'
import { revertPromotions } from '@/components/products/utils/Promotions'
import { revertLabels } from '@/components/products/utils/Label'

const getVariantIndex = (variantParams, parameters) => {
  const stayUnknown = variantParams.some(variantParam => variantParam && !parameters[variantParam])

  if (stayUnknown) {
    return 'unknown'
  } else {
    return `${variantParams[0]}-${variantParams[0] ? parameters[variantParams[0]] : ''}--${variantParams[1]}-${
      variantParams[1] ? parameters[variantParams[1]] : ''
    }`
  }
}

const getVariantTitle = (parentTitle, parameters, helperParams) => {
  let newTitle = parentTitle
  for (const parameter in parameters) {
    newTitle = newTitle.concat(
      `, ${parameters[parameter][0]}${helperParams[parameter].unit ? ' ' + helperParams[parameter].unit : ''}`,
    )
  }
  return newTitle
}

//fce returns basic data needed to generate variant
const getVariantData = (param1, param2, variantParams) => {
  const variant = {
    index: `${variantParams[0]}-${param1}--${variantParams[1]}-${param2}`,
    parameters: {},
  }

  if (variantParams[0]) {
    variant.parameters[variantParams[0]] = [param1]
  }
  if (variantParams[1]) {
    variant.parameters[variantParams[1]] = [param2]
  }
  return variant
}

export function convertVariantsForProductForm(variants = [], variable_parameters = []) {
  const variantParams = variable_parameters.sort()

  return variants.map(variant => {
    const parameters = typeof variant.parameters == 'object' ? variant.parameters : JSON.parse(variant.parameters)
    return { ...variant, index: getVariantIndex(variantParams, parameters), parameters }
  })
}

export function getParamsFromVariants(paramName, convertedVariants) {
  const parameters = convertedVariants?.flatMap(variant => variant.parameters[paramName]) || []
  return [...new Set(parameters.filter(parameter => parameter))]
}

export function regenerateVariants(form) {
  const convertedParameters = form.helper?.parameters || {}
  const convertedVariants = form.helper?.variants || []
  const variantParams = Object.keys(convertedParameters)
    .filter(paramName => convertedParameters[paramName].variantParam)
    .sort()
  const params1 = convertedParameters[variantParams[0]]?.values || []
  const params2 = convertedParameters[variantParams[1]]?.values || []
  const variantsToGenerate = []

  //loops defining which variants will be generated
  if (variantParams.length === 2 && params1.length && params2.length) {
    params1.forEach(param1 => {
      params2.forEach(param2 => {
        variantsToGenerate.push(getVariantData(param1, param2, variantParams))
      })
    })
  } else if (variantParams.length === 1 && params1.length) {
    params1.forEach(param1 => {
      variantsToGenerate.push(getVariantData(param1, '', variantParams))
    })
  }

  //generates new set of variants
  return variantsToGenerate.map(variantData => {
    const existingVariant = convertedVariants.find(variant => variant.index === variantData.index)
    if (existingVariant) {
      return existingVariant
    } else {
      return {
        index: variantData.index,
        title: getVariantTitle(form.title, variantData.parameters, form.helper.parameters),
        shortdesc: form.shortdesc,
        longdesc: form.longdesc,
        // price
        price: parseFloat(form.price),
        force_price: parseFloat(form.force_price) || null,
        rrp: form.rrp,
        promotions: [],
        labels: [],
        // images
        media: form.media.map(img => ({ ...img, excluded: true, main: false })),
        // structure
        id: '',
        barcode: form.barcode,
        priority: form.priority,
        // transport
        availability: {
          status: PRODUCT_STATUSES.ACTIVE,
          in_stock: null,
        },
        delivery_delay: form.delivery_delay,
        free_delivery: form.free_delivery,
        package_size: form.package_size,
        dimensions: form.dimensions,
        // parameters
        parameters: variantData.parameters,
        recommended: [],
      }
    }
  })
}

export function revertVariantsForProduct(convertedVariants, availableParameters) {
  return convertedVariants.map(convertedVariant => {
    // eslint-disable-next-line no-unused-vars
    const { index, force_price, fair_price, ...variant } = convertedVariant
    return {
      ...variant,
      delivery_delay: parseInt(variant.delivery_delay),
      dimensions: {
        weight: variant.dimensions.weight !== null ? parseFloat(variant.dimensions.weight) : null,
        width: variant.dimensions.width !== null ? parseFloat(variant.dimensions.width) : null,
        length: variant.dimensions.length !== null ? parseFloat(variant.dimensions.length) : null,
        height: variant.dimensions.height !== null ? parseFloat(variant.dimensions.height) : null,
      },
      availability: {
        status: variant.availability?.status || PRODUCT_STATUSES.ACTIVE,
        in_stock: variant.availability?.in_stock != null ? parseInt(variant.availability.in_stock) : null,
      },
      priority: parseInt(variant.priority),
      price: parseFloat(variant.price),
      promotions: revertPromotions(variant.promotions),
      labels: revertLabels(variant.labels),
      parameters: JSON.stringify(removeExcludedParameters(variant.parameters, availableParameters)),
      media: removeExcluded(variant.media),
    }
  })
}

export function removeExcluded(media) {
  return media
    .filter(img => img.excluded === false || img.excluded === undefined)
    .map(img => ({
      url: img.url,
      main: img.main,
      energy_label: img.energy_label,
      information_list: img.information_list,
    }))
}

export function removeExcludedParameters(variantParameters, availableParameters) {
  const parameters = {}

  Object.keys(variantParameters).forEach(param => {
    if (availableParameters.includes(param)) {
      parameters[param] = variantParameters[param]
    }
  })
  return parameters
}
