import { ORDERS } from '@/store/modules/orders/types'
import { resetState } from '@/store/utils'
import createState from '@/store/modules/orders/state'

export default {
  [ORDERS.MUTATIONS.GET_ORDER_RESPONSE]: (state, data) => {
    state.order = data
  },
  [ORDERS.MUTATIONS.ORDERS_STATUS_COUNT_RESPONSE]: (state, payload) => {
    state[payload.status] = payload.value
  },
  [ORDERS.MUTATIONS.ORDERS_MISSING_STATUS_COUNT_RESPONSE]: (state, payload) => {
    state.missingStatus = payload.value
  },
  [ORDERS.MUTATIONS.INVOICES_RESPONSE]: (state, payload) => {
    state.invoices = payload
  },
  [ORDERS.MUTATIONS.DOWNLOADING_DELIVERY_NOTE]: (state, payload) => {
    if (payload.status === true) {
      state.downloadingDeliveryNoteStatus.push(payload.id)
    } else {
      state.downloadingDeliveryNoteStatus = state.downloadingDeliveryNoteStatus.filter(id => id !== payload.id)
    }
  },
  [ORDERS.MUTATIONS.ORDERS_DASHBOARD_RESPONSE]: (state, payload) => {
    state.ordersDashboard = payload
  },
  [ORDERS.MUTATIONS.RESET]: state => {
    resetState(state, createState())
  },
}
