<template>
  <div>
    <small class="d-block text-left">{{ $t('registration.passwordStrength') }}</small>
    <div class="strength-meter-wrapper">
      <div :class="`strength-meter strength-meter-color-${(strength / stepMove)}`" :style="`width: ${strength}%`" />
    </div>
  </div>
</template>

<script>
import {
  atleastOneLowercaseChar,
  atleastOneSpecialChar,
  atleastOneNumber,
  atleastOneUppercaseChar,
  hasMinCharacters,
} from '@/validations/password'

const STEPS = 5

export default {
  name: 'PasswordStrengthBar',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      strength: 0,
    }
  },
  computed: {
    stepMove() {
      return 100 / STEPS
    },
  },
  watch: {
    value(val) {
      this.computeStrength(val)
    },
  },
  created() {
    this.computeStrength(this.value)
  },
  methods: {
    computeStrength(value) {
      this.strength = 0

      if (value === null || value === undefined || value === '') {
        return 0
      }

      if (atleastOneLowercaseChar(value)) {
        this.strength += this.stepMove
      }
      if (atleastOneUppercaseChar(value)) {
        this.strength += this.stepMove
      }
      if (atleastOneNumber(value)) {
        this.strength += this.stepMove
      }
      if (atleastOneSpecialChar(value)) {
        this.strength += this.stepMove
      }
      if (hasMinCharacters(value)) {
        this.strength += this.stepMove
      }
    },
  },
}
</script>

<style scoped lang="sass">
@import 'src/assets/styles/colors'

.strength-meter-wrapper
  position: relative
  width: 100%
  height: 8px
  border-radius: 4px
  background: $border-color

  .strength-meter
    height: 100%
    border-radius: 4px
    position: absolute
    left: 0
    top: 0
    transition: 250ms ease-in-out

    &.strength-meter-color-1
      background: $password-strength-1
    &.strength-meter-color-2
      background: $password-strength-2
    &.strength-meter-color-3
      background: $password-strength-3
    &.strength-meter-color-4
      background: $password-strength-4
    &.strength-meter-color-5
      background: $password-strength-5
</style>
