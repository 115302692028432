<template>
  <btn
    :class="activeClass"
    action="button.finish_workflow_steps"
    color="primary"
    @click="onClick"
  >
    <span>{{ $t('button.finish_workflow_steps', [workflowNumber, WORKFLOW_STEPS_COUNT]) }}</span>
  </btn>
</template>

<script>
import { WORKFLOW_STEPS_COUNT } from '@/enums'

export default {
  name: 'FinishWorkflowButton',
  props: {
    workflowNumber: {
      type: Number,
      required: true,
    },
    onClick: {
      type: Function,
      required: true,
    },
    activeClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      WORKFLOW_STEPS_COUNT,
    }
  },
}
</script>
