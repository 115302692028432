<template>
  <div>
    <help-hero :tracking-data="trackingData" :user-id="userId" />
    <hotjar :tracking-data="trackingData" :user-id="userId" />
  </div>
</template>

<script>
import HelpHero from '@/components/helpHero/HelpHero'
import Hotjar from '@/components/hotjar/Hotjar'

export default {
  name: 'Tracking',
  components: { Hotjar, HelpHero },
  props: {
    partner: {
      type: Object,
      required: true,
    },
    account: {
      type: Object,
      required: true,
    },
    mdp: {
      type: String,
      required: true,
    },
    billing: {
      type: String,
      required: true,
    },
  },
  computed: {
    userId() {
      return this.account.email
    },
    trackingData() {
      return {
        lang: this.account.lang,
        sapId: this.partner.sapId,
        shopId: this.partner.shopId,
        provider: this.partner.provider,
        workflowStatus: this.partner.workflow.status,
        mdp: this.mdp,
        billing: this.billing,
      }
    },
  },
}
</script>
