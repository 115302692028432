export const ANNUAL_TURNOVER = {
  FROM_0_TO_5: 'FROM_0_TO_5',
  FROM_5_TO_20: 'FROM_5_TO_20',
  FROM_20_TO_100: 'FROM_20_TO_100',
  FROM_100: 'FROM_100',
}

export const SALES_COUNT = {
  TO_50: 'TO_50',
  TO_100: 'TO_100',
  TO_500: 'TO_500',
  TO_1000: 'TO_1000',
  TO_2000: 'TO_2000',
  TO_5000: 'TO_5000',
  TO_10000: 'TO_10000',
  FROM_10000: 'FROM_10000',
}

export const PROVIDER_TYPES = {
  API: 'API',
  MANUAL: 'MANUAL',
  MALL_FEED: 'MALL_FEED',
  HEUREKA_FEED: 'HEUREKA_FEED',
}
