export const BRANDS = {
  NAMESPACE: 'brands',
  ACTIONS: {
    GET_BRANDS_REQUEST: 'GET_BRANDS_REQUEST',
    GET_BRANDS_HISTORY_REQUEST: 'GET_BRANDS_HISTORY_REQUEST',
    CREATE_BRANDS_REQUEST: 'CREATE_BRANDS_REQUEST',
    UPLOAD_LOGO_REQUEST: 'UPLOAD_LOGO_REQUEST',
    ADD_BRAND_ROW: 'ADD_BRAND_ROW',
    EDIT_BRAND_ROW: 'EDIT_BRAND_ROW',
    DELETE_BRAND_ROW: 'DELETE_BRAND_ROW',
    SEARCH_BRANDS: 'SEARCH_BRANDS',
    FILTER_BRANDS: 'FILTER_BRANDS',
    TOGGLE_EXPAND: 'TOGGLE_EXPAND',
    RESET_BRAND_REQUESTS: 'RESET_BRAND_REQUESTS',
    CREATE_SINGLE_BRAND_REQUEST: 'CREATE_SINGLE_BRAND_REQUEST',
    ADD_SINGLE_BRAND: 'ADD_SINGLE_BRAND',
    RESET: 'RESET',
  },
  MUTATIONS: {
    GET_BRANDS_RESPONSE: 'GET_BRANDS_RESPONSE',
    GET_BRANDS_HISTORY_RESPONSE: 'GET_BRANDS_HISTORY_RESPONSE',
    ADD_BRAND_ROW: 'ADD_BRAND_ROW',
    EDIT_BRAND_ROW: 'EDIT_BRAND_ROW',
    DELETE_BRAND_ROW: 'DELETE_BRAND_ROW',
    RESET_BRAND_REQUESTS: 'RESET_BRAND_REQUESTS',
    SEARCH_BRANDS: 'SEARCH_BRANDS',
    FILTER_BRANDS: 'FILTER_BRANDS',
    TOGGLE_EXPAND: 'TOGGLE_EXPAND',
    SIMILAR_BRANDS: 'SIMILAR_BRANDS',
    UPLOAD_LOGO_RESPONSE: 'UPLOAD_LOGO_RESPONSE',
    ADD_SINGLE_BRAND: 'ADD_SINGLE_BRAND',
    RESET: 'RESET',
  },
}
