<template>
  <div class="full-container">
    <loader />
  </div>
</template>

<script>
import Loader from '@/components/commons/loader/Loader'
export default {
  name: 'AuthLoading',
  components: { Loader },
}
</script>

<style scoped lang="sass">
.full-container
  height: 100vh
  width: 100%
  position: absolute
  z-index: 99
  background: rgba(255, 255, 255, 0.8)
  display: flex
  justify-content: center
  align-items: center
</style>
