import { ORDER_STATUSES } from '@/enums'

export default () => ({
  order: null,
  [ORDER_STATUSES.OPEN]: false,
  [ORDER_STATUSES.SHIPPING]: false,
  [ORDER_STATUSES.SHIPPED]: false,
  [ORDER_STATUSES.DELIVERED]: false,
  missingStatus: false,
  invoices: [],
  downloadingDeliveryNoteStatus: [],
  ordersDashboard: null,
})
