export default {
  getSettings: {
    id: 'GET_SETTINGS',
    request: ({ partnerId }) => ({
      query: `
        query ($partnerId: String!) {
          notification {
            eventSettingsList(input: { partnerId: $partnerId }) {
              partnerId
              groups
              contacts {
                email
                phone
              }
              eventType
              disabled
              active
            }
          }
        }
      `,
      variables: {
        partnerId,
      },
    }),
    reduce: data => {
      return data.notification.eventSettingsList
    },
  },
  updateSettings: {
    id: 'UPDATE_SETTINGS',
    request: ({ partnerId, eventType, data }) => ({
      query: `
        mutation (
          $partnerId: String!
          $eventType: String!
          $data: EventSettingsUpdateInputData!
        ) {
          notification {
            eventSettingsUpdate(
              input: { partnerId: $partnerId, eventType: $eventType, data: $data }
            ) {
              partnerId
              groups
              contacts {
                email
                phone
              }
              eventType
              disabled
              active
            }
          }
        }
      `,
      variables: {
        partnerId,
        eventType,
        data,
      },
    }),
    reduce: data => {
      return data.notification.eventSettingsUpdate
    },
  },
}
