import createState from '@/store/modules/public/state'
import actions from '@/store/modules/public/actions'
import mutations from '@/store/modules/public/mutations'

export default {
  namespaced: true,
  state: createState(),
  actions,
  mutations,
}
